<template>
  <v-menu bottom offset-y :close-on-content-click="false">
    <template #activator="{ on: onMenu, attrs: attrsMenu }">
      <v-tooltip top>
        <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
          <v-btn
            x-small
            text
            v-bind="{ ...attrsTooltip, ...attrsMenu }"
            v-on="{ ...onTooltip, ...onMenu }"
          >
            <v-icon>mdi-folder-multiple-image</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("components.fileMenu.fileTypes.title") }}</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item-group
        :value="value"
        @change="handleMenuItemSelected"
        multiple
      >
        <v-list-item
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          <template #default="{ active }">
            <v-list-item-avatar size="50">
              <v-img contain max-height="30" :src="option.iconSrc"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ option.text }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: Array,
    options: Array,
  },
  emits: ["input"],
  methods: {
    handleMenuItemSelected(value) {
      this.$emit("input", value);
    },
  },
};
</script>
