<template>
  <div>
    <h4 class="text-h4">
      {{ $t("pages.featureRequest.confirmation.title") }}
    </h4>
    <v-spacer class="mb-4"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col col="12" lg="8">
          <v-card elevation="1">
            <v-card-text>
              <div class="text-center">
                <v-icon class="text-h2" color="yellow darken-2"
                  >mdi-alert-circle-outline</v-icon
                >
              </div>
              <p class="text-h4 text--primary text-center">
                {{ $t("pages.featureRequest.confirmation.alreadyResponded") }}
              </p>
              <i18n
                path="pages.featureRequest.confirmation.alreadyAction"
                tag="p"
                class="text-body-1"
              >
                <span
                  class="text--darken-2"
                  :class="
                    responseType === 'Accept' ? 'green--text' : 'red--text'
                  "
                >
                  {{
                    responseType === "Accept"
                      ? $t("pages.featureRequest.confirmation.accepted")
                      : $t("pages.featureRequest.confirmation.rejected")
                  }}.
                </span>
              </i18n>
              <p class="text-body-1">
                <b>{{
                  $t("pages.featureRequest.confirmation.primeUserMail")
                }}</b>
                {{ contactEmail }}
              </p>
              <p class="text-body-1">
                {{ $t("pages.featureRequest.confirmation.closeWindow") }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";

export default {
  layout: appLayout({ title: "pages.featureRequest.confirmation.title" }),
  props: {
    responseType: String,
    contactEmail: String,
  },
};
</script>
