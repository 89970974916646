<template>
  <v-dialog
    style="z-index: 20001"
    v-model="show"
    scrollable
    max-width="80vw"
    hide-overlay
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-filter</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("components.attributes.filters.filter") }}
          </p>
        </div>
      </v-btn>
    </template>
    <v-card height="70vh" class="base-background-color">
      <v-card-title>{{
        $t("components.attributes.filters.title")
      }}</v-card-title>
      <v-card-text style="height: 300px">
        <v-row
          v-for="(attributeFilter, index) in form.attributeFilters"
          :key="index"
          class="black--text"
        >
          <v-col>
            <div>
              {{ $t("components.attributes.filters.fields.attribute.label") }}
            </div>
            <v-autocomplete
              dense
              v-model="attributeFilter.attributeId"
              :items="availableAttributes"
              :error-messages="form.errors[index]"
              :placeholder="
                $t('components.attributes.filters.fields.attribute.placeholder')
              "
              hide-selected
              item-text="name"
              item-value="id"
              solo
            >
            </v-autocomplete>
          </v-col>

          <v-col>
            <div>
              {{ $t("components.attributes.filters.fields.filter.label") }}
            </div>
            <v-select
              dense
              v-model="attributeFilter.filter"
              :items="filterOptions"
              :error-messages="form.errors[index + '-data']"
              :placeholder="
                $t('components.attributes.filters.fields.filter.placeholder')
              "
              item-value="value"
              solo
              clearable
            >
              <template v-slot:item="{ item }">
                <v-icon class="black--text mr-2">{{ item.icon }}</v-icon>
                <p class="black--text mb-0">{{ item.text }}</p>
              </template>
              <template v-slot:selection="{ item }">
                <v-icon class="black--text mr-2">{{ item.icon }}</v-icon>
                <p class="black--text mb-0">{{ item.text }}</p>
              </template>
            </v-select>
          </v-col>

          <v-col>
            <div>{{ $t("components.attributes.filters.fields.filterBy") }}</div>
            <v-text-field
              type="text"
              v-model="attributeFilter.filterBy"
              solo
              dense
            />
          </v-col>

          <v-col>
            <div>{{ $t("components.attributes.filters.fields.sorting") }}</div>
            <v-select
              dense
              v-model="attributeFilter.sortDesc"
              :error-messages="form.errors[index + '-data']"
              :items="sortingOptions"
              item-text="text"
              item-value="value"
              solo
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-btn color="blue darken-1" text @click="addAttributeFilter()">
            <v-icon class="black--text">mdi-plus</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("components.attributes.filters.addAnother") }}
            </p>
          </v-btn>
        </v-row>

        <v-row class="black--text">
          <v-col>{{
            $t("components.attributes.filters.appliedFilters")
          }}</v-col>
        </v-row>

        <v-col
          v-for="(attributeFilter, index) in form.appliedAttributeFilters"
          :key="index + '-applied'"
          class="black--text"
        >
          <v-divider></v-divider>
          <v-row no-gutters justify="space-between">
            <v-col>
              {{
                attributeFilter.attributeId != null
                  ? attributes.find((x) => x.id == attributeFilter.attributeId)
                      .name
                  : propertyOptions.find(
                      (x) => x.value == attributeFilter.propertyName
                    ).text
              }}
            </v-col>
            <v-col v-if="attributeFilter.filter != null">
              <v-icon class="black--text">{{
                filterOptions.find((x) => attributeFilter.filter == x.value)
                  .icon
              }}</v-icon>
              {{
                filterOptions.find((x) => attributeFilter.filter == x.value)
                  .text
              }}
            </v-col>
            <v-col v-else />
            <v-col>
              {{ attributeFilter.filterBy }}
            </v-col>
            <v-col>
              {{
                sortingOptions.find((x) => x.value == attributeFilter.sortDesc)
                  .text
              }}
            </v-col>
            <v-icon
              @click="removeAppliedAttributeFilter(attributeFilter)"
              class="black--text"
              >mdi-close</v-icon
            >
          </v-row>
          <v-divider></v-divider>
        </v-col>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row justify="space-between">
          <v-btn
            color="blue darken-1"
            text
            @click="removeAllAttributeFilters()"
          >
            <v-icon class="black--text">mdi-trash-can-outline</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("components.attributes.filters.removeAllFilters") }}
            </p>
          </v-btn>
          <v-row justify="end">
            <v-alert dense v-if="form.errors.alert" type="error" class="mb-0">
              {{ form.errors.alert }}
            </v-alert>
            <v-btn color="blue darken-1" text @click="show = false">
              <v-icon class="black--text">mdi-close</v-icon>
              <p class="teal--text text--darken-4 mb-0">
                {{ $t("common.actions.close") }}
              </p>
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="applyAttributeFilter()"
              :disabled="loading"
            >
              <v-icon class="black--text">mdi-content-save-outline</v-icon>
              <p class="teal--text text--darken-4 mb-0">
                {{ $t("common.actions.apply") }}
              </p>
            </v-btn>
          </v-row>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    attributeFilters: Array,
    attributes: Array,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    availableAttributes() {
      return this.attributes.map((x) => {
        x.disabled =
          this.form.appliedAttributeFilters.some(
            (filter) => filter.attributeId == x.id
          ) ||
          this.form.attributeFilters.some(
            (filter) => filter.attributeId == x.id
          );
        return x;
      });
    },
  },
  data() {
    return {
      loading: false,
      form: this.$inertia.form({
        attributeFilters: [],
        appliedAttributeFilters: this.attributeFilters,
      }),
      search: "",
      propertyOptions: [
        {
          text: this.$t("common.fieldNames.id"),
          value: "referenceKey",
        },
        {
          text: this.$t("common.dataTable.locationType"),
          value: "locationType",
        },
        {
          text: this.$t("common.dataTable.solutionType"),
          value: "solutionType",
        },
      ],
      sortingOptions: [
        {
          text: this.$t("common.sorting.ascending"),
          value: false,
        },
        {
          text: this.$t("common.sorting.descending"),
          value: true,
        },
        {
          text: this.$t("common.sorting.noSorting"),
          value: null,
        },
      ],
      filterOptions: [
        {
          text: this.$t("common.filters.include"),
          value: "Include",
          icon: "mdi-alphabetical-variant",
        },
        {
          text: this.$t("common.filters.notInclude"),
          value: "NotInclude",
          icon: "mdi-alphabetical-variant-off",
        },
        {
          text: this.$t("common.filters.startsWith"),
          value: "Starts",
          icon: "mdi-format-letter-starts-with",
        },
        {
          text: this.$t("common.filters.endsWith"),
          value: "Ends",
          icon: "mdi-format-letter-ends-with",
        },
        {
          text: this.$t("common.filters.equalTo"),
          value: "Equal",
          icon: "mdi-equal",
        },
        {
          text: this.$t("common.filters.notEqualTo"),
          value: "NotEqual",
          icon: "mdi-not-equal-variant",
        },
        {
          text: "",
          value: "Range",
          icon: "",
        },
      ],
    };
  },
  watch: {
    attributeFilters() {
      this.form.appliedAttributeFilters = this.attributeFilters;
    },
  },
  methods: {
    changeAttributeFilters() {
      this.$emit("changeAttributeFilters", this.form.appliedAttributeFilters);
      this.show = false;
    },
    removeAttributeFilter(option) {
      this.form.attributeFilters = this.form.attributeFilters.filter(
        (x) => x !== option
      );
    },
    removeAppliedAttributeFilter(option) {
      this.form.appliedAttributeFilters =
        this.form.appliedAttributeFilters.filter((x) => x !== option);
    },
    removeAllAttributeFilters() {
      this.form.attributeFilters = [];
      this.form.appliedAttributeFilters = [];
      this.changeAttributeFilters();
    },
    addAttributeFilter() {
      this.form.attributeFilters.push({ sortDesc: null });
    },
    applyAttributeFilter() {
      this.form.errors = {};
      this.form.attributeFilters.forEach((filter, index) => {
        if (filter.attributeId == null) {
          this.form.errors[index] = this.$t(
            "components.attributes.filters.errors.noAttribute"
          );
        } else if (filter.filter == null && filter.sortDesc == null) {
          this.form.errors[index + "-data"] = this.$t(
            "components.attributes.filters.errors.filteringOrSorting"
          );
        }
      });
      if (Object.keys(this.form.errors).length) {
        return;
      }

      this.form.attributeFilters.forEach((x) => {
        x.attribute = this.attributes.find(
          (attribute) => attribute.id == x.attributeId
        );
      });

      this.form.appliedAttributeFilters =
        this.form.appliedAttributeFilters.concat(this.form.attributeFilters);

      this.form.appliedAttributeFilters.forEach((x, index) => {
        x.sortOrder = index;
      });

      this.form.attributeFilters = [];
      this.changeAttributeFilters();
    },
  },
};
</script>
<style scoped>
.solo-item-list-area {
  height: 30vh;
  overflow-y: auto;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  background-origin: padding-box;
  background-clip: border-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.no-gutters {
  margin: 0;
  padding: 0;
}
</style>
