<template>
  <v-tab-item>
    <v-card flat>
      <v-card-text style="min-height: 25rem">
        <v-row>
          <v-col cols="6">
            <attribute-link-input
              :attribute="startDateAttribute"
              :value="startDateData"
              @input="handleStartDateChanged"
              @dateTime_changed="updateDateValue(startDateAttribute, $event)"
            />
            <attribute-link-input
              :attribute="endDateAttribute"
              :value="endDateData"
              :disabledDate="(date) => date < startDateData"
              @input="updateData(fixedAttributes.slaEndDateId, $event)"
              @dateTime_changed="updateDateValue(endDateAttribute, $event)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>
<script>
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import { fixedAttributes } from "../../../util/fixedSolutionData";
import { normalizeISOString } from "../../../util/dateTime";
import { DateTime } from "luxon";

export default {
  components: { AttributeLinkInput },
  props: {
    solution: Object,
    attributes: Array,
    attributeLinkData: Object,
  },
  emits: ["updateForm"],
  data() {
    return {
      fixedAttributes,
    };
  },
  computed: {
    startDateAttribute() {
      return this.getAttribute(fixedAttributes.slaStartDateId);
    },
    endDateAttribute() {
      return this.getAttribute(fixedAttributes.slaEndDateId);
    },
    startDateData() {
      return this.attributeLinkData[fixedAttributes.slaStartDateId];
    },
    endDateData() {
      return this.attributeLinkData[fixedAttributes.slaEndDateId];
    },
  },
  methods: {
    getAttribute(attributeId) {
      return this.attributes?.find((x) => x.id === attributeId) ?? null;
    },
    formatDate(date) {
      if (!date) return "";

      const normalizedDate = normalizeISOString(date);

      return DateTime.fromISO(normalizedDate).toFormat("yyyy-MM-dd");
    },
    updateDateValue(attribute, value) {
      this.$emit("dateTime_changed", value, attribute);
    },
    updateData(attributeId, value) {
      this.$emit("attribute_link_data_changed", attributeId, value);
    },
    handleStartDateChanged(date) {
      if (date > this.endDateData) {
        this.updateData(fixedAttributes.slaEndDateId, null);
        this.updateDateValue(this.endDateAttribute, null);
      }

      this.updateData(fixedAttributes.slaStartDateId, date);
    },
  },
};
</script>
