var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"grey--text",attrs:{"cols":"12","sm":"9"}},[(_vm.currentAttributeFilters.length != 0 && _vm.allResults)?_c('div',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$t("components.attributes.filterStatusLabels.appliedFilters")))]),_c('i18n',{staticClass:"mx-2",attrs:{"path":"components.attributes.filterStatusLabels.searchingEntries","tag":"span"},scopedSlots:_vm._u([{key:"entries",fn:function(){return [_vm._v(" "+_vm._s(_vm.entryTranslation)+" ")]},proxy:true}],null,false,3356494205)},[_c('strong',[_vm._v(_vm._s(_vm.$t("components.attributes.filterStatusLabels.searchCount", { filteredCount: _vm.resultsFilteredCount, originalCount: _vm.resultsOriginalCount, })))])]),_c('a',{staticClass:"black--text text-decoration-underline ml-6",on:{"click":function($event){return _vm.$emit('allResultsUpdated', false)}}},[_vm._v(" "+_vm._s(_vm.$t( "components.attributes.filterStatusLabels.clickSearchFilteredEntries", { entries: _vm.entryTranslation } ))+" ")])],1):(_vm.currentAttributeFilters.length != 0)?_c('div',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$t("components.attributes.filterStatusLabels.appliedFilters")))]),_c('i18n',{staticClass:"mx-2",attrs:{"path":"components.attributes.filterStatusLabels.searchingEntries","tag":"span"},scopedSlots:_vm._u([{key:"entries",fn:function(){return [_vm._v(" "+_vm._s(_vm.entryTranslation)+" ")]},proxy:true}])},[_c('strong',[_vm._v(_vm._s(_vm.$t("components.attributes.filterStatusLabels.searchCount", { filteredCount: _vm.resultsFilteredCount, originalCount: _vm.resultsOriginalCount, })))])]),_c('a',{staticClass:"black--text text-decoration-underline ml-6",on:{"click":function($event){return _vm.$emit('allResultsUpdated', true)}}},[_vm._v(" "+_vm._s(_vm.$t("components.attributes.filterStatusLabels.clickSearchAllEntries", { entries: _vm.entryTranslation, }))+" ")])],1):_vm._e(),(
      _vm.currentLayout.attributeIds.length != _vm.attributes.length &&
      _vm.allAttributes &&
      _vm.hasSearched
    )?_c('div',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$t("components.attributes.filterStatusLabels.hiddenAttributes")))]),_c('i18n',{staticClass:"mx-2",attrs:{"path":"components.attributes.filterStatusLabels.searchingAttributes","tag":"span"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("components.attributes.filterStatusLabels.searchCount", { filteredCount: _vm.attributes.length, originalCount: _vm.attributes.length, })))])]),_c('a',{staticClass:"black--text text-decoration-underline ml-6",on:{"click":function($event){return _vm.$emit('allAttributesUpdated', false)}}},[_vm._v(" "+_vm._s(_vm.$t( "components.attributes.filterStatusLabels.clickSearchShownAttributes" ))+" ")])],1):(
      _vm.currentLayout.attributeIds.length != _vm.attributes.length && _vm.hasSearched
    )?_c('div',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$t("components.attributes.filterStatusLabels.hiddenAttributes")))]),_c('i18n',{staticClass:"mx-2",attrs:{"path":"components.attributes.filterStatusLabels.searchingAttributes","tag":"span"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("components.attributes.filterStatusLabels.searchCount", { filteredCount: _vm.currentLayout.attributeIds.length, originalCount: _vm.attributes.length, })))])]),_c('a',{staticClass:"black--text text-decoration-underline ml-6",on:{"click":function($event){return _vm.$emit('allAttributesUpdated', true)}}},[_vm._v(" "+_vm._s(_vm.$t( "components.attributes.filterStatusLabels.clickSearchAllAttributes" ))+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }