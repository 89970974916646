<template>
  <div>
    <h4 class="text-h4">{{ $t("pages.supportCases.form.title") }}</h4>
    <v-spacer class="mb-6"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col md="8" lg="6">
          <form @submit.prevent="submit">
            <v-card elevation="1">
              <v-card-text>
                <v-row no-gutters class="align-start mt-4">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.supportCases.form.fields.subject")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      name="name"
                      type="text"
                      :error-messages="form.errors.name"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start mt-4">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.supportCases.form.fields.organization")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-autocomplete
                      v-model="form.organizationId"
                      :items="organizations"
                      item-text="name"
                      item-value="id"
                      clearable
                      name="organizations"
                      :label="$t('pages.supportCases.form.fields.organization')"
                      :error-messages="form.errors.organizationId"
                      @change="handleOrganizationChanged"
                      @input="form.organizationId = $event ? $event : null"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start mt-4">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.supportCases.form.fields.location")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <location-picker
                      :locations="locations"
                      v-model="form.locationId"
                      clearable
                      name="locations"
                      :label="$t('pages.supportCases.form.fields.location')"
                      :error-messages="form.errors.locationId"
                      @change="handleLocationChanged"
                      @input="form.locationId = $event ? $event : null"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start mt-4">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.supportCases.form.fields.solution")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      :items="solutionList"
                      v-model="form.solutionId"
                      @input="form.solutionId = $event ? $event : null"
                      name="solutions"
                      :label="$t('pages.supportCases.form.fields.solution')"
                      :error-messages="form.errors.solutionId"
                      item-text="name"
                      item-value="id"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start mt-4">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.supportCases.form.fields.region")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      :items="regions"
                      v-model="form.regionId"
                      @input="form.regionId = $event ? $event : null"
                      clearable
                      name="regions"
                      :label="$t('pages.supportCases.form.fields.region')"
                      :error-messages="form.errors.regionId"
                      item-text="name"
                      item-value="id"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.supportCases.form.fields.start")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-menu
                      v-model="manualStartedAtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :error="form.errors.manualStartedAt"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.manualStartedAt"
                          append-icon="mdi-calendar"
                          @click:append="manualStartedAtMenu = true"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="form.errors.manualStartedAt"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.manualStartedAt"
                        @input="manualStartedAtMenu = false"
                        :max="currentDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.supportCases.form.fields.closed")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-menu
                      v-model="manualClosedAtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.manualClosedAt"
                          append-icon="mdi-calendar"
                          @click:append="manualClosedAtMenu = true"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error="form.errors.manualClosedAt"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.manualClosedAt"
                        @input="manualClosedAtMenu = false"
                        :max="currentDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start mt-4">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.supportCases.form.fields.rootCause")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-autocomplete
                      v-model="form.rootCause"
                      :items="formattedRootCauses"
                      :error-messages="form.errors.rootCause"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  outlined
                  color="primary"
                  :disabled="form.processing"
                  >{{ $t("pages.supportCases.form.save") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";
import LocationPicker from "@/Components/LocationPicker";

export default {
  layout: appLayout({ title: "pages.supportCases.form.pageTitle" }),
  components: { LocationPicker },
  props: {
    solutions: Array,
    locations: Array,
    regions: Array,
    rootCauses: Array,
    organizations: Array,
  },
  data() {
    const params = new URLSearchParams(window.location.search);

    return {
      form: this.$inertia.form({
        name: null,
        locationId: params.get("locationId"),
        regionId: null,
        solutionId: null,
        organizationId: null,
        manualStartedAt: this.currentDate(),
        manualClosedAt: null,
        rootCause: null,
      }),
      manualStartedAtMenu: false,
      manualClosedAtMenu: false,
    };
  },
  computed: {
    solutionList() {
      const otherOption = {
        id: null,
        name: this.$t("pages.supportCases.form.other"),
      };

      if (!this.solutions) {
        return [otherOption];
      } else {
        return [...this.solutions, otherOption];
      }
    },
    formattedRootCauses() {
      return this.rootCauses.map((rootCause) => {
        rootCause = this.$t(`common.rootCauses.${rootCause}`);

        const item = {
          text: null,
          value: rootCause,
        };

        const issueCauses = [
          this.$t("common.rootCauses.Other"),
          this.$t("common.rootCauses.Installation"),
        ];

        if (issueCauses.includes(rootCause)) {
          item.text = this.$t("pages.supportCases.form.issue", {
            type: rootCause,
          });
        } else if (rootCause === this.$t("common.rootCauses.Spam")) {
          item.text = rootCause;
        } else {
          item.text = this.$t("pages.supportCases.form.relatedIssue", {
            type: rootCause,
          });
        }

        return item;
      });
    },
  },
  methods: {
    handleOrganizationChanged() {
      this.form.locationId = null;
      this.form.solutionId = null;
      this.form.regionId = null;

      this.selectItem();
    },
    handleLocationChanged() {
      this.form.solutionId = null;
      this.form.regionId = null;

      this.selectItem();
    },
    selectItem() {
      let reloadProps = ["organizations"];

      if (this.form.organizationId && !this.form.locationId) {
        reloadProps.push("locations");
      }

      if (this.form.locationId) {
        reloadProps.push("solutions", "regions");
      }

      if (this.form.organizationId === null) {
        this.form.locationId = null;
      }

      if (this.form.locationId === null) {
        this.form.solutionId = null;
        this.form.regionId = null;
      }

      this.$inertia.get(
        this.route("support-cases.create", {
          organizationId: this.form.organizationId,
          locationId: this.form.locationId,
        }),
        {},
        { only: reloadProps, preserveState: true }
      );
    },
    submit() {
      this.form.post(this.route("support-cases.store"), {
        only: ["solutions", "regions"],
      });
    },
    clearField(fieldName) {
      this.form[fieldName] = null;
    },
    currentDate() {
      const isoDate = new Date().toISOString();

      return isoDate.split("T")[0];
    },
    reloadWithData() {
      this.$inertia.reload({
        only: ["organizations", "locations", "solutions", "regions"],
      });
    },
  },
  created() {
    this.reloadWithData();
  },
};
</script>
