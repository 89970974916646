const locationImportFields = {
  locationType: "LOCATIONTYPE",
  description: "DESCRIPTION",
  locationNumber: "locationNumber",
  email: "EMAIL",
  phoneNumber: "PHONENUMBER",
  country: "COUNTRY",
  address1: "ADDRESS1",
  address2: "ADDRESS2",
  city: "CITY",
  postCode: "POSTCODE",
  state: "STATE",
  latitude: "LATITUDE",
  longitude: "LONGITUDE",
};

export { locationImportFields };
