<template>
  <div class="fill-height" style="min-height: 60vh">
    <v-row>
      <v-col cols="4">
        <div class="mb-4">
          <strong> {{ $t("pages.assets.bulkEdit.newValue") }} </strong>
        </div>
        <asset-attribute-link-input
          :attribute="attribute"
          :currencies="currencies"
          :value="value"
          :secondValue="secondValue"
          :integerInput="integerInput"
          :numberMinValue="numberMinValue"
          :numberMaxValue="numberMaxValue"
          @bool_changed="handleValueChanged('boolValue', $event)"
          @currency_changed="handleValueChanged('decimalValue', $event)"
          @currencyCode_changed="handleValueChanged('stringValue', $event)"
          @dateTime_changed="handleValueChanged('dateTimeValue', $event)"
          @decimal_changed="handleValueChanged('decimalValue', $event)"
          @file_changed="handleValueChanged('newFiles', $event)"
          @select_changed="
            handleValueChanged('attributeSelectOptionId', $event)
          "
          @string_changed="handleValueChanged('stringValue', $event)"
          hideLabel
        />
        <p class="mb-0 mt-1 grey--text">
          {{
            $t("pages.assets.bulkEdit.attributeFormat", {
              format: attribute
                ? $t(`common.attributeFormats.${attribute.format}`)
                : "N/A",
            })
          }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AssetAttributeLinkInput from "../../Attribute/AttributeLinkInput.vue";
import { fixedAttributes } from "../../../util/fixedAssetData";

export default {
  components: { AssetAttributeLinkInput },
  props: {
    attribute: Object,
    currencies: Array,
    stringValue: String,
    decimalValue: Number,
    dateTimeValue: Date,
    boolValue: Boolean,
    attributeSelectOptionId: String,
    newFiles: FileList,
  },
  emits: ["updateForm"],
  computed: {
    value() {
      switch (this.attribute?.format) {
        case "Text":
          return this.stringValue;
        case "TextArea":
          return this.stringValue;
        case "Select":
          return this.attributeSelectOptionId;
        case "Number":
        case "Percentage":
        case "Currency":
          return this.decimalValue;
        case "Date":
        case "DateTime":
          return this.dateTimeValue;
        case "Boolean":
          return this.boolValue;
        case "File":
          return this.newFiles;
        default:
          return null;
      }
    },
    secondValue() {
      switch (this.attribute?.format) {
        case "Currency":
          return this.stringValue;
        default:
          return null;
      }
    },
    integerInput() {
      switch (this.attribute?.id) {
        case fixedAttributes.fixedAssetsDepreciationPeriodId:
          return true;
        default:
          return undefined;
      }
    },
    numberMinValue() {
      switch (this.attribute?.id) {
        case fixedAttributes.fixedAssetsDepreciationPeriodId:
          return 1;
        default:
          return undefined;
      }
    },
    numberMaxValue() {
      switch (this.attribute?.id) {
        case fixedAttributes.fixedAssetsDepreciationPeriodId:
          return 1000;
        default:
          return undefined;
      }
    },
  },
  methods: {
    handleValueChanged(property, value) {
      this.$emit("updateForm", property, value);
    },
  },
};
</script>
