<template>
  <v-card elevation="1" class="pb-2">
    <v-card-title class="headline">Preview</v-card-title>
    <div
      v-bind:style="{ backgroundColor: value.color2 }"
      class="preview-container"
    >
      <div class="header" :style="{ backgroundColor: value.color2 }">
        <div style="color: white; margin-right: auto">Prime</div>
        <v-icon dark class="pr-4">mdi-camera</v-icon>
        <v-icon dark>mdi-menu</v-icon>
      </div>
      <div v-bind:style="{ backgroundColor: value.color7 }" class="app-bar">
        {{ new Date().toJSON().split("T")[0] }}
      </div>
      <div
        v-bind:style="{ backgroundColor: value.color3 }"
        class="name-container"
      >
        <div>Welcome</div>
        <img v-if="value.logo" :src="logoPreview" style="width: 60px" />
        <img
          v-else-if="value.logoFile"
          :src="
            route('api.files.content', {
              id: value.logoFile?.id,
              filename: value.logoFile?.originalName,
            })
          "
          style="width: 60px"
        />
        <div>{{ value.name }}</div>
      </div>
      <div v-bind:style="{ backgroundColor: value.color8 }" class="menu-item">
        <div>Stores</div>
        <v-icon dark>mdi-chevron-right</v-icon>
      </div>
      <div v-bind:style="{ backgroundColor: value.color7 }" class="menu-item">
        <div>Solutions</div>
        <v-icon dark>mdi-chevron-right</v-icon>
      </div>
      <div v-bind:style="{ backgroundColor: value.color6 }" class="menu-item">
        <div>Support cases</div>
        <v-icon dark>mdi-chevron-right</v-icon>
      </div>
      <div v-bind:style="{ backgroundColor: value.color5 }" class="menu-item">
        <div>Smart office</div>
        <v-icon dark>mdi-chevron-right</v-icon>
      </div>
      <div v-bind:style="{ backgroundColor: value.color4 }" class="menu-item">
        <div>Service desk</div>
        <v-icon dark>mdi-chevron-right</v-icon>
      </div>
      <div class="footer">
        <div>Encountered an error? Let us know!</div>
        <div>About your privacy on this page.</div>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  props: ["value"],
  computed: {
    logoPreview() {
      if (this.value.logo != null) {
        return URL.createObjectURL(this.value.logo);
      }
      return "";
    },
  },
};
</script>
<style scoped>
.preview-container {
  height: 610px;
  width: 300px;
  color: white;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.header {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 20px;
  justify-content: flex-end;
}

.name-container {
  height: 160px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  flex-direction: column;
  align-items: center;
}
.color-label input {
  visibility: hidden;
}
.color-label {
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 15px;
}
.menu-item {
  padding-left: 40px;
  padding-right: 20px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
}
.footer {
  display: flex;
  font-size: 0.6rem;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.app-bar {
  height: 30px;
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}
</style>
