<template>
  <div
    v-if="!listView"
    class="fill-height fill-width d-flex flex-column cursor-pointer relative"
    :class="draggingFileEntries.includes(fileEntry) ? 'drag-source' : ''"
    :draggable="fileEntry.fileType !== 'folder'"
    @click="handleClicked"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
  >
    <v-img
      contain
      draggable="false"
      :src="getFileThumbnail()"
      :style="`width: calc(100% - 8px); height: ${maxThumbnailHeight}px;`"
      :title="fileEntry.originalName"
      class="mx-1 mt-1"
    />
    <span
      class="text-caption text-truncate text-center mt-1"
      :title="fileEntry.originalName"
    >
      {{ fileEntry.originalName }}
    </span>
    <div
      v-if="multiSelectActive && fileEntry.fileType !== 'folder'"
      class="multi-select-checkbox-container"
    >
      <v-icon small color="white">
        {{
          fileIsMultiSelected
            ? "mdi-checkbox-marked-circle"
            : "mdi-checkbox-blank-circle"
        }}
      </v-icon>
    </div>
  </div>
  <v-list-item
    v-else
    dense
    :ripple="false"
    :title="fileEntry.originalName"
    :class="fileIsMultiSelected ? 'drag-source' : ''"
    :draggable="fileEntry.fileType !== 'folder'"
    @click="handleClicked"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
  >
    <v-list-item-action
      v-if="multiSelectActive && fileEntry.fileType !== 'folder'"
    >
      <v-simple-checkbox
        ripple
        :value="fileIsMultiSelected"
        @click="handleClicked"
      ></v-simple-checkbox>
    </v-list-item-action>

    <v-list-item-avatar tile size="25">
      <v-img contain max-height="15" :src="getFileThumbnail()"></v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>{{ fileEntry.originalName }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import SvgPdf from "../../../assets/svg/file-pdf-regular.svg";
import SvgExcel from "../../../assets/svg/file-excel-regular.svg";
import SvgWord from "../../../assets/svg/file-word-regular.svg";
import SvgImage from "../../../assets/svg/image-regular.svg";
import SvgFolder from "../../../assets/svg/folder-solid.svg";
import SvgFile from "../../../assets/svg/file-regular.svg";

export default {
  props: {
    fileEntry: Object,
    listView: Boolean,
    currentFolder: String,
    filePerLine: Number,
    draggingFileEntries: Array,
    multiSelectActive: Boolean,
    multiSelectedFileEntries: Array,
  },
  emits: ["clicked", "dragStarted", "dragEnded"],
  data() {
    return {
      icons: {
        SvgPdf,
        SvgExcel,
        SvgWord,
        SvgImage,
        SvgFolder,
        SvgFile,
      },
    };
  },
  computed: {
    maxThumbnailHeight() {
      switch (this.filesPerLine) {
        case 1:
          return 130;
        case 3:
          return 70;
        case 4:
        default:
          return 60;
      }
    },
    fileIsMultiSelected() {
      return this.multiSelectedFileEntries.some(
        (x) => x.id === this.fileEntry.id
      );
    },
  },
  methods: {
    getFileThumbnail() {
      switch (this.fileEntry.fileType) {
        case "pdf":
          return this.icons.SvgPdf;
        case "spreadsheet":
          return this.icons.SvgExcel;
        case "document":
          return this.icons.SvgWord;
        case "image":
          return this.route("api.files.content", {
            id: this.fileEntry.id,
            filename: this.fileEntry.originalName,
            thumbnail: true,
          });
        case "folder":
          return this.icons.SvgFolder;
        default:
          return this.icons.SvgFile;
      }
    },
    handleClicked() {
      this.$emit("clicked", this.fileEntry);
    },
    handleDragStart(event) {
      if (this.multiSelectActive && !this.fileIsMultiSelected) {
        this.handleClicked();
      }

      this.$emit("dragStarted", event, this.fileEntry);
    },
    handleDragEnd() {
      this.$emit("dragEnded", this.fileEntry);
    },
  },
};
</script>
<style scoped>
.drag-source {
  outline: 2px solid #00695c;
}

.multi-select-checkbox-container {
  position: absolute;
  bottom: 12px;
  right: 0px;
}

.multi-select-checkbox-container .v-icon {
  outline: 2px solid #000000;
  outline-offset: -2px;
  border-radius: 9999px;
  background-color: #000000;
}
</style>
