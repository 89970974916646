export const roundedNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 2,
});

export const fullNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 20,
});

export const currencyFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export const decimalFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
});

export const integerNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 0,
});

export function formatAttributeLink(attributeLink, defaultCurrencyCode) {
  switch (attributeLink?.attribute?.format) {
    case "Date":
    case "DateTime":
    case "Text":
    case "TextArea":
    case "Number":
    case "PhoneNumber":
      return attributeLink.searchString;
    case "Select":
      return attributeLink.attributeSelectOption?.value;
    case "Percentage":
      if (attributeLink.decimalValue != null) {
        return attributeLink.searchString;
      }
      return "";
    case "Currency":
      return `${
        attributeLink.stringValue
          ? attributeLink.stringValue + " "
          : defaultCurrencyCode + " "
      }${currencyFormatter.format(attributeLink.decimalValue)}`;
    case "Boolean":
      return attributeLink.boolValue;
    default:
      return;
  }
}

export function formatAndConvertStringToFloat(stringToConvert) {
  stringToConvert = stringToConvert.replace(/\./g, "");
  stringToConvert = stringToConvert.replace(/,/g, ".");
  let floatValue = parseFloat(stringToConvert);

  return floatValue;
}
