<template>
  <div>
    <h4 class="text-h4">{{ $t("pages.locations.oldImport.title") }}</h4>
    <v-spacer class="mb-4"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col col="12" lg="8">
          <v-card elevation="1">
            <v-card-text>
              <p class="text-h4 text--primary">
                {{ $t("pages.locations.oldImport.importCsvExcel") }}
              </p>
              <p>
                {{ $t("pages.locations.oldImport.instruction") }}
              </p>
              <ul class="cols-2 mb-2">
                <li class="font-weight-bold">
                  {{ $t("pages.locations.oldImport.fields.locationType") }}
                </li>
                <li>{{ $t("pages.locations.oldImport.fields.description") }}</li>
                <li class="font-weight-bold">
                  {{ $t("pages.locations.oldImport.fields.organization") }}
                </li>
                <li>
                  {{ $t("pages.locations.oldImport.fields.locationNumber") }}
                </li>
                <li>{{ $t("pages.locations.oldImport.fields.email") }}</li>
                <li>{{ $t("pages.locations.oldImport.fields.phoneNumber") }}</li>
                <li class="font-weight-bold">
                  {{ $t("pages.locations.oldImport.fields.country") }}
                </li>
                <li>{{ $t("pages.locations.oldImport.fields.addressOne") }}</li>
                <li>{{ $t("pages.locations.oldImport.fields.addressTwo") }}</li>
                <li class="font-weight-bold">
                  {{ $t("pages.locations.oldImport.fields.city") }}
                </li>
                <li>{{ $t("pages.locations.oldImport.fields.postCode") }}</li>
                <li>{{ $t("pages.locations.oldImport.fields.state") }}</li>
                <li>{{ $t("pages.locations.oldImport.fields.latitude") }}</li>
                <li>{{ $t("pages.locations.oldImport.fields.longitude") }}</li>
              </ul>
              <p>
                {{ $t("pages.locations.oldImport.constraints") }}
              </p>
              <div>
                <input
                  ref="fileUpload"
                  class="d-none"
                  type="file"
                  accept=".xlsx,.xlsb,.xls,.csv"
                  @change="handleUpload"
                />
                <v-alert
                  v-if="
                    result &&
                    result.matchedColumnNames &&
                    result.matchedColumnNames.length > 0
                  "
                  type="info"
                >
                  <span>{{ $t("pages.locations.oldImport.foundColumns") }}</span>
                  <ul>
                    <li
                      v-for="columnName in result.matchedColumnNames"
                      :key="columnName"
                    >
                      {{ columnName }}
                    </li>
                  </ul>
                  <span>
                    {{ $t("pages.locations.oldImport.missingColumns") }}
                  </span>
                </v-alert>
                <v-alert v-if="form.errors.locationSpreadsheet" type="error">
                  {{ form.errors.locationSpreadsheet }}
                </v-alert>
                <v-alert
                  v-if="result"
                  :type="mapImportStatusToAlertType(result.status)"
                >
                  {{ result.description }}
                </v-alert>
                <v-btn
                  color="primary"
                  :loading="form.processing"
                  :disabled="form.processing"
                  @click="$refs.fileUpload.click()"
                >
                  {{ $t("pages.locations.oldImport.upload") }}
                  <v-icon right>mdi-cloud-upload</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="result" no-gutters class="mt-4">
        <v-col col="12" lg="8">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <v-icon color="red">mdi-alert-circle</v-icon>
                  {{
                    $t("pages.locations.oldImport.invalidRows", {
                      amount: result.invalidRows.length,
                    })
                  }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="body-1">
                  {{ $t("pages.locations.oldImport.rowInvalidDetails") }}
                </p>
                <v-simple-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th
                          v-for="columnName in result.columnNames"
                          :key="columnName"
                        >
                          {{ columnName }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(invalidRow, index) in result.invalidRows"
                        :key="index"
                        @click.stop="
                          openInvalidRowDialog(invalidRow.errorMessages)
                        "
                      >
                        <td
                          v-for="columnName in result.columnNames"
                          :key="columnName"
                        >
                          {{ invalidRow.row[columnName] }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <v-icon color="green">mdi-check-circle</v-icon>
                  {{
                    $t("pages.locations.oldImport.importedRows", {
                      amount: result.importedRows.length,
                    })
                  }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th
                          v-for="columnName in result.columnNames"
                          :key="columnName"
                        >
                          {{ columnName }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(importedRow, index) in result.importedRows"
                        :key="index"
                      >
                        <td
                          v-for="columnName in result.columnNames"
                          :key="columnName"
                        >
                          {{ importedRow.row[columnName] }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-dialog v-model="invalidRowDialog" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("pages.locations.oldImport.rowErrorMessages") }}
          </v-card-title>
          <v-card-text>
            <ul>
              <li
                v-for="message in invalidRowMessages"
                :key="message"
                class="body-1"
              >
                {{ message }}
              </li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="invalidRowDialog = false">
              {{ $t("pages.locations.oldImport.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";

export default {
  layout: appLayout({ title: "pages.locations.oldImport.pageTitle" }),
  props: {
    result: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        locationSpreadsheet: null,
      }),

      invalidRowDialog: false,
      invalidRowMessages: [],
    };
  },
  methods: {
    handleUpload(event) {
      this.form.locationSpreadsheet = event.target.files[0];

      if (!this.form.locationSpreadsheet) return;

      this.form.post(this.route("locations.import.store"), {
        onFinish: () => {
          this.form.locationSpreadsheet = null;
          this.$refs.fileUpload.value = "";
        },
      });
    },
    mapImportStatusToAlertType(status) {
      switch (status) {
        case "Ok":
          return "success";
        case "Warning":
          return "warning";
        case "Error":
          return "error";
      }
    },
    openInvalidRowDialog(messages) {
      this.invalidRowMessages = messages;
      this.invalidRowDialog = true;
    },
  },
};
</script>
<style scoped>
ul.cols-2 {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
