<template>
  <div>
    <file-drag-detector @draggingActive="setOsFilesDragging" />
    <v-row>
      <v-col cols="12" sm="3">
        <h4 class="text-h4">
          {{
            isTemplatePage
              ? $t("pages.solutions.index.templateTitle")
              : $t("pages.solutions.index.title")
          }}
        </h4>
        <v-spacer></v-spacer>
      </v-col>
      <filter-status-labels
        :currentLayout="currentLayout"
        :currentAttributeFilters="currentAttributeFilters"
        :attributes="attributes"
        :allResults="postFilters.allResults"
        :allAttributes="postFilters.allAttributes"
        :hasSearched="hasSearched"
        :resultsFilteredCount="solutions.filteredCount"
        :resultsOriginalCount="solutions.originalCount"
        entryType="solution"
        @allAttributesUpdated="postFilters.allAttributes = $event"
        @allResultsUpdated="postFilters.allResults = $event"
      />
    </v-row>
    <v-row justify="space-between" class="d-flex align-center">
      <v-col md="3">
        <v-spacer></v-spacer>
      </v-col>
      <v-col md="3">
        <v-text-field
          :value="postFilters.search"
          @change="searchAttributes($event)"
          clearable
          append-icon="mdi-magnify"
          :label="$t('common.dropdowns.search.label')"
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center">
      <solution-form
        v-model="formDialog"
        :solution="solution"
        :attributeClasses="attributeClasses"
        :attributes="attributes"
        :currencies="currencies"
        :isTemplatePage="isTemplatePage"
        :locations="locations"
        :visibilities="visibilities"
        :solutionTemplates="solutionTemplates"
        :assetTemplates="assetTemplates"
        @clear="solution = null"
        @reload="reloadSolution"
      />
      <filters-modal
        v-model="filtersDialog"
        :attributes="attributes"
        :attributeFilters="currentAttributeFilters"
        @changeAttributeFilters="updateAttributeFilters"
      />
      <choose-attributes-modal
        v-model="chooseAttributesDialog"
        :layout="currentLayout"
        :attributes="attributes"
        :attributeClasses="attributeClasses"
        :organizationId="organizationId"
        @changeLayout="updateLayout"
      />
      <layout-options-modal
        v-model="layoutOptionsDialog"
        :layout="currentLayout"
        :attributeDisplaySizes="attributeDisplaySizes"
        @changeLayout="updateLayout"
        @saveLayout="saveLayout"
      />
      <save-layout-button
        :currentLayout="currentLayout"
        :currentAttributeFilters="currentAttributeFilters"
        :rowsPerPage="pagination.itemsPerPage"
        :triggerSave="triggerSaveLayout"
        :isTemplatePage="isTemplatePage"
        routePrefix="solutions"
        @resetTriggerSave="triggerSaveLayout = false"
      />
      <div style="flex: 1000000"></div>
      <v-btn
        class="mr-4 mb-2 float-right"
        height="3rem"
        text
        color="primary"
        @click="toggleFileMenu"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon v-if="!showFileMenu" class="black--text"
            >mdi-toggle-switch-off-outline</v-icon
          >
          <v-icon v-else class="black--text">mdi-toggle-switch-outline</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.solutions.index.toolbar.fileModule") }}
          </p>
        </div>
      </v-btn>
      <v-spacer></v-spacer>
    </v-row>
    <v-row dense no-gutters>
      <v-col :cols="showFileMenu ? 10 : 12">
        <v-data-table
          v-sortable-table="{ onEnd: sortTableHeaders }"
          :key="tableRerenderCounter"
          v-model="selectedRows"
          class="elevation-1 row-pointer rounded-r-0 fill-height attributes-table"
          :class="isDisplaySize('Minimal') ? 'attributes-fixed-table' : ''"
          :headers="headers"
          :items="mappedSolutions"
          :server-items-length="solutions.totalCount"
          :options.sync="pagination"
          :footer-props="tableFooterProps"
          :no-data-text="$t('common.dataTable.noData')"
          height="calc(100% - 58px)"
          multi-sort
          show-select
          @update:page="changePage(pagination)"
          @update:items-per-page="changePage(pagination)"
          @update:sort-desc="changeSorting(pagination)"
        >
          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <v-tooltip top v-bind:key="header.key">
              <template v-slot:activator="{ on }">
                <em v-on="on">
                  <span>{{ header.text }}</span>
                </em>
              </template>
              <span>{{ header.text }}</span>
            </v-tooltip>
          </template>
          <template v-slot:body.prepend>
            <header-search-row
              :columnIdentifiers="columnIdentifiers"
              :attributeFilters="currentAttributeFilters"
              :attributes="attributes"
              :columnFilterData="columnFilterData"
              :postFilters="postFilters"
              :showActionRow="!showFileMenu"
              @updateAttributeFilters="currentAttributeFilters = $event"
              @updatePostFilters="postFilters = $event"
              @updateColumnFilterData="
                (key, value) => (columnFilterData[key] = value)
              "
            />
          </template>
          <template #item="{ isSelected, select, item }">
            <attribute-data-row
              :isSelected="isSelected"
              :select="select"
              :item="item"
              :attributes="attributes"
              :currentLayout="currentLayout"
              :filesDragging="filesDragging"
              :headers="headers"
              :onlyFileAttribute="onlyFileAttribute"
              :hoveredAttributeId="hoveredAttributeId"
              :hoveredRowId="hoveredSolutionId"
              :defaultCurrencyCode="defaultCurrencyCode"
              entryType="solution"
              @editRowClicked="editRow"
              @copyRowClicked="copyRow"
              @destroyRowClicked="destroyRow"
              @hoveredAttributeIdUpdated="hoveredAttributeId = $event"
              @hoveredRowIdUpdated="hoveredSolutionId = $event"
              @linkFilesToRow="handleSolutionFileLinking"
            />
          </template>
          <template #body.append="{ items }">
            <tr>
              <td></td>
              <td
                v-for="header in headers"
                :key="header.value"
                :class="header.value === 'action' ? 'fixed' : ''"
              >
                <span
                  v-if="dynamicHeaderIds.includes(header.value)"
                  class="font-weight-bold"
                >
                  {{
                    summarizeAttribute(mappedAttributes[header.value], items)
                  }}
                </span>
              </td>
            </tr>
          </template>
          <template #footer.prepend>
            <v-row justify="space-between" class="ml-0 mr-8 align-center">
              <v-btn text small @click="removeRowRange">
                {{ $t("pages.solutions.index.deleteSelectedSolutions") }}
              </v-btn>
            </v-row>
          </template>
          <template #footer.page-text="pageTextProps">
            {{ $t("common.dataTable.visibleRows", pageTextProps) }}
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="showFileMenu" cols="2">
        <file-menu
          :refreshFiles="refreshFileMenu"
          :osFilesDragging="osFilesDragging"
          :organizationId="currentOrganizationId"
          entryType="solution"
          @filesRefreshed="refreshFileMenu = false"
          @filesDraggingChanged="handleFilesDraggingChanged"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import qs from "qs";
import { currencyFormatter } from "../../util/formatters";
import { fixedClasses } from "../../util/fixedSolutionData";
import { appLayout } from "@/util/layout";
import { footerProps, sortableTableDirective } from "@/util/dataTable";
import {
  attributeOverviewComputed,
  attributeOverviewMethods,
} from "@/util/sharedVueProperties";
import SolutionForm from "./Form.vue";
import ChooseAttributesModal from "../../Components/Attribute/ChooseAttributesModal.vue";
import FiltersModal from "../../Components/Attribute/FiltersModal.vue";
import HeaderSearchRow from "../../Components/Attribute/HeaderSearchRow.vue";
import FilterStatusLabels from "../../Components/Attribute/FilterStatusLabels.vue";
import LayoutOptionsModal from "../../Components/Layout/LayoutOptionsModal.vue";
import SaveLayoutButton from "../../Components/Layout/SaveLayoutButton.vue";
import AttributeDataRow from "../../Components/Attribute/AttributeDataRow.vue";
import FileMenu from "../../Components/Attribute/FileMenu.vue";
import FileDragDetector from "../../Components/FileDragDetector.vue";
import { fixedAttributes } from "../../util/fixedSolutionData";

import "../../assets/css/attributeTable.css";
import { serialize } from "object-to-formdata";

export default {
  layout: appLayout({ title: "pages.solutions.index.title" }),
  components: {
    SolutionForm,
    ChooseAttributesModal,
    FiltersModal,
    LayoutOptionsModal,
    AttributeDataRow,
    FileMenu,
    FileDragDetector,
    SaveLayoutButton,
    HeaderSearchRow,
    FilterStatusLabels,
  },
  props: {
    solutions: Object,
    currentOrganizationId: String,
    attributeClasses: Array,
    attributes: Array,
    currencies: Array,
    solutionLayout: Object,
    attributeDisplaySizes: Array,
    locations: Array,
    visibilities: Array,
    template: Object,
    solutionTemplates: Array,
    assetTemplates: Object,
    modifiedSolution: Object,
  },
  data() {
    let searchParams = qs.parse(window.location.search.substring(1));

    let columnFilterProperties = ["referenceKey"];
    let columnFilterData = {};
    let sortBy = [];
    let sortDesc = [];

    columnFilterProperties.forEach((x) => {
      columnFilterData[`${x}-filter`] = null;
    });
    this.attributes.forEach((x) => {
      columnFilterData[`${x.id}-filter`] = null;
    });

    if (this.solutionLayout.attributeFilters != null) {
      this.solutionLayout.attributeFilters.forEach((x) => {
        if (x.filterBy != null) {
          if (x.attributeId != null) {
            columnFilterData[`${x.attributeId}-filter`] = x.filter;
            columnFilterData[`${x.attributeId}-filterBy`] = x.filterBy;
          } else {
            columnFilterData[`${x.propertyName}-filter`] = x.filter;
            columnFilterData[`${x.propertyName}-filterBy`] = x.filterBy;
          }
        }
      });

      var filters = this.solutionLayout.attributeFilters.filter(
        (x) => x.sortDesc != null
      );
      sortBy = filters.map((x) => {
        if (x.sortDesc != null) {
          if (x.attributeId != null) {
            return x.attributeId;
          }
          return x.propertyName;
        }
      });
      sortDesc = filters.map((x) => {
        if (x.sortDesc != null) {
          return x.sortDesc;
        }
      });
    }

    return {
      solution: null,
      currentLayout: this.solutionLayout,
      currentAttributeFilters: this.solutionLayout.attributeFilters,
      formDialog: false,
      filtersDialog: false,
      chooseAttributesDialog: false,
      layoutOptionsDialog: false,
      hasSearched: false,
      pagination: {
        page: this.solutions.currentPage,
        itemsPerPage: this.solutions.pageSize,
        sortBy,
        sortDesc,
      },
      tableFooterProps: footerProps,
      postFilters: {
        search: searchParams.postFilters?.search ?? null,
        allAttributes: searchParams.postFilters?.allAttributes ?? null,
        allResults: searchParams.postFilters?.allResults,
      },
      organizationId:
        this.currentOrganizationId ?? searchParams.organizationId ?? null,
      columnFilterData,
      columnFilterProperties,
      filesDragging: false,
      osFilesDragging: false,
      showFileMenu: false,
      refreshFileMenu: false,
      selectedRows: [],
      hoveredAttributeId: null,
      hoveredSolutionId: null,
      tableRerenderCounter: 0,
      currentRoute: this.routeRaw().current(),
      isTemplatePage: this.routeRaw().current() === "solution-templates.index",
      triggerSaveLayout: false,
    };
  },
  computed: {
    ...attributeOverviewComputed,
    mappedSolutions() {
      return this.solutions?.data.map(this.mapSolution);
    },
    visibleAttributes() {
      if (!this.attributes || !this.currentLayout) return [];

      return this.attributes.filter((x) =>
        this.currentLayout.attributeIds.includes(x.id)
      );
    },
    onlyFileAttribute() {
      if (this.attributes.filter((x) => x.format === "File").length <= 1)
        return this.attributes.find((x) => x.format === "File") ?? null;

      if (this.visibleAttributes.filter((x) => x.format === "File").length <= 1)
        return this.visibleAttributes.find((x) => x.format === "File") ?? null;

      return null;
    },
    defaultCurrencyId() {
      return this.$inertia.page.props.auth.settings.defaultCurrencyId;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.defaultCurrencyId
      );

      return currency?.currencyCode ?? null;
    },
  },
  methods: {
    ...attributeOverviewMethods,
    setOsFilesDragging(value) {
      this.osFilesDragging = value;
    },
    handleFilesDraggingChanged(value) {
      this.filesDragging = value;
    },
    toggleFileMenu() {
      this.showFileMenu = !this.showFileMenu;
    },
    summarizeAttribute(attribute, rows) {
      if (attribute.format === "Currency") {
        const decimals = attribute.visibleDecimals ?? 2;
        const scale = Math.pow(10, decimals);

        const sum = rows
          .map(
            (x) =>
              x.solutionAttributeLinks.find(
                (y) => y.attributeId === attribute.id
              )?.decimalValue
          )
          .filter((x) => !!x)
          .reduce((total, current) => total + current * scale, 0);

        return currencyFormatter.format(sum / scale);
      }
    },
    changePage(options) {
      let query = {
        postFilters: pickBy(this.postFilters),
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.post(
        this.route(this.currentRoute, query),
        {
          ...this.currentLayout,
          attributeFilters: this.currentAttributeFilters,
        },
        { preserveState: true }
      );
    },
    editRow(item) {
      this.solution = { ...item };
      this.formDialog = true;
    },
    copyRow(item) {
      this.solution = { ...item, id: null };
      this.formDialog = true;
    },
    destroyRow(id) {
      if (!confirm(this.$t("pages.solutions.index.confirmDelete"))) {
        return;
      }

      this.$inertia.delete(this.route("solutions.destroy", id));
    },
    reloadSolution() {
      if (!this.modifiedSolution) return;

      let mappedSolution = {
        ...this.modifiedSolution,
      };

      this.modifiedSolution.solutionAttributeLinks.forEach((link) => {
        mappedSolution[link.attributeId] = link;
      });

      this.solution = { ...mappedSolution };
    },
    handleSolutionFileLinking(solutionId, attributeId, formData) {
      fetch(
        this.route("api.solutions.attributes.files.link", {
          solutionid: solutionId,
          attributeid: attributeId,
        }),
        {
          method: "POST",
          body: formData,
        }
      ).then((res) => {
        if (!res.ok) return Promise.reject();

        this.changePage({
          page: this.pagination.page,
          itemsPerPage: this.pagination.itemsPerPage,
        });

        this.refreshFileMenu = true;
      });
    },
    removeRowRange() {
      if (
        !this.selectedRows.length ||
        !confirm(
          this.$t("pages.solutions.index.confirmDeleteSelected", {
            amount: this.selectedRows.length,
          })
        )
      )
        return;

      const form = serialize({
        solutionIds: this.selectedRows.map((x) => x.id),
      });

      this.$inertia.post(this.route("solutions.destroy.range"), form);
    },
    mapSolution(solution) {
      let mappedSolution = { ...solution };
      solution.solutionAttributeLinks.forEach((attributeLink) => {
        mappedSolution[attributeLink.attributeId] = attributeLink;

        if (
          attributeLink.attributeId === fixedAttributes.masterDataLocationId
        ) {
          let location = this.locations.find(
            (obj) => obj.id === solution.locationId
          );

          if (!location) return;

          mappedSolution[attributeLink.attributeId].searchString =
            location.name;
        }
      });

      return mappedSolution;
    },
  },
  mounted() {
    if (this.template) {
      const template = { ...this.template };

      template.solutionAttributeLinks = template.solutionAttributeLinks.filter(
        (x) => x.attribute.attributeClassId !== fixedClasses.primeId
      );

      let mappedSolution = {
        ...this.mapSolution(template),
        solutionTemplateId: template.id,
        id: null,
        fromTemplate: true,
        copiedAssets: template.assets,
        assets: [],
      };

      this.solution = mappedSolution;
      this.formDialog = true;

      let searchParams = qs.parse(window.location.search.substring(1));
      delete searchParams.templateId;
      const queryString = qs.stringify(searchParams);

      let newRelativeUrl = window.location.pathname;

      if (queryString) newRelativeUrl += "?" + queryString;

      setTimeout(
        () => window.history.replaceState(null, "", newRelativeUrl),
        100
      );
    }
  },
  directives: {
    "sortable-table": sortableTableDirective,
  },
  watch: {
    postFilters: {
      handler: throttle(function () {
        this.changePage({
          page: 1,
          itemsPerPage: this.pagination.itemsPerPage,
        });
      }, 150),
      deep: true,
    },
  },
};
</script>
