const RGB_MAX = 255;
const HUE_MAX = 360;
const SV_MAX = 100;

function _normalizeAngle(degrees) {
  return ((degrees % 360) + 360) % 360;
}

export function rgbToHsv(r, g, b) {
  if (typeof r === "object") {
    const args = r;
    r = args.r;
    g = args.g;
    b = args.b;
  }

  // It converts [0,255] format, to [0,1]
  r = r === RGB_MAX ? 1 : (r % RGB_MAX) / parseFloat(RGB_MAX);
  g = g === RGB_MAX ? 1 : (g % RGB_MAX) / parseFloat(RGB_MAX);
  b = b === RGB_MAX ? 1 : (b % RGB_MAX) / parseFloat(RGB_MAX);

  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var h,
    s,
    v = max;

  var d = max - min;

  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [
    Math.round(h * HUE_MAX),
    Math.round(s * SV_MAX),
    Math.round(v * SV_MAX),
  ];
}

export function hsvToRgb(h, s, v) {
  if (typeof h === "object") {
    const args = h;
    h = args.h;
    s = args.s;
    v = args.v;
  }

  h = _normalizeAngle(h);
  h = h === HUE_MAX ? 1 : ((h % HUE_MAX) / parseFloat(HUE_MAX)) * 6;
  s = s === SV_MAX ? 1 : (s % SV_MAX) / parseFloat(SV_MAX);
  v = v === SV_MAX ? 1 : (v % SV_MAX) / parseFloat(SV_MAX);

  var i = Math.floor(h);
  var f = h - i;
  var p = v * (1 - s);
  var q = v * (1 - f * s);
  var t = v * (1 - (1 - f) * s);
  var mod = i % 6;
  var r = [v, q, p, p, t, v][mod];
  var g = [t, v, v, q, p, p][mod];
  var b = [p, p, t, v, v, q][mod];

  return [
    Math.floor(r * RGB_MAX),
    Math.floor(g * RGB_MAX),
    Math.floor(b * RGB_MAX),
  ];
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
