<template>
  <v-row no-gutters dense class="mb-2">
    <v-col cols="8">
      <v-text-field
        :value="value"
        :clearable="false"
        solo
        dense
        hide-details
        @input="$emit('input', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="3" class="d-flex justify-center">
      <v-tooltip top :disabled="!radioDisabled">
        <template #activator="{ on, attrs }">
          <div class="d-flex" v-bind="attrs" v-on="on">
            <v-radio :value="index" :disabled="radioDisabled"></v-radio>
          </div>
        </template>
        <span>{{ $t("pages.portfolios.form.preselectDisabledHint") }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="1">
      <v-btn icon @click="$emit('deleteEntry', index)">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: String,
    index: Number,
    radioDisabled: Boolean,
  },
  emits: ["input", "textEmpty", "deleteEntry"],
  methods: {
    textIsEmpty(text) {
      return !text || (text + "").trim() === "";
    },
  },
  watch: {
    value(newValue) {
      if (this.textIsEmpty(newValue)) this.$emit("textEmpty", this.index);
    },
  },
};
</script>
