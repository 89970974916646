<template>
  <div class="fill-height" style="min-height: 60vh">
    <v-row>
      <v-col cols="4">
        <div>
          <strong>
            {{ $t("pages.assets.bulkEdit.chooseAssets.title") }}
          </strong>
        </div>
        <v-row>
          <v-col cols="9">
            <v-radio-group
              v-model="filterOptionValue"
              column
              mandatory
              hide-details
            >
              <v-radio :value="assetListFilterOptions.allResults">
                <template #label>
                  <div class="d-flex flex-row justify-space-between fill-width">
                    <strong>
                      {{ $t("pages.assets.bulkEdit.chooseAssets.all") }}
                    </strong>
                    <span>
                      ({{
                        $t("pages.assets.bulkEdit.chooseAssets.count", {
                          count: allCount,
                        })
                      }})
                    </span>
                  </div>
                </template>
              </v-radio>
              <v-radio :value="assetListFilterOptions.filteredAssets">
                <template #label>
                  <div class="d-flex flex-row justify-space-between fill-width">
                    <strong>
                      {{ $t("pages.assets.bulkEdit.chooseAssets.filtered") }}
                    </strong>
                    <span>
                      ({{
                        $t("pages.assets.bulkEdit.chooseAssets.count", {
                          count: filteredCount,
                        })
                      }})
                    </span>
                  </div>
                </template>
              </v-radio>
              <v-radio :value="assetListFilterOptions.selectedAssets">
                <template #label>
                  <div class="d-flex flex-row justify-space-between fill-width">
                    <strong>
                      {{ $t("pages.assets.bulkEdit.chooseAssets.selected") }}
                    </strong>
                    <span>
                      ({{
                        $t("pages.assets.bulkEdit.chooseAssets.count", {
                          count: selectedCount,
                        })
                      }})
                    </span>
                  </div>
                </template>
              </v-radio>
              <v-radio :value="assetListFilterOptions.specificPortfolio">
                <template #label>
                  <div class="d-flex flex-row justify-space-between fill-width">
                    <strong>
                      {{
                        $t("pages.assets.bulkEdit.chooseAssets.portfolio.label")
                      }}
                    </strong>
                    <span>
                      ({{
                        $t("pages.assets.bulkEdit.chooseAssets.count", {
                          count: portfolioAssetCount,
                        })
                      }})
                    </span>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="9" class="pt-0">
            <v-autocomplete
              v-model="portfolioIdValue"
              :items="portfolios"
              item-text="name"
              item-value="id"
              :placeholder="
                $t('pages.assets.bulkEdit.chooseAssets.portfolio.placeholder')
              "
              solo
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { assetListFilterOptions } from "../../../util/assetListFilterOptions";

export default {
  props: {
    filterOption: String,
    portfolioId: String,
    allCount: Number,
    filteredCount: Number,
    selectedCount: Number,
    portfolioAssetCount: Number,
    portfolios: Array,
  },
  emits: ["updateForm"],
  data() {
    return {
      assetListFilterOptions,
    };
  },
  computed: {
    filterOptionValue: {
      get() {
        return this.filterOption;
      },
      set(value) {
        this.$emit("updateForm", "filterOption", value);
      },
    },
    portfolioIdValue: {
      get() {
        return this.portfolioId;
      },
      set(value) {
        this.$emit("updateForm", "filterPortfolioId", value);
      },
    },
  },
};
</script>
