<template>
  <div class="d-flex flex-column fill-height" style="min-height: 60vh">
    <v-row dense class="flex-grow-0">
      <v-col cols="2">
        <label for="stepOneImportType" class="subtitle-2 d-block mb-2">{{
          $t("pages.locations.import.stepOne.importFrom")
        }}</label>
        <v-select
          id="stepOneImportType"
          v-model="selectedImportOption"
          :items="importOptions"
          item-text="text"
          item-value="id"
          :label="$t('pages.locations.import.stepOne.importFrom')"
          solo
          dense
        ></v-select>
      </v-col>
    </v-row>
    <div
      class="file-input-container"
      :class="isFileHovered ? 'file-hover-border' : ''"
      @drop="onFileContainerDrop"
      @dragover="onFileContainerDragOver"
      @dragleave="onFileContainerDragLeave"
    >
      <div>
        <v-icon x-large class="black--text">mdi-cloud-upload-outline</v-icon>
      </div>
      <div v-if="selectedImportFile" class="subtitle-2 file-selection">
        <span>{{ $t("pages.locations.import.stepOne.selectedFile") }}</span>
        <span>{{ selectedImportFile.name }}</span>
      </div>
      <div class="subtitle-2 instructions">
        <span>{{ $t("pages.locations.import.stepOne.dragAndDrop") }}</span>
        <span>{{ $t("pages.locations.import.stepOne.or") }}</span>
      </div>
      <div>
        <v-btn color="primary" @click="triggerFileDialog">
          {{ $t("pages.locations.import.stepOne.browseFiles") }}
        </v-btn>
      </div>
    </div>
    <input
      type="file"
      accept=".xlsx,.xls,.csv"
      ref="fileUpload"
      class="d-none"
      @change="onFileUploadChanged"
    />
  </div>
</template>
<script>
const ALLOWED_IMPORT_MIME_TYPES = [
  "text/csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export default {
  props: {
    selectedImportFile: File,
  },
  emits: ["errorChanged", "invalidFormatChanged", "fileChanged"],
  data() {
    return {
      selectedImportOption: 1,
      importOptions: [{ id: 1, text: "File (.xls, .xlsx, .csv)" }],
      isFileHovered: false,
    };
  },
  methods: {
    triggerFileDialog() {
      this.$refs.fileUpload.value = "";
      this.$refs.fileUpload.click();
    },
    onFileUploadChanged(event) {
      const files = [...event.target.files];

      this.$emit("errorChanged", null);
      this.$emit("invalidFormatChanged", false);

      if (files.length === 0) {
        return;
      }

      this.setSelectedImportFile(files[0]);
    },
    onFileContainerDrop(event) {
      event.preventDefault();
      this.isFileHovered = false;

      this.$emit("errorChanged", null);
      this.$emit("invalidFormatChanged", false);

      const files = [...event.dataTransfer.files];

      if (!files.length) return;

      const firstFile = files[0];

      this.setSelectedImportFile(firstFile);
    },
    setSelectedImportFile(file) {
      if (!!file && ALLOWED_IMPORT_MIME_TYPES.includes(file.type)) {
        this.$emit("fileChanged", file);
      } else {
        this.$emit("fileChanged", null);
        this.$emit(
          "errorChanged",
          this.$t("pages.locations.import.stepOne.filetypeNotSupported")
        );
        this.$emit("invalidFormatChanged", true);
      }
    },
    onFileContainerDragOver(event) {
      event.preventDefault();
      this.isFileHovered = true;
    },
    onFileContainerDragEnter(event) {
      event.preventDefault();
      this.isFileHovered = true;
    },
    onFileContainerDragLeave(event) {
      event.preventDefault();
      this.isFileHovered = false;
    },
  },
};
</script>
<style scoped>
.file-input-container {
  border: 2px dashed grey;
  background-color: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.file-hover-border {
  border: 2px solid #00695c;
}

.file-input-container .instructions span,
.file-input-container .file-selection span {
  display: block;
  color: black;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
</style>
