<template>
  <v-dialog v-model="visible" max-width="500px">
    <v-card>
      <v-card-title>{{ $t("components.contents.title") }}</v-card-title>
      <v-card-text>
        <v-select
          ref="contentTypeList"
          v-model="selectedContentTypes"
          :items="contents"
          :label="$t('components.contents.dataType')"
          multiple
          return-object
          item-text="name"
          item-value="id"
        >
          <v-list-item
            slot="append-item"
            ripple
            class="close-menu-button"
            @click="closeContentTypeListMenu"
          >
            <v-list-item-action>
              <v-icon>mdi-close</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{
              $t("components.contents.closeMenu")
            }}</v-list-item-title>
          </v-list-item>
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="visible = false">
          {{ $t("components.contents.close") }}
        </v-btn>
        <v-btn color="primary" text @click="addContent">
          {{ $t("components.contents.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["value", "contents"],
  data() {
    return {
      selectedContentTypes: [],
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    addContent() {
      const content = this.selectedContentTypes.map((c) => {
        return {
          informationTypeId: c.id,
          name: c.name,
          visibility: c.defaultVisibility,
          informationType: c,
        };
      });
      this.selectedContentTypes = [];
      this.$emit("submit", content);
      this.visible = false;
    },
    closeContentTypeListMenu() {
      this.$refs.contentTypeList.isMenuActive = false;
      this.$refs.contentTypeList.blur();
    },
  },
};
</script>
