<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">{{ $t("pages.users.index.title") }}</h4>
    </div>
    <v-row class="d-flex align-center">
      <v-col>
        <v-select
          v-model="filters.role"
          :items="roles"
          :item-text="
            (role) =>
              $te(`common.roles.${role.name}`)
                ? $t(`common.roles.${role.name}`)
                : role.name
          "
          :label="$t('pages.users.index.role')"
          dense
          hide-details
          solo
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          :value="filters.search"
          @change="filters.search = $event"
          append-icon="mdi-magnify"
          :label="$t('common.dropdowns.search.label')"
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
      <v-col v-if="isCurrentUserAdmin">
        <v-checkbox
          v-model="filters.showAllSupporters"
          true-value="true"
          :false-value="null"
          :label="$t('pages.users.index.showAllSupporters')"
          hide-details
          class="mt-0"
        />
      </v-col>
      <v-col>
        <v-icon class="pt-0" @click="resetFilter">
          mdi-filter-off-outline
        </v-icon>
      </v-col>
      <v-spacer></v-spacer>
      <v-col col="1" class="d-flex justify-end">
        <v-btn text color="primary" v-inertia :href="route('users.create')">
          {{ $t("pages.users.index.create") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="users.data"
      :server-items-length="users.totalCount"
      :options.sync="pagination"
      :must-sort="true"
      :footer-props="tableFooterProps"
      @click:row="rowClick"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
    >
      <template v-slot:item.role="{ item }">
        <v-chip :color="item.role.color" dark>
          {{
            $te(`common.roles.${item.role.name}`)
              ? $t(`common.roles.${item.role.name}`)
              : item.role.name
          }}
        </v-chip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:item.disabledAt="{ item }">
        {{
          !!item.disabledAt
            ? $t("pages.users.index.inactive")
            : $t("pages.users.index.active")
        }}
      </template>
      <template v-slot:item.actions>
        <v-icon class="mr-2"> mdi-chevron-right </v-icon>
      </template>
      <template #footer.page-text="pageTextProps">
        {{ $t("common.dataTable.visibleRows", pageTextProps) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import qs from "qs";
import { appLayout } from "@/util/layout";
import { footerProps } from "@/util/dataTable";
import { systemRoles } from "../../util/roles";

export default {
  layout: appLayout({ title: "pages.users.index.title" }),
  props: {
    users: Object,
    roles: Array,
  },
  data() {
    let searchParams = qs.parse(window.location.search.substring(1));
    return {
      headers: [
        { text: this.$t("common.fieldNames.name"), value: "name" },
        { text: this.$t("pages.users.index.columns.email"), value: "email" },
        {
          text: this.$t("pages.users.index.columns.role"),
          value: "role",
          sortable: false,
        },
        { text: this.$t("common.fieldNames.createdAt"), value: "createdAt" },
        {
          text: this.$t("pages.users.index.columns.status"),
          value: "disabledAt",
        },
        {
          text: this.$t("common.fieldNames.actions"),
          value: "actions",
          sortable: false,
          align: "right",
        },
      ],
      pagination: {
        page: this.users.currentPage,
        itemsPerPage: Number(searchParams.pageSize) || 50,
        sortBy: [searchParams.sortBy || "name"],
        sortDesc: [searchParams.sortDesc === "true"],
      },
      tableFooterProps: footerProps,
      filters: {
        search: searchParams.filters?.search ?? null,
        role: searchParams.filters?.role ?? null,
        showAllSupporters: searchParams.filters?.showAllSupporters ?? null,
      },
    };
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = { page: 1, sortBy: [], sortDesc: [] };
        this.changePage(this.pagination);
      }, 150),
      deep: true,
    },
  },
  methods: {
    rowClick(item) {
      this.$inertia.get(this.route("users.edit", item.id));
    },
    changePage(options) {
      let query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };
      this.$inertia.get(this.route("users.index", query));
    },
    resetFilter() {
      for (let filter in this.filters) {
        this.filters[filter] = null;
      }
    },
    getColor(role) {
      if (role == "Support") {
        return "green";
      } else if (role == "Admin") {
        return "blue";
      }
    },
  },
  computed: {
    isCurrentUserAdmin() {
      return this.$inertia.page.props.auth.user?.roleId === systemRoles.admin;
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
