<template>
  <div class="d-flex flex-column fill-height">
    <v-tabs
      v-model="selectedTab"
      class="flex-grow-0"
      background-color="teal darken-3"
      center-active
      dark
      grow
    >
      <v-tab v-for="tab in tabs" :key="tab">
        {{ tab }}
      </v-tab>
    </v-tabs>
    <v-toolbar
      height="56"
      elevation="0"
      class="teal darken-3 flex-grow-0 flex-shrink-1 pt-1"
    >
      <v-text-field
        v-model="searchText"
        @input="setSearchReload"
        append-icon="mdi-magnify"
        :label="$t('common.dropdowns.search.label')"
        single-line
        dense
        solo
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-toolbar
      height="56"
      elevation="0"
      class="teal darken-3 flex-grow-0 flex-shrink-1 pb-2"
    >
      <v-row justify="center" align="center" class="!-my-3">
        <v-col cols="10">
          <v-select
            :value="queryParams.sortBy"
            :items="sortTypes"
            @change="setSortBy"
            :label="$t('components.supportCases.list.sortBy')"
            item-text="text"
            item-value="value"
            dense
            background-color="white"
            hide-details
            solo
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-btn icon @click="changeSortDirection">
            <v-icon color="white">{{
              !queryParams.sortDesc && queryParams.sortDesc !== undefined
                ? "mdi-sort-ascending"
                : "mdi-sort-descending mdi-flip-v"
            }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-toolbar
      height="56"
      elevation="0"
      class="teal darken-3 flex-grow-0 flex-shrink-1 pb-2"
    >
      <v-row justify="center" align="center" class="!-my-3">
        <v-col cols="10">
          <v-select
            :value="selectedMultiAction"
            :items="multiActions"
            @change="handleMultiAction"
            item-text="name"
            item-value="value"
            :label="multiActionLabel"
            dense
            background-color="white"
            hide-details
            solo
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-btn icon @click="clearMultiActionSelections">
            <v-icon color="white"> mdi-filter-remove </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-list class="flex-grow-1">
      <v-list-item-group>
        <v-list-item
          two-line
          v-for="listItem in supportCases.data"
          :key="listItem.id"
          :value="listItem.id"
          @click="selectSupportCase(listItem.id)"
          :class="{
            'selected-list-item':
              selectedCase && selectedCase.id === listItem.id,
            'color-timer-red': checkColorTimerRed(listItem),
            'color-timer-yellow': checkColorTimerYellow(listItem),
          }"
        >
          <template>
            <div>
              <v-checkbox
                :value="listItem.id"
                v-model="selectedEntries"
                @click.stop
              />
            </div>
            <v-list-item-content>
              <v-list-item-title>{{ listItem.name }}</v-list-item-title>
              <v-list-item-subtitle>
                <span class="font-weight-bold mr-1">
                  {{ getSupportCaseCreationDiff(listItem) }}
                </span>
                <span>
                  {{ getSupportCaseTimestamp(listItem) }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon
              class="list-case-icon"
              v-if="listItem.locationId == null"
              :title="$t('components.supportCases.list.missingLocation')"
            >
              <v-icon color="red">mdi-map-marker-radius-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-icon
              class="list-case-icon"
              v-else-if="listItem.solutionId == null"
              :title="$t('components.supportCases.list.missingSolution')"
            >
              <v-icon color="red">mdi-home-assistant</v-icon>
            </v-list-item-icon>

            <v-list-item-icon
              class="list-case-icon"
              v-if="
                !isReadByAssignee(listItem) &&
                listItem.assigneeId === auth.user.id
              "
              :title="$t('components.supportCases.list.unreadMessages')"
            >
              <v-icon>mdi-email-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-icon
              class="list-case-icon"
              v-else-if="listItem.isLastMessageCreatedByBackoffice"
              :title="$t('components.supportCases.list.awaitingCustomer')"
            >
              <v-icon>mdi-account-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-icon
              class="list-case-icon"
              v-else-if="isInactivityIconVisible(listItem)"
              :title="
                $t('components.supportCases.list.noReply', {
                  hours: inactivityIconInHours,
                })
              "
            >
              <v-icon>mdi-sleep</v-icon>
            </v-list-item-icon>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-pagination
      @input="setPageNumber"
      v-model="queryParams.page"
      :length="Math.ceil(supportCases.totalCount / supportCases.pageSize)"
    ></v-pagination>
  </div>
</template>
<script>
import { pascalToSpaces } from "@/util/text";
import {
  toLocaleUTCString,
  normalizeISOString,
  timeAgoInDaysAndHours,
} from "@/util/dateTime";

import pickBy from "lodash/pickBy";
import qs from "qs";

export default {
  props: {
    auth: Object,
    supportCases: Object,
    selectedCase: Object,
    inactivityIconInHours: Number,
    colorTimerSetting: Object,
  },
  data() {
    let queryString = window.location.search;
    if (queryString.startsWith("?")) queryString = queryString.substring(1);

    let queryParameters = qs.parse(queryString);
    queryParameters.filters = queryParameters.filters ?? {};
    queryParameters.page = isNaN(parseInt(queryParameters.page))
      ? 1
      : parseInt(queryParameters.page);
    queryParameters.sortDesc =
      queryParameters.sortDesc === "true" || !queryParameters.sortDesc;

    return {
      queryParams: queryParameters,
      tabs: [
        this.$t("components.supportCases.list.tabs.new"),
        this.$t("components.supportCases.list.tabs.open"),
        this.$t("components.supportCases.list.tabs.mine"),
        this.$t("components.supportCases.list.tabs.all"),
      ],
      sortTypes: [
        {
          text: this.$t(
            "components.supportCases.list.sortOptions.latestMessage"
          ),
          value: "LastMessageReceivedAt",
        },
        {
          text: this.$t("components.supportCases.list.sortOptions.creation"),
          value: "CreatedAt",
        },
        {
          text: this.$t("components.supportCases.list.sortOptions.name"),
          value: "Name",
        },
      ],
      searchText: queryParameters.filters.search,
      searchReloadTimeout: null,
      multiActions: [
        {
          name: this.$t("components.supportCases.list.multiActions.close"),
          value: "CLOSE",
        },
        {
          name: this.$t("components.supportCases.list.multiActions.delete"),
          value: "DELETE",
        },
        {
          name: this.$t("components.supportCases.list.multiActions.relate"),
          value: "RELATE",
        },
      ],
      selectedMultiAction: null,
      selectedEntries: [],
    };
  },
  methods: {
    setSortBy(value) {
      this.updateQueryParams({ sortBy: value }, ["supportCases"]);
    },
    changeSortDirection() {
      let sortDesc;

      if (this.queryParams.sortDesc === undefined) {
        sortDesc = false;
      } else {
        sortDesc = !this.queryParams.sortDesc;
      }

      this.updateQueryParams({ sortDesc }, ["supportCases"]);
    },
    setPageNumber(page) {
      this.updateQueryParams({ page }, ["supportCases"]);
    },
    selectSupportCase(selectedCaseId) {
      this.updateQueryParams(
        { selectedCaseId },
        ["selectedCase", "supportCases"],
        this.markCaseAsRead
      );
    },
    setSearchReload() {
      clearTimeout(this.searchReloadTimeout);

      this.searchReloadTimeout = setTimeout(() => {
        this.cleanSearchText();

        this.updateQueryParams(
          {
            filters: { ...this.queryParams.filters, search: this.searchText },
            page: 1,
          },
          ["supportCases"]
        );
      }, 1000);
    },
    cleanSearchText() {
      if (!this.searchText) return null;

      this.searchText = this.searchText.replace(/[#/]/g, "");
    },
    updateQueryParams(updatedParams, extraLoadList = [], callback = null) {
      this.queryParams = {
        ...this.queryParams,
        ...updatedParams,
      };

      let query = {
        page: this.queryParams.page,
        sortDesc: this.queryParams.sortDesc,
        selectedCaseId: this.queryParams.selectedCaseId,
        filters: pickBy(this.queryParams.filters),
        sortBy: this.queryParams.sortBy,
      };

      let inertiaOptions = {
        preserveState: true,
        preserveScroll: true,
      };

      if (extraLoadList.length > 0) {
        inertiaOptions.only = [...extraLoadList];
      }

      if (typeof callback === "function") {
        inertiaOptions.onSuccess = () => {
          callback();
        };
      }

      this.$inertia.get(
        this.route("support-cases.index", query),
        {},
        inertiaOptions
      );
    },
    isReadByAssignee(supportCase) {
      if (!supportCase) return null;

      if (!supportCase.readByAssignee) {
        return false;
      }

      return (
        new Date(supportCase.readByAssignee) >=
        new Date(supportCase.lastMessageReceivedAt)
      );
    },
    isInactivityIconVisible(supportCase) {
      if (!supportCase) return null;

      if (
        supportCase.status === "Closed" ||
        supportCase.status === "Third party"
      ) {
        return false;
      }

      let now = new Date();
      let lastReceivedMessageDate = new Date(
        supportCase.lastMessageReceivedAt ||
          supportCase.manualStartedAt ||
          supportCase.createdAt
      );

      return (
        Math.abs(now - lastReceivedMessageDate) / (60 * 60 * 1000) >
        this.inactivityIconInHours
      );
    },
    formattedEnumList(enumList) {
      return enumList.map((value) => {
        return {
          text: this.formatPascal(value),
          value: value,
        };
      });
    },
    formatPascal(text) {
      return pascalToSpaces(text || "");
    },
    markCaseAsRead() {
      if (
        this.auth.user.id === this.selectedCase?.assigneeId &&
        !this.isReadByAssignee(this.selectedCase)
      ) {
        this.$inertia.post(
          this.route("support-cases.mark-as-read", this.selectedCase.id),
          null,
          {
            preserveState: true,
            only: ["supportCases"],
          }
        );
      }
    },
    handleMultiAction(action) {
      switch (action) {
        case "CLOSE":
          this.closeSelectedEntries();
          break;
        case "DELETE":
          this.deleteSelectedEntries();
          break;
        case "RELATE":
          this.relateSelectedEntries();
          break;
      }

      this.selectedMultiAction = action;

      this.$nextTick(() => {
        this.selectedMultiAction = null;
        this.selectedEntries = [];
      });
    },
    closeSelectedEntries() {
      if (
        !confirm(
          this.$t("components.supportCases.list.confirmClose", {
            amount: this.selectedEntries.length,
          })
        )
      ) {
        return;
      }

      this.$inertia.post(
        this.route("support-cases.multi.close"),
        {
          supportCaseIds: this.selectedEntries,
        },
        {
          preserveState: true,
          preserveScroll: true,
          only: ["users", "statuses", "selectedCase", "supportCases"],
        }
      );
    },
    deleteSelectedEntries() {
      if (
        !confirm(
          this.$t("components.supportCases.list.confirmDelete", {
            amount: this.selectedEntries.length,
          })
        )
      ) {
        return;
      }

      this.$inertia.delete(
        this.route("support-cases.multi.delete"),
        {
          supportCaseIds: this.selectedEntries,
        },
        {
          preserveState: true,
          preserveScroll: true,
          only: ["users", "statuses", "selectedCase", "supportCases"],
          onSuccess: () => {
            const params = new URLSearchParams(location.search);

            if (
              !params.get("selectedCaseId") &&
              !!this.queryParams["selectedCaseId"]
            ) {
              this.queryParams = {
                ...this.queryParams,
                page: 1,
                sortDesc: false,
                filters: {
                  ...this.queryParams.filters,
                  search: null,
                },
              };

              delete this.queryParams.selectedCaseId;

              if (this.queryParams["sortBy"]) {
                delete this.queryParams.sortBy;
              }
            }
          },
        }
      );
    },
    relateSelectedEntries() {
      if (
        !confirm(
          this.$t("components.supportCases.list.confirmRelate", {
            amount: this.selectedEntries.length,
          })
        )
      ) {
        return;
      }

      this.$inertia.post(
        this.route("support-cases.multi.relate"),
        {
          supportCaseIds: this.selectedEntries,
        },
        {
          preserveState: true,
          preserveScroll: true,
          only: ["users", "statuses", "selectedCase", "supportCases"],
        }
      );
    },
    clearMultiActionSelections() {
      this.selectedEntries = [];
    },
    checkColorTimerRed(supportCase) {
      if (
        supportCase.status == "Closed" ||
        supportCase.status == "Third party"
      ) {
        return false;
      }

      const lastActionableMessageAt =
        supportCase.lastActionableMessageReceivedAt;
      const createdByBackoffice =
        supportCase.isLastActionableMessageCreatedByBackoffice;

      if (!lastActionableMessageAt) return false;

      const lastMessageAt = new Date(lastActionableMessageAt);
      const now = new Date();
      const hours = (now.getTime() - lastMessageAt.getTime()) / 3600000;
      if (createdByBackoffice) {
        return this.colorTimerSetting
          ? this.colorTimerSetting.customerTransition2 < hours
          : false;
      } else {
        return this.colorTimerSetting
          ? this.colorTimerSetting.supportTransition2 < hours
          : false;
      }
    },
    checkColorTimerYellow(supportCase) {
      if (
        supportCase.status == "Closed" ||
        supportCase.status == "Third party"
      ) {
        return false;
      }

      const lastActionableMessageAt =
        supportCase.lastActionableMessageReceivedAt;
      const createdByBackoffice =
        supportCase.isLastActionableMessageCreatedByBackoffice;

      if (!lastActionableMessageAt) return false;

      const lastMessageAt = new Date(lastActionableMessageAt);
      const now = new Date();
      const hours = (now.getTime() - lastMessageAt.getTime()) / 3600000;
      if (createdByBackoffice) {
        return this.colorTimerSetting
          ? this.colorTimerSetting.customerTransition1 < hours &&
              hours < this.colorTimerSetting.customerTransition2
          : false;
      } else {
        return this.colorTimerSetting
          ? this.colorTimerSetting.supportTransition1 < hours &&
              hours < this.colorTimerSetting.supportTransition2
          : false;
      }
    },
    getSupportCaseTimestamp(supportCase) {
      let relevantTimestamp =
        supportCase.lastMessageReceivedAt ||
        supportCase.manualStartedAt ||
        supportCase.createdAt;

      relevantTimestamp = normalizeISOString(relevantTimestamp);

      return toLocaleUTCString(relevantTimestamp);
    },
    getSupportCaseCreationDiff(supportCase) {
      return timeAgoInDaysAndHours(supportCase.createdAt);
    },
  },
  computed: {
    selectedTab: {
      get() {
        switch (this.queryParams.filters.section) {
          case "new":
            return 0;
          case "open":
            return 1;
          case "mine":
            return 2;
          default:
            return 3;
        }
      },
      set(value) {
        let updateParams = {
          page: 1,
          filters: { ...this.queryParams.filters },
        };

        switch (value) {
          case 0:
            updateParams.filters.section = "new";
            break;
          case 1:
            updateParams.filters.section = "open";
            break;
          case 2:
            updateParams.filters.section = "mine";
            break;
          case 3:
            delete updateParams.filters.section;
            break;
        }

        this.updateQueryParams(updateParams, ["supportCases"]);
      },
    },
    multiActionLabel() {
      if (this.selectedEntries.length === 0) {
        return this.$t("components.supportCases.list.multiAction");
      }

      return this.$t("components.supportCases.list.multiActionOn", {
        amount: this.selectedEntries.length,
      });
    },
  },
  mounted() {
    this.markCaseAsRead();
  },
};
</script>
<style scoped>
.list-case-icon {
  margin-bottom: 24px;
}
.v-list-item--active:hover::before,
.v-list-item--active::before {
  opacity: 0;
}

.selected-list-item:hover::before,
.selected-list-item::before {
  opacity: 0.12 !important;
}

.color-timer-red::before,
.color-timer-red:hover::before {
  opacity: 0.2;
  background: red;
}

.selected-list-item.color-timer-red:hover::before,
.selected-list-item.color-timer-red::before {
  opacity: 0.35 !important;
  background: red;
}

.color-timer-yellow::before,
.color-timer-yellow:hover::before {
  opacity: 0.26;
  background: yellow;
}

.selected-list-item.color-timer-yellow:hover::before,
.selected-list-item.color-timer-yellow::before {
  opacity: 0.45 !important;
  background: yellow;
}

.\!-my-3 {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}
</style>
