<template>
  <div>
    <h4 class="text-h4">{{ $t("pages.profile.title") }}</h4>
    <v-spacer class="mb-6"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col md="8" lg="6">
          <form @submit.prevent="submit">
            <v-card elevation="1">
              <v-card-title>{{ $t("pages.profile.general") }}</v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>
                      {{
                      $t("common.fieldNames.name")
                      }}
                    </v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field v-model="form.name"
                                  name="name"
                                  type="text"
                                  :error-messages="form.errors.name" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>{{ $t("pages.profile.email") }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field v-model="form.email"
                                  name="email"
                                  type="email"
                                  :error-messages="form.errors.email"
                                  autocomplete="email" />
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>
                      {{
                      $t("common.fieldNames.lastUpdated")
                      }}
                    </v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field v-model="updatedAt"
                                  name="updatedAt"
                                  disabled
                                  type="text" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>
                      {{
                      $t("common.fieldNames.createdAt")
                      }}
                    </v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field v-model="createdAt"
                                  name="createdAt"
                                  disabled
                                  type="text" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>
                      {{
                      $t("pages.profile.language")
                      }}
                    </v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-autocomplete v-model="form.locale"
                                    :items="formattedLocales"
                                    hide-details></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-3 pt-3">
                  <v-col cols="4">
                    <v-subheader>
                      {{
                      $t("pages.profile.allowNotification")
                      }}
                    </v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-checkbox hide-details
                                class="mt-1"
                                v-model="form.allowNotification" />
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-3 pt-3">
                  <v-col cols="4">
                    <v-subheader>
                      {{ $t("pages.users.form.fields.supportCaseNotification.title") }}
                    </v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-checkbox v-model="form.notifyOnNewSupportCase"
                                :label="$t('pages.users.form.fields.supportCaseNotification.label')"
                                class="mt-1" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <flash-messages />
                <v-spacer></v-spacer>

                <v-btn
                  type="submit"
                  outlined
                  color="primary"
                  :disabled="form.processing"
                  >{{ $t("pages.profile.save") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </form>
          <v-card elevation="1" class="mt-6">
            <v-card-title>{{ $t("pages.profile.account") }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-subheader>{{ $t("pages.profile.logout") }}</v-subheader>
                </v-col>
                <v-col cols="6" offset="2" class="d-flex justify-end">
                  <v-btn
                    outlined
                    block
                    color="primary"
                    href="/logout"
                    v-inertia:post
                    >{{ $t("pages.profile.logout") }}</v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="d-none">
                <v-col cols="4">
                  <v-subheader>{{
                    $t("pages.profile.resetPassword")
                  }}</v-subheader>
                </v-col>
                <v-col cols="6" offset="2" class="d-flex justify-end">
                  <v-btn outlined block color="primary">{{
                    $t("pages.profile.resetPassword")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import FlashMessages from "@/Shared/FlashMessages";

export default {
  layout: appLayout({ title: "pages.profile.title" }),
  components: {
    FlashMessages,
  },
  props: {
    auth: Object,
    locales: Array,
  },
  data() {
    return {
      form: this.$inertia.form({
        name: this.auth.user.name,
        email: this.auth.user.email,
        locale: this.auth.user.preferredLocale,
        allowNotification: this.auth.user.allowNotification,
        notifyOnNewSupportCase: this.auth.user.notifyOnNewSupportCase,
      }),
    };
  },
  computed: {
    createdAt() {
      return new Date(this.auth.user.createdAt).toLocaleString();
    },
    updatedAt() {
      return new Date(this.auth.user.updatedAt).toLocaleString();
    },
    formattedLocales() {
      return this.locales.map((x) => ({
        text: this.$t(`pages.profile.languages.${x}`),
        value: x,
      }));
    },
  },
  methods: {
    submit() {
      const submittedData = { ...this.form };

      this.form
        .transform((data) => ({
          ...data,
          locale: data.locale ?? "en",
        }))
        .put(this.route("profile.update"), {
          preserveScroll: true,
          onSuccess: () => {
            this.form.locale = submittedData.locale ?? "en";

            this.$i18n.locale = this.form.locale;
          },
        });
    },
  },
};
</script>
