<template>
  <div class="fill-height" style="min-height: 60vh">
    <v-row dense>
      <v-col cols="4">
        <label for="stepTwoPortfolioSelect" class="subtitle-2 d-block mb-2">{{
          $t("pages.assets.import.stepTwo.toPortfolio")
        }}</label>
        <v-select
          id="stepTwoPortfolioSelect"
          :items="portfolioOptions"
          :value="selectedPortfolioId"
          item-text="name"
          item-value="id"
          solo
          @change="handlePortfolioChanged"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense v-if="!selectedPortfolioId">
      <v-col cols="4">
        <label for="stepTwoPortfolioNameInput" class="subtitle-2 d-block mb-2">
          {{ $t("pages.assets.import.stepTwo.enterName") }}
        </label>
        <v-text-field
          id="stepTwoPortfolioNameInput"
          solo
          :value="newPortfolioName"
          @input="handlePortfolioNameInput"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <label for="stepTwoFileField" class="subtitle-2 d-block mb-2">
          {{ $t("pages.assets.import.stepTwo.fileName") }}
        </label>
        <v-text-field
          id="stepTwoFileField"
          disabled
          solo
          :value="selectedImportFile.name"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    selectedImportFile: File,
    portfolios: Array,
    selectedPortfolioId: String,
    newPortfolioName: String,
  },
  emits: ["portfolioIdChanged", "newPortfolioNameChanged"],
  data() {
    return {};
  },
  computed: {
    portfolioOptions() {
      const portfolios = this.portfolios ?? [];

      portfolios.push({
        id: null,
        name: this.$t("pages.assets.import.stepTwo.newPortfolio"),
      });

      return portfolios;
    },
  },
  methods: {
    handlePortfolioChanged(portfolioId) {
      this.$emit("portfolioIdChanged", portfolioId);
    },
    handlePortfolioNameInput(portfolioName) {
      this.$emit("newPortfolioNameChanged", portfolioName);
    },
  },
};
</script>
