var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',_vm._l((_vm.attributeGroups),function(attributeGroup,index){return _c('v-col',{key:index,attrs:{"col":"6"}},_vm._l((attributeGroup),function(attribute){return _c('attribute-link-input',{key:attribute.id,attrs:{"attribute":attribute,"currencies":_vm.currencies,"value":_vm.attributeLinkData[attribute.id],"secondValue":_vm.attributeLinkData[attribute.id + '-secondValue']},on:{"input":function($event){return _vm.$emit('attribute_link_data_changed', attribute.id, $event)},"input_secondValue":function($event){return _vm.$emit(
                'attribute_link_data_second_value_changed',
                attribute.id,
                arguments[0]
              )},"bool_changed":function($event){return _vm.$emit('bool_changed', $event, attribute)},"currencyCode_changed":function($event){return _vm.$emit(
                'currencyCode_changed',
                _vm.attributeLinkData[attribute.id],
                $event,
                attribute
              )},"currency_changed":function($event){return _vm.$emit(
                'currency_changed',
                $event,
                _vm.attributeLinkData[attribute.id + '-secondValue'],
                attribute
              )},"dateTime_changed":function($event){return _vm.$emit('dateTime_changed', $event, attribute)},"decimal_changed":function($event){return _vm.$emit('decimal_changed', $event, attribute)},"select_changed":function($event){return _vm.$emit('select_changed', $event, attribute)},"string_changed":function($event){return _vm.$emit('string_changed', $event, attribute)},"file_changed":function($event){return _vm.$emit('file_changed', $event, attribute)},"file_removed":function($event){return _vm.$emit('file_removed', $event, attribute)}}})}),1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }