<template>
  <v-dialog
    style="z-index: 20001"
    v-model="show"
    scrollable
    max-width="80vw"
    hide-overlay
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-file-table-box-outline</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("components.attributes.chooseAttributes.title") }}
          </p>
        </div>
      </v-btn>
    </template>
    <v-card height="70vh" class="base-background-color">
      <v-card-title>{{
        $t("components.attributes.chooseAttributes.title")
      }}</v-card-title>
      <v-card-text style="height: 300px">
        <v-row class="black--text">
          <v-col cols="12" sm="5">
            <v-row dense>
              <v-col>
                <v-text-field
                  :placeholder="
                    $t('components.attributes.chooseAttributes.searchAttribute')
                  "
                  v-model="search"
                  solo
                  dense
                  type="text"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="black--text">
          <v-col cols="12">
            <v-row dense>
              <v-col>
                <div class="pl-3">
                  {{ $t("components.attributes.chooseAttributes.className") }}
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row dense class="py-2">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col>
                    <v-expansion-panels accordion class="accordeon-checkbox">
                      <v-expansion-panel
                        v-for="(
                          attributeList, classId
                        ) in attributeListsBasedOnClass"
                        :key="attributeList.id"
                      >
                        <v-expansion-panel-header>
                          <v-checkbox
                            class="no-gutters"
                            hide-details
                            :input-value="selectedPartial(classId)"
                            :indeterminate="
                              selectedPartialIndeterminate(classId)
                            "
                            @change="togglePartial(classId, $event)"
                            @click="stopPropagation($event)"
                          >
                          </v-checkbox>
                          <span class="black--text" style="font-weight: bold">
                            {{ getClassName(classId) }}
                          </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          v-for="attribute in attributeList"
                          :key="attribute.id"
                        >
                          <v-checkbox
                            class="no-gutters"
                            hide-details
                            v-model="form.attributeIds"
                            :value="attribute.id"
                          >
                            <template v-slot:label>
                              <span
                                class="black--text"
                                style="font-size: 0.875rem"
                                >{{ attribute.name }}</span
                              >
                            </template>
                          </v-checkbox>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row dense>
              <v-col cols="6">
                <div class="pl-2">
                  <v-checkbox hide-details v-model="selectedAll">
                    <template v-slot:label>
                      <span class="black--text" style="font-size: 0.875rem">
                        {{ $t("common.actions.selectAll") }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row justify="end">
          <v-alert dense v-if="form.errors.alert" type="error" class="mb-0">
            {{ form.errors.alert }}
          </v-alert>
          <v-btn color="blue darken-1" text @click="show = false">
            <v-icon class="black--text">mdi-close</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.close") }}
            </p>
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="changeLayout()"
            :disabled="loading"
          >
            <v-icon class="black--text">mdi-content-save-outline</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.save") }}
            </p>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import "core-js/actual/array/group-by";

export default {
  props: {
    value: Boolean,
    dialog: Boolean,
    layout: Object,
    attributes: Array,
    attributeClasses: Array,
    organizationId: String,
  },
  emits: ["input", "changeLayout"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    availableAttributes() {
      var search = this.search.toLowerCase();
      return this.attributes.filter((x) => {
        return x.name.toLowerCase().includes(search);
      });
    },
    attributeListsBasedOnClass() {
      const combinedAttributes = [...this.attributes];

      const attributeGroups = combinedAttributes.groupBy((attribute) => {
        return attribute.attributeClass?.id ?? null;
      });

      return attributeGroups;
    },
    selectedAll: {
      set(val) {
        this.form.attributeIds = [];
        if (val) {
          this.form.attributeIds = this.attributes.map((x) => x.id);
        }
      },
      get() {
        return this.form.attributeIds.length === this.attributes.length;
      },
    },
  },
  data() {
    return {
      loading: false,
      form: this.$inertia.form({
        attributeIds: this.layout.attributeIds ?? [],
      }),
      search: "",
    };
  },
  watch: {
    layout() {
      this.form.attributeIds = this.layout.attributeIds;
    },
  },
  methods: {
    changeLayout() {
      this.$emit("changeLayout", {
        attributeIds: this.form.attributeIds,
      });
      this.show = false;
    },
    selectedPartial(classId) {
      let partialAttributeIds = this.getPartialAttributeIds(classId);

      let checkForAllSelected = partialAttributeIds.every((x) =>
        this.form.attributeIds.includes(x)
      );

      if (checkForAllSelected) return checkForAllSelected;

      let checkForNoneSelected = this.form.attributeIds.some((x) =>
        partialAttributeIds.includes(x)
      );

      if (checkForNoneSelected) return !checkForNoneSelected;
    },
    selectedPartialIndeterminate(classId) {
      let partialAttributeIds = this.getPartialAttributeIds(classId);

      let checkForAllSelected = partialAttributeIds.every((x) =>
        this.form.attributeIds.includes(x)
      );

      let checkForNoneSelected = this.form.attributeIds.some((x) =>
        partialAttributeIds.includes(x)
      );

      if (!checkForNoneSelected) return null;

      return !checkForAllSelected;
    },
    togglePartial(classId, value) {
      let partialAttributeIds = this.getPartialAttributeIds(classId);

      if (value) {
        let attributesNotIncluded = partialAttributeIds.filter(
          (x) => !this.form.attributeIds.includes(x)
        );

        this.form.attributeIds = [
          ...this.form.attributeIds,
          ...attributesNotIncluded,
        ];
      } else {
        let attributesIncluded = partialAttributeIds.filter((x) =>
          this.form.attributeIds.includes(x)
        );

        this.form.attributeIds = this.form.attributeIds.filter(
          (x) => !attributesIncluded.includes(x)
        );
      }
    },
    getPartialAttributeIds(classId) {
      return this.attributes
        .filter((x) => x.attributeClassId + "" === classId)
        .map((x) => x.id);
    },
    stopPropagation(event) {
      event.stopPropagation();
    },
    getClassName(classId) {
      if (!classId || classId === "null")
        return this.$t("components.attributes.chooseAttributes.noClass");

      return this.attributeClasses.find((x) => x.id === classId)?.name;
    },
  },
};
</script>
<style scoped>
.solo-item-list-area {
  height: 25vh;
  overflow-y: auto;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  background-origin: padding-box;
  background-clip: border-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.no-gutters {
  margin: 0;
  padding: 0;
}

.accordeon-checkbox
  .v-expansion-panel-header
  > :not(.v-expansion-panel-header__icon) {
  flex: unset !important;
}
</style>
