import {
  generateAttributeTableHeaders,
  sortTableColumnLayout,
  changeLayoutSorting,
} from "@/util/dataTable";

export const attributeOverviewMethods = {
  updateLayout(value) {
    this.currentLayout = {
      ...this.currentLayout,
      ...value,
    };

    if (this.currentLayout.sortOrderMappings) {
      const sortOrderKeys = Object.keys(this.currentLayout.sortOrderMappings);

      for (let key of sortOrderKeys) {
        let sortValue = this.currentLayout.sortOrderMappings[key];

        if (!this.currentLayout.attributeIds.includes(sortValue)) {
          delete this.currentLayout.sortOrderMappings[key];
        }
      }

      this.tableRerenderCounter++;
    }

    if (this.postFilters.search != null && !this.postFilters.allAttributes) {
      this.changePage({
        page: 1,
        itemsPerPage: this.pagination.itemsPerPage,
      });
    }
  },
  saveLayout() {
    this.triggerSaveLayout = true;
  },
  searchAttributes(value) {
    this.postFilters.search = value;
    if (!value || value.length === 0) {
      this.hasSearched = false;
    } else {
      this.hasSearched = true;
    }
  },
  updateAttributeFilters(filters) {
    this.currentAttributeFilters = filters;
    this.setColumnFilters(this.currentAttributeFilters ?? []);
    this.setColumnSorting(this.currentAttributeFilters ?? []);
    this.postFilters = { ...this.postFilters, allResults: false };
  },
  setColumnSorting(filters) {
    filters = filters.filter((x) => x.sortDesc != null);
    let sortBy = filters.map((x) => {
      if (x.sortDesc != null) {
        if (x.attributeId != null) {
          return x.attributeId;
        }
        return x.propertyName;
      }
    });
    let sortDesc = filters.map((x) => {
      if (x.sortDesc != null) {
        return x.sortDesc;
      }
    });
    this.pagination.sortBy = sortBy;
    this.pagination.sortDesc = sortDesc;
  },
  setColumnFilters(filters) {
    let columnFilterData = {};

    this.columnFilterProperties.forEach((x) => {
      columnFilterData[`${x}-filter`] = null;
    });
    this.attributes.forEach((x) => {
      columnFilterData[`${x.id}-filter`] = null;
    });
    filters.forEach((x) => {
      if (x.filterBy != null) {
        if (x.attributeId != null) {
          columnFilterData[`${x.attributeId}-filter`] = x.filter;
          columnFilterData[`${x.attributeId}-filterBy`] = x.filterBy;
        } else {
          columnFilterData[`${x.propertyName}-filter`] = x.filter;
          columnFilterData[`${x.propertyName}-filterBy`] = x.filterBy;
        }
      }
    });

    this.columnFilterData = columnFilterData;
  },
  sortTableHeaders(event) {
    this.currentLayout = sortTableColumnLayout(
      event,
      this.headers,
      this.currentLayout
    );

    this.tableRerenderCounter++;
  },
  changeSorting(options) {
    this.currentAttributeFilters = changeLayoutSorting(
      options,
      this.currentAttributeFilters,
      this.attributes
    );

    this.changePage({
      page: 1,
      itemsPerPage: this.pagination.itemsPerPage,
      sortBy: options.sortBy,
      sortDesc: options.sortDesc,
    });
  },
  isDisplaySize(attributeDisplaySize) {
    return this.currentLayout.attributeDisplaySize === attributeDisplaySize;
  },
};

export const attributeOverviewComputed = {
  headers() {
    return generateAttributeTableHeaders(
      this.currentLayout,
      this.attributes,
      this.showFileMenu
    );
  },
  mappedAttributes() {
    let mappedAttributes = [];
    this.attributes.forEach((attribute) => {
      mappedAttributes[attribute.id] = attribute;
    });
    return mappedAttributes;
  },
  defaultCurrencyId() {
    return this.$inertia.page.props.auth.settings.defaultCurrencyId;
  },
  defaultCurrencyCode() {
    const currency = this.currencies.find(
      (x) => x.id === this.defaultCurrencyId
    );

    return currency?.currencyCode ?? null;
  },
  dynamicHeaderIds() {
    return this.headers.filter((x) => x.dynamic).map((x) => x.value);
  },
  columnIdentifiers() {
    let identifiers = [];

    if (this.columnFilterProperties) {
      identifiers = identifiers.concat(this.columnFilterProperties);
    }

    if (this.dynamicHeaderIds) {
      identifiers = identifiers.concat(this.dynamicHeaderIds);
    }

    return identifiers;
  },
};
