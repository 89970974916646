<template>
  <div class="flex d-flex justify-center align-center">
    <inertia-link id="back-arrow" :href="route('login')">
      <v-icon x-large>mdi-arrow-left</v-icon>
    </inertia-link>
    <v-form class="col-10 col-md-7">
      <h3 class="text-h4 cyan--text text--darken-4 font-weight-light">
        {{ $t("pages.forgotPassword.confirmation.title") }}
      </h3>
      <p class="caption mb-6">
        {{ $t("pages.forgotPassword.confirmation.sentInstructions") }}
      </p>

      <i18n
        path="pages.forgotPassword.confirmation.didntReceive"
        tag="p"
        class="caption mb-6"
      >
        <inertia-link :href="route('password.request')">{{
          $t("pages.forgotPassword.confirmation.tryAnother")
        }}</inertia-link>
      </i18n>
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";

export default {
  metaInfo: { title: "pages.forgotPassword.confirmation.pageTitle" },
  layout: Layout,
};
</script>
<style scoped>
.relative {
  position: relative;
}

#back-arrow {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: none;
}
</style>
