<template>
  <div>
    <h4 class="text-h4">{{ $t("pages.dataTypes.create.title") }}</h4>
    <v-spacer class="mb-6"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col md="8" lg="6">
          <form @submit.prevent="form.post(route('information-types.store'))">
            <v-card elevation="1">
              <v-card-title>{{
                $t("pages.dataTypes.create.general")
              }}</v-card-title>
              <v-card-text>
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.name")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      name="name"
                      type="text"
                      :error-messages="form.errors.name"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.dataTypes.create.defaultVisibility")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-radio-group
                      v-model="form.defaultVisibility"
                      row
                      :error-messages="form.errors.defaultVisibility"
                    >
                      <v-radio
                        v-for="visibility in visibilities"
                        :key="visibility"
                        :label="$t(`common.visibilities.${visibility}`)"
                        :value="visibility"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <flash-messages />
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  outlined
                  color="primary"
                  :disabled="form.processing"
                  >{{ $t("pages.dataTypes.create.save") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import FlashMessages from "@/Shared/FlashMessages";

export default {
  layout: appLayout({ title: "pages.dataTypes.create.pageTitle" }),
  components: {
    FlashMessages,
  },
  props: {
    visibilities: Array,
  },
  data() {
    return {
      sending: false,
      form: this.$inertia.form({
        name: null,
        defaultVisibility: null,
      }),
    };
  },
};
</script>
