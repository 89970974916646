<template>
  <div class="flex d-flex justify-center align-center relative">
    <inertia-link id="back-arrow" :href="route('login')">
      <v-icon x-large>mdi-arrow-left</v-icon>
    </inertia-link>

    <v-form class="col-10 col-md-7" @submit.prevent="submit">
      <h3 class="text-h4 cyan--text text--darken-4 font-weight-light">
        {{ $t("pages.forgotPassword.title") }}
      </h3>
      <p class="caption mb-6">
        {{ $t("pages.forgotPassword.enterEmail") }}
      </p>
      <v-text-field
        v-model="form.email"
        :label="$t('pages.forgotPassword.email')"
        name="email"
        type="email"
        :error-messages="form.errors.email"
        autocomplete="email"
        autofocus
      />

      <div class="d-flex justify-end align-center mt-4">
        <v-btn type="submit" large color="primary" :disabled="form.processing">
          {{ $t("pages.forgotPassword.sendInstructions") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";

export default {
  metaInfo: { title: "pages.forgotPassword.pageTitle" },
  layout: Layout,
  props: {
    errors: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        email: null,
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.route("password.email"));
    },
  },
};
</script>
<style scoped>
.relative {
  position: relative;
}

#back-arrow {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: none;
}
</style>
