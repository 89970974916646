<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">{{ $t("pages.portfolios.index.title") }}</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row class="d-flex align-center" dense>
      <v-col>
        <portfolio-form
          v-model="dialog"
          :portfolio="portfolio"
          :attributes="attributes"
          :currencies="currencies"
          @clear="portfolio = null"
        />

        <v-btn class="mb-2" height="3rem" text color="primary">
          <div class="d-flex flex-column justify-center align-center">
            <v-icon class="black--text">mdi-filter</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("pages.portfolios.index.filter") }}
            </p>
          </div>
        </v-btn>
      </v-col>
      <v-col md="3">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          :label="$t('common.dropdowns.search.label')"
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1 row-pointer"
      :headers="headers"
      :items="portfolios.data"
      :server-items-length="portfolios.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      :must-sort="true"
      @dblclick:row="(event, { item }) => editPortfolio(item)"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
    >
      <template v-slot:[`item.referenceKey`]="{ item }">
        <td :class="item.fixed ? 'disabled' : ''">
          <div style="min-width: 100px">
            {{ item.referenceKey }}
          </div>
        </td>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <td :class="item.fixed ? 'disabled' : ''">
          <div style="min-width: 200px">
            {{ item.name }}
          </div>
        </td>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <td :class="item.fixed ? 'disabled' : ''">
          <div style="min-width: 350px">
            {{ item.description }}
          </div>
        </td>
      </template>

      <template v-slot:item.action="{ item }">
        <td :class="item.fixed ? 'disabled' : ''">
          <v-row class="actionrow" justify="center">
            <v-tooltip content-class="custom-tooltip" left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" v-bind="attrs" v-on="on"
                  >mdi-eye-outline</v-icon
                >
              </template>
              <v-col class="px-6 black--text">
                <v-row class="mb-8" justify="space-between">
                  <span class="mr-2">{{
                    $t("components.attributes.actionMenu.info.id")
                  }}</span>
                  <span class="font-weight-black">{{ item.referenceKey }}</span>
                </v-row>
                <v-row justify="space-between">
                  <span>{{ $t("components.attributes.actionMenu.info.created") }}</span>
                  <span class="font-weight-black">{{
                    formatDate(item.createdAt)
                  }}</span>
                </v-row>
                <v-row justify="space-between">
                  <span>{{ $t("components.attributes.actionMenu.info.createdBy") }}</span>
                  <span class="font-weight-black">{{
                    item.createdBy ? item.createdBy.name : ""
                  }}</span>
                </v-row>
                <v-row justify="space-between">
                  <span>{{ $t("components.attributes.actionMenu.info.lastUpdated") }}</span>
                  <span class="font-weight-black">{{
                    formatDate(item.updatedAt)
                  }}</span>
                </v-row>
                <v-row justify="space-between">
                  <span>{{ $t("components.attributes.actionMenu.info.lastUpdatedBy") }}</span>
                  <span class="font-weight-black">{{
                    item.updatedBy ? item.updatedBy.name : ""
                  }}</span>
                </v-row>
                <v-row class="mt-8" justify="space-between">
                  <span class="mr-16">{{
                    $t("components.attributes.actionMenu.info.portfoliosWithData")
                  }}</span>
                  <span class="font-weight-black">Data</span>
                </v-row>
              </v-col>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="!item.fixed ? on : undefined"
                  @click="copyPortfolio(item)"
                  >mdi-content-copy</v-icon
                >
              </template>
              <span>{{ $t("pages.portfolios.index.actions.copy") }}</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="!item.fixed ? on : undefined"
                  @click="editPortfolio(item)"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>{{ $t("pages.portfolios.index.actions.edit") }}</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="!item.fixed ? on : undefined"
                  @click="destroyPortfolio(item)"
                  >mdi-trash-can-outline</v-icon
                >
              </template>
              <span>{{ $t("pages.portfolios.index.actions.delete") }}</span>
            </v-tooltip>
          </v-row>
        </td>
      </template>
      <template #footer.page-text="pageTextProps">
        {{ $t("common.dataTable.visibleRows", pageTextProps) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import { appLayout } from "@/util/layout";
import PortfolioForm from "./Form.vue";
import { footerProps } from "@/util/dataTable";

export default {
  layout: appLayout({ title: "pages.portfolios.index.title" }),
  components: {
    PortfolioForm,
  },
  props: {
    portfolios: Object,
    attributes: Array,
    currencies: Array,
    reloadFilters: Object,
  },
  data() {
    var searchParams = new URLSearchParams(window.location.search);

    return {
      dialog: false,
      portfolio: null,
      headers: [
        { text: this.$t("common.fieldNames.id"), value: "referenceKey" },
        { text: this.$t("common.fieldNames.name"), value: "name" },
        {
          text: this.$t("pages.portfolios.index.columns.description"),
          value: "description",
        },
        {
          text: this.$t("common.fieldNames.actions"),
          value: "action",
          width: 120,
          sortable: false,
          align: "center fixed",
          class: "fixed",
        },
      ],
      pagination: {
        page: this.portfolios.currentPage,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") || "referenceKey"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
      },
      tableFooterProps: footerProps,
      filters: this.reloadFilters ?? {},
    };
  },
  methods: {
    editPortfolio(item) {
      if (item.fixed) return;

      this.portfolio = { ...item };
      this.dialog = true;
    },
    copyPortfolio(item) {
      if (item.fixed) return;

      this.portfolio = { ...item, id: null };
      this.dialog = true;
    },
    destroyPortfolio(item) {
      if (item.fixed) return;

      if (!confirm(this.$t("pages.portfolios.index.confirmDelete"))) {
        return;
      }
      this.$inertia.delete(this.route("portfolios.destroy", item.id));
    },
    formatDate(date) {
      if (!date) return this.$t("pages.portfolios.index.notAvailable");

      return new Date(date).toLocaleDateString();
    },
    resetFilter() {
      this.filters = {};
    },
    changePage(options) {
      let query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("portfolios.index", query),
        {},
        { preserveState: true }
      );
    },
  },
  watch: {
    filters: {
      handler: throttle(function () {
        this.pagination = { page: 1, sortBy: [], sortDesc: [] };
        this.changePage(this.pagination);
      }, 150),
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.custom-tooltip {
  opacity: 1 !important;
  background: #e2dee3 !important;
  border-radius: 0 !important;
  border: 1px solid #cfd8dc !important;
  z-index: 10000 !important;
}

table tbody tr td.fixed:nth-last-child(1),
table thead tr th.fixed:nth-last-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 0;
  z-index: 9998;
  background: #e2dee3;
  -webkit-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
}

table thead tr th.fixed:nth-last-child(1) {
  z-index: 9999;
  border-left: 1px black;
}

table td.disabled {
  background: lightgray !important;
}
</style>
