var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"z-index":"20001"},attrs:{"scrollable":"","max-width":"80vw","hide-overlay":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"height":"3rem","text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('clear')}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('v-icon',{staticClass:"black--text"},[_vm._v("mdi-plus")]),_c('p',{staticClass:"teal--text text--darken-4 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("pages.attributeClasses.form.create"))+" ")])],1)])]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"base-background-color",attrs:{"height":"70vh"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.modalTitle))]),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('v-row',{staticClass:"black--text"},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('span',[_vm._v(_vm._s(_vm.$t("pages.attributeClasses.form.className")))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"name":"name","solo":"","dense":"","type":"text","disabled":_vm.attributeClass && _vm.attributeClass.fixed,"error-messages":_vm.form.errors.name},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)],1)],1),_c('v-row',{staticClass:"black--text"},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('span',[_vm._v(_vm._s(_vm.$t("pages.attributeClasses.form.availableAttributes.label")))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"placeholder":_vm.$t(
                    'pages.attributeClasses.form.availableAttributes.placeholder'
                  ),"solo":"","dense":"","type":"text"},model:{value:(_vm.availableAttributesSearch),callback:function ($$v) {_vm.availableAttributesSearch=$$v},expression:"availableAttributesSearch"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"solo-item-list-area py-2"},_vm._l((_vm.availableAttributes),function(attribute){return _c('span',{key:attribute.id,class:{
                    selected: _vm.isAttributeSelected(attribute),
                    disabled: _vm.isUnassignedAttributeDisabled(attribute),
                  },on:{"click":function($event){_vm.selectedAttribute = attribute}}},[_vm._v(" "+_vm._s(attribute.name)+" ")])}),0)])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2","align-self":"center"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12"}},[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-btn',{attrs:{"outlined":"","icon":"","color":"blue darken-1","text":""},on:{"click":function($event){return _vm.removeAttributeClass(_vm.selectedAttribute)}}},[_c('v-icon',{staticClass:"black--text"},[_vm._v("mdi-chevron-left")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"12"}},[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-btn',{attrs:{"outlined":"","icon":"","color":"blue darken-1","text":""},on:{"click":function($event){return _vm.addAttributeClass(_vm.selectedAttribute)}}},[_c('v-icon',{staticClass:"black--text"},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('span',[_vm._v(_vm._s(_vm.$t("pages.attributeClasses.form.availableAttributes.label")))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"placeholder":_vm.$t(
                    'pages.attributeClasses.form.availableAttributes.placeholder'
                  ),"solo":"","dense":"","type":"text"},model:{value:(_vm.selectedAttributesSearch),callback:function ($$v) {_vm.selectedAttributesSearch=$$v},expression:"selectedAttributesSearch"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"solo-item-list-area py-2"},_vm._l((_vm.selectedAttributes),function(attribute){return _c('span',{key:attribute.id,class:{
                    selected: _vm.isAttributeSelected(attribute),
                    disabled: _vm.isAssignedAttributeDisabled(attribute),
                  },on:{"click":function($event){_vm.selectedAttribute = attribute}}},[_vm._v(" "+_vm._s(attribute.name)+" ")])}),0)])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-6"},[_c('v-row',{attrs:{"justify":"end"}},[(_vm.form.errors.alert)?_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.form.errors.alert)+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',{staticClass:"black--text"},[_vm._v("mdi-close")]),_c('p',{staticClass:"teal--text text--darken-4 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("common.actions.close"))+" ")])],1),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.saveAttributeClass()}}},[_c('v-icon',{staticClass:"black--text"},[_vm._v("mdi-content-save-outline")]),_c('p',{staticClass:"teal--text text--darken-4 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("common.actions.save"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }