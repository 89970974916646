<template>
  <div class="fill-height grow d-flex flex-column flex-nowrap">
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">{{ $t("pages.dashboard.title") }}</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row class="d-flex align-center text-center grow">
      <v-col cols="12" md="3">
        <h1 class="text-h1">{{ locationCount }}</h1>
        <v-spacer></v-spacer>
        <p class="subtitle-1">{{ $t("pages.dashboard.locations") }}</p>
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="12" md="3">
        <h1 class="text-h1">{{ solutionCount }}</h1>
        <v-spacer></v-spacer>
        <p class="subtitle-1">{{ $t("pages.dashboard.solutions") }}</p>
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="12" md="3">
        <h1 class="text-h1">{{ assetsCount }}</h1>
        <v-spacer></v-spacer>
        <p class="subtitle-1">{{ $t("pages.dashboard.assets") }}</p>
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="12" md="3">
        <h1 class="text-h1">{{ supportCaseCount }}</h1>
        <v-spacer></v-spacer>
        <p class="subtitle-1">{{ $t("pages.dashboard.openSupportCases") }}</p>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";

export default {
  layout: appLayout({ title: "Dashboard" }),
  props: {
    locationCount: Number,
    solutionCount: Number,
    assetsCount: Number,
    supportCaseCount: Number,
  },
};
</script>
