import actions from "./actions.json";
import attributeFormats from "./attributeFormats.json";
import boolean from "./boolean.json";
import dataTable from "./dataTable.json";
import dropdowns from "./dropdowns.json";
import fieldNames from "./fieldNames.json";
import filters from "./filters.json";
import flashMessages from "./flashMessages.json";
import placeholders from "./placeholders.json";
import roles from "./roles.json";
import rootCauses from "./rootCauses.json";
import sorting from "./sorting.json";
import visibilities from "./visibilities.json";

export default {
  actions,
  attributeFormats,
  boolean,
  dataTable,
  dropdowns,
  fieldNames,
  filters,
  flashMessages,
  placeholders,
  roles,
  rootCauses,
  sorting,
  visibilities,
};
