<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">{{ $t("pages.settings.index.title") }}</h4>
      <v-spacer></v-spacer>
    </div>
    <v-card elevation="2" min-height="17.625rem" class="d-flex flex-column">
      <v-card-text class="pa-0 flex-grow-1">
        <v-tabs v-model="tab" class="mb-4">
          <v-tab
            v-for="contentTab in tabs"
            :key="contentTab.id"
            :href="`#${getTabHref(contentTab.id)}`"
            @change="setTabInUrl(contentTab.id)"
          >
            {{ contentTab.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="fill-height fill-width px-4 pb-4">
          <tab-general v-bind="generalBindings" @updateForm="updateForm" />
          <tab-documents
            v-bind="documentsBindings"
            @updateForm="updateForm"
            @removeOrganizationalFile="removeOrganizationalFile"
          />
          <tab-organization
            v-bind="organizationBindings"
            ref="organizationTab"
          />
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="primary"
          :loading="form.processing"
          @click="submit"
        >
          {{ $t("common.actions.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";
import { serialize } from "object-to-formdata";
import TabGeneral from "./Tabs/TabGeneral.vue";
import TabDocuments from "./Tabs/TabDocuments.vue";
import TabOrganization from "./Tabs/TabOrganization.vue";

export default {
  layout: appLayout({ title: "pages.settings.index.title" }),
  components: {
    TabGeneral,
    TabDocuments,
    TabOrganization,
  },
  props: {
    settings: Object,
    currencies: Array,
    organization: Object,
  },
  data() {
    return {
      form: this.$inertia.form(this.generateInitialForm()),
      tab: window.location.hash?.substring(1) ?? null,
      tabs: [
        {
          id: 1,
          name: this.$t("pages.settings.index.tabs.general"),
        },
        {
          id: 2,
          name: this.$t("pages.settings.index.tabs.documents"),
        },
        {
          id: 3,
          name: this.$t("pages.settings.index.tabs.fixedAssets"),
        },
        {
          id: 4,
          name: this.$t("pages.settings.index.tabs.organization"),
        },
      ],
    };
  },
  computed: {
    generalBindings() {
      return {
        tabHref: this.getTabHref(1),
        currencies: this.currencies,
        defaultCurrencyId: this.form.defaultCurrencyId,
      };
    },
    documentsBindings() {
      return {
        tabHref: this.getTabHref(2),
        organizationalFiles: this.settings.organizationalFiles,
        organizationalFileIds: this.form.organizationalFileIds,
        newOrganizationalFiles: this.form.newOrganizationalFiles,
      };
    },
    organizationBindings() {
      return {
        organization: this.organization,
        tabHref: this.getTabHref(4),
      };
    },
  },
  methods: {
    submit() {
      if (this.tab === "organization") {
        this.$refs.organizationTab.submit();
      } else {
        this.form
          .transform((data) =>
            serialize(data, { noFilesWithArrayNotation: true })
          )
          .put(this.route("settings.update"), {
            onSuccess: () => {
              this.form = this.$inertia.form(this.generateInitialForm());

              if (this.tab) window.location.hash = `#${this.tab}`;
            },
          });
      }
    },
    reloadSettings(updatedSettings) {
      this.form.defaultCurrencyId = updatedSettings.defaultCurrencyId;
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
    generateInitialForm() {
      return {
        defaultCurrencyId: this.settings.defaultCurrencyId,
        organizationalFileIds: this.settings.organizationalFiles.map(
          (x) => x.id
        ),
        newOrganizationalFiles: null,
      };
    },
    getTabHref(tabId) {
      switch (tabId) {
        case 1:
          return "general";
        case 2:
          return "documents";
        case 3:
          return "fixed-assets";
        case 4:
          return "organization";
      }
    },
    setTabInUrl(tabId) {
      const tabHref = this.getTabHref(tabId);

      if (!tabHref) return;

      window.location.hash = `#${tabHref}`;
    },
    removeOrganizationalFile(fileId) {
      this.form.organizationalFileIds = this.form.organizationalFileIds.filter(
        (x) => x !== fileId
      );
    },
  },
  watch: {
    settings(value) {
      this.reloadSettings(value);
    },
  },
};
</script>
