<template>
  <v-sheet color="white" class="d-flex fill-height flex-column">
    <div
      class="overflow-y-auto flex-grow-1 flex-shrink-1"
      :class="{ 'height-0': !isMobileLayout }"
    >
      <v-expansion-panels accordion multiple v-model="expandedPanels">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>
              <v-icon color="teal darken-3"> mdi-information </v-icon>
              <span class="ml-2 sidebar-header-text">{{
                $t("components.supportCases.sidebar.generalInfo.title")
              }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pb-1">
            <v-list class="py-1">
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title class="text-wrap">
                    <v-text-field
                      v-if="editingName"
                      :value="selectedCase.name"
                      @change="
                        (value) => newName = value
                      "
                      filled
                      dense
                      hide-details
                    ></v-text-field>
                    {{ `${selectedCase.name} [${selectedCase.referenceKey}]` }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t(
                        "components.supportCases.sidebar.generalInfo.nameAndReference"
                      )
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    v-if="!editingName"
                    icon
                    @click="toggleEditingName" 
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="editingName"
                    icon
                    @click="submitName"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="organization" two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ organization }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t(
                        "components.supportCases.sidebar.generalInfo.organization"
                      )
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ selectedCase.supportRegionName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t(
                        "components.supportCases.sidebar.generalInfo.supportRegion"
                      )
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ selectedCase.isSupportRegionFallback ? "Yes" : "No" }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t(
                        "components.supportCases.sidebar.generalInfo.manuallyAssigned"
                      )
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    <v-autocomplete
                      :items="formattedRootCauses"
                      :value="selectedCase.rootCause"
                      :clearable="selectedCase.status !== 'Closed'"
                      @change="
                        (value) => $emit('editCase', { rootCause: value })
                      "
                      filled
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t(
                        "components.supportCases.sidebar.generalInfo.rootCause"
                      )
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line v-if="selectedCase.originalToEmails">
                <v-list-item-content class="py-1">
                  <v-list-item-title
                    v-for="mail in originalToEmails"
                    :key="mail"
                  >
                    {{ mail }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t(
                        "components.supportCases.sidebar.generalInfo.originallySentTo"
                      )
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="location">
          <v-expansion-panel-header>
            <div>
              <v-icon color="teal darken-3">
                mdi-map-marker-radius-outline
              </v-icon>
              <span class="ml-2 sidebar-header-text">{{
                $t("components.supportCases.sidebar.location.title")
              }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pb-1">
            <v-list class="py-1" v-if="location">
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ locationType.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.location.name") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    v-inertia
                    :href="route('location-types.edit', locationType.id)"
                  >
                    <v-icon>mdi-open-in-app</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-if="location.addressLine1"
                :two-line="!!location.addressLine1"
                :three-line="!!location.addressLine2"
              >
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ location.addressLine1 }}
                  </v-list-item-title>
                  <v-list-item-title v-if="location.addressLine2">
                    {{ location.addressLine2 }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.location.address") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ location.city }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.location.city") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ location.postalCode }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t("components.supportCases.sidebar.location.postalCode")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ location.state }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t("components.supportCases.sidebar.location.stateRegion")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ location.country }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.location.country") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ location.regionName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.location.region") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ location.locationNumber }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t(
                        "components.supportCases.sidebar.location.locationNumber"
                      )
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ location.email }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.location.email") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ location.phoneNumber }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t("components.supportCases.sidebar.location.phoneNumber")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line v-if="location.slaContent">
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ formatDate(location.slaContent.startsAt) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t("components.supportCases.sidebar.location.slaStart")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line v-if="location.slaContent">
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ formatDate(location.slaContent.endsAt) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.location.slaEnd") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list class="py-1" v-else>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-center">
                    {{
                      $t("components.supportCases.sidebar.location.noLocation")
                    }}
                  </v-list-item-title>
                  <i18n
                    path="components.supportCases.sidebar.location.assignInstructions"
                    tag="v-list-item-subtitle"
                    class="whitespace-pre-line text-overflow-clip"
                  >
                    <v-icon color="teal darken-3">mdi-dots-horizontal</v-icon>
                  </i18n>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="solution">
          <v-expansion-panel-header>
            <div>
              <v-icon color="teal darken-3">mdi-home-assistant</v-icon>
              <span class="ml-2 sidebar-header-text">{{
                $t("components.supportCases.sidebar.solution.title")
              }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ solutionTemplate && solutionTemplate.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.solution.name") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="false">
                  <v-btn
                    icon
                    v-inertia
                    :href="route('solution-types.edit', solutionTemplate.id)"
                  >
                    <v-icon>mdi-open-in-app</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item two-line v-if="solution.slaContent">
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ formatDate(solution.slaContent.startsAt) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t("components.supportCases.sidebar.solution.slaStart")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line v-if="solution.slaContent">
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ formatDate(solution.slaContent.endsAt) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.solution.slaEnd") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="asset">
          <v-expansion-panel-header>
            <div>
              <v-icon color="teal darken-3">mdi-list-box-outline</v-icon>
              <span class="ml-2 sidebar-header-text">{{
                $t("components.supportCases.sidebar.asset.title")
              }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ asset.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.asset.name") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line v-if="asset.model">
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ asset.model }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.asset.model") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line v-if="asset.serialNumber">
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ asset.serialNumber }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t("components.supportCases.sidebar.asset.serialNumber")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="user">
          <v-expansion-panel-header>
            <div>
              <v-icon color="teal darken-3">mdi-account</v-icon>
              <span class="ml-2 sidebar-header-text">
                {{ $t("components.supportCases.sidebar.user.title") }}
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.user.name") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    v-if="user.id !== null"
                    icon
                    v-inertia
                    :href="route('users.edit', user.id)"
                  >
                    <v-icon>mdi-open-in-app</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ user.email }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.user.email") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ user.role.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("components.supportCases.sidebar.user.role") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- <v-expansion-panel v-if="selectedCase.manualContactEmail">
          <v-expansion-panel-header>
            <div>
              <v-icon color="teal darken-3">mdi-wrench</v-icon>
              <span class="ml-2 sidebar-header-text">
                Manual changes
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ selectedCase.manualContactEmail }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Contact email
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel> -->
        <v-expansion-panel
          v-if="selectedCase.relations && selectedCase.relations.length"
        >
          <v-expansion-panel-header>
            <div>
              <v-icon color="teal darken-3">mdi-link</v-icon>
              <span class="ml-2 sidebar-header-text">
                {{ $t("components.supportCases.sidebar.supportCaseRelations") }}
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-list-item
                two-line
                v-for="relation in selectedCase.relations"
                :key="`relation-${selectedCase.id}-${relation.relatedCase.id}`"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    {{
                      `${relation.relatedCase.name} [${relation.relatedCase.referenceKey}]`
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ relation.relationType }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    v-inertia
                    :href="
                      route('support-cases.index', {
                        selectedCaseId: relation.relatedCase.id,
                      })
                    "
                  >
                    <v-icon>mdi-open-in-app</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn
                    icon
                    :disabled="deletingRelation"
                    @click="() => removeRelation(relation.relatedCase.id)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-sheet>
</template>
<script>
import { toLocaleUTCDateString, normalizeISOString } from "@/util/dateTime";
import { fixedAttributes } from "../../util/fixedAssetData";

export default {
  props: {
    selectedCase: Object,
    isMobileLayout: Boolean,
    rootCauses: Array,
  },
  data() {
    return {
      expandedPanels: [0],
      editingName: false,
      name: this.selectedCase.name,
      deletingRelation: false,
    };
  },
  computed: {
    originalToEmails() {
      return JSON.parse(this.selectedCase?.originalToEmails);
    },
    location() {
      return this.selectedCase?.location;
    },
    locationType() {
      return this.location?.locationType;
    },
    solution() {
      return this.selectedCase?.solution;
    },
    solutionTemplate() {
      return this.solution?.solutionTemplate;
    },
    asset() {
      const asset = this.selectedCase?.asset;

      if (!asset) return null;

      return {
        id: asset.id,
        name: this.getAssetStringValue(asset, fixedAttributes.masterDataNameId),
        model: this.getAssetStringValue(
          asset,
          fixedAttributes.masterDataModelId
        ),
        serialNumber: this.getAssetStringValue(
          asset,
          fixedAttributes.masterDataSerialNumberId
        ),
      };
    },
    user() {
      return this.selectedCase?.user;
    },
    organization() {
      if (this.selectedCase?.solution) {
        return this.selectedCase.solution.organization.name;
      }
      if (this.selectedCase?.location) {
        return this.selectedCase.location.organization.name;
      }
      return this.selectedCase?.organization
        ? this.selectedCase.organization.name
        : "";
    },
    formattedRootCauses() {
      return this.rootCauses.map((rootCause) => {
        const formattedRootCause = this.$t(`common.rootCauses.${rootCause}`);

        const item = {
          text: null,
          value: rootCause,
        };

        const issueCauses = [
          this.$t("common.rootCauses.Other"),
          this.$t("common.rootCauses.Installation"),
        ];

        if (issueCauses.includes(formattedRootCause)) {
          item.text = this.$t("pages.supportCases.form.issue", {
            type: formattedRootCause,
          });
        } else if (formattedRootCause === this.$t("common.rootCauses.Spam")) {
          item.text = formattedRootCause;
        } else {
          item.text = this.$t("pages.supportCases.form.relatedIssue", {
            type: formattedRootCause,
          });
        }

        return item;
      });
    },
  },
  methods: {
    toggleEditingName() {
      this.editingName = !this.editingName;
    },
    submitName() {
      this.editingName = !this.editingName;
      this.$emit('editCase', { name: this.newName });
    },
    formatDate(date) {
      if (!date) return this.$t("components.supportCases.sidebar.notAvailable");

      const isoDateTime = normalizeISOString(date);

      return toLocaleUTCDateString(isoDateTime);
    },
    removeRelation(relatedCaseId) {
      this.deletingRelation = true;
      this.$inertia.delete(
        this.route("support-cases.remove-relation", {
          id: this.selectedCase.id,
          relatedcaseid: relatedCaseId,
        }),
        {},
        {
          preserveState: true,
          only: ["selectedCase"],
          onFinish: () => {
            this.deletingRelation = false;
          },
        }
      );
    },
    getAssetStringValue(asset, attributeId) {
      return asset?.assetAttributeLinks?.find(
        (x) => x.attributeId === attributeId
      )?.stringValue;
    },
  },
};
</script>
<style scoped>
.sidebar-header-text {
  vertical-align: center;
}
.height-0 {
  height: 0px;
}
.whitespace-pre-line {
  white-space: pre-line;
}
.text-overflow-clip {
  text-overflow: clip;
}
</style>
