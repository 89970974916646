<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-row no-gutters>
        <v-col md="4">
          <v-autocomplete
            v-model="defaultCurrencyIdProxy"
            :items="currencies"
            item-text="currencyCode"
            item-value="id"
            filled
            hide-details
            :label="$t('pages.settings.index.fields.standardCurrency')"
          />
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    tabHref: String,
    defaultCurrencyId: String,
    currencies: Array,
  },
  emits: ["updateForm"],
  computed: {
    defaultCurrencyIdProxy: {
      get() {
        return this.defaultCurrencyId;
      },
      set(value) {
        this.$emit("updateForm", "defaultCurrencyId", value);
      },
    },
  },
};
</script>
