<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-row>
        <v-col md="6">
          <v-row dense>
            <v-col class="d-flex flex-row flex-wrap">
              <template v-for="file in filteredOrganizationalFiles">
                <v-tooltip top :key="file.id">
                  <template #activator="{ on, attrs }">
                    <v-chip
                      outlined
                      color="primary"
                      class="mt-1 mr-1"
                      :href="
                        route('api.files.content', {
                          id: file.id,
                          filename: file.originalName,
                        })
                      "
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                      close
                      @click:close="$emit('removeOrganizationalFile', file.id)"
                    >
                      <span class="file-chip-content">
                        {{ file.originalName }}
                      </span>
                    </v-chip>
                  </template>
                  <span>{{ file.originalName }}</span>
                </v-tooltip>
              </template>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4" align-self="center">
              {{ $t("pages.settings.index.fields.organizationalFiles") }}:
            </v-col>
            <v-col align-self="center">
              <v-file-input
                prepend-icon=""
                multiple
                dense
                hide-details
                v-model="newOrganizationalFilesProxy"
                show-size
                :rules="fileSizeRules"
                solo
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    tabHref: String,
    organizationalFiles: Array,
    organizationalFileIds: Array,
    newOrganizationalFiles: Array,
  },
  emits: ["updateForm", "removeOrganizationalFile"],
  data() {
    return {
      fileSizeRules: [
        (value) =>
          !value ||
          value.size < 30000000 ||
          "File size should be less than 26 MB",
      ],
    };
  },
  computed: {
    newOrganizationalFilesProxy: {
      get() {
        return this.newOrganizationalFiles;
      },
      set(value) {
        this.$emit("updateForm", "newOrganizationalFiles", value);
      },
    },
    filteredOrganizationalFiles() {
      return this.organizationalFiles.filter((x) =>
        this.organizationalFileIds.includes(x.id)
      );
    },
  },
};
</script>
<style scoped>
.file-chip-content {
  max-width: 16rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
