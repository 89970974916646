<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <v-btn
        :class="className"
        icon
        v-bind="attrs"
        v-on="on"
        @click="downloadFile"
      >
        <v-img
          contain
          :src="getFileThumbnail()"
          max-height="25"
          max-width="25"
        ></v-img>
      </v-btn>
    </template>
    <span>{{ file.originalName }}</span>
  </v-tooltip>
</template>
<script>
import SvgPdf from "../../assets/svg/file-pdf-regular.svg";
import SvgExcel from "../../assets/svg/file-excel-regular.svg";
import SvgWord from "../../assets/svg/file-word-regular.svg";
import SvgImage from "../../assets/svg/image-regular.svg";
import SvgFolder from "../../assets/svg/folder-solid.svg";
import SvgFile from "../../assets/svg/file-regular.svg";

export default {
  props: {
    className: String,
    file: Object,
  },
  data() {
    return {
      icons: {
        SvgPdf,
        SvgExcel,
        SvgWord,
        SvgImage,
        SvgFolder,
        SvgFile,
      },
    };
  },
  computed: {
    downloadLink() {
      return this.route("api.files.content", {
        id: this.file.id,
        filename: this.file.originalName,
        thumbnail: true,
      });
    },
  },
  methods: {
    getFileThumbnail() {
      switch (this.file.fileType) {
        case "pdf":
          return this.icons.SvgPdf;
        case "spreadsheet":
          return this.icons.SvgExcel;
        case "document":
          return this.icons.SvgWord;
        case "image":
          return this.downloadLink;
        case "folder":
          return this.icons.SvgFolder;
        default:
          return this.icons.SvgFile;
      }
    },
    downloadFile() {
      if (!this.downloadLink) return;

      fetch(this.downloadLink)
        .then((res) => res.blob())
        .then((blob) => {
          const anchor = document.createElement("a");
          anchor.href = URL.createObjectURL(blob);
          anchor.download = this.file.originalName;

          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        });
    },
  },
};
</script>
