<template>
  <v-app>
    <v-container fluid ma-0 pa-0 class="fill-height cyan darken-4">
      <v-row class="fill-height">
        <v-col
          cols="5"
          class="justify-center d-none d-sm-flex align-center white--text"
          no-gutters
        >
          <h1 class="text-h2 ma-md-0 ma-10">LCD PRIME</h1>
          <div id="bg-circle" class="d-flex justify-center align-center">
            <div id="bg-inner-circle" class="cyan darken-4"></div>
          </div>
        </v-col>
        <v-col class="white d-flex" cols="12" sm="7">
          <page-transition>
            <slot />
          </page-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import PageTransition from "@/Shared/PageTransition";
export default {
  components: {
    PageTransition,
  },
  computed: {
    auth() {
      return this.$page.props.auth;
    },
  },
  mounted() {
    this.$i18n.locale = this.auth?.user?.preferredLocale ?? "en";
  },
};
</script>
<style scoped>
.v-container {
  width: 60%;
}
.text-h2 {
  z-index: 1;
}

#bg-circle {
  position: absolute;
  width: 70vh;
  height: 70vh;
  border-radius: 100%;
  left: -36vh;
  background-color: #ffffff3a;
}
#bg-inner-circle {
  position: absolute;
  width: 60%;
  height: 60%;
  border-radius: 100%;
}
</style>
