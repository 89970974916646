<template>
  <page-modal v-model="show">
    <template #title>
      <span>
        {{
          $t("pages.assets.bulkEdit.title", { attributeName: attribute?.name })
        }}
      </span>
      <v-spacer></v-spacer>
      <v-stepper
        v-model="currentStep"
        flat
        class="base-background-color min-w-1/2"
      >
        <v-stepper-header class="h-fit-content">
          <template v-for="step in steps">
            <v-stepper-step
              :key="`step-${step}`"
              :complete="currentStep > step"
              :step="step"
              class="px-0 py-0 ml-6 mr-6 h-fit-content"
            />
            <v-divider v-if="step !== steps" :key="step"></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
    </template>
    <template #body>
      <asset-bulk-import-step-one
        v-if="currentStep === 1"
        :filterOption="form.filterOption"
        :portfolioId="form.filterPortfolioId"
        :allCount="allAssetsCount"
        :filteredCount="filteredAssetsCount"
        :selectedCount="selectedAssetsCount"
        :portfolioAssetCount="portfolioAssetCount"
        :portfolios="portfolios"
        @updateForm="updateForm"
      />
      <asset-bulk-import-step-two
        v-if="currentStep === 2"
        :attribute="attribute"
        :currencies="currencies"
        :stringValue="form.stringValue"
        :decimalValue="form.decimalValue"
        :dateTimeValue="form.dateTimeValue"
        :boolValue="form.boolValue"
        :attributeSelectOptionId="form.attributeSelectOptionId"
        @updateForm="updateForm"
      />
      <asset-bulk-import-step-three
        v-if="currentStep === 3"
        :attributeName="attribute?.name"
        :assetCount="currentAssetCount"
      />
    </template>
    <template #actions>
      <v-btn color="blue darken-1" text @click="hideModal()">
        <v-icon class="black--text">mdi-close</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ $t("common.actions.close") }}
        </p>
      </v-btn>
      <template v-if="validationMessage">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn color="blue darken-1" text v-bind="attrs" v-on="on">
              <v-icon class="black--text">
                {{
                  currentStep < steps ? "mdi-chevron-right" : "mdi-content-save"
                }}
              </v-icon>
              <p class="teal--text text--darken-4 mb-0">
                {{
                  currentStep < steps
                    ? $t("pages.assets.bulkEdit.next")
                    : $t("pages.assets.bulkEdit.confirm")
                }}
              </p>
            </v-btn>
          </template>
          <span>{{ validationMessage }}</span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-btn
          v-if="currentStep < steps"
          color="blue darken-1"
          text
          @click="incrementStep"
        >
          <v-icon class="black--text">mdi-chevron-right</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.assets.bulkEdit.next") }}
          </p>
        </v-btn>
        <v-btn
          v-else
          color="blue darken-1"
          text
          :loading="loading"
          :disabled="loading"
          @click="saveChanges"
        >
          <v-icon class="black--text">mdi-content-save</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.assets.bulkEdit.confirm") }}
          </p>
        </v-btn>
      </template>
    </template>
  </page-modal>
</template>
<script>
import PageModal from "../PageModal.vue";

import AssetBulkImportStepOne from "./BulkEditSteps/AssetBulkEditStepOne.vue";
import AssetBulkImportStepTwo from "./BulkEditSteps/AssetBulkEditStepTwo.vue";
import AssetBulkImportStepThree from "./BulkEditSteps/AssetBulkEditStepThree.vue";

import { assetListFilterOptions } from "../../util/assetListFilterOptions";
import { removeTimezoneOffset } from "@/util/dateTime";
import { serialize } from "object-to-formdata";

export default {
  components: {
    PageModal,
    AssetBulkImportStepOne,
    AssetBulkImportStepTwo,
    AssetBulkImportStepThree,
  },
  props: {
    value: Boolean,
    attribute: Object,
    portfolios: Array,
    assets: Object,
    selectedAssets: Array,
    assetLayout: Object,
    attributeFilters: Array,
    postFilters: Object,
    portfolioIds: Array,
    currencies: Array,
    isTemplatePage: Boolean,
  },
  data() {
    return {
      steps: 3,
      currentStep: 1,
      loading: false,
      form: this.$inertia.form({
        filterOption: null,
        filterPortfolioId: null,

        stringValue: null,
        decimalValue: null,
        dateTimeValue: null,
        boolValue: null,
        attributeSelectOptionId: null,
        newFiles: null,
      }),
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    allAssetsCount() {
      return this.assets?.originalCount ?? 0;
    },
    filteredAssetsCount() {
      return this.assets?.totalCount ?? 0;
    },
    selectedAssetsCount() {
      return this.selectedAssets?.length ?? 0;
    },
    selectedPortfolio() {
      if (!this.form.filterPortfolioId) return null;

      return (
        this.portfolios?.find((x) => x.id === this.form.filterPortfolioId) ??
        null
      );
    },
    portfolioAssetCount() {
      return this.selectedPortfolio?.assetCount ?? 0;
    },
    currentAssetCount() {
      switch (this.form.filterOption) {
        case assetListFilterOptions.allResults:
          return this.allAssetsCount;
        case assetListFilterOptions.selectedAssets:
          return this.selectedAssetsCount;
        case assetListFilterOptions.filteredAssets:
          return this.filteredAssetsCount;
        case assetListFilterOptions.specificPortfolio:
          return this.portfolioAssetCount;
        default:
          return 0;
      }
    },
    validationMessage() {
      if (this.currentStep === 1 && this.currentAssetCount === 0)
        return this.$t("pages.assets.bulkEdit.validation.noAssets");

      return null;
    },
  },
  methods: {
    hideModal(reset) {
      if (reset) this.$emit("reset");

      this.show = false;
      this.resetModal();
    },
    incrementStep() {
      this.currentStep++;
    },
    resetModal() {
      this.currentStep = 1;
      this.form.reset();
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
    saveChanges() {
      const portfolioIds =
        this.form.filterOption === assetListFilterOptions.specificPortfolio
          ? [this.form.filterPortfolioId]
          : [...this.portfolioIds];

      let formData = {
        attributeId: this.attribute.id,
        filterOption: this.form.filterOption,
        stringValue: this.form.stringValue,
        decimalValue: this.form.decimalValue,
        dateTimeValue: this.form.dateTimeValue
          ? removeTimezoneOffset(this.form.dateTimeValue)
          : null,
        boolValue: this.form.boolValue,
        attributeSelectOptionId: this.form.attributeSelectOptionId,
        newFiles: this.form.newFiles,
        postFilters: this.postFilters,
        assetLayout: {
          ...this.assetLayout,
          attributeFilters: this.attributeFilters,
        },
        page: this.assets.currentPage,
        pageSize: this.assets.pageSize,
        portfolioIds: portfolioIds,
        assetIds: this.selectedAssets?.map((x) => x.id) ?? [],
        template: this.isTemplatePage,
      };

      formData = serialize(formData, {
        noFilesWithArrayNotation: true,
        indices: true,
      });

      this.loading = true;

      fetch(this.route("assets.bulk-edit"), {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          if (!res.ok) Promise.reject();
        })
        .then(() => this.hideModal(true))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.v-stepper__step:deep(.v-stepper__step__step) {
  margin-right: 0px !important;
}
</style>
