<template>
  <v-app>
    <v-navigation-drawer
      v-if="!hideMenu"
      v-model="navigationDrawer"
      app
      color="#E2DEE3"
      light
      width="200px"
    >
      <v-app-bar flat class="d-flex justify-center" color="#E2DEE3">
        <h1 class="font-weight-light">
          <img
            src="../assets/svg/prime-logo.svg"
            alt="Prime"
            style="width: 100px; height: 100px"
          />
        </h1>
      </v-app-bar>
      <menu-items :url="url()" />

      <template v-slot:append>
        <template v-if="hasMultipleOrganizations">
          <div class="px-2 py-4 body-2">
            <div class="d-flex flex-column align-center">
              <label class="text-subtitle-1 pb-2 w-full">
                {{ $t("components.layout.currentOrganization") }}
              </label>
              <organization-menu />
            </div>
          </div>
          <v-divider class="navigation-divider" />
        </template>
        <div class="px-2 py-4 #E2DEE3 body-2" style="visibility: hidden">
          <div class="d-flex align-center">
            <notification-menu />
          </div>
        </div>
        <v-divider class="navigation-divider" />
        <div class="px-4 #E2DEE3 py-4 body-2">
          <div class="d-flex align-center">
            <img
              class="mr-4"
              style="height: 48px; width: 48px"
              :src="`https://eu.ui-avatars.com/api/?name=${$page.props.auth.user.name}&rounded=true&size=48&background=cef2ef`"
            />
            <div class="d-flex flex-column">
              <div class="black--text">{{ $page.props.auth.user.name }}</div>
              <inertia-link
                :href="route('profile')"
                class="text-decoration-none black--text text--lighten-2"
                >{{ $t("components.layout.viewProfile") }}</inertia-link
              >
            </div>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main class="base-background-color">
      <v-container fluid fill-height class="align-start main-container">
        <div
          v-if="!hideMenu"
          class="hidden-lg-and-up"
          style="height: 48px"
        ></div>
        <v-app-bar
          v-if="!hideMenu"
          color="teal darken-4"
          dense
          dark
          fixed
          class="hidden-lg-and-up"
        >
          <v-app-bar-nav-icon @click="navigationDrawer = !navigationDrawer">
          </v-app-bar-nav-icon>
        </v-app-bar>
        <slot />
        <v-snackbar
          outlined
          color="error"
          :value="showError"
          :timeout="2000"
          v-if="errorMessage && !$page.props.flash.hidePopup"
        >
          {{ errorMessage }}
        </v-snackbar>
        <v-snackbar
          outlined
          color="success"
          :value="showSuccess"
          :timeout="2000"
          v-if="successMessage && !$page.props.flash.hidePopup"
        >
          {{ successMessage }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import MenuItems from "@/Shared/MenuItems";
import NotificationMenu from "@/Shared/NotificationMenu";
import OrganizationMenu from "@/Shared/OrganizationMenu";

import { Inertia } from "@inertiajs/inertia";
export default {
  components: {
    MenuItems,
    NotificationMenu,
    OrganizationMenu,
  },
  computed: {
    snackbar() {
      return this.$page.props.flash.success != "";
    },
    successMessage() {
      const success = this.$page.props.flash.success;

      const languageKey = `common.flashMessages.success.${success}`;

      if (success && this.$te(languageKey)) return this.$t(languageKey);

      return success;
    },
    errorMessage() {
      const error = this.$page.props.flash.error;

      const languageKey = `common.flashMessages.error.${error}`;

      if (error && this.$te(languageKey)) return this.$t(languageKey);

      return error;
    },
    auth() {
      return this.$page.props.auth;
    },
    hasMultipleOrganizations() {
      if (!this.auth) return false;

      return this.auth.organizations?.length > 1;
    },
  },
  data: () => ({
    navigationDrawer: window.innerWidth >= 1264 ? true : false,
    drawer: true,
    mini: true,
    items: [
      {
        title: "Dashboard",
        route: "dashboard.index",
        icon: "mdi-view-dashboard",
      },
      {
        title: "Organizations",
        route: "organizations.index",
        icon: "mdi-file-tree",
      },
    ],
    showSuccess: false,
    showError: false,
  }),
  methods: {
    url() {
      return location.pathname.substr(1);
    },
    enableSuccess() {
      this.showSuccess = true;

      setTimeout(() => (this.showSuccess = false), 2000);
    },
    enableError() {
      this.showError = true;

      setTimeout(() => (this.showError = false), 2000);
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    successMessage() {
      this.enableSuccess();
    },
    errorMessage() {
      this.enableError();
    },
  },
  mounted() {
    const preferredLocale = this.auth.user.preferredLocale ?? "en";

    const selectedLocale = this.$i18n.availableLocales.includes(preferredLocale)
      ? preferredLocale
      : "en";

    this.$i18n.locale = selectedLocale;

    Inertia.on("success", () => {
      this.drawer = false;
    });

    Inertia.on("finish", () => {
      this.enableSuccess();
      this.enableError();
    });
  },
  updated() {
    const title = this.$te(this.title) ? this.$t(this.title) : this.title;

    document.title = title
      ? `${title} - LCDPrime Backoffice`
      : "LCDPrime Backoffice";
  },
};
</script>
<style>
.main-container > * {
  width: 100%;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}

.max-w-1\/2 {
  max-width: 50%;
}

.max-w-4\/5 {
  max-width: 80%;
}
.fill-width {
  width: 100%;
}
.navigation-divider {
  border-color: rgba(255, 255, 255, 0.24) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.relative {
  position: relative;
}
.v-application {
  caret-color: red !important;
}
.base-background-color {
  background-color: #e2dee3 !important;
}
.font-size-4 {
  font-size: 1rem !important;
}
.transition-none {
  transition-property: none !important;
}
.min-w-1\/2 {
  min-width: 50% !important;
}
.min-w-6\/10 {
  min-width: 60% !important;
}
.h-fit-content {
  height: fit-content !important;
}
</style>
