<template>
  <div class="fill-height" style="height: 60vh">
    <strong>{{ $t("pages.assets.bulkEdit.pleaseConfirm") }}</strong>
    <div
      class="d-flex flex-column justify-content-center"
      style="height: calc(100% - 18px)"
    >
      <v-row align-content="center" justify="space-between">
        <v-col cols="12">
          <i18n
            path="pages.assets.bulkEdit.confirmChanges"
            tag="h4"
            class="text-h4 text-center black--text fill-width"
          >
            <span class="green--text">{{ attributeName }}</span>
            <span class="red--text">{{ assetCount }}</span>
          </i18n>
        </v-col>
        <v-col cols="12" class="mt-4">
          <h4 class="text-h4 black--text fill-width text-center">
            {{ $t("pages.assets.bulkEdit.areYouSure") }}
          </h4>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    attributeName: String,
    assetCount: Number,
  },
};
</script>
