<template>
  <div class="flex d-flex justify-center align-center relative">
    <inertia-link id="back-arrow" :href="route('login')">
      <v-icon x-large>mdi-arrow-left</v-icon>
    </inertia-link>

    <v-form class="col-10 col-md-7" @submit.prevent="submit">
      <h3 class="text-h4 cyan--text text--darken-4 font-weight-light">
        {{ $t("pages.resetPassword.title") }}
      </h3>
      <p class="caption mb-6">
        {{ $t("pages.resetPassword.enterPassword") }}
      </p>
      <input
        type="text"
        name="email"
        autocomplete="username email"
        :value="userEmail"
        class="d-none"
      />
      <v-text-field
        v-model="form.password"
        :label="$t('pages.resetPassword.password')"
        name="password"
        type="password"
        :error-messages="form.errors.password"
        autocomplete="new-password"
      />
      <v-text-field
        v-model="form.confirmPassword"
        :label="$t('pages.resetPassword.confirmPassword')"
        name="password"
        type="password"
        :error-messages="form.errors.confirmPassword"
        autocomplete="new-password"
      />
      <div class="d-flex justify-end align-center mt-4">
        <v-btn type="submit" large color="primary" :disabled="form.processing">
          {{ $t("common.actions.update") }}
        </v-btn>
      </div>
      <v-alert
        type="error"
        :class="{ hidden: !form.errors.alert }"
        class="mt-8"
      >
        {{ form.errors.alert }}
      </v-alert>
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";
import { objectToFormData } from "@/util/formData";

export default {
  metaInfo: { title: "pages.resetPassword.pageTitle" },
  layout: Layout,
  props: {
    userId: String,
    resetToken: String,
    userEmail: String,
  },
  data() {
    return {
      form: this.$inertia.form({
        userId: this.userId,
        resetToken: this.resetToken,
        password: null,
        confirmPassword: null,
      }),
    };
  },
  methods: {
    submit() {
      this.form
        .transform((data) => objectToFormData(data))
        .post(this.route("password.update"));
    },
  },
};
</script>
<style scoped>
.relative {
  position: relative;
}

#back-arrow {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: none;
}

.hidden {
  visibility: hidden;
}
</style>
