var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tab-item',{attrs:{"value":_vm.tabValue}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('asset-attribute-link-input',{attrs:{"attribute":_vm.assessmentPrice1Attribute,"value":_vm.assessmentPrice1Data,"currencies":_vm.currencies,"secondValue":_vm.assessmentPrice1CurrencyData},on:{"input":function($event){return _vm.updateData(_vm.fixedAttributes.assessmentPrice1Id, $event)},"input_secondValue":function($event){return _vm.updateSecondValueData(
                _vm.fixedAttributes.assessmentPrice1Id,
                arguments[0]
              )},"currency_changed":function($event){return _vm.updateCurrencyValue(
                _vm.assessmentPrice1Attribute,
                $event,
                _vm.assessmentPrice1CurrencyData
              )},"currencyCode_changed":function($event){return _vm.updateCurrencyCodeValue(
                _vm.assessmentPrice1Attribute,
                _vm.assessmentPrice1Data,
                $event
              )}}}),_c('asset-attribute-link-input',{attrs:{"attribute":_vm.assessmentPrice2Attribute,"value":_vm.assessmentPrice2Data,"currencies":_vm.currencies,"secondValue":_vm.assessmentPrice2CurrencyData},on:{"input":function($event){return _vm.updateData(_vm.fixedAttributes.assessmentPrice2Id, $event)},"input_secondValue":function($event){return _vm.updateSecondValueData(
                _vm.fixedAttributes.assessmentPrice2Id,
                arguments[0]
              )},"currency_changed":function($event){return _vm.updateCurrencyValue(
                _vm.assessmentPrice2Attribute,
                $event,
                _vm.assessmentPrice2CurrencyData
              )},"currencyCode_changed":function($event){return _vm.updateCurrencyCodeValue(
                _vm.assessmentPrice2Attribute,
                _vm.assessmentPrice2Data,
                $event
              )}}})],1),_c('v-col',{attrs:{"cols":"5","offset":"1"}},[_c('asset-attribute-link-input',{attrs:{"attribute":_vm.assessmentPrice3Attribute,"value":_vm.assessmentPrice3Data,"currencies":_vm.currencies,"secondValue":_vm.assessmentPrice3CurrencyData},on:{"input":function($event){return _vm.updateData(_vm.fixedAttributes.assessmentPrice3Id, $event)},"input_secondValue":function($event){return _vm.updateSecondValueData(
                _vm.fixedAttributes.assessmentPrice3Id,
                arguments[0]
              )},"currency_changed":function($event){return _vm.updateCurrencyValue(
                _vm.assessmentPrice3Attribute,
                $event,
                _vm.assessmentPrice3CurrencyData
              )},"currencyCode_changed":function($event){return _vm.updateCurrencyCodeValue(
                _vm.assessmentPrice3Attribute,
                _vm.assessmentPrice3Data,
                $event
              )}}}),_c('asset-attribute-link-input',{attrs:{"attribute":_vm.assessmentPrice4Attribute,"value":_vm.assessmentPrice4Data,"currencies":_vm.currencies,"secondValue":_vm.assessmentPrice4CurrencyData},on:{"input":function($event){return _vm.updateData(_vm.fixedAttributes.assessmentPrice4Id, $event)},"input_secondValue":function($event){return _vm.updateSecondValueData(
                _vm.fixedAttributes.assessmentPrice4Id,
                arguments[0]
              )},"currency_changed":function($event){return _vm.updateCurrencyValue(
                _vm.assessmentPrice4Attribute,
                $event,
                _vm.assessmentPrice4CurrencyData
              )},"currencyCode_changed":function($event){return _vm.updateCurrencyCodeValue(
                _vm.assessmentPrice4Attribute,
                _vm.assessmentPrice4Data,
                $event
              )}}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }