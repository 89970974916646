<template>
  <div class="fill-height" style="min-height: 60vh">
    <v-row v-if="hasErrors" class="d-flex justify-end align-center mb-2 mx-2">
      <p class="mb-0 mr-4">
        {{ $t("pages.locations.import.stepThree.showAllData") }}
      </p>
      <v-switch v-model="onlyErroredRows" hide-details class="mt-0">
        <template #label>
          <i18n
            path="pages.locations.import.stepThree.onlyShow"
            tag="p"
            class="mb-0 ml-3"
          >
            <span class="red--text">{{
              $t("pages.locations.import.stepThree.invalid")
            }}</span>
          </i18n>
        </template>
      </v-switch>
    </v-row>
    <v-data-table
      v-model="selectedRows"
      class="table-container"
      :class="hasErrors ? 'compressed' : ''"
      :headers="tableHeaders"
      height="calc(100% - 59px)"
      :items="filteredLocations"
      :item-class="getLocationRowClass"
      item-key="index"
      show-select
      dense
      disable-sort
      fixed-header
      :options.sync="tableOptions"
      :footer-props="tableFooterProps"
    >
      <template
        v-for="header in headers"
        v-slot:[`header.row.${header.mapping}`]
      >
        <v-autocomplete
          v-if="getMapping(header.mapping).type === 'field'"
          class="my-2"
          :class="getMappingField(header.mapping) === null ? 'unknown' : ''"
          :key="header.mapping"
          :items="
            getMappingField(header.mapping) !== null
              ? fieldOptionsWithSkip()
              : fieldOptionsWithUnknown(header.name)
          "
          :item-disabled="getFieldOptionDisabled"
          :value="getMappingField(header.mapping)"
          @change="(value) => handleHeaderMappingChanged(header.mapping, value)"
          item-text="name"
          item-value="id"
          dense
          solo
          hide-details
        >
          <template #item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{
                  item.name.replace(
                    " " +
                      $t("pages.locations.import.stepThree.unknownSuffixPart"),
                    ""
                  )
                }}
                <span v-if="item.unknown" class="yellow--text accent-3 ml-1">
                  {{ $t("pages.locations.import.stepThree.unknownSuffixPart") }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </template>
      <template
        v-for="header in headers"
        v-slot:[`item.row.${header.mapping}`]="{ item }"
      >
        <div
          :key="header.mapping"
          class="cell-container"
          :class="
            item.columnErrorMessages && item.columnErrorMessages[header.mapping]
              ? 'red'
              : ''
          "
        >
          <v-autocomplete
            v-if="
              editingRows.includes(item) &&
              selectFields.includes(getMappingField(header.mapping))
            "
            :items="getSelectItemsByFieldName(getMappingField(header.mapping))"
            :value="item.row[header.mapping]"
            item-text="name"
            item-value="name"
            solo
            dense
            hide-details
            clearable
            @change="
              (value) => updateLocationField(item, header.mapping, value)
            "
          ></v-autocomplete>
          <v-text-field
            v-else-if="editingRows.includes(item)"
            :value="item.row[header.mapping]"
            solo
            dense
            hide-details
            clearable
            class="my-2"
            @input="(value) => updateLocationField(item, header.mapping, value)"
            @keydown="(event) => saveEditingRow(event, item)"
          ></v-text-field>
          <span v-else>{{ getCellValue(item.row, header.mapping) }}</span>
          <div
            v-if="
              item.columnErrorMessages &&
              item.columnErrorMessages[header.mapping] &&
              !editingRows.includes(item)
            "
            class="flex-grow-1"
          ></div>
          <v-tooltip
            v-if="
              item.columnErrorMessages &&
              item.columnErrorMessages[header.mapping] &&
              !editingRows.includes(item)
            "
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <div>
              <h6 class="text-h6">
                {{ $t("pages.locations.import.stepThree.formatInvalid") }}
              </h6>
              <p class="text-body-1">
                {{
                  getCellFormatHelpText(
                    item.columnErrorMessages[header.mapping]
                  )
                }}
              </p>
              <ul
                v-if="
                  getCellValueExamples(
                    item.columnErrorMessages[header.mapping],
                    getMappingField(header.mapping)
                  )
                "
              >
                <li>{{ $t("pages.locations.import.stepThree.eg") }}</li>
                <ul class="help-text-sublist">
                  <li
                    v-for="example in getCellValueExamples(
                      item.columnErrorMessages[header.mapping],
                      getMappingField(header.mapping)
                    )"
                    :key="example"
                  >
                    {{ example }}
                  </li>
                </ul>
              </ul>
            </div>
          </v-tooltip>
        </div>
      </template>
      <template #item.action="{ item }">
        <v-row class="actionrow" justify="space-between">
          <v-icon class="ml-2" small @click="toggleLocationEditMode(item)">{{
            editingRows.includes(item) ? "mdi-content-save" : "mdi-pencil"
          }}</v-icon>
          <v-icon class="mr-2" small @click="removeLocation(item)"
            >mdi-trash-can-outline</v-icon
          >
        </v-row>
      </template>
      <template #footer.prepend>
        <v-row justify="space-between" class="ml-0 mr-8 align-center">
          <v-btn text small @click="removeLocationRange">
            {{ $t("pages.locations.import.stepThree.deleteSelected") }}
          </v-btn>
          <p
            class="mb-0"
            :class="hasErrors && onlyErroredRows ? 'red--text' : ''"
          >
            {{
              $t("pages.locations.import.stepThree.locationsFields", {
                locationAmount: filteredLocations.length,
                fieldAmount: selectedFieldCount,
              })
            }}
          </p>
        </v-row>
      </template>
      <template #footer.page-text="pageTextProps">
        {{ $t("common.dataTable.visibleRows", pageTextProps) }}
      </template>
    </v-data-table>
    <v-row dense>
      <v-col>
        <v-checkbox
          class="mt-0"
          :input-value="updateOnId"
          :label="$t('pages.locations.import.stepThree.updateOnId')"
          @change="(value) => $emit('updateOnIdChanged', value)"
          hide-details
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { footerProps } from "@/util/dataTable";
import { locationImportFields } from "@/util/importFields";

export default {
  props: {
    fields: Array,
    headerToFieldMappings: Object,
    locationRows: Array,
    isEditing: Boolean,
    hasErrors: Boolean,
    updateOnId: Boolean,
    locationTypes: Array,
    countries: Array,
  },
  emits: [
    "isEditingUpdated",
    "locationRemoved",
    "locationsRemoved",
    "headerMappingChanged",
    "locationFieldUpdated",
  ],
  data() {
    return {
      tableOptions: {
        page: 1,
        itemsPerPage: 50,
      },
      tableFooterProps: {
        ...footerProps,
        itemsPerPageOptions: [50, 100, 500, -1],
      },
      selectedRows: [],
      editingRows: [],
      onlyErroredRows: true,
      selectFields: [
        locationImportFields.locationType,
        locationImportFields.country,
      ],
    };
  },
  computed: {
    headers() {
      if (!this.headerToFieldMappings) return [];

      return Object.keys(this.headerToFieldMappings)
        .filter(
          (header) =>
            this.updateOnId ||
            this.headerToFieldMappings[header].fieldName !== "ID"
        )
        .map((header) => {
          const splitIndex = header.lastIndexOf("|");

          return {
            name: header.substring(0, splitIndex),
            index: parseInt(header.substring(splitIndex + 1)),
            mapping: header,
          };
        });
    },
    tableHeaders() {
      const headers = [];

      this.headers.forEach((header) => {
        headers.push({
          text: header.name,
          value: `row.${header.mapping}`,
          divider: true,
          width: 300,
        });
      });

      headers.push({
        text: this.$t("pages.locations.import.stepThree.action"),
        value: "action",
        width: 80,
        divider: true,
        align: "center fixed",
        class: "fixed",
      });

      return headers;
    },
    locations() {
      if (!this.locationRows) return [];

      return this.locationRows.map((location, index) => ({
        ...location,
        index,
      }));
    },
    filteredLocations() {
      if (!this.hasErrors || !this.onlyErroredRows) return this.locations;

      return this.locations.filter(
        (x) =>
          !!x.errorMessages?.length ||
          (!!x.columnErrorMessages &&
            !!Object.keys(x.columnErrorMessages).length) ||
          this.editingRows.includes(x)
      );
    },
    fieldOptions() {
      return this.fields
        .filter((x) => this.updateOnId || x !== "ID")
        .map((x) => ({ id: x, name: x }));
    },
    selectedFieldCount() {
      return Object.values(this.headerToFieldMappings).filter(
        (x) => x?.type === "field" && !!x.fieldName
      ).length;
    },
  },
  methods: {
    toggleLocationEditMode(locationRow) {
      if (this.editingRows.indexOf(locationRow) !== -1) {
        this.editingRows = this.editingRows.filter((x) => x !== locationRow);
      } else {
        this.editingRows.push(locationRow);
      }

      const isEditing = !!this.editingRows.length;

      if (isEditing !== this.isEditing) {
        this.$emit("isEditingUpdated", isEditing);
      }
    },
    removeLocation(locationRow) {
      if (!confirm(this.$t("pages.locations.import.stepThree.confirmDelete")))
        return;

      const originalLocationRow = this.locationRows[locationRow.index];

      this.$emit("locationRemoved", originalLocationRow);

      this.editingRows = this.editingRows.filter((x) => x !== locationRow);
    },
    removeLocationRange() {
      if (
        !this.selectedRows.length ||
        !confirm(
          this.$t("pages.locations.import.stepThree.confirmMultiDelete", {
            amount: this.selectedRows.length,
          })
        )
      )
        return;

      const originalLocationRows = [];

      this.selectedRows.forEach((row) => {
        originalLocationRows.push(this.locationRows[row.index]);
      });

      this.$emit("locationsRemoved", originalLocationRows);

      this.editingRows = this.editingRows.filter(
        (x) => !this.selectedRows.includes(x)
      );

      this.selectedRows = [];
    },
    fieldOptionsWithSkip() {
      const fields = [
        {
          id: null,
          name: this.$t("pages.locations.import.stepThree.skip"),
        },
      ].concat([...this.fieldOptions]);

      return fields;
    },
    fieldOptionsWithUnknown() {
      const fields = [
        {
          id: null,
          name: this.$t("pages.locations.import.stepThree.unknownSuffix"),
          unknown: true,
        },
      ].concat([...this.fieldOptions]);

      return fields;
    },
    handleHeaderMappingChanged(header, fieldName) {
      this.$emit("headerMappingChanged", header, fieldName);
    },
    getLocationRowClass(locationRow) {
      return this.editingRows.includes(locationRow) ? "yellow accent-3" : "";
    },
    getFieldOptionDisabled(fieldOption) {
      return Object.values(this.headerToFieldMappings).some(
        (x) =>
          x?.type === "field" &&
          x.fieldName === fieldOption.id &&
          x.fieldName !== null
      );
    },
    updateLocationField(locationRow, header, value) {
      const originalLocationRow = this.locationRows[locationRow.index];

      this.$emit("locationFieldUpdated", originalLocationRow, header, value);
    },
    saveEditingRow(event, locationRow) {
      if (event.key !== "Enter") return;

      this.toggleLocationEditMode(locationRow);
    },
    getCellFormatHelpText(errorType) {
      switch (errorType) {
        case "INVALID_VALUE_NUMBER":
          return this.$t(
            "pages.locations.import.stepThree.invalidValues.number"
          );
        case "INVALID_VALUE_SELECT":
          return this.$t(
            "pages.locations.import.stepThree.invalidValues.select"
          );
        case "INVALID_VALUE_ID":
          return this.$t("pages.locations.import.stepThree.invalidValues.id");
        case "INACCESSIBLE_ID":
          return this.$t("pages.locations.import.stepThree.accessErrors.id");
        case "NONEXISTANT_ID":
          return this.$t(
            "pages.locations.import.stepThree.accessErrors.idDoesntExist"
          );
        case "REQUIRED_VALUE":
          return this.$t(
            "pages.locations.import.stepThree.invalidValues.required"
          );
        case "UNCHANGABLE_ORGANIZATION_ID":
          return this.$t(
            "pages.locations.import.stepThree.accessErrors.organizationUnchangable"
          );
        default:
          return this.$t(
            "pages.locations.import.stepThree.invalidValues.unknown"
          );
      }
    },
    getCellValueExamples(errorType) {
      switch (errorType) {
        case "INVALID_VALUE_ID":
        case "INVALID_VALUE_NUMBER":
          return ["14"];
        default:
          return undefined;
      }
    },
    getMapping(headerMapping) {
      return this.headerToFieldMappings[headerMapping];
    },
    getMappingField(headerMapping) {
      const mapping = this.headerToFieldMappings[headerMapping];

      if (!mapping || mapping.type !== "field") return null;

      return mapping.fieldName;
    },
    getSelectItemsByFieldName(fieldName) {
      switch (fieldName) {
        case locationImportFields.locationType:
          return this.locationTypes;
        case locationImportFields.country:
          return this.countries.map((x) => ({ id: x, name: x }));
        default:
          return [];
      }
    },
    getCellValue(itemRow, headerMapping) {
      const fieldName = this.getMappingField(headerMapping);

      if (!this.selectFields.includes(fieldName)) return itemRow[headerMapping];

      const foundValue = this.getSelectItemsByFieldName(fieldName).find(
        (x) => x.name === itemRow[headerMapping]
      );

      return foundValue?.name ?? itemRow[headerMapping];
    },
  },
};
</script>
<style scoped>
.table-container {
  height: 60vh;
}

.table-container.compressed {
  height: 56vh;
}

.cell-container {
  height: 100%;
  width: calc(100% + 32px);
  margin-left: -16px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.help-text-sublist {
  list-style-type: circle;
}
</style>
<style>
.v-autocomplete.unknown .v-input__slot {
  background-color: #ffea00 !important;
  border-color: #ffea00 !important;
}

.table-container table thead tr th:nth-child(1),
.table-container table tbody tr td:nth-child(1) {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
</style>
