<template>
  <tr>
    <td />
    <td v-for="identifier in columnIdentifiers" :key="identifier">
      <timestamp-filter
        v-if="
          getAttributeFormatFromIdentifier(identifier) === 'DateTime' ||
          getAttributeFormatFromIdentifier(identifier) === 'Date'
        "
        :value="columnFilterData[`${identifier}-filterBy`]"
        :identifier="identifier"
        @submit="updateDateFilter"
      />
      <div v-else class="d-flex input-float-left">
        <v-select
          class="hide-caret filter-button mr-1"
          :value="columnFilterData[`${identifier}-filter`]"
          dense
          hide-details
          :items="filterOptions"
          item-value="value"
          solo
          @input="updateFilter(identifier, $event)"
        >
          <template v-slot:item="{ item }">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            <p class="mb-0">{{ item.text }}</p>
          </template>
          <template v-slot:selection="{ item }">
            <v-icon small>{{ item.icon }}</v-icon>
          </template>
        </v-select>
        <v-text-field
          class="filter-text-field"
          dense
          hide-details
          :value="columnFilterData[`${identifier}-filterBy`]"
          clearable
          @input="updateFilterBy(identifier, $event)"
          type="text"
        >
        </v-text-field>
      </div>
    </td>
    <td v-if="showActionRow" class="fixed">
      <v-row class="actionrow" justify="center"> </v-row>
    </td>
  </tr>
</template>
<script>
import TimestampFilter from "../../Components/Asset/Filters/TimestampFilter.vue";

export default {
  components: { TimestampFilter },
  props: {
    columnIdentifiers: Array,
    columnFilterData: Object,
    attributeFilters: Array,
    postFilters: Object,
    attributes: Array,
    showActionRow: Boolean,
  },
  emits: [
    "updateColumnFilterData",
    "updateAttributeFilters",
    "updatePostFilters",
  ],
  data() {
    return {
      timeouts: [],
      propertyIdentifiers: ["referenceKey"],
      filterOptions: [
        {
          text: this.$t("common.filters.include"),
          value: "Include",
          icon: "mdi-alphabetical-variant",
        },
        {
          text: this.$t("common.filters.notInclude"),
          value: "NotInclude",
          icon: "mdi-alphabetical-variant-off",
        },
        {
          text: this.$t("common.filters.startsWith"),
          value: "Starts",
          icon: "mdi-format-letter-starts-with",
        },
        {
          text: this.$t("common.filters.endsWith"),
          value: "Ends",
          icon: "mdi-format-letter-ends-with",
        },
        {
          text: this.$t("common.filters.equalTo"),
          value: "Equal",
          icon: "mdi-equal",
        },
        {
          text: this.$t("common.filters.notEqualTo"),
          value: "NotEqual",
          icon: "mdi-not-equal-variant",
        },
        {
          text: this.$t("common.filters.reset"),
          value: null,
          icon: "mdi-magnify",
        },
      ],
    };
  },
  methods: {
    updateFilterBy(identifier, value) {
      this.$emit("updateColumnFilterData", `${identifier}-filterBy`, value);

      const isAttributeId = !this.propertyIdentifiers.includes(identifier);

      const debouncedCallback = this.debounce(
        () => this.updateColumnSearch(value, identifier, isAttributeId),
        1000,
        `${identifier}-search`
      );

      debouncedCallback();
    },
    updateFilter(identifier, value) {
      this.$emit("updateColumnFilterData", `${identifier}-filter`, value);

      const isAttributeId = !this.propertyIdentifiers.includes(identifier);

      const debouncedCallback = this.debounce(
        () => this.updateColumnFilter(value, identifier, isAttributeId),
        500,
        `${identifier}-filter`
      );

      debouncedCallback();
    },
    updateColumnSearch(value, identifier, isAttributeId) {
      let currentAttributeFilters = [...this.attributeFilters];

      let attributeFilter =
        currentAttributeFilters.find(
          (x) =>
            (!isAttributeId && x.propertyName === identifier) ||
            (isAttributeId && x.attributeId === identifier)
        ) ?? null;

      const attribute = isAttributeId
        ? this.attributes.find((x) => x.id === identifier)
        : null;

      if (attributeFilter?.sortDesc == null && !value) {
        this.$emit("updateColumnFilterData", `${identifier}-filter`, null);
        currentAttributeFilters = currentAttributeFilters.filter(
          (x) =>
            (!isAttributeId && x.propertyName !== identifier) ||
            (isAttributeId && x.attributeId !== identifier)
        );
      } else if (attributeFilter !== null) {
        attributeFilter.filter = this.columnFilterData[`${identifier}-filter`];
        attributeFilter.filterBy = value;
        if (attributeFilter.filter === null) {
          attributeFilter.filter = "Include";
        }
      } else if (attributeFilter === null && value) {
        if (this.columnFilterData[`${identifier}-filter`] === null) {
          // checking if the value is a date range
          const pattern = /^\d{4}-\d{2}-\d{2} \| \d{4}-\d{2}-\d{2}$/;
          if (pattern.test(value)) {
            this.$emit(
              "updateColumnFilterData",
              `${identifier}-filter`,
              "Range"
            );
          } else {
            this.$emit(
              "updateColumnFilterData",
              `${identifier}-filter`,
              "Include"
            );
          }
        }

        const newAttributeFilter = {
          sortOrder: currentAttributeFilters.length,
          filter: this.columnFilterData[`${identifier}-filter`],
          filterBy: value,
        };

        if (isAttributeId) {
          newAttributeFilter.attributeId = identifier;
          newAttributeFilter.attribute = attribute;
        } else {
          newAttributeFilter.propertyName = identifier;
        }

        currentAttributeFilters.push(newAttributeFilter);
      }

      this.$emit("updateAttributeFilters", currentAttributeFilters);
      this.$emit("updatePostFilters", {
        ...this.postFilters,
        allResults: false,
      });
    },
    updateColumnFilter(value, identifier, isAttributeId) {
      let currentAttributeFilters = [...this.attributeFilters];

      let attributeFilter =
        currentAttributeFilters.find(
          (x) =>
            (!isAttributeId && x.propertyName === identifier) ||
            (isAttributeId && x.attributeId === identifier)
        ) ?? null;

      if (attributeFilter === null) return;

      if (value === null && this.columnFilterData[`${identifier}-filterBy`]) {
        this.$emit("updateColumnFilterData", `${identifier}-filter`, null);
      }

      if (value === null) {
        currentAttributeFilters = currentAttributeFilters.filter(
          (x) =>
            (!isAttributeId && x.propertyName !== identifier) ||
            (isAttributeId && x.attributeId !== identifier)
        );
      } else {
        attributeFilter.filter = value;
      }

      this.$emit("updateAttributeFilters", currentAttributeFilters);
      this.$emit("updatePostFilters", {
        ...this.postFilters,
        allResults: false,
      });
    },
    debounce(fn, delay, timeoutId) {
      timeoutId = timeoutId ?? "default";

      const vueInstance = this;

      return function () {
        clearTimeout(vueInstance.timeouts[timeoutId]);
        var args = arguments;
        var that = this;
        vueInstance.timeouts[timeoutId] = setTimeout(function () {
          fn.apply(that, args);
        }, delay);
      };
    },
    getAttributeFormatFromIdentifier(identifier) {
      let result = this.attributes.find((a) => a.id === identifier);

      if (result !== undefined) {
        return result.format;
      } else {
        return "";
      }
    },
    updateDateFilter(data) {
      if (data.value === " | ") {
        data.value = null;
      }

      this.updateColumnSearch(data.value, data.identifier, true);
      this.updateColumnFilter("Range", data.identifier, true);
    },
  },
};
</script>
