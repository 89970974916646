<template>
  <page-modal
    v-model="show"
    :title="modalTitle"
    cardStyle="min-height: 90vh"
    cardTextClass="d-flex flex-column"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
        @click="$emit('clear')"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-plus</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.locations.form.create") }}
          </p>
        </div>
      </v-btn>
    </template>
    <template #body>
      <v-tabs v-model="tab" show-arrows class="flex-grow-0">
        <v-tab
          v-for="attributeClass in attributeClassTabs"
          :key="attributeClass.id"
        >
          {{ attributeClass.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        style="min-height: calc(100% - 48px)"
        class="flex-grow-1"
      >
        <template v-for="attributeClass in attributeClassTabs">
          <tab-static-general
            v-if="attributeClass.id === fixedClasses.generalId"
            :key="`general-${attributeClass.id}`"
            :attributes="attributes"
            :location="location"
            :regionId="form.regionId"
            :hideInCustomerFrontend="form.hideInCustomerFrontend"
            :attributeLinkData="locationAttributeLinkData"
            :fieldRerenderCounter="fieldRerenderCounter"
            :locationTypes="locationTypes"
            :regions="regions"
            @string_changed="handleStringChanged"
            @dateTime_changed="handleDateTimeChanged"
            @attribute_link_data_changed="updateAttributeLinkData"
            @location_type_changed="handleLocationTypeChanged"
            @updateForm="updateForm"
          />
          <tab-static-address
            v-else-if="attributeClass.id === fixedClasses.addressId"
            :key="`address-${attributeClass.id}`"
            :attributes="attributes"
            :attributeLinkData="locationAttributeLinkData"
            :countries="countries"
            @string_changed="handleStringChanged"
            @attribute_link_data_changed="updateAttributeLinkData"
          />
          <tab-dynamic
            v-else
            :key="`dynamic-${attributeClass.id}`"
            :attributeClass="attributeClass"
            :attributeLinkData="locationAttributeLinkData"
            :attributes="attributes"
            :currencies="currencies"
            :fieldRerenderCounter="fieldRerenderCounter"
            @bool_changed="handleBoolChanged"
            @currencyCode_changed="handleCurrencyChanged"
            @currency_changed="handleCurrencyChanged"
            @dateTime_changed="handleDateTimeChanged"
            @decimal_changed="handleDecimalChanged"
            @select_changed="handleSelectChanged"
            @string_changed="handleStringChanged"
            @file_changed="handleFileChanged"
            @file_removed="handleFileRemoved"
            @attribute_link_data_changed="updateAttributeLinkData"
            @attribute_link_data_second_value_changed="
              updateAttributeLinkDataSecondValue
            "
          />
        </template>
      </v-tabs-items>
    </template>
    <template #actions>
      <modal-button
        icon="mdi-close"
        :label="$t('common.actions.close')"
        @click="show = false"
      />
      <modal-button
        v-if="form.id"
        icon="mdi-content-save-outline"
        :label="$t('common.actions.save')"
        :loading="loading"
        @click="saveLocation(false)"
      />
      <modal-button
        icon="mdi-content-save-outline"
        :label="$t('common.actions.saveAndClose')"
        :loading="loading"
        @click="saveLocation"
      />
    </template>
  </page-modal>
</template>
<script>
import PageModal from "../../Components/PageModal.vue";
import ModalButton from "../../Components/ModalButton.vue";
import { generatePredefinedAttributeLinks } from "../../util/attributeForm";
import { addTimezoneOffset, removeTimezoneOffset } from "../../util/dateTime";
import { fixedClasses } from "../../util/fixedLocationData";
import { serialize } from "object-to-formdata";
import TabDynamic from "./Form/TabDynamic.vue";
import TabStaticGeneral from "./Form/TabStaticGeneral.vue";
import TabStaticAddress from "./Form/TabStaticAddress.vue";

export default {
  components: {
    PageModal,
    ModalButton,
    TabDynamic,
    TabStaticGeneral,
    TabStaticAddress,
  },
  props: {
    value: Boolean,
    location: Object,
    attributes: Array,
    attributeClasses: Array,
    currencies: Array,
    defaultCurrencyCode: String,
    countries: Array,
    supportCaseId: String,
    locationTypes: Array,
    regions: Array,
  },
  data() {
    const [locationAttributeLinkData, locationAttributeLinks] =
      generatePredefinedAttributeLinks(this.attributes);

    return {
      loading: false,
      tab: null,
      locationAttributeLinkData,
      form: this.$inertia.form({
        id: null,
        locationTypeId: null,
        regionId: null,
        locationAttributeLinks,
        supportCaseId: null,
        hideInCustomerFrontend: false,
      }),
      boolValueOptions: [
        { value: true, text: this.$t("common.boolean.true") },
        { value: false, text: this.$t("common.boolean.false") },
      ],
      fixedClasses,
      fieldRerenderCounter: 0,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    modalTitle() {
      if (!this.location) return this.$t("pages.locations.form.title");

      const locationName = this.location?.name ?? "";

      return `#${this.location.referenceKey} ${locationName}`;
    },
    attributeClassTabs() {
      if (!this.attributes.some((x) => x.attributeClassId == null))
        return this.attributeClasses;
      return [
        ...this.attributeClasses,
        { name: this.$t("pages.locations.form.noClass"), id: null },
      ];
    },
  },
  methods: {
    setForm(location) {
      const formValues = {};

      if (location !== null) {
        formValues.id = location.id;
        formValues.locationTypeId = location.locationTypeId;
        formValues.regionId = location.regionId;
        formValues.hideInCustomerFrontend = location.hideInCustomerFrontend;
        this.setAttributeLinkData(location.locationAttributeLinks ?? []);
        formValues.locationAttributeLinks =
          location.locationAttributeLinks?.map((x) => ({
            attributeId: x.attributeId,
            stringValue: x.stringValue,
            dateTimeValue: x.dateTimeValue,
            decimalValue: x.decimalValue,
            boolValue: x.boolValue,
            fileId: x.fileId,
            fileIds: x.locationAttributeFileLinks
              ? x.locationAttributeFileLinks.map((y) => y.fileId)
              : [],
            attributeSelectOptionId: x.attributeSelectOptionId,
          })) ?? [];
      } else {
        formValues.id = null;
        formValues.locationTypeId = null;
        formValues.regionId = null;
        formValues.hideInCustomerFrontend = false;
        formValues.locationAttributeLinks = [];
        generatePredefinedAttributeLinks(
          this.attributes,
          this.locationAttributeLinkData,
          formValues.locationAttributeLinks
        );
        formValues.contents = null;
        formValues.slaContents = null;
        formValues.supportCaseId = this.supporCaseId;
      }
      this.form = this.$inertia.form(formValues);
    },
    setAttributeLinkData(attributeLinks) {
      this.attributes?.forEach((attribute) => {
        var attributeLink = attributeLinks.find(
          (x) => x.attributeId == attribute.id
        );
        if (attributeLink) {
          switch (attribute.format) {
            case "Text":
            case "TextArea":
            case "PhoneNumber":
              this.locationAttributeLinkData[attribute.id] =
                attributeLink.stringValue;
              break;
            case "Select":
              this.locationAttributeLinkData[attribute.id] =
                attributeLink.attributeSelectOptionId;
              break;
            case "Number":
            case "Percentage":
              this.locationAttributeLinkData[attribute.id] =
                attributeLink.decimalValue;
              break;
            case "DateTime":
            case "Date":
              this.locationAttributeLinkData[attribute.id] = addTimezoneOffset(
                new Date(attributeLink.dateTimeValue + "Z")
              );
              break;
            case "Currency":
              this.locationAttributeLinkData[attribute.id + "-secondValue"] =
                attributeLink.stringValue;
              this.locationAttributeLinkData[attribute.id] =
                attributeLink.decimalValue;
              break;
            case "Boolean":
              this.locationAttributeLinkData[attribute.id] =
                attributeLink.boolValue;
              break;
            case "File": {
              let files = [];
              if (attributeLink.file) {
                files.push(attributeLink.file);
              }

              files = files.concat(
                attributeLink.locationAttributeFileLinks.map((x) => x.file)
              );

              this.locationAttributeLinkData[attribute.id + "-secondValue"] =
                files;
              break;
            }
            default:
              break;
          }
        } else {
          this.locationAttributeLinkData[attribute.id] = null;
          if (attribute.format == "File" || attribute.format == "Currency") {
            this.locationAttributeLinkData[attribute.id + "-secondValue"] =
              null;
          }
        }
      });
    },
    setAttributeLinkValue(
      attribute,
      key,
      value,
      secondKey = null,
      secondValue = null
    ) {
      if (
        (value == null && secondValue == null) ||
        (Number.isNaN(value) && !secondValue) ||
        (value === "" && !secondValue)
      ) {
        this.form.locationAttributeLinks =
          this.form.locationAttributeLinks.filter(
            (x) => x.attributeId !== attribute.id
          );
        return;
      }

      if (Number.isNaN(value) && !!secondValue) {
        value = null;
      }

      if (key === "dateTimeValue") value = removeTimezoneOffset(value);

      let attributeLink = this.form.locationAttributeLinks.find(
        (x) => x.attributeId === attribute.id
      );

      if (!attributeLink) {
        attributeLink = { attributeId: attribute.id };

        this.form.locationAttributeLinks.push(attributeLink);
      }

      attributeLink[key] = value;

      if (secondKey) attributeLink[secondKey] = secondValue;
    },
    handleBoolChanged(value, attribute) {
      this.setAttributeLinkValue(attribute, "boolValue", value);
    },
    handleDateTimeChanged(value, attribute) {
      this.setAttributeLinkValue(attribute, "dateTimeValue", value);
    },
    handleDecimalChanged(value, attribute) {
      this.setAttributeLinkValue(attribute, "decimalValue", value);
    },
    handleSelectChanged(value, attribute) {
      this.setAttributeLinkValue(attribute, "attributeSelectOptionId", value);
    },
    handleStringChanged(value, attribute) {
      this.setAttributeLinkValue(attribute, "stringValue", value);
    },
    handleCurrencyChanged(value, currencyCode, attribute) {
      this.setAttributeLinkValue(
        attribute,
        "decimalValue",
        value,
        "stringValue",
        currencyCode
      );
    },
    handleFileChanged(value, attribute) {
      if (value == null || value.length === 0) {
        const locationAttributeLink = this.form.locationAttributeLinks.find(
          (x) => x.attributeId === attribute.id
        );

        if (!locationAttributeLink.fileIds.length) {
          this.form.locationAttributeLinks =
            this.form.locationAttributeLinks.filter(
              (x) => x.attributeId != attribute.id
            );
        }

        return;
      }

      const newFiles = value.length === undefined ? [value] : value;

      let locationAttributeLink = this.form.locationAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (locationAttributeLink != null) {
        locationAttributeLink.newFiles = newFiles;
      } else {
        this.form.locationAttributeLinks.push({
          attributeId: attribute.id,
          newFiles: newFiles,
        });
      }
    },
    handleFileRemoved(fileId, attribute) {
      let locationAttributeLink = this.form.locationAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );

      if (!locationAttributeLink) return;

      locationAttributeLink.fileIds = locationAttributeLink.fileIds.filter(
        (x) => x !== fileId
      );

      if (!locationAttributeLink.fileIds.length) {
        this.form.locationAttributeLinks =
          this.form.locationAttributeLinks.filter(
            (x) => x.attributeId !== attribute.id
          );

        this.locationAttributeLinkData[attribute.id + "-secondValue"] = null;

        return;
      }

      this.locationAttributeLinkData[attribute.id + "-secondValue"] =
        this.locationAttributeLinkData[attribute.id + "-secondValue"].filter(
          (x) => x.id !== fileId
        );
    },
    handleLocationTypeChanged(locationTypeId) {
      this.form.locationTypeId = locationTypeId;
    },
    updateAttributeLinkData(attributeId, value) {
      this.locationAttributeLinkData[attributeId] = value;
    },
    updateAttributeLinkDataSecondValue(attributeId, value) {
      this.locationAttributeLinkData[attributeId + "-secondValue"] = value;
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
    saveLocation(close = true) {
      if (this.loading) return;

      this.loading = true;

      this.form.supportCaseId = this.supportCaseId;

      const preparedForm = this.form.transform((data) => {
        return serialize(data, {
          dotsForObjectNotation: true,
          indices: true,
          nullsAsUndefineds: true,
          noFilesWithArrayNotation: true,
        });
      });

      if (this.form.id) {
        preparedForm.put(this.route("locations.update", this.location.id), {
          preserveScroll: true,
          onSuccess: () => {
            if (!close) {
              this.$emit("reload", this.location.id);
              return;
            }

            this.show = false;
            this.$emit("clear");
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      } else {
        preparedForm.post(this.route("locations.store"), {
          preserveScroll: true,
          onSuccess: () => {
            if (!close) return;

            this.show = false;
            this.setForm(null);
            this.$emit("clear");
            this.redirectToSupportCase();
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      }
    },
    redirectToSupportCase() {
      if (this.supportCaseId) {
        const url = this.route("support-cases.index", {
          selectedCaseId: this.supportCaseId,
        });
        this.$inertia.get(url);
      }
    },
  },
  watch: {
    location: function (location) {
      this.setForm(location);
    },
    show(value) {
      if (!value) this.setForm(null);
      else this.fieldRerenderCounter++;
    },
  },
};
</script>
<style scoped>
.bg-transparent {
  background-color: transparent;
}
</style>
