<template>
  <v-tab-item>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col
            col="6"
            v-for="(attributeGroup, index) in attributeGroups"
            :key="index"
          >
            <attribute-link-input
              v-for="attribute in attributeGroup"
              :key="attribute.id"
              :attribute="attribute"
              :currencies="currencies"
              :value="attributeLinkData[attribute.id]"
              @input="
                $emit('attribute_link_data_changed', attribute.id, $event)
              "
              :secondValue="attributeLinkData[attribute.id + '-secondValue']"
              @input_secondValue="
                $emit(
                  'attribute_link_data_second_value_changed',
                  attribute.id,
                  arguments[0]
                )
              "
              @bool_changed="$emit('bool_changed', $event, attribute)"
              @currencyCode_changed="
                $emit(
                  'currencyCode_changed',
                  attributeLinkData[attribute.id],
                  $event,
                  attribute
                )
              "
              @currency_changed="
                $emit(
                  'currency_changed',
                  $event,
                  attributeLinkData[attribute.id + '-secondValue'],
                  attribute
                )
              "
              @dateTime_changed="$emit('dateTime_changed', $event, attribute)"
              @decimal_changed="$emit('decimal_changed', $event, attribute)"
              @select_changed="$emit('select_changed', $event, attribute)"
              @string_changed="$emit('string_changed', $event, attribute)"
              @file_changed="$emit('file_changed', $event, attribute)"
              @file_removed="$emit('file_removed', $event, attribute)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>
<script>
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";

export default {
  components: {
    AttributeLinkInput,
  },
  props: {
    attributeClass: Object,
    attributeLinkData: Object,
    attributes: Array,
    currencies: Array,
  },
  computed: {
    attributeGroups() {
      const attributes = this.attributes.filter(
        (x) => x.attributeClassId == this.attributeClass.id
      );
      const halfLength = Math.ceil(attributes.length / 2);
      return [
        attributes.slice(0, halfLength),
        attributes.slice(halfLength, attributes.length),
      ];
    },
  },
  methods: {},
};
</script>
