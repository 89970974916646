<template>
  <v-tab-item>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="4" align-self="center">
                {{ $t("pages.locations.form.locationTypes.title") }}:
              </v-col>
              <v-col
                align-self="center"
                style="padding-left: 7px; padding-bottom: 4px"
              >
                <v-autocomplete
                  v-model="selectedLocationType"
                  dense
                  hide-details
                  hide-selected
                  solo
                  filled
                  chips
                  clearable
                  :items="localLocationTypes"
                  item-text="name"
                  item-value="id"
                  @update:search-input="handleLocationTypeInput"
                  @change="updateLocationType"
                  @keydown.enter.prevent="addNewLocationType"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeLocationType()"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.name }}
                  </template>
                  <template v-slot:no-data>
                    <v-list-item style="width: min-content">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            $t("pages.locations.form.locationTypes.noMatches")
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{
                            $t("pages.locations.form.locationTypes.likeToAdd")
                          }}
                          {{ selectedLocationTypesInput }}
                          {{
                            $t("pages.locations.form.locationTypes.asNewType")
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <attribute-link-input
              :attribute="locationNumberAttribute"
              :value="locationNumberData"
              :fieldRerenderCounter="fieldRerenderCounter"
              @input="updateData(locationNumberAttribute.id, $event)"
              @string_changed="
                updateStringValue(locationNumberAttribute, $event)
              "
            />
            <attribute-link-input
              :attribute="locationNameAttribute"
              :value="locationNameData"
              :fieldRerenderCounter="fieldRerenderCounter"
              @input="updateData(locationNameAttribute.id, $event)"
              @string_changed="updateStringValue(locationNameAttribute, $event)"
            />
            <attribute-link-input
              :attribute="descriptionAttribute"
              :value="descriptionData"
              :fieldRerenderCounter="fieldRerenderCounter"
              @input="updateData(descriptionAttribute.id, $event)"
              @string_changed="updateStringValue(descriptionAttribute, $event)"
            />
            <attribute-link-input
              :attribute="emailAttribute"
              :value="emailData"
              :fieldRerenderCounter="fieldRerenderCounter"
              @input="updateData(emailAttribute.id, $event)"
              @string_changed="updateStringValue(emailAttribute, $event)"
            />
            <attribute-link-input
              :attribute="phoneNumberAttribute"
              :value="phoneNumberData"
              :fieldRerenderCounter="fieldRerenderCounter"
              @input="updateData(phoneNumberAttribute.id, $event)"
              @string_changed="updateStringValue(phoneNumberAttribute, $event)"
            />
            <attribute-link-input
              :attribute="installedAtAttribute"
              :value="installedAtData"
              :fieldRerenderCounter="fieldRerenderCounter"
              @input="updateData(installedAtAttribute.id, $event)"
              @dateTime_changed="
                updateDateTimeValue(installedAtAttribute, $event)
              "
            />
            <attribute-link-input
              :attribute="regionAttribute"
              :select-options="regions"
              select-item-text="name"
              :value="regionId"
              :fieldRerenderCounter="fieldRerenderCounter"
              @select_changed="$emit('updateForm', 'regionId', $event)"
            />
            <v-row dense>
              <v-col cols="4" align-self="center">
                {{ $t("pages.locations.form.hideLocation") }}:
              </v-col>
              <v-col align-self="center">
                <v-checkbox
                  :label="$t('pages.locations.form.hideLocation')"
                  :input-value="hideInCustomerFrontend"
                  @change="
                    $emit('updateForm', 'hideInCustomerFrontend', $event)
                  "
                  hide-details
                  class="mt-0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" offset="1">
            <v-row dense>
              <v-col cols="5">
                {{ $t("pages.locations.form.fixedLabels.created") }}:
              </v-col>
              <v-col cols="7">
                {{ createdAt }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.locations.form.fixedLabels.createdBy") }}:
              </v-col>
              <v-col cols="7">
                {{ createdBy }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.locations.form.fixedLabels.updated") }}:
              </v-col>
              <v-col cols="7">
                {{ updatedAt }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.locations.form.fixedLabels.updatedBy") }}:
              </v-col>
              <v-col cols="7">
                {{ updatedBy }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>
<script>
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import { fixedAttributes } from "../../../util/fixedLocationData";
import { normalizeISOString } from "../../../util/dateTime";
import { DateTime } from "luxon";

export default {
  components: { AttributeLinkInput },
  props: {
    location: Object,
    regionId: String,
    hideInCustomerFrontend: Boolean,
    attributes: Array,
    attributeLinkData: Object,
    fieldRerenderCounter: Number,
    locationTypes: Array,
    regions: Array,
  },
  data() {
    return {
      localLocationTypes: [...this.locationTypes],
      selectedLocationType:
        this.location !== null ? this.location.locationTypeId : null,
      selectedLocationTypesInput: "",
    };
  },
  watch: {
    location(newLocation) {
      if (newLocation !== null)
        this.selectedLocationType = newLocation.locationTypeId;
      else this.selectedLocationType = "";
    },
  },
  computed: {
    locationNumberAttribute() {
      return this.getAttribute(fixedAttributes.generalLocationNumberId);
    },
    locationNumberData() {
      return this.attributeLinkData[fixedAttributes.generalLocationNumberId];
    },
    locationNameAttribute() {
      return this.getAttribute(fixedAttributes.generalLocationNameId);
    },
    locationNameData() {
      return this.attributeLinkData[fixedAttributes.generalLocationNameId];
    },
    descriptionAttribute() {
      return this.getAttribute(fixedAttributes.generalDescriptionId);
    },
    descriptionData() {
      return this.attributeLinkData[fixedAttributes.generalDescriptionId];
    },
    emailAttribute() {
      return this.getAttribute(fixedAttributes.generalEmailId);
    },
    emailData() {
      return this.attributeLinkData[fixedAttributes.generalEmailId];
    },
    phoneNumberAttribute() {
      return this.getAttribute(fixedAttributes.generalPhoneNumberId);
    },
    phoneNumberData() {
      return this.attributeLinkData[fixedAttributes.generalPhoneNumberId];
    },
    installedAtAttribute() {
      return this.getAttribute(fixedAttributes.generalInstalledAtId);
    },
    installedAtData() {
      return this.attributeLinkData[fixedAttributes.generalInstalledAtId];
    },
    regionAttribute() {
      return this.getAttribute(fixedAttributes.generalRegionId);
    },
    createdBy() {
      return this.location?.createdBy?.name ?? "";
    },
    updatedBy() {
      return this.location?.updatedBy?.name ?? "";
    },
    createdAt() {
      return this.formatDate(this.location?.createdAt);
    },
    updatedAt() {
      return this.formatDate(this.location?.updatedAt);
    },
  },
  methods: {
    getAttribute(attributeId) {
      return this.attributes?.find((x) => x.id === attributeId) ?? null;
    },
    formatDate(date) {
      if (!date) return "";

      const normalizedDate = normalizeISOString(date);

      return DateTime.fromISO(normalizedDate).toFormat("yyyy-MM-dd");
    },
    updateData(attributeId, value) {
      this.$emit("attribute_link_data_changed", attributeId, value);
    },
    updateStringValue(attribute, value) {
      this.$emit("string_changed", value, attribute);
    },
    updateDateTimeValue(attribute, value) {
      this.$emit("dateTime_changed", value, attribute);
    },
    removeLocationType() {
      this.selectedLocationType = "";
      this.updateLocationType();
    },
    addNewLocationType() {
      const inputString = String(this.selectedLocationTypesInput).trim();
      if (inputString === "") {
        return;
      }

      this.selectedLocationTypesInput = "";

      const formData = new FormData();
      formData.append("name", inputString);
      fetch(this.route("api.locations.createType"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          let createdLocationType = data;

          this.localLocationTypes.push(createdLocationType);
          this.selectedLocationType = createdLocationType.id;
          this.updateLocationType();
        });

      this.updateLocationType();
    },
    handleLocationTypeInput(event) {
      this.selectedLocationTypesInput = event;
    },
    updateLocationType() {
      this.$emit("location_type_changed", this.selectedLocationType);
    },
  },
};
</script>
<style scoped>
.v-autocomplete__content {
  max-width: none !important;
}
.v-select.v-input--dense .v-chip {
  margin: 4px;
}
</style>
