<template>
  <div>
    <file-drag-detector @draggingActive="setOsFilesDragging" />
    <v-row>
      <v-col cols="12" sm="3">
        <h4 class="text-h4">{{ $t("pages.locations.index.title") }}</h4>
        <v-spacer></v-spacer>
      </v-col>
      <filter-status-labels
        :currentLayout="currentLayout"
        :currentAttributeFilters="currentAttributeFilters"
        :attributes="attributes"
        :allResults="postFilters.allResults"
        :allAttributes="postFilters.allAttributes"
        :hasSearched="hasSearched"
        :resultsFilteredCount="locations.filteredCount"
        :resultsOriginalCount="locations.originalCount"
        entryType="location"
        @allAttributesUpdated="postFilters.allAttributes = $event"
        @allResultsUpdated="postFilters.allResults = $event"
      />
    </v-row>
    <v-row justify="space-between" class="d-flex align-center">
      <v-col md="3">
        <v-spacer></v-spacer>
      </v-col>
      <v-col md="3">
        <v-text-field
          :value="postFilters.search"
          @change="searchAttributes($event)"
          clearable
          append-icon="mdi-magnify"
          :label="$t('common.dropdowns.search.label')"
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center">
      <location-form
        v-model="formDialog"
        :location="location"
        :attributeClasses="attributeClasses"
        :attributes="attributes"
        :currencies="currencies"
        :countries="countries"
        :supportCaseId="supportCaseId"
        :locationTypes="locationTypes"
        :regions="regions"
        @clear="location = null"
        @reload="reloadLocation"
      />
      <filters-modal
        v-model="filtersDialog"
        :attributes="attributes"
        :attributeFilters="currentAttributeFilters"
        @changeAttributeFilters="updateAttributeFilters"
      />
      <choose-attributes-modal
        v-model="chooseAttributesDialog"
        :layout="currentLayout"
        :attributes="attributes"
        :attributeClasses="attributeClasses"
        :organizationId="organizationId"
        @changeLayout="updateLayout"
      />
      <layout-options-modal
        v-model="layoutOptionsDialog"
        :layout="currentLayout"
        :attributeDisplaySizes="attributeDisplaySizes"
        @changeLayout="updateLayout"
        @saveLayout="saveLayout"
      />
      <save-layout-button
        :currentLayout="currentLayout"
        :currentAttributeFilters="currentAttributeFilters"
        :rowsPerPage="pagination.itemsPerPage"
        :triggerSave="triggerSaveLayout"
        routePrefix="locations"
        @resetTriggerSave="triggerSaveLayout = false"
      />
      <import-locations-modal v-model="importLocationsDialog" />
      <div style="flex: 1000000"></div>
      <v-btn
        class="mr-4 mb-2 float-right"
        height="3rem"
        text
        color="primary"
        @click="toggleFileMenu"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon v-if="!showFileMenu" class="black--text"
            >mdi-toggle-switch-off-outline</v-icon
          >
          <v-icon v-else class="black--text">mdi-toggle-switch-outline</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.locations.index.toolbar.fileModule") }}
          </p>
        </div>
      </v-btn>
    </v-row>
    <v-row dense no-gutters>
      <v-col :cols="showFileMenu ? 10 : 12">
        <v-data-table
          v-sortable-table="{ onEnd: sortTableHeaders }"
          :key="tableRerenderCounter"
          v-model="selectedRows"
          class="elevation-1 row-pointer rounded-r-0 fill-height attributes-table"
          :class="isDisplaySize('Minimal') ? 'attributes-fixed-table' : ''"
          :headers="headers"
          :items="mappedLocations"
          :server-items-length="locations.totalCount"
          :options.sync="pagination"
          :footer-props="tableFooterProps"
          :no-data-text="$t('common.dataTable.noData')"
          height="calc(100% - 58px)"
          multi-sort
          show-select
          @update:page="changePage(pagination)"
          @update:items-per-page="changePage(pagination)"
          @update:sort-desc="changeSorting(pagination)"
        >
          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <v-tooltip top v-bind:key="header.key">
              <template v-slot:activator="{ on }">
                <em v-on="on">
                  <span>{{ header.text }}</span>
                </em>
              </template>
              <span>{{ header.text }}</span>
            </v-tooltip>
          </template>
          <template v-slot:body.prepend>
            <header-search-row
              :columnIdentifiers="columnIdentifiers"
              :attributeFilters="currentAttributeFilters"
              :attributes="attributes"
              :columnFilterData="columnFilterData"
              :postFilters="postFilters"
              :showActionRow="!showFileMenu"
              @updateAttributeFilters="currentAttributeFilters = $event"
              @updatePostFilters="postFilters = $event"
              @updateColumnFilterData="
                (key, value) => (columnFilterData[key] = value)
              "
            />
          </template>
          <template #item="{ isSelected, select, item }">
            <attribute-data-row
              :isSelected="isSelected"
              :select="select"
              :item="item"
              :attributes="attributes"
              :currentLayout="currentLayout"
              :filesDragging="filesDragging"
              :headers="headers"
              :onlyFileAttribute="onlyFileAttribute"
              :hoveredAttributeId="hoveredAttributeId"
              :hoveredRowId="hoveredLocationId"
              :defaultCurrencyCode="defaultCurrencyCode"
              entryType="location"
              @editRowClicked="editRow"
              @copyRowClicked="copyRow"
              @destroyRowClicked="destroyRow"
              @hoveredAttributeIdUpdated="hoveredAttributeId = $event"
              @hoveredRowIdUpdated="hoveredLocationId = $event"
              @linkFilesToRow="handleLocationFileLinking"
            />
          </template>
          <template #body.append="{ items }">
            <tr>
              <td></td>
              <td
                v-for="header in headers"
                :key="header.value"
                :class="header.value === 'action' ? 'fixed' : ''"
              >
                <span
                  v-if="dynamicHeaderIds.includes(header.value)"
                  class="font-weight-bold"
                >
                  {{
                    summarizeAttribute(mappedAttributes[header.value], items)
                  }}
                </span>
              </td>
            </tr>
          </template>
          <template #footer.prepend>
            <v-row justify="space-between" class="ml-0 mr-8 align-center">
              <v-btn text small @click="removeRowRange">
                {{ $t("pages.locations.index.deleteSelectedLocations") }}
              </v-btn>
            </v-row>
          </template>
          <template #footer.page-text="pageTextProps">
            {{ $t("common.dataTable.visibleRows", pageTextProps) }}
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="showFileMenu" cols="2">
        <file-menu
          :refreshFiles="refreshFileMenu"
          :osFilesDragging="osFilesDragging"
          :organizationId="currentOrganizationId"
          entryType="location"
          @filesRefreshed="refreshFileMenu = false"
          @filesDraggingChanged="handleFilesDraggingChanged"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import qs from "qs";
import { currencyFormatter } from "../../util/formatters";
import { appLayout } from "@/util/layout";
import { footerProps, sortableTableDirective } from "@/util/dataTable";
import {
  attributeOverviewComputed,
  attributeOverviewMethods,
} from "@/util/sharedVueProperties";
import ChooseAttributesModal from "../../Components/Attribute/ChooseAttributesModal.vue";
import FiltersModal from "../../Components/Attribute/FiltersModal.vue";
import HeaderSearchRow from "../../Components/Attribute/HeaderSearchRow.vue";
import FilterStatusLabels from "../../Components/Attribute/FilterStatusLabels.vue";
import LayoutOptionsModal from "../../Components/Layout/LayoutOptionsModal.vue";
import SaveLayoutButton from "../../Components/Layout/SaveLayoutButton.vue";
import AttributeDataRow from "../../Components/Attribute/AttributeDataRow.vue";
import LocationForm from "./Form.vue";
import FileMenu from "../../Components/Attribute/FileMenu.vue";
import FileDragDetector from "../../Components/FileDragDetector.vue";
import ImportLocationsModal from "../../Components/Location/ImportLocationsModal.vue";
import { serialize } from "object-to-formdata";

import "../../assets/css/attributeTable.css";

export default {
  layout: appLayout({ title: "pages.locations.index.title" }),
  components: {
    ChooseAttributesModal,
    FiltersModal,
    HeaderSearchRow,
    FilterStatusLabels,
    LayoutOptionsModal,
    SaveLayoutButton,
    AttributeDataRow,
    LocationForm,
    FileMenu,
    FileDragDetector,
    ImportLocationsModal,
  },
  props: {
    locations: Object,
    currentOrganizationId: String,
    attributeClasses: Array,
    attributes: Array,
    currencies: Array,
    countries: Array,
    locationLayout: Object,
    attributeDisplaySizes: Array,
    supportCaseId: String,
    locationTypes: Array,
    regions: Array,
  },
  data() {
    let searchParams = qs.parse(window.location.search.substring(1));

    let columnFilterProperties = ["referenceKey"];
    let columnFilterData = {};
    let sortBy = [];
    let sortDesc = [];

    columnFilterProperties.forEach((x) => {
      columnFilterData[`${x}-filter`] = null;
    });
    this.attributes.forEach((x) => {
      columnFilterData[`${x.id}-filter`] = null;
    });

    if (this.locationLayout.attributeFilters != null) {
      this.locationLayout.attributeFilters.forEach((x) => {
        if (x.filterBy != null) {
          if (x.attributeId != null) {
            columnFilterData[`${x.attributeId}-filter`] = x.filter;
            columnFilterData[`${x.attributeId}-filterBy`] = x.filterBy;
          } else {
            columnFilterData[`${x.propertyName}-filter`] = x.filter;
            columnFilterData[`${x.propertyName}-filterBy`] = x.filterBy;
          }
        }
      });

      var filters = this.locationLayout.attributeFilters.filter(
        (x) => x.sortDesc != null
      );
      sortBy = filters.map((x) => {
        if (x.sortDesc != null) {
          if (x.attributeId != null) {
            return x.attributeId;
          }
          return x.propertyName;
        }
      });
      sortDesc = filters.map((x) => {
        if (x.sortDesc != null) {
          return x.sortDesc;
        }
      });
    }

    return {
      location: null,
      currentLayout: this.locationLayout,
      currentAttributeFilters: this.locationLayout.attributeFilters,
      filtersDialog: false,
      chooseAttributesDialog: false,
      layoutOptionsDialog: false,
      importLocationsDialog: false,
      formDialog: false,
      hasSearched: false,
      pagination: {
        page: this.locations.currentPage,
        itemsPerPage: this.locations.pageSize,
        sortBy,
        sortDesc,
      },
      tableFooterProps: footerProps,
      postFilters: {
        search: searchParams.postFilters?.search ?? null,
        allAttributes: searchParams.postFilters?.allAttributes ?? null,
        allResults: searchParams.postFilters?.allResults,
      },
      organizationId:
        this.currentOrganizationId ?? searchParams.organizationId ?? null,
      columnFilterData,
      columnFilterProperties,
      filesDragging: false,
      osFilesDragging: false,
      showFileMenu: false,
      refreshFileMenu: false,
      selectedRows: [],
      hoveredAttributeId: null,
      hoveredLocationId: null,
      tableRerenderCounter: 0,
      triggerSaveLayout: false,
    };
  },
  computed: {
    ...attributeOverviewComputed,
    mappedLocations() {
      let mappedLocations = [];

      this.locations.data.forEach((location) => {
        let mappedLocation = { ...location };

        location.locationAttributeLinks.forEach((attributeLink) => {
          mappedLocation[attributeLink.attributeId] = attributeLink;
        });

        mappedLocations.push(mappedLocation);
      });

      return mappedLocations;
    },
    visibleAttributes() {
      if (!this.attributes || !this.currentLayout) return [];

      return this.attributes.filter((x) =>
        this.currentLayout.attributeIds.includes(x.id)
      );
    },
    onlyFileAttribute() {
      if (this.attributes.filter((x) => x.format === "File").length <= 1)
        return this.attributes.find((x) => x.format === "File") ?? null;

      if (this.visibleAttributes.filter((x) => x.format === "File").length <= 1)
        return this.visibleAttributes.find((x) => x.format === "File") ?? null;

      return null;
    },
    defaultCurrencyId() {
      return this.$inertia.page.props.auth.settings.defaultCurrencyId;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.defaultCurrencyId
      );

      return currency?.currencyCode ?? null;
    },
  },
  methods: {
    ...attributeOverviewMethods,
    changePage(options) {
      let query = {
        postFilters: pickBy(this.postFilters),
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.post(
        this.route("locations.index", query),
        {
          ...this.currentLayout,
          attributeFilters: this.currentAttributeFilters,
        },
        { preserveState: true }
      );
    },
    changePageReset(options) {
      let query = {
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(this.route("locations.index", query), {
        preserveState: true,
        preserveScroll: true,
      });
    },
    editRow(item) {
      this.location = { ...item };
      this.formDialog = true;
    },
    copyRow(item) {
      this.location = { ...item, id: null };
      this.formDialog = true;
    },
    destroyRow(id) {
      if (!confirm(this.$t("pages.locations.index.confirmDelete"))) {
        return;
      }

      this.$inertia.delete(this.route("locations.destroy", id));
    },
    handleLocationFileLinking(locationId, attributeId, formData) {
      fetch(
        this.route("api.locations.attributes.files.link", {
          locationid: locationId,
          attributeid: attributeId,
        }),
        {
          method: "POST",
          body: formData,
        }
      ).then((res) => {
        if (!res.ok) return Promise.reject();

        this.changePage({
          page: this.pagination.page,
          itemsPerPage: this.pagination.itemsPerPage,
        });

        this.refreshFileMenu = true;
      });
    },
    removeRowRange() {
      if (
        !this.selectedRows.length ||
        !confirm(
          this.$t("pages.locations.index.confirmDeleteSelected", {
            amount: this.selectedRows.length,
          })
        )
      )
        return;

      const form = serialize({
        locationIds: this.selectedRows.map((x) => x.id),
      });

      this.$inertia.post(this.route("locations.destroy.range"), form);
    },
    summarizeAttribute(attribute, rows) {
      if (attribute.format === "Currency") {
        const decimals = attribute.visibleDecimals ?? 2;
        const scale = Math.pow(10, decimals);

        const sum = rows
          .map(
            (x) =>
              x.locationAttributeLinks.find(
                (y) => y.attributeId === attribute.id
              )?.decimalValue
          )
          .filter((x) => !!x)
          .reduce((total, current) => total + current * scale, 0);

        return currencyFormatter.format(sum / scale);
      }
    },
    setOsFilesDragging(value) {
      this.osFilesDragging = value;
    },
    handleFilesDraggingChanged(value) {
      this.filesDragging = value;
    },
    toggleFileMenu() {
      this.showFileMenu = !this.showFileMenu;
    },
    reloadLocation(locationId) {
      const location = this.locations.data.find((x) => x.id === locationId);

      if (location) this.location = { ...location };
    },
  },
  directives: {
    "sortable-table": sortableTableDirective,
  },
  watch: {
    postFilters: {
      handler: throttle(function () {
        this.changePage({
          page: 1,
          itemsPerPage: this.pagination.itemsPerPage,
        });
      }, 150),
      deep: true,
    },
    importLocationsDialog: function (value) {
      if (value) return;

      this.changePageReset(this.pagination);
    },
  },
  mounted() {
    if (this.supportCaseId) {
      this.formDialog = true;

      let searchParams = qs.parse(window.location.search.substring(1));
      delete searchParams.solutionId;
      const queryString = qs.stringify(searchParams);

      let newRelativeUrl = window.location.pathname;

      if (queryString) newRelativeUrl += "?" + queryString;

      setTimeout(
        () => window.history.replaceState(null, "", newRelativeUrl),
        100
      );
    }
  },
};
</script>
