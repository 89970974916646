<template>
  <v-tab-item>
    <h5 v-if="slaContents && slaContents.length" class="text-h5 mt-2">
      {{ $t("components.contents.sections.slaContents") }}
    </h5>
    <vue-draggable
      v-model="slaContentList"
      group="slacontents"
      @choose="onDraggingStart"
      @unchoose="onDraggingEnd"
      @end="onDraggingEnd"
    >
      <content-card
        v-for="(content, index) in slaContentList"
        :key="index"
        :content="content"
        :index="index"
        :formErrors="formErrors"
        :visibilities="visibilities"
        :isContentDragging="isContentDragging"
        elevation="1"
        isSla
        @updateContent="(i, key, value) => updateContent(true, i, key, value)"
        @removeContent="(index) => removeContent(true, index)"
        @removeFile="(index, fileIndex) => removeFile(true, index, fileIndex)"
      />
    </vue-draggable>
    <h5 v-if="contents && contents.length" class="text-h5 mt-2">
      {{ $t("components.contents.sections.contents") }}
    </h5>
    <vue-draggable
      v-model="contentList"
      group="contents"
      @choose="onDraggingStart"
      @unchoose="onDraggingEnd"
      @end="onDraggingEnd"
    >
      <content-card
        v-for="(content, index) in contentList"
        :key="index"
        :content="content"
        :index="index"
        :formErrors="formErrors"
        :visibilities="visibilities"
        :isContentDragging="isContentDragging"
        elevation="1"
        @updateContent="(i, key, value) => updateContent(false, i, key, value)"
        @removeContent="(index) => removeContent(false, index)"
        @removeFile="(index, fileIndex) => removeFile(false, index, fileIndex)"
      />
    </vue-draggable>
  </v-tab-item>
</template>
<script>
import ContentCard from "../../../Components/Content/ContentCard.vue";

export default {
  components: { ContentCard },
  props: {
    contents: Array,
    slaContents: Array,
    formErrors: Object,
    visibilities: Array,
  },
  emits: ["updateForm"],
  data() {
    return {
      isContentDragging: false,
    };
  },
  computed: {
    contentList: {
      get() {
        return this.contents;
      },
      set(value) {
        this.$emit("updateForm", "contents", value);
      },
    },
    slaContentList: {
      get() {
        return this.slaContents;
      },
      set(value) {
        this.$emit("updateForm", "slaContents", value);
      },
    },
  },
  methods: {
    onDraggingStart() {
      this.isContentDragging = true;
    },
    onDraggingEnd() {
      this.isContentDragging = false;
    },
    getContentValues(isSla) {
      if (isSla) {
        return { key: "slaContents", contents: this.slaContents };
      }

      return { key: "contents", contents: this.contents };
    },
    updateContent(isSla, index, property, value) {
      const { key, contents } = this.getContentValues(isSla);

      const modifiedContent = {
        ...contents[index],
        [property]: value,
      };

      let updatedContents = [...contents];
      updatedContents[index] = modifiedContent;

      this.$emit("updateForm", key, updatedContents);
    },
    removeContent(isSla, index) {
      const { key, contents } = this.getContentValues(isSla);

      const updatedContents = contents.filter((_, i) => i !== index);

      this.$emit("updateForm", key, updatedContents);
    },
    removeFile(isSla, index, fileIndex) {
      const { key, contents } = this.getContentValues(isSla);

      const modifiedContent = {
        ...contents[index],
      };

      modifiedContent.files = modifiedContent.files.filter(
        (_, i) => i !== fileIndex
      );

      let updatedContents = [...contents];
      updatedContents[index] = modifiedContent;

      this.$emit("updateForm", key, updatedContents);
    },
  },
};
</script>
