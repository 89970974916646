<template>
  <v-tab-item>
    <v-card flat>
      <v-card-text>
        <v-row class="asset-row">
          <v-card
            class="asset-card"
            variant="outlined"
            v-for="(asset, index) in mappedAssets"
            :key="index"
          >
            <v-row no-gutters fill-height>
              <v-col cols="12">
                <v-card-text>
                  <v-row>
                    <v-col cols="5">
                      {{ $t("pages.solutions.form.assetsTab.assetName") }}
                    </v-col>
                    <v-col class="asset-card-text"> {{ asset.name }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="5">
                      {{ $t("pages.solutions.form.assetsTab.model") }}
                    </v-col>
                    <v-col class="asset-card-text"> {{ asset.model }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="5">
                      {{ $t("pages.solutions.form.assetsTab.serial") }}
                    </v-col>
                    <v-col class="asset-card-text"> {{ asset.serial }} </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
              <v-col class="card-footer">
                <v-card-actions class="px-6" justify="end">
                  <v-row justify="end">
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="removeAssetFromList(asset.id)"
                    >
                      <p class="teal--text text--darken-4 mb-0">
                        {{ $t("pages.solutions.form.assetsTab.delete") }}
                      </p>
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="editAsset(asset.id)"
                    >
                      <p class="teal--text text--darken-4 mb-0">
                        {{ $t("pages.solutions.form.assetsTab.edit") }}
                      </p>
                    </v-btn>
                  </v-row>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="asset-card" variant="outlined">
            <v-btn
              class="asset-card"
              height="300px"
              text
              color="primary"
              @click="addButtonClick()"
            >
              <div class="d-flex flex-column justify-center align-center">
                <v-icon class="black--text" size="84">mdi-plus</v-icon>
                <p class="teal--text text--darken-4 mb-0">
                  {{ $t("pages.solutions.form.assetsTab.add") }}
                </p>
              </div>
            </v-btn>
          </v-card>
        </v-row>
      </v-card-text>
      <div class="popup" v-if="showPopup">
        <div class="popup-inner">
          <h2 class="popup-title">
            {{ $t("pages.solutions.form.assetsTab.addAssetNewTemplate") }}
          </h2>
          <v-autocomplete
            :label="$t('pages.solutions.form.assetsTab.selectTemplate')"
            :items="mappedAssetTemplates"
            v-model="selectedAssetTemplateId"
            hide-details
            clearable
            item-text="name"
            item-value="id"
            dense
            solo
          ></v-autocomplete>
          <div class="button-container">
            <modal-button
              icon="mdi-plus"
              :label="$t('pages.solutions.form.assetsTab.addNew')"
              @click="addAsset()"
            />
            <modal-button
              icon="mdi-plus"
              :label="$t('pages.solutions.form.assetsTab.addFromTemplate')"
              @click="addTemplateAsset()"
            />
            <modal-button
              icon="mdi-close"
              :label="$t('common.actions.close')"
              @click="showPopup = false"
            />
          </div>
        </div>
      </div>
    </v-card>
  </v-tab-item>
</template>
<script>
import { fixedAttributes } from "../../../util/fixedAssetData";
import ModalButton from "../../../Components/ModalButton.vue";

export default {
  props: {
    solution: Object,
    solutionName: String,
    locationId: String,
    solutionTemplateId: String,
    quantity: Number,
    locations: Array,
    attributes: Array,
    solutionTemplates: Array,
    isTemplatePage: Boolean,
    assetTemplates: Object,
    copiedAssets: Array,
    selectedTemplateIds: Array,
    deletedAssetIds: Array,
  },

  components: {
    ModalButton,
  },

  data() {
    return {
      showPopup: false,
      selectedAssetTemplateId: null,
    };
  },

  computed: {
    mappedAssets() {
      let mappedAssets = [];

      if (this.solution) {
        this.solution.assets.forEach((asset) => {
          if (this.deletedAssetIds.includes(asset.id)) return;

          let mappedAsset = this.getMappedAsset(asset);

          mappedAssets.push(mappedAsset);
        });
      }

      this.selectedTemplateIds.forEach((templateId) => {
        let assetTemplate = this.assetTemplates.data.find(
          (x) => x.id === templateId
        );

        if (!assetTemplate) {
          assetTemplate = this.copiedAssets.find((x) => x.id === templateId);
        }

        if (!assetTemplate) return;

        let mappedAssetTemplate = this.getMappedAsset(assetTemplate);

        mappedAssets.push(mappedAssetTemplate);
      });

      return mappedAssets;
    },

    mappedAssetTemplates() {
      let mappedAssetTemplates = [];

      if (this.assetTemplates) {
        this.assetTemplates.data.forEach((assetTemplate) => {
          let mappedAssetTemplate = this.getMappedAsset(assetTemplate);

          mappedAssetTemplates.push(mappedAssetTemplate);
        });
      }

      return mappedAssetTemplates;
    },
  },
  methods: {
    addButtonClick() {
      this.showPopup = true;
    },
    addAsset() {
      const emptyGuid = "00000000-0000-0000-0000-000000000000";

      const url = this.route("assets.index", {
        solutionId: this.solution === null ? emptyGuid : this.solution?.id,
      });

      this.$inertia.get(url);
    },
    removeAssetFromList(id) {
      if (!confirm(this.$t("pages.assets.index.confirmDelete"))) {
        return;
      }

      this.$emit("assetRemoved", id);
    },
    editAsset(id) {
      if (
        !confirm(this.$t("pages.solutions.form.assetsTab.redirectWithoutSave"))
      )
        return;

      const url = this.route("assets.index", {
        assetId: id,
      });

      this.$inertia.get(url);
    },
    addTemplateAsset() {
      this.$emit("assetTemplateAdded", this.selectedAssetTemplateId);

      this.selectedAssetTemplateId = null;

      this.showPopup = false;
    },
    getMappedAsset(asset) {
      let mappedAsset = {
        ...asset,
      };

      let currentNameAttribute = mappedAsset.assetAttributeLinks.find(
        (x) => x.attributeId === fixedAttributes.masterDataNameId
      );

      let currentModelAttribute = mappedAsset.assetAttributeLinks.find(
        (x) => x.attributeId === fixedAttributes.masterDataModelId
      );

      let currentSerialAttribute = mappedAsset.assetAttributeLinks.find(
        (x) => x.attributeId === fixedAttributes.masterDataSerialNumberId
      );

      if (currentNameAttribute) {
        mappedAsset.name = currentNameAttribute.stringValue;
      }

      if (currentModelAttribute) {
        mappedAsset.model = currentModelAttribute.stringValue;
      }

      if (currentSerialAttribute) {
        mappedAsset.serial = currentSerialAttribute.stringValue;
      }

      return mappedAsset;
    },
  },
};
</script>
<style>
.asset-row {
  padding-top: 1rem;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.asset-card {
  height: 300px;
  width: 300px;
}

.asset-card-text {
  overflow: hidden;
}

.card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-style: solid;
  border-radius: 5px;
  border: 3px;
  border-color: black;
  background-color: white;
  padding: 20px;
}

.popup-title {
  padding-bottom: 20px;
}

.button-container {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}
</style>
