<template>
  <v-tab-item>
    <div class="fill-height fill-width">
      <v-row class="black--text">
        <v-col cols="12" sm="5">
          <v-row dense>
            <v-col>
              <span>{{
                $t("pages.portfolios.form.fields.portfolioName")
              }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="form.name"
                name="name"
                solo
                dense
                type="text"
                :error-messages="form.errors.name"
                @input="updateForm('name', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>{{
                $t("pages.portfolios.form.fields.portfolioDescription")
              }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                solo
                name="input-7-4"
                :value="form.description"
                :error-messages="form.errors.description"
                @input="updateForm('description', $event)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    form: Object,
  },
  emits: ["updateForm"],
  methods: {
    updateForm(formKey, value) {
      this.$emit("updateForm", formKey, value);
    },
  },
};
</script>
