<template>
  <v-menu
    v-if="!selectedCase.activeReminder"
    v-model="showReminderMenu"
    :close-on-content-click="false"
    offset-y
    nudge-bottom="16px"
    nudge-left="100px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="teal darken-3" v-bind="attrs" v-on="on">
        <v-icon class="mr-2">mdi-calendar-clock</v-icon>
        {{ $t("components.supportCases.reminder.setReminder") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <v-date-picker
              v-model="reminderForm.selectedDate"
              :locale="$i18n.local"
              @change="setReminderMinValues"
              :min="reminderMinDate"
              :disabled="reminderForm.processing"
            ></v-date-picker>
          </v-col>
          <v-col>
            <v-time-picker
              format="24hr"
              scrollable
              v-model="reminderForm.selectedTime"
              :min="reminderMinTime"
              :disabled="reminderForm.processing"
            ></v-time-picker>
          </v-col>
        </v-row>
        <v-row align="center" no-gutters class="mt-2">
          <v-col>
            <v-alert
              dense
              type="error"
              class="m-0"
              :class="{ hidden: !reminderForm.missingValues }"
            >
              {{ $t("components.supportCases.reminder.missingValues") }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="teal darken-3" @click="showReminderMenu = false">
          {{ $t("components.supportCases.reminder.close") }}
        </v-btn>
        <v-btn
          text
          color="teal darken-3"
          @click="setReminder"
          :disabled="reminderForm.processing"
        >
          {{ $t("components.supportCases.reminder.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
  <v-btn v-else text color="teal darken-3" @click="deleteReminder">
    <v-icon class="mr-2">mdi-calendar-remove</v-icon>
    {{ $t("components.supportCases.reminder.removeReminder") }}
  </v-btn>
</template>
<script>
import { objectToFormData } from "@/util/formData";

export default {
  props: {
    selectedCase: Object,
  },
  data() {
    return {
      reminderForm: this.$inertia.form({
        selectedDate: null,
        selectedTime: null,
        missingValues: false,
      }),
      showReminderMenu: false,
      reminderMinDate: null,
      reminderMinTime: null,
      reminderMinValuesInterval: null,
    };
  },
  methods: {
    setReminder() {
      let { selectedDate, selectedTime } = this.reminderForm;

      if (!selectedDate || !selectedTime) {
        this.reminderForm.missingValues = true;
        return;
      }

      this.reminderForm
        .transform((data) =>
          objectToFormData({
            reminderTime: new Date(
              `${data.selectedDate} ${data.selectedTime}`
            ).toISOString(),
          })
        )
        .post(this.route("support-cases.set-reminder", this.selectedCase.id), {
          only: ["selectedCase"],
        });

      this.showReminderMenu = false;
      this.reminderForm.selectedDate = null;
      this.reminderForm.selectedTime = null;
      this.reminderForm.missingValues = false;
    },
    deleteReminder() {
      this.$inertia.delete(
        this.route("support-cases.delete-reminder", this.selectedCase.id),
        {},
        {
          only: ["selectedCase"],
        }
      );
    },
    setReminderMinValues() {
      let currentDate = new Date().toISOString().split("T")[0];

      if (this.reminderMinDate !== currentDate) {
        this.reminderMinDate = currentDate;
      }

      if (this.reminderForm.selectedDate !== currentDate) {
        this.reminderMinTime = null;
        return;
      }

      let currentTime = new Date();
      currentTime.setMinutes(currentTime.getMinutes() + 1);

      this.reminderMinTime = currentTime
        .toLocaleTimeString("en-GB")
        .substring(0, 5);

      if (this.reminderForm.selectedTime < this.reminderMinTime) {
        this.reminderForm.selectedTime = null;
      }
    },
  },
  watch: {
    showReminderMenu: function (value) {
      if (value) {
        this.setReminderMinValues();
        this.reminderMinValuesInterval = setInterval(() => {
          this.setReminderMinValues();
        }, 60000);
      } else {
        clearInterval(this.reminderMinTimeInterval);
      }
    },
  },
};
</script>
<style scoped>
.hidden {
  visibility: hidden;
}
</style>
