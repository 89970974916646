<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-spacer class="mb-6"></v-spacer>
      <div>
        <v-col col="12" lg="8">
          <form v-if="form">
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.name")
                }}</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="form.name"
                  name="name"
                  type="text"
                  :error-messages="form.errors.name"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.subName")
                }}</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="form.subName"
                  name="name"
                  type="text"
                  :error-messages="$page.props.errors.subName"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.description")
                }}</v-subheader>
              </v-col>
              <v-col cols="8">
                <editor v-model="form.description" />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.cmsUrl")
                }}</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="form.contentManagementSystemUrl"
                  name="cms"
                  type="text"
                  :error-messages="form.errors.contentManagementSystemUrl"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.logo.label")
                }}</v-subheader>
              </v-col>
              <v-col cols="8" class="mb-4">
                <v-file-input
                  v-model="form.logo"
                  :rules="logoRules"
                  accept="image/png, image/jpeg, image/bmp"
                  name="file"
                  show-size
                  :placeholder="
                    $t('pages.settings.index.fields.logo.placeholder')
                  "
                  :error-messages="form.errors.logo"
                />
                <img
                  v-if="organization.logoFile"
                  :src="
                    route('api.files.content', {
                      id: organization.logoFile?.id,
                      filename: organization.logoFile?.originalName,
                    })
                  "
                  class="pt-4 pl-8 max-w-1/2"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.taxNumber")
                }}</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="form.taxNumber"
                  name="taxNumber"
                  type="text"
                  :error-messages="form.errors.taxNumber"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.address")
                }}</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="form.address"
                  name="address"
                  type="text"
                  :error-messages="form.errors.address"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.email")
                }}</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="form.email"
                  name="email"
                  type="text"
                  :error-messages="form.errors.email"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.contactName")
                }}</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="form.contactName"
                  name="contactName"
                  type="text"
                  :error-messages="form.errors.contactName"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.contactTitle")
                }}</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="form.contactTitle"
                  name="contactTitle"
                  type="text"
                  :error-messages="form.errors.contactTitle"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-start">
              <v-col cols="4">
                <v-subheader>{{
                  $t("pages.settings.index.fields.contactEmail")
                }}</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="form.contactEmail"
                  name="contactEmail"
                  type="text"
                  :error-messages="form.errors.contactEmail"
                />
              </v-col>
            </v-row>
          </form>
        </v-col>
      </div>
    </div>
  </v-tab-item>
</template>

<script>
import Editor from "@/Shared/Editor";
import { objectToFormData } from "@/util/formData";

export default {
  components: {
    Editor,
  },
  props: {
    tabHref: String,
    organization: Object,
  },
  data() {
    return {
      logoRules: [
        (value) =>
          !value ||
          value.size < 1000000 ||
          this.$t("pages.settings.index.fileSizeRule"),
      ],
      form: this.$inertia.form({
        id: this.organization.id,
        name: this.organization.name,
        subName: this.organization.subName,
        subdomain: this.organization.subdomain,
        contentManagementSystemUrl:
          this.organization.contentManagementSystemUrl,
        logo: null,
        description: this.organization.description,
        taxNumber: this.organization.taxNumber,
        address: this.organization.address,
        email: this.organization.email,
        contactName: this.organization.contactName,
        contactTitle: this.organization.contactTitle,
        contactEmail: this.organization.contactEmail,
        contents: this.organization.contents || [],
        defaultLocationContents:
          this.organization.defaultLocationContents || [],
      }),
    };
  },
  methods: {
    submit() {
      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
          contents: data.contents.map((c, index) => ({
            ...c,
            sortOrder: index + 1,
          })),
          defaultLocationContents: data.defaultLocationContents.map(
            (c, index) => ({ ...c, sortOrder: index + 1 })
          ),
        };
        return objectToFormData(transformedData);
      });

      preparedForm.put(
        this.route("settings.organization.update", this.organization.id),
        {
          preserveScroll: true,
          onSucces: () => {
            this.form.logo = null;
          },
        }
      );
    },
  },
};
</script>
<style>
.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
