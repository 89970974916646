<template>
  <div class="d-flex flex justify-center align-center">
    <v-form class="col-10 col-md-7" @submit.prevent="submit">
      <h3 class="text-h4 cyan--text text--darken-4 font-weight-light mb-6">
        {{ $t("pages.login.title") }}
      </h3>
      <flash-messages />
      <v-text-field
        autofocus
        v-model="form.email"
        :label="$t('pages.login.email')"
        name="email"
        type="email"
        :error-messages="form.errors.email"
        autocomplete="email"
      />

      <v-text-field
        v-model="form.password"
        :label="$t('pages.login.password')"
        name="password"
        type="password"
        :error-messages="form.errors.password"
        autocomplete="current-password"
      />

      <v-checkbox
        class="pa-0 ma-0"
        :label="$t('pages.login.rememberMe')"
        v-model="form.rememberMe"
      ></v-checkbox>
      <v-spacer />
      <div class="d-flex justify-end align-center">
        <inertia-link
          class="mr-4 text-body-2"
          :href="route('password.request')"
        >
          {{ $t("pages.login.forgotPassword") }}
        </inertia-link>
        <v-btn large color="primary" type="submit" :disabled="form.processing">
          {{ $t("pages.login.login") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";
import FlashMessages from "@/Shared/FlashMessages";

export default {
  metaInfo: { title: "pages.login.title" },
  layout: Layout,
  components: {
    FlashMessages,
  },
  props: {
    errors: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        email: null,
        password: null,
        rememberMe: true,
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.route("login.attempt"));
    },
  },
};
</script>
