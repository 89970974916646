<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">
        {{
          !!role.id
            ? $t("pages.roles.form.edit.title")
            : $t("pages.roles.form.create.title")
        }}
        Role
      </h4>
    </div>
    <div>
      <v-row no-gutters>
        <v-col col="12" lg="8">
          <form @submit.prevent="submit">
            <v-card elevation="1">
              <v-card-text>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.name")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      name="name"
                      type="text"
                      :error-messages="form.errors.name"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.roles.form.fields.description")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.description"
                      name="description"
                      type="text"
                      :error-messages="form.errors.description"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-start mt-4">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.roles.form.fields.color")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <label
                      for="color"
                      :style="{ backgroundColor: form.color }"
                      class="color-label"
                    >
                      <input
                        class="hidden"
                        type="color"
                        v-model="form.color"
                        id="color"
                      />
                    </label>
                  </v-col>
                </v-row>
                <v-alert v-if="errors.alert" type="error" class="mt-2 mb-0">
                  {{ errors.alert }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <flash-messages />
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  text
                  color="error"
                  :disabled="sending || form.processing"
                  @click="deleteRole"
                  v-if="role.id"
                  >{{ $t("common.actions.delete") }}</v-btn
                >
                <v-btn
                  type="submit"
                  outlined
                  color="primary"
                  :disabled="form.processing"
                  >{{ $t("common.actions.save") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import FlashMessages from "@/Shared/FlashMessages";

export default {
  layout: appLayout({ title: "pages.roles.form.pageTitle" }),
  props: {
    role: Object,
    errors: Object,
  },
  components: {
    FlashMessages,
  },
  data() {
    return {
      sending: false,
      form: this.$inertia.form({
        id: this.role.id,
        name: this.role.name,
        description: this.role.description,
        color: this.role.color ? this.role.color : "#646568",
      }),
    };
  },
  methods: {
    submit() {
      if (this.role.id) {
        this.form.put(this.route("roles.update", this.role.id), {
          preserveScroll: true,
          preserveState: true,
        });
      } else {
        this.form.post(this.route("roles.store"));
      }
    },
    deleteRole() {
      this.$inertia.delete(this.route("roles.destroy", this.role.id), {
        onStart: () => (this.sending = true),
        onFinish: () => (this.sending = false),
      });
    },
  },
};
</script>
<style>
.hidden {
  visibility: hidden;
}

.color-label {
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 15px;
  margin: 0 2px;
}
</style>
