<template>
  <v-tab-item :value="tabValue">
    <v-card flat>
      <v-card-text>
        <v-alert
          v-if="factorIsDefined && asset.emissionFactorId === nameData"
          prominent
          type="warning"
          elevation="2"
        >
          <v-row align="center">
            <v-col class="grow">
              {{ $t("pages.assets.form.emissionAiDefined") }}
            </v-col>
            <v-col class="shrink">
              <v-btn @click="approveEmission">
                {{ $t("pages.assets.form.approve") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-row>
          <v-col v-if="!isPurchasedAsUsed" col="6">
            <asset-attribute-link-input
              :attribute="methodAttribute"
              :value="methodData"
              @input="updateData(fixedAttributes.co2MethodId, $event)"
              @select_changed="handleMethodChanged"
            />
            <asset-attribute-link-input
              :attribute="sectorAttribute"
              :value="sectorData"
              :selectOptions="emissionSectors"
              @input="updateData(fixedAttributes.co2SectorId, $event)"
              @select_changed="handleSectorChanged"
            />
            <asset-attribute-link-input
              :attribute="categoryAttribute"
              :value="categoryData"
              :selectOptions="emissionCategories"
              @input="updateData(fixedAttributes.co2CategoryId, $event)"
              @select_changed="handleCategoryChanged"
            />
            <asset-attribute-link-input
              :attribute="regionAttribute"
              :value="regionData"
              :selectOptions="emissionRegions"
              @input="updateData(fixedAttributes.co2RegionId, $event)"
              @select_changed="handleRegionChanged"
            />
            <asset-attribute-link-input
              :attribute="nameAttribute"
              :value="nameData"
              :selectOptions="emissionFactors"
              @input="updateData(fixedAttributes.co2NameId, $event)"
              @select_changed="handleNameChanged"
            />
          </v-col>
          <v-col offset="1" cols="5">
            <v-row v-if="isSpendBasedMethod" dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.purchasePrice") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="isPurchasedAsUsed" class="blue--text">
                  {{ $t("pages.assets.form.validation.assetPurchasedAsUsed") }}
                </span>
                <span
                  v-else-if="!purchasePrice || !purchasePriceCurrency"
                  class="red--text"
                >
                  {{ $t("pages.assets.form.validation.missingPurchasePrice") }}
                </span>
                <span
                  v-else-if="!validCurrencies.includes(purchasePriceCurrency)"
                  class="red--text"
                >
                  {{
                    $t(
                      "pages.assets.form.validation.wrongPurchasePriceCurrency"
                    )
                  }}
                </span>
                <template v-else>
                  {{ formattedPurchasePrice }}
                </template>
              </v-col>
            </v-row>
            <v-row v-if="isAverageDataMethod" dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.weight") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="isPurchasedAsUsed" class="blue--text">
                  {{ $t("pages.assets.form.validation.assetPurchasedAsUsed") }}
                </span>
                <span v-else-if="!weight" class="red--text">
                  {{ $t("pages.assets.form.validation.missingWeight") }}
                </span>
                <span v-else-if="!isActivityUnitKg" class="red--text">
                  {{ $t("pages.assets.form.validation.noWeightData") }}
                </span>
                <template v-else>
                  {{ formattedWeight }}
                </template>
              </v-col>
            </v-row>
            <v-row dense class="mt-4">
              <v-col cols="5">
                {{
                  $t("pages.assets.form.fixedLabels.emissionFactor", {
                    currencyType: emissionFactor
                      ? emissionFactor.activityUnit
                      : "?",
                  })
                }}:
              </v-col>
              <v-col cols="7">
                <span v-if="isPurchasedAsUsed"> </span>
                <span v-else-if="!emissionFactor" class="red--text">
                  {{ $t("pages.assets.form.validation.missingEmissionFactor") }}
                </span>
                <span v-else>
                  {{ formattedEmissionFactor }}
                </span>
              </v-col>
            </v-row>
            <v-row dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.source") }}:
              </v-col>
              <v-col cols="7">
                <a
                  v-if="emissionFactor"
                  :href="emissionFactor.sourceLink"
                  target="_blank"
                >
                  {{ emissionFactor.source }}
                </a>
              </v-col>
            </v-row>
            <v-row dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.uuid") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="emissionFactor">
                  {{ emissionFactor.id }}
                </span>
              </v-col>
            </v-row>
            <v-row dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.emissions") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="isPurchasedAsUsed" class="blue--text">
                  {{ calculatedEmissions }}
                </span>
                <span v-else>
                  {{ calculatedEmissions }}
                </span>
              </v-col>
            </v-row>
            <v-row dense class="mt-4">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.factorCalculationType") }}
              </v-col>
              <v-col cols="7">
                {{ selectedFactor }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>
<script>
import AssetAttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import {
  fixedAttributes,
  fixedFieldAttributes,
  emissionCurrencies,
  co2MethodOptions,
  emissionFactorStatus,
} from "../../../util/fixedAssetData";
import { fullNumberFormatter } from "../../../util/formatters";
import {
  calculateEmissionsSpendBased,
  calculateEmissionsAverageData,
} from "../../../util/calculators";

export default {
  components: { AssetAttributeLinkInput },
  props: {
    asset: Object,
    assetAttributeLinkData: Object,
    attributes: Array,
    emissionFactor: Object,
    defaultCurrencyCode: String,
    tabValue: String,
  },
  data() {
    return {
      fixedAttributes,
      emissionSectors: [],
      emissionCategories: [],
      emissionRegions: [],
      emissionFactors: [],
      validCurrencies: emissionCurrencies,
    };
  },
  methods: {
    updateData(attributeId, value) {
      this.$emit("asset_attribute_data_changed", attributeId, value);
    },
    handleMethodChanged(value) {
      this.$emit("select_changed", value, this.methodAttribute);

      if (this.sectorData) {
        this.$emit("string_changed", null, this.sectorAttribute);
        this.updateData(fixedAttributes.co2SectorId, null);
      }

      if (this.categoryData) {
        this.$emit("string_changed", null, this.categoryAttribute);
        this.updateData(fixedAttributes.co2CategoryId, null);
      }

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionCategories.length) this.emissionCategories = [];
      if (this.emissionRegions.length) this.emissionRegions = [];
      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleSectorChanged(value) {
      this.$emit("string_changed", value, this.sectorAttribute);

      if (this.categoryData) {
        this.$emit("string_changed", null, this.categoryAttribute);
        this.updateData(fixedAttributes.co2CategoryId, null);
      }

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionRegions.length) this.emissionRegions = [];
      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleCategoryChanged(value) {
      this.$emit("string_changed", value, this.categoryAttribute);

      if (this.regionData) {
        this.$emit("string_changed", null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }

      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleRegionChanged(value) {
      this.$emit("string_changed", value, this.regionAttribute);

      if (this.nameData) {
        this.$emit("string_changed", null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.$emit("emission_factor_changed", null);
      }
    },
    handleNameChanged(value) {
      this.$emit("string_changed", value, this.nameAttribute);

      const emissionFactor =
        this.emissionFactors.find((x) => x.id === value) ?? null;

      this.$emit("emission_factor_changed", emissionFactor);
    },
    getEmissionSectors() {
      fetch(
        this.route("api.emission-sectors.index", {
          weightBased: this.isAverageDataMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionSectors = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        });
    },
    getEmissionCategories(sectorId) {
      fetch(
        this.route("api.emission-categories.index", {
          emissionSectorId: sectorId,
          weightBased: this.isAverageDataMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionCategories = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        });
    },
    getEmissionRegions(categoryId) {
      fetch(
        this.route("api.emission-regions.index", {
          emissionCategoryId: categoryId,
          weightBased: this.isAverageDataMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionRegions = data.map((x) => ({
            ...x,
            value: x.countryName ? x.countryName : x.name,
          }));
        });
    },
    getEmissionFactors(sectorId, categoryId, regionId) {
      fetch(
        this.route("api.emission-factors.index", {
          emissionSectorId: sectorId,
          emissionCategoryId: categoryId,
          emissionRegionId: regionId,
          weightBased: this.isAverageDataMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionFactors = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        });
    },
    approveEmission() {
      if (!this.asset?.id) return;

      this.$inertia.patch(
        this.route("assets.emission-factor.approve", this.asset.id),
        {},
        {
          onSuccess: () => this.$emit("reload"),
        }
      );
    },
  },
  computed: {
    methodData() {
      return this.assetAttributeLinkData[fixedAttributes.co2MethodId];
    },
    methodAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2MethodId);
    },
    sectorData() {
      return this.assetAttributeLinkData[fixedAttributes.co2SectorId];
    },
    sectorAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2SectorId);
    },
    categoryData() {
      return this.assetAttributeLinkData[fixedAttributes.co2CategoryId];
    },
    categoryAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2CategoryId
      );
    },
    regionData() {
      return this.assetAttributeLinkData[fixedAttributes.co2RegionId];
    },
    regionAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2RegionId);
    },
    nameData() {
      return this.assetAttributeLinkData[fixedAttributes.co2NameId];
    },
    nameAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2NameId);
    },
    purchasePrice() {
      return this.assetAttributeLinkData[
        fixedAttributes.masterDataPurchasePriceId
      ];
    },
    purchasePriceCurrency() {
      const currency =
        this.assetAttributeLinkData[
          fixedAttributes.masterDataPurchasePriceId + "-secondValue"
        ];

      return currency?.trim() ?? this.defaultCurrencyCode;
    },
    weight() {
      return this.assetAttributeLinkData[fixedAttributes.masterDataWeightId];
    },
    emissionFactorAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.co2EmissionFactorId
      );
    },
    emissionFactorCurrencyAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.co2EmissionFactorCurrencyId
      );
    },
    sourceAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.co2SourceId
      );
    },
    uuidAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.co2UuidId
      );
    },
    emissionsAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.co2EmissionsId
      );
    },
    factorTypeAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.co2FactorTypeId
      );
    },
    assetQuantity() {
      return this.assetAttributeLinkData[fixedAttributes.masterDataQuantityId];
    },
    multipliedPurchasePrice() {
      let quantity = this.assetQuantity > 0 ? this.assetQuantity : 1;
      return quantity * this.purchasePrice;
    },
    formattedPurchasePrice() {
      if (!this.purchasePrice || !this.purchasePriceCurrency) return null;

      return `${this.purchasePriceCurrency} ${fullNumberFormatter.format(
        this.multipliedPurchasePrice
      )}`;
    },
    formattedWeight() {
      return this.weight * this.assetQuantity;
    },
    formattedEmissionFactor() {
      if (!this.emissionFactor) return "";

      const factor = this.emissionFactor.kgCo2eAr5
        ? this.emissionFactor.kgCo2eAr5
        : this.emissionFactor.kgCo2eAr4;

      return fullNumberFormatter.format(factor);
    },
    calculatedEmissions() {
      if (this.isPurchasedAsUsed) {
        return "0";
      }

      if (this.isSpendBasedMethod) {
        return calculateEmissionsSpendBased(
          this.multipliedPurchasePrice,
          this.purchasePriceCurrency,
          this.emissionFactor
        );
      } else if (this.isAverageDataMethod && this.isActivityUnitKg) {
        return calculateEmissionsAverageData(
          this.formattedWeight,
          this.emissionFactor
        );
      }
      return undefined;
    },
    selectedFactor() {
      const factorCurrency = this.emissionFactor?.activityUnit ?? null;

      if (this.isSpendBasedMethod && !this.purchasePrice) return;

      if (this.isAverageDataMethod && (!this.weight || !this.isActivityUnitKg))
        return;

      if (
        !this.emissionFactor ||
        !this.validCurrencies.includes(factorCurrency)
      )
        return;

      return this.emissionFactor.kgCo2eAr5 ? "AR 5" : "AR 4";
    },
    isAverageDataMethod() {
      return this.methodData === co2MethodOptions.averageDataMethodId;
    },
    isSpendBasedMethod() {
      return this.methodData === co2MethodOptions.spendBasedMethodId;
    },
    isActivityUnitKg() {
      if (this.emissionFactor !== null) {
        return this.emissionFactor.activityUnit === "kg";
      }
      return false;
    },
    factorStatusData() {
      return this.assetAttributeLinkData[
        fixedFieldAttributes.co2EmissionFactorStatusId
      ];
    },
    factorIsDefined() {
      return this.factorStatusData === emissionFactorStatus.defined;
    },
    isPurchasedAsUsed() {
      let isPurchasedAsUsed =
        this.assetAttributeLinkData[fixedAttributes.masterDataProductionDateId];

      if (isPurchasedAsUsed) {
        this.$emit("emission_factor_changed", null);
        this.$emit("decimal_changed", 0, this.emissionsAttribute);
      }

      return isPurchasedAsUsed;
    },
  },
  mounted() {
    this.getEmissionSectors();

    if (this.sectorData) {
      this.getEmissionCategories(this.sectorData);
    }

    if (this.categoryData) {
      this.getEmissionRegions(this.categoryData);
    }

    if (this.sectorData && this.categoryData && this.regionData) {
      this.getEmissionFactors(
        this.sectorData,
        this.categoryData,
        this.regionData
      );
    }
  },
  watch: {
    methodData() {
      this.getEmissionSectors();
    },
    sectorData(value) {
      this.getEmissionCategories(value);
    },
    categoryData(value) {
      this.getEmissionRegions(value);
    },
    regionData(value) {
      if (!this.sectorData || !this.categoryData) return;

      this.getEmissionFactors(this.sectorData, this.categoryData, value);
    },
  },
};
</script>
