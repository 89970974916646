<template>
  <v-btn
    color="blue darken-1"
    text
    @click="$emit('click', $event)"
    :href="href"
    :loading="loading"
    :disabled="loading || disabled"
  >
    <v-icon class="black--text">{{ icon }}</v-icon>
    <p v-if="label" class="teal--text text--darken-4 mb-0">
      {{ label }}
    </p>
    <slot v-else name="label"></slot>
  </v-btn>
</template>
<script>
export default {
  props: {
    icon: String,
    label: String,
    loading: Boolean,
    href: String,
    disabled: Boolean,
  },
  emits: ["click"],
};
</script>
