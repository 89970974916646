<template>
  <div>
    <h4 class="text-h4">
      {{ $t("pages.solutionTypes.form.edit.title") }} {{ form.name }}
    </h4>
    <v-spacer class="mb-6"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col col="12" lg="8">
          <form @submit.prevent="submit">
            <v-card elevation="1">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.name")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      name="name"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.name"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.lastUpdated")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="updatedAt"
                      name="updatedAt"
                      disabled
                      type="text"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.createdAt")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="createdAt"
                      name="createdAt"
                      disabled
                      type="text"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <flash-messages />
                <request-size-error :errors="form.errors" />
              </v-card-actions>
            </v-card>
            <h5 v-if="form.contents.length" class="text-h5 mt-2">
              {{ $t("pages.solutionTypes.form.contents") }}
            </h5>
            <vue-draggable
              v-model="form.contents"
              group="contents"
              @choose="onDraggingStart"
              @unchoose="onDraggingEnd"
              @end="onDraggingEnd"
            >
              <v-card
                elevation="1"
                v-for="(content, index) in form.contents"
                :key="index"
                class="mt-4 cursor-grab"
              >
                <v-card-title class="pb-1">
                  {{ content.informationType.name }}
                  <v-spacer />
                  <v-btn icon @click="removeContent(index)"
                    ><v-icon>mdi-trash-can</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters class="align-center">
                    <v-col cols="4">
                      <v-subheader>{{
                        $t("components.contents.visibility")
                      }}</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-radio-group
                        v-model="content.visibility"
                        row
                        class="mt-1 visibility-buttons"
                        :error-messages="
                          form.errors[`contents${index}Visibility`]
                        "
                      >
                        <v-radio
                          v-for="visibility in visibilities"
                          :key="visibility"
                          :label="$t(`common.visibilities.${visibility}`)"
                          :value="visibility"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <editor
                    v-model="content.description"
                    :disabled="isContentDragging"
                  />
                </v-card-text>
                <v-card-text>
                  <v-file-input
                    v-model="content.newFiles"
                    :placeholder="
                      $t('components.contents.addFiles.placeholder')
                    "
                    :label="$t('components.contents.addFiles.label')"
                    multiple
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-card-text>
                <v-card-text v-if="content.files && content.files.length">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("common.fieldNames.name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("common.fieldNames.createdAt") }}
                          </th>
                          <th class="text-right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, fIndex) in content.files"
                          :key="item.id"
                        >
                          <td>{{ item.name }}{{ item.extension }}</td>
                          <td>
                            {{ new Date(item.createdAt).toLocaleString() }}
                          </td>
                          <td class="text-right">
                            <v-btn
                              small
                              text
                              @click.stop
                              :href="
                                route('api.files.content', {
                                  id: item.id,
                                  filename: item.originalName,
                                })
                              "
                              target="_blank"
                            >
                              <v-icon class="mr-2">mdi-attachment</v-icon>
                            </v-btn>
                            <v-btn
                              small
                              text
                              @click.stop="removeFile(index, fIndex)"
                            >
                              <v-icon class="mr-2"
                                >mdi-trash-can-outline</v-icon
                              >
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </vue-draggable>
            <h5 v-if="selectedAssetTemplates.length" class="text-h5 mt-2">
              {{ $t("pages.solutionTypes.form.assetTemplates") }}
            </h5>
            <v-row>
              <v-col
                v-for="assetTemplate in selectedAssetTemplates"
                :key="assetTemplate.id"
                cols="4"
              >
                <asset-preview
                  :asset="assetTemplate"
                  @remove="removeAsset"
                  @edit="openAssetForm"
                />
              </v-col>
            </v-row>
          </form>
        </v-col>
      </v-row>
    </div>
    <content-dialog
      v-model="contentDialog"
      :contents="informationTypes"
      @submit="addContent"
    />

    <asset-dialog
      v-model="assetDialog"
      :assets="assetTemplates"
      :existingAssetIds="form.assetTemplates"
      @submit="addAssets"
    />

    <solution-range-dialog
      v-model="solutionRangeDialog"
      :solution-type-id="solutionType.id"
      :used-location-ids="usedLocationIds"
      :locations="locations"
    />

    <v-speed-dial v-model="fab" fixed fab bottom right>
      <template v-slot:activator>
        <v-btn
          fab
          color="primary"
          v-if="fab"
          :disabled="sending || form.processing"
          @click="submit"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn fab color="secondary" v-else>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="indigo"
            v-on="on"
            @click="openContentDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pages.solutionTypes.form.menu.addContent") }}</span>
      </v-tooltip>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="indigo"
            v-on="on"
            @click="openAssetDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pages.solutionTypes.form.menu.addAssetTemplates") }}</span>
      </v-tooltip>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="indigo"
            v-on="on"
            @click="openSolutionRangeDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pages.solutionTypes.form.menu.createSolutions") }}</span>
      </v-tooltip>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="red"
            v-on="on"
            :disabled="sending || form.processing"
            @click="destroy"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
        <span>{{
          $t("pages.solutionTypes.form.menu.deleteSolutionType")
        }}</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import { objectToFormData } from "@/util/formData";
import { pascalToSpaces } from "@/util/text";
import Editor from "@/Shared/Editor";
import FlashMessages from "@/Shared/FlashMessages";
import ContentDialog from "@/Shared/ContentDialog";
import AssetDialog from "@/Shared/AssetDialog";
import RequestSizeError from "@/Shared/RequestSizeError";
import SolutionRangeDialog from "@/Components/SolutionType/SolutionRangeDialog";
import AssetPreview from "@/Components/Asset/AssetPreview.vue";

export default {
  layout: appLayout({ title: "pages.solutionTypes.form.edit.pageTitle" }),
  props: {
    solutionType: Object,
    informationTypes: Array,
    visibilities: Array,
    assetTemplates: Array,
    usedLocationIds: Array,
    locations: Array,
  },
  components: {
    FlashMessages,
    Editor,
    ContentDialog,
    AssetDialog,
    SolutionRangeDialog,
    RequestSizeError,
    AssetPreview,
  },
  data() {
    return {
      contentDialog: false,
      assetDialog: false,
      solutionRangeDialog: false,
      fab: false,
      sending: false,
      form: this.$inertia.form({
        id: this.solutionType.id,
        name: this.solutionType.name,
        assetTemplates: this.solutionType.assetTemplates.map((x) => x.id),
        contents: this.solutionType.contents,
      }),
      selectedContentTypes: [],
      isContentDragging: false,
    };
  },
  computed: {
    createdAt() {
      return new Date(this.solutionType.createdAt).toLocaleString();
    },
    updatedAt() {
      return new Date(this.solutionType.createdAt).toLocaleString();
    },
    selectedAssetTemplates() {
      return this.assetTemplates.filter((x) =>
        this.form.assetTemplates.includes(x.id)
      );
    },
  },
  methods: {
    addContent(contents) {
      this.form.contents = this.form.contents.concat(contents);
    },
    addAssets(assets) {
      this.form.assetTemplates = this.form.assetTemplates.concat(assets);
    },
    removeContent(index) {
      this.form.contents.splice(index, 1);
    },
    removeAsset(asset) {
      this.form.assetTemplates = this.form.assetTemplates.filter(
        (x) => x !== asset.id
      );
    },
    removeFile(contentIndex, fileIndex) {
      this.form.contents[contentIndex].files.splice(fileIndex, 1);
    },
    openAssetForm(asset) {
      this.$inertia.get(
        this.route("assets.index", {
          "portfolioIds[]": asset.portfolioId,
          editAssetId: asset.id,
        })
      );
    },
    openContentDialog() {
      this.contentDialog = true;
    },
    openAssetDialog() {
      this.assetDialog = true;
    },
    openSolutionRangeDialog() {
      this.solutionRangeDialog = true;
    },
    destroy() {
      if (window.confirm(this.$t("pages.solutionTypes.form.confirmDelete"))) {
        this.$inertia.delete(
          this.route("solution-types.destroy", this.solutionType.id),
          {
            onStart: () => (this.sending = true),
            onFinish: () => (this.sending = false),
          }
        );
      }
    },
    submit() {
      this.form
        .transform((data) => {
          const transformedData = {
            ...data,
            contents: data.contents.map((c, index) => ({
              ...c,
              sortOrder: index + 1,
            })),
          };

          return objectToFormData(transformedData);
        })
        .put(this.route("solution-types.update", this.solutionType.id), {
          preserveScroll: true,
          preserveState: false,
        });
    },
    pascalToSpaces(text) {
      return pascalToSpaces(text);
    },
    onDraggingStart() {
      this.isContentDragging = true;
    },
    onDraggingEnd() {
      this.isContentDragging = false;
    },
  },
};
</script>
