import { DateTime } from "luxon";

export function addTimezoneOffset(date) {
  if (!date) return null;

  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
}

export function removeTimezoneOffset(date) {
  if (!date) return null;

  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset);
}

export function diffMonths(date1, date2) {
  if (!DateTime.isDateTime(date1) || !DateTime.isDateTime(date2)) return null;

  return date1.diff(date2, ["months"]).toObject().months;
}

export const normalizeISOString = (isoString) => {
  const longFormRegex =
    /\d{4}-[0-1][0-9]-[0-2][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9]\.\d*/;

  if (isoString.length === 10) {
    isoString = `${isoString}T00:00:00Z`;
  } else if (isoString.length === 19 || longFormRegex.test(isoString)) {
    isoString = `${isoString}Z`;
  }

  return isoString;
};

export const toLocaleUTCDateString = (date) => {
  if (Object.prototype.toString.call(date) === "[object String]") {
    date = new Date(date);
  }

  const adjustedDate = addTimezoneOffset(date);

  return adjustedDate.toLocaleDateString();
};

export const toLocaleUTCTimeString = (date) => {
  if (Object.prototype.toString.call(date) === "[object String]") {
    date = new Date(date);
  }

  const adjustedDate = addTimezoneOffset(date);

  return `${adjustedDate.toLocaleTimeString()} UTC`;
};

export const toLocaleTimeString = (date) => {
  if (Object.prototype.toString.call(date) === "[object String]") {
    date = new Date(date);
  }

  return date.toLocaleTimeString();
};

export const toLocaleUTCDateStringFiltered = (date) => {
  if (Object.prototype.toString.call(date) === "[object String]") {
    date = new Date(date);
  }

  const adjustedDate = addTimezoneOffset(date);

  return DateTime.fromJSDate(adjustedDate).toISODate();
};

export const toLocaleUTCString = (date) => {
  if (Object.prototype.toString.call(date) === "[object String]") {
    date = new Date(date);
  }

  const adjustedDate = addTimezoneOffset(date);

  return `${adjustedDate.toLocaleString()} UTC`;
};

export const timeAgoInDaysAndHours = (timestamp) => {
  timestamp = normalizeISOString(timestamp);

  const timestampUtc = DateTime.fromISO(timestamp);
  const currentTimeUtc = DateTime.utc();

  const diff = currentTimeUtc.diff(timestampUtc, ["days", "hours"]).toObject();

  const roundedHours = Math.floor(diff.hours ?? 0);

  return `${diff.days ?? 0}d ${roundedHours}h`;
};
