<template>
  <v-dialog v-model="visible" max-width="500px">
    <v-card>
      <v-card-title>{{ $t("components.createSolutions.title") }}</v-card-title>
      <v-card-text>
        <v-row no-gutters class="align-start mt-4">
          <v-col cols="4">
            <v-subheader>{{
              $t("components.createSolutions.fields.locations")
            }}</v-subheader>
          </v-col>
          <v-col cols="8">
            <location-picker
              ref="locationList2"
              :locations="locationList"
              v-model="form.locations"
              multiple
              name="locations"
              :label="$t('components.createSolutions.fields.locations')"
              :error-messages="form.errors.locations"
              :menu-props="{ closeOnClick: true }"
            >
              <v-list-item
                slot="append-item"
                ripple
                class="close-menu-button"
                @click="closeLocationListMenu"
              >
                <v-list-item-action>
                  <v-icon>mdi-close</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{
                  $t("components.createSolutions.closeMenu")
                }}</v-list-item-title>
              </v-list-item>
            </location-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="visible = false">
          {{ $t("common.actions.close") }}
        </v-btn>
        <v-btn color="primary" text @click="submit">
          {{ $t("common.actions.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { objectToFormData } from "@/util/formData";
import LocationPicker from "@/Components/LocationPicker";

export default {
  components: { LocationPicker },
  props: ["value", "solutionTypeId", "usedLocationIds", "locations"],
  data() {
    return {
      form: this.$inertia.form({
        locations: [],
      }),
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    locationList() {
      if (!this.locations) return [];

      if (!this.usedLocationIds) return this.locations;

      return this.locations.map((location) => {
        if (this.usedLocationIds.includes(location.id)) {
          location.disabled = true;
        }

        return location;
      });
    },
  },
  methods: {
    closeLocationListMenu() {
      this.$refs.locationList.isMenuActive = false;
      this.$refs.locationList.blur();

      this.$refs.locationList2.closeMenu();
    },
    submit() {
      this.form
        .transform((data) => objectToFormData(data))
        .post(
          this.route("solution-types.store.solutions", this.solutionTypeId),
          {
            onFinish: () => {
              this.form.reset();
              this.visible = false;
            },
          }
        );
    },
  },
};
</script>
