<template>
  <v-menu
    top
    offset-x
    nudge-right="16"
    max-width="700"
    :disabled="noNotifications"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip right :disabled="!noNotifications">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            text
            @click="markNotificationsRead"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            {{ $t("components.notificationMenu.notifications") }}
            <v-icon class="ml-2">mdi-bell</v-icon>
            <span class="red-dot" v-if="notificationsUnread"></span>
          </v-btn>
        </template>
        <span>{{ $t("components.notificationMenu.noNotifications") }}</span>
      </v-tooltip>
    </template>

    <v-list max-height="97vh" class="overflow-y-auto">
      <v-list-item
        three-line
        v-for="notification in notifications"
        :key="notification.id"
        @click="() => navigateNotification(notification.id)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ notification.title }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ notification.content }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ localeString(notification.createdAt) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon v-if="!notification.readByUser">
          <v-icon color="red" small>mdi-circle</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item v-intersect="handlebottomIntersect"></v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import concat from "lodash/concat";

export default {
  props: {
    auth: Object,
  },
  data() {
    return {
      notifications: [],
      currentUser: null,
      cutoffTime: null,
      page: 1,
      endReached: false,
    };
  },
  methods: {
    fetchNotifications(initial = false) {
      let query = {
        cutoffTime: this.cutoffTime,
        page: this.page,
      };

      return fetch(this.route("api.notifications.index", query), {
        method: "GET",
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((notifications) => {
          if (notifications.pageCount <= notifications.currentPage)
            this.endReached = true;

          if (initial) {
            this.notifications = notifications.data;
          } else {
            this.notifications = concat(this.notifications, notifications.data);
          }
        });
    },
    fetchCurrentUser() {
      return fetch(this.route("api.me.get"), {
        method: "GET",
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((currentUser) => {
          this.currentUser = currentUser;
        });
    },
    initialFetch() {
      this.cutoffTime = new Date().toISOString();
      this.page = 1;
      this.endReached = false;
      this.fetchCurrentUser().then(() => this.fetchNotifications(true));
    },
    handlebottomIntersect() {
      if (this.endReached) return;

      this.page++;
      this.fetchNotifications();
    },
    localeString(text) {
      if (!text) return "";

      return new Date(text).toLocaleString();
    },
    navigateNotification(id) {
      this.$inertia.post(
        this.route("api.notifications.navigate", id),
        {},
        { preserveState: false, onSuccess: () => this.fetchNotifications(true) }
      );
    },
    markNotificationsRead() {
      fetch(this.route("api.notifications.read"), {
        method: "POST",
      }).then(() => {
        this.fetchCurrentUser();
      });
    },
  },
  computed: {
    noNotifications() {
      return !this.notifications || this.notifications.length === 0;
    },
    notificationsUnread() {
      // Don't show red circle while loading props
      if (!this.currentUser) return false;

      if (!this.currentUser.notificationsReadAt) return true;

      let notificationReadTime = new Date(this.currentUser.notificationsReadAt);

      return (
        this.notifications &&
        this.notifications.some(
          (n) => new Date(n.createdAt) > notificationReadTime
        )
      );
    },
  },
  created() {
    this.$inertia.on("start", (event) => {
      if (event.detail.visit.url.pathname !== window.location.pathname) {
        this.initialFetch();
      }
    });

    this.initialFetch();
  },
};
</script>
<style scoped>
.red-dot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  background-color: red;
  top: -2px;
  right: -0px;
  width: 10px;
  height: 10px;
}
</style>
