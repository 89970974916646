<template>
  <div></div>
</template>
<script>
// The reason for using the frontend to redirect is to make sure the Cookie gets set correctly,
// since the browser might discard "Set-Cookie" instructions when the response code is not 2xx
export default {
  created() {
    this.$inertia.get(this.route("dashboard.index"));
  },
};
</script>
