<template>
  <div class="fill-height" style="min-height: 60vh">
    <i18n :path="reportLanguageKey" tag="p" class="text-body-1 mb-8">
      <span v-if="updateCount" class="font-weight-bold">{{ updateCount }}</span>
      <span
        v-if="importCount || (!importCount && !updateCount)"
        class="font-weight-bold"
        >{{ importCount }}</span
      >
      <span class="font-weight-bold">{{ portfolioName }}</span>
    </i18n>
    <v-row dense class="mx-0 mt-8">
      <v-col cols="4">
        <v-checkbox
          v-model="saveImportedFileProxy"
          @change="handleSaveImportedFileChanged"
          :label="$t('pages.assets.import.stepFour.saveFile')"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row dense class="mx-0 mt-2">
      <v-col cols="4">
        <label for="stepFourFileNameInput">{{
          $t("pages.assets.import.stepFour.saveFileAs")
        }}</label>
        <v-text-field
          id="stepFourFileNameInput"
          :value="importedFileName"
          @change="handleImportedFileNameChanged"
          :disabled="!saveImportedFile"
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    importCount: Number,
    updateCount: Number,
    portfolioName: String,
    saveImportedFile: Boolean,
    importedFileName: String,
  },
  emits: ["saveImportedFileChanged", "importedFileNameChanged"],
  data() {
    return {
      saveImportedFileProxy: null,
    };
  },
  methods: {
    handleSaveImportedFileChanged(value) {
      this.$emit("saveImportedFileChanged", value);
    },
    handleImportedFileNameChanged(value) {
      this.$emit("importedFileNameChanged", value);
    },
  },
  computed: {
    reportLanguageKey() {
      if (this.importCount && this.updateCount)
        return "pages.assets.import.stepFour.importReportCreateUpdate";
      else if (this.updateCount)
        return "pages.assets.import.stepFour.importReportUpdate";
      else return "pages.assets.import.stepFour.importReportCreate";
    },
  },
  mounted() {
    this.saveImportedFileProxy = this.saveImportedFile;
  },
  watch: {
    saveImportedFile: function () {
      this.saveImportedFileProxy = this.saveImportedFile;
    },
  },
};
</script>
