<template>
  <div>
    <h4 class="text-h4">{{ $t("pages.solutionTypes.form.create.title") }}</h4>
    <v-spacer class="mb-6"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col md="8" lg="8">
          <form @submit.prevent="submit">
            <v-card elevation="1">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.name")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      name="name"
                      type="text"
                      :disabled="isContentDragging"
                      :error-messages="form.errors.name"
                    />
                  </v-col>
                </v-row>
                <v-row class="my-4" />
                <v-row no-gutters class="align-start mt-4">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.solutionTypes.form.fields.locations")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <location-picker
                      :locations="locations"
                      v-model="form.locations"
                      multiple
                      name="locations"
                      :label="$t('pages.solutionTypes.form.fields.locations')"
                      :disabled="isContentDragging"
                      :errorMessages="form.errors.locations"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <flash-messages />
                <request-size-error :errors="form.errors" />
                <v-spacer />
              </v-card-actions>
            </v-card>
            <h5 v-if="form.contents.length" class="text-h5 mt-2">
              {{ $t("pages.solutionTypes.form.contents") }}
            </h5>
            <vue-draggable
              v-model="form.contents"
              group="contents"
              @choose="onDraggingStart"
              @unchoose="onDraggingEnd"
              @end="onDraggingEnd"
            >
              <v-card
                elevation="1"
                v-for="(content, index) in form.contents"
                :key="index"
                class="mt-4 cursor-grab"
              >
                <v-card-title class="pb-1">
                  {{ content.name }} <v-spacer />
                  <v-btn icon @click="removeContent(content, index)"
                    ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters class="align-center">
                    <v-col cols="4">
                      <v-subheader>{{
                        $t("components.contents.visibility")
                      }}</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-radio-group
                        v-model="content.visibility"
                        row
                        class="mt-1 visibility-buttons"
                        :error-messages="
                          form.errors[`contents${index}Visibility`]
                        "
                      >
                        <v-radio
                          v-for="visibility in visibilities"
                          :key="visibility"
                          :label="$t(`common.visibilities.${visibility}`)"
                          :value="visibility"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <editor
                    v-model="content.description"
                    :disabled="isContentDragging"
                  />
                </v-card-text>
                <v-card-text>
                  <v-file-input
                    v-model="content.newFiles"
                    :placeholder="
                      $t('components.contents.addFiles.placeholder')
                    "
                    :label="$t('components.contents.addFiles.label')"
                    multiple
                    small-chips
                    prepend-icon="mdi-paperclip"
                  />
                </v-card-text>
              </v-card>
            </vue-draggable>
            <h5 v-if="selectedAssetTemplates.length" class="text-h5 mt-2">
              {{ $t("pages.solutionTypes.form.assetTemplates") }}
            </h5>
            <v-row>
              <v-col
                v-for="assetTemplate in selectedAssetTemplates"
                :key="assetTemplate.id"
                cols="4"
              >
                <asset-preview
                  :asset="assetTemplate"
                  @remove="removeAsset"
                  @edit="openAssetForm"
                />
              </v-col>
            </v-row>
          </form>
        </v-col>
      </v-row>
      <content-dialog
        v-model="dialog"
        :contents="informationTypes"
        @submit="addContent"
      />
      <asset-dialog
        v-model="assetDialog"
        :assets="assetTemplates"
        :existingAssetIds="form.assetTemplates"
        @submit="addAssets"
      />
    </div>
    <v-speed-dial v-model="fab" fixed fab bottom right>
      <template v-slot:activator>
        <v-btn
          fab
          color="primary"
          v-if="fab"
          :disabled="form.processing"
          @click="submit"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn fab color="primary" v-else>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn fab dark small color="indigo" v-on="on" @click="openDialog">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pages.solutionTypes.form.menu.addContent") }}</span>
      </v-tooltip>
      <v-tooltip
        :nudge-left="20"
        left
        :value="true"
        class="v-tooltip--speed-dial"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="indigo"
            v-on="on"
            @click="openAssetDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pages.solutionTypes.form.menu.addAssetTemplates") }}</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import { objectToFormData } from "@/util/formData";
import { pascalToSpaces } from "@/util/text";
import Editor from "@/Shared/Editor";
import FlashMessages from "@/Shared/FlashMessages";
import ContentDialog from "@/Shared/ContentDialog";
import AssetDialog from "@/Shared/AssetDialog";
import RequestSizeError from "@/Shared/RequestSizeError";
import AssetPreview from "@/Components/Asset/AssetPreview.vue";
import LocationPicker from "@/Components/LocationPicker";

export default {
  layout: appLayout({ title: "pages.solutionTypes.form.create.pageTitle" }),
  props: {
    informationTypes: Array,
    visibilities: Array,
    assetTemplates: Array,
    locations: Array,
  },
  components: {
    FlashMessages,
    ContentDialog,
    AssetDialog,
    Editor,
    RequestSizeError,
    AssetPreview,
    LocationPicker,
  },
  data() {
    return {
      fab: false,
      dialog: false,
      assetDialog: false,
      selectedContentTypes: [],
      form: this.$inertia.form({
        name: "",
        locations: [],
        assetTemplates: [],
        contents: [],
      }),
      isContentDragging: false,
    };
  },
  methods: {
    removeContent(content, index) {
      this.form.contents.splice(index, 1);
    },
    addContent(contents) {
      this.form.contents = this.form.contents.concat(contents);
    },
    removeAsset(asset) {
      this.form.assetTemplates = this.form.assetTemplates.filter(
        (x) => x !== asset.id
      );
    },
    addAssets(assets) {
      this.form.assetTemplates = this.form.assetTemplates.concat(assets);
    },
    openAssetForm(asset) {
      this.$inertia.get(
        this.route("assets.index", {
          "portfolioIds[]": asset.portfolioId,
          editAssetId: asset.id,
        })
      );
    },
    openDialog() {
      this.dialog = true;
    },
    openAssetDialog() {
      this.assetDialog = true;
    },
    submit() {
      this.form
        .transform((data) => {
          const transformedData = {
            ...data,
            contents: data.contents.map((c, index) => ({
              ...c,
              sortOrder: index + 1,
            })),
          };

          return objectToFormData(transformedData);
        })
        .post(this.route("solution-types.store"));
    },
    pascalToSpaces(text) {
      return pascalToSpaces(text);
    },
    onDraggingStart() {
      this.isContentDragging = true;
    },
    onDraggingEnd() {
      this.isContentDragging = false;
    },
  },
  computed: {
    selectedAssetTemplates() {
      return this.assetTemplates.filter((x) =>
        this.form.assetTemplates.includes(x.id)
      );
    },
  },
};
</script>
