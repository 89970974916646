<template>
  <div>
    <h4 class="text-h4">{{ $t("pages.featureRequest.title") }}</h4>
    <v-spacer class="mb-4"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col col="12" lg="8">
          <v-card elevation="1">
            <v-card-text>
              <p class="text-h4 text--primary text-center">
                {{
                  type === "Accept"
                    ? $t("pages.featureRequest.confirmAccept")
                    : $t("pages.featureRequest.confirmReject")
                }}
              </p>
              <p class="text-body-1 text-center">
                {{ $t("pages.featureRequest.instruction") }}
              </p>
              <v-row>
                <v-spacer />
                <v-col cols="4">
                  <v-btn
                    color="primary"
                    class="fill-width"
                    @click="submitResponse"
                  >
                    {{
                      type === "Accept"
                        ? $t("pages.featureRequest.accept")
                        : $t("pages.featureRequest.reject")
                    }}
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";

export default {
  layout: appLayout({ title: "pages.featureRequest.pageTitle" }),
  props: {
    id: String,
    type: String,
    token: String,
  },
  methods: {
    submitResponse() {
      if (this.type === "Accept") {
        this.$inertia.post(
          this.route("feature-requests.accept", {
            id: this.id,
            token: this.token,
          })
        );
      } else if (this.type === "Reject") {
        this.$inertia.post(
          this.route("feature-requests.reject", {
            id: this.id,
            token: this.token,
          })
        );
      }
    },
  },
};
</script>
