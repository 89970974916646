<template>
  <div class="fill-height" style="min-height: 60vh">
    <v-row dense>
      <v-col cols="4">
        <label for="stepTwoFileField" class="subtitle-2 d-block mb-2">
          {{ $t("pages.locations.import.stepTwo.fileName") }}
        </label>
        <v-text-field
          id="stepTwoFileField"
          disabled
          solo
          :value="selectedImportFile.name"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    selectedImportFile: File,
  },
};
</script>
