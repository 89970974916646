<template>
  <div></div>
</template>
<script>
export default {
  emits: ["draggingActive"],
  data() {
    return {
      timeout: null,
      draggingActive: false,
    };
  },
  methods: {
    handleDragover(event) {
      if (!event.dataTransfer.types.some((x) => x === "Files")) return;

      if (!this.draggingActive) {
        this.draggingActive = true;
        this.$emit("draggingActive", this.draggingActive);
      }

      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.timeout = setTimeout(() => {
        this.draggingActive = false;
        this.$emit("draggingActive", this.draggingActive);
        this.timeout = null;
      }, 500);
    },
    handleDrop(event) {
      event.preventDefault();
    },
  },
  created() {
    document.addEventListener("dragover", this.handleDragover);
    document.addEventListener("drop", this.handleDrop);
  },
  beforeDestroy() {
    document.removeEventListener("dragover", this.handleDragover);
    document.removeEventListener("drop", this.handleDrop);
  },
};
</script>
<style scoped>
.dragoverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
