var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height",staticStyle:{"min-height":"60vh"}},[(_vm.hasErrors)?_c('v-row',{staticClass:"d-flex justify-end align-center mb-2 mx-2"},[_c('p',{staticClass:"mb-0 mr-4"},[_vm._v(" "+_vm._s(_vm.$t("pages.locations.import.stepThree.showAllData"))+" ")]),_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('i18n',{staticClass:"mb-0 ml-3",attrs:{"path":"pages.locations.import.stepThree.onlyShow","tag":"p"}},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("pages.locations.import.stepThree.invalid")))])])]},proxy:true}],null,false,4030556307),model:{value:(_vm.onlyErroredRows),callback:function ($$v) {_vm.onlyErroredRows=$$v},expression:"onlyErroredRows"}})],1):_vm._e(),_c('v-data-table',{staticClass:"table-container",class:_vm.hasErrors ? 'compressed' : '',attrs:{"headers":_vm.tableHeaders,"height":"calc(100% - 59px)","items":_vm.filteredLocations,"item-class":_vm.getLocationRowClass,"item-key":"index","show-select":"","dense":"","disable-sort":"","fixed-header":"","options":_vm.tableOptions,"footer-props":_vm.tableFooterProps},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:`header.row.${header.mapping}`,fn:function(){return [(_vm.getMapping(header.mapping).type === 'field')?_c('v-autocomplete',{key:header.mapping,staticClass:"my-2",class:_vm.getMappingField(header.mapping) === null ? 'unknown' : '',attrs:{"items":_vm.getMappingField(header.mapping) !== null
            ? _vm.fieldOptionsWithSkip()
            : _vm.fieldOptionsWithUnknown(header.name),"item-disabled":_vm.getFieldOptionDisabled,"value":_vm.getMappingField(header.mapping),"item-text":"name","item-value":"id","dense":"","solo":"","hide-details":""},on:{"change":(value) => _vm.handleHeaderMappingChanged(header.mapping, value)},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name.replace( " " + _vm.$t("pages.locations.import.stepThree.unknownSuffixPart"), "" ))+" "),(item.unknown)?_c('span',{staticClass:"yellow--text accent-3 ml-1"},[_vm._v(" "+_vm._s(_vm.$t("pages.locations.import.stepThree.unknownSuffixPart"))+" ")]):_vm._e()])],1)]}}],null,true)}):_vm._e()]},proxy:true}}),_vm._l((_vm.headers),function(header){return {key:`item.row.${header.mapping}`,fn:function({ item }){return [_c('div',{key:header.mapping,staticClass:"cell-container",class:item.columnErrorMessages && item.columnErrorMessages[header.mapping]
            ? 'red'
            : ''},[(
            _vm.editingRows.includes(item) &&
            _vm.selectFields.includes(_vm.getMappingField(header.mapping))
          )?_c('v-autocomplete',{attrs:{"items":_vm.getSelectItemsByFieldName(_vm.getMappingField(header.mapping)),"value":item.row[header.mapping],"item-text":"name","item-value":"name","solo":"","dense":"","hide-details":"","clearable":""},on:{"change":(value) => _vm.updateLocationField(item, header.mapping, value)}}):(_vm.editingRows.includes(item))?_c('v-text-field',{staticClass:"my-2",attrs:{"value":item.row[header.mapping],"solo":"","dense":"","hide-details":"","clearable":""},on:{"input":(value) => _vm.updateLocationField(item, header.mapping, value),"keydown":(event) => _vm.saveEditingRow(event, item)}}):_c('span',[_vm._v(_vm._s(_vm.getCellValue(item.row, header.mapping)))]),(
            item.columnErrorMessages &&
            item.columnErrorMessages[header.mapping] &&
            !_vm.editingRows.includes(item)
          )?_c('div',{staticClass:"flex-grow-1"}):_vm._e(),(
            item.columnErrorMessages &&
            item.columnErrorMessages[header.mapping] &&
            !_vm.editingRows.includes(item)
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('div',[_c('h6',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("pages.locations.import.stepThree.formatInvalid"))+" ")]),_c('p',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.getCellFormatHelpText( item.columnErrorMessages[header.mapping] ))+" ")]),(
                _vm.getCellValueExamples(
                  item.columnErrorMessages[header.mapping],
                  _vm.getMappingField(header.mapping)
                )
              )?_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t("pages.locations.import.stepThree.eg")))]),_c('ul',{staticClass:"help-text-sublist"},_vm._l((_vm.getCellValueExamples(
                    item.columnErrorMessages[header.mapping],
                    _vm.getMappingField(header.mapping)
                  )),function(example){return _c('li',{key:example},[_vm._v(" "+_vm._s(example)+" ")])}),0)]):_vm._e()])]):_vm._e()],1)]}}}),{key:"item.action",fn:function({ item }){return [_c('v-row',{staticClass:"actionrow",attrs:{"justify":"space-between"}},[_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.toggleLocationEditMode(item)}}},[_vm._v(_vm._s(_vm.editingRows.includes(item) ? "mdi-content-save" : "mdi-pencil"))]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.removeLocation(item)}}},[_vm._v("mdi-trash-can-outline")])],1)]}},{key:"footer.prepend",fn:function(){return [_c('v-row',{staticClass:"ml-0 mr-8 align-center",attrs:{"justify":"space-between"}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":_vm.removeLocationRange}},[_vm._v(" "+_vm._s(_vm.$t("pages.locations.import.stepThree.deleteSelected"))+" ")]),_c('p',{staticClass:"mb-0",class:_vm.hasErrors && _vm.onlyErroredRows ? 'red--text' : ''},[_vm._v(" "+_vm._s(_vm.$t("pages.locations.import.stepThree.locationsFields", { locationAmount: _vm.filteredLocations.length, fieldAmount: _vm.selectedFieldCount, }))+" ")])],1)]},proxy:true},{key:"footer.page-text",fn:function(pageTextProps){return [_vm._v(" "+_vm._s(_vm.$t("common.dataTable.visibleRows", pageTextProps))+" ")]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":_vm.updateOnId,"label":_vm.$t('pages.locations.import.stepThree.updateOnId'),"hide-details":""},on:{"change":(value) => _vm.$emit('updateOnIdChanged', value)}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }