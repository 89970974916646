<template>
  <v-dialog
    style="z-index: 20001"
    v-model="show"
    scrollable
    max-width="80vw"
    hide-overlay
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mb-2"
        height="3rem"
        text
        color="primary"
        @click="$emit('clear')"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-plus</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.portfolios.form.create") }}
          </p>
        </div>
      </v-btn>
    </template>
    <v-card height="70vh" class="base-background-color">
      <v-card-title>{{
        portfolio != null && portfolio.id != null
          ? "#" + portfolio.referenceKey + " " + portfolio.name
          : $t("pages.portfolios.form.title")
      }}</v-card-title>
      <v-card-text class="d-flex flex-column">
        <v-tabs v-model="tab" class="flex-grow-0">
          <v-tab v-for="contentTab in tabs" :key="contentTab.id">
            {{ contentTab.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="fill-height fill-width px-4 flex-grow-1"
        >
          <tab-master-data :form="form" @updateForm="updateForm" />
          <tab-subcategory :form="form" @updateForm="updateForm" />
          <tab-default-values
            :form="form"
            :attributes="attributes"
            :currencies="currencies"
            :visible="value"
            @updateForm="updateForm"
            @attributeDefaultValueUpdated="handlePortfolioAttributeUpdate"
          />
        </v-tabs-items>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row justify="end">
          <v-alert dense v-if="form.errors.alert" type="error" class="mb-0">
            {{ form.errors.alert }}
          </v-alert>
          <v-btn color="blue darken-1" text @click="show = false">
            <v-icon class="black--text">mdi-close</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.close") }}
            </p>
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="savePortfolio()"
            :disabled="loading"
          >
            <v-icon class="black--text">mdi-content-save-outline</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.save") }}
            </p>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { removeTimezoneOffset, addTimezoneOffset } from "@/util/dateTime";

import TabMasterData from "./FormTabs/TabMasterData.vue";
import TabSubcategory from "./FormTabs/TabSubcategory.vue";
import TabDefaultValues from "./FormTabs/TabDefaultValues.vue";

export default {
  components: {
    TabMasterData,
    TabSubcategory,
    TabDefaultValues,
  },
  props: {
    value: Boolean,
    portfolio: Object,
    dialog: Boolean,
    attributes: Array,
    currencies: Array,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    mappedAttributes() {
      if (!this.attributes) return [];

      const mappings = [];

      this.attributes.forEach((attribute) => {
        mappings[attribute.id] = attribute;
      });

      return mappings;
    },
  },
  data() {
    return {
      loading: false,
      form: this.$inertia.form({
        id: "",
        name: "",
        description: "",
        subcategories: [],
        preselectedSubcategoryIndex: null,
        portfolioAttributes: [],
      }),
      tab: 0,
      tabs: [
        {
          id: 1,
          name: "Master data",
        },
        {
          id: 2,
          name: "Subcategory",
        },
        {
          id: 3,
          name: "Default values",
        },
      ],
    };
  },
  watch: {
    portfolio: function (portfolio) {
      this.setForm(portfolio);
    },
    show(value) {
      if (!value) {
        this.tab = 0;
      }
    },
  },
  methods: {
    setForm(portfolio) {
      if (portfolio != null) {
        this.form.id = portfolio.id;
        this.form.name = portfolio.name;
        this.form.description = portfolio.description;
        this.form.subcategories = portfolio.subcategories.map((x) => ({
          id: x.id,
          name: x.name,
        }));
        this.form.preselectedSubcategoryIndex =
          portfolio.preselectedSubcategoryId
            ? portfolio.subcategories.findIndex(
                (x) => x.id === portfolio.preselectedSubcategoryId
              )
            : null;
        this.form.portfolioAttributes = portfolio.portfolioAttributes.map(
          (x) => ({
            id: x.id,
            attributeId: x.attributeId,
            stringValue: x.stringValue,
            dateTimeValue: x.dateTimeValue
              ? addTimezoneOffset(new Date(x.dateTimeValue + "Z"))
              : null,
            decimalValue: x.decimalValue,
            boolValue: x.boolValue,
            attributeSelectOptionId: x.attributeSelectOptionId,
          })
        );
      } else {
        this.form.id = "";
        this.form.name = "";
        this.form.description = "";
        this.form.subcategories = [];
        this.form.preselectedSubcategoryIndex = null;
        this.form.portfolioAttributes = [];
      }
    },
    savePortfolio() {
      if (this.loading) return;
      this.loading = true;

      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
          subcategories: data.subcategories.filter(
            (x) => !this.textIsEmpty(x.name)
          ),
        };

        return transformedData;
      });
      if (this.form.id) {
        preparedForm.put(this.route("portfolios.update", this.portfolio.id), {
          preserveScroll: true,
          onSuccess: () => {
            this.show = false;
            this.$emit("clear");
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      } else {
        preparedForm.post(this.route("portfolios.store"), {
          preserveScroll: true,
          onSuccess: () => {
            this.show = false;
            this.setForm(null);
            this.$emit("clear");
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      }
    },
    textIsEmpty(text) {
      return !text || (text + "").trim() === "";
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
    handlePortfolioAttributeUpdate(portfolioAttribute) {
      const attribute = this.mappedAttributes[portfolioAttribute.attributeId];

      const existingIndex = this.form.portfolioAttributes.findIndex(
        (x) => x.attributeId === portfolioAttribute.attributeId
      );

      if (!attribute) return;

      const {
        boolValue,
        decimalValue,
        stringValue,
        dateTimeValue,
        attributeSelectOptionId,
      } = portfolioAttribute;

      let checkValue = null;

      switch (attribute.format) {
        case "Boolean":
          checkValue = boolValue;
          break;
        case "Currency":
          checkValue = decimalValue || stringValue?.trim();
          break;
        case "DateTime":
        case "Date":
          checkValue = dateTimeValue;
          break;
        case "Number":
        case "Percentage":
          checkValue = decimalValue;
          break;
        case "Select":
          checkValue = attributeSelectOptionId;
          break;
        case "Text":
          checkValue = stringValue?.trim();
          break;
        case "TextArea":
          checkValue = stringValue?.trim();
          break;
      }

      portfolioAttribute = { ...portfolioAttribute };

      if (isNaN(portfolioAttribute.decimalValue))
        portfolioAttribute.decimalValue = null;

      if (portfolioAttribute.dateTimeValue)
        portfolioAttribute.dateTimeValue = removeTimezoneOffset(
          portfolioAttribute.dateTimeValue
        );

      const portfolioAttributes = [...this.form.portfolioAttributes];

      if (checkValue && existingIndex !== -1) {
        portfolioAttributes.splice(existingIndex, 1, portfolioAttribute);
      } else if (!checkValue && existingIndex !== -1) {
        portfolioAttributes.splice(existingIndex, 1);
      } else if (checkValue && existingIndex === -1) {
        portfolioAttributes.push(portfolioAttribute);
      }

      this.form.portfolioAttributes = portfolioAttributes;
    },
  },
};
</script>
<style scoped>
.radio-group-full-width >>> .v-input__control {
  width: 100% !important;
}
.solo-item-list-area {
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 12px;
  background: #ffffff;
  background-origin: padding-box;
  background-clip: border-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.selected {
  background: #004d40;
  color: white;
}
</style>
