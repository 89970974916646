<template>
  <v-tab-item :value="tabValue">
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <asset-attribute-link-input
              :attribute="nameAttribute"
              :value="nameData"
              @input="updateData(nameAttribute.id, $event)"
              @string_changed="handleNameChanged($event)"
            />
            <asset-attribute-link-input
              :attribute="manufacturerAttribute"
              :value="manufacturerData"
              @input="updateData(manufacturerAttribute.id, $event)"
              @string_changed="handleManufacturerChanged($event)"
            />
            <asset-attribute-link-input
              :attribute="purchasePriceAttribute"
              :value="purchasePriceData"
              :currencies="currencies"
              @input="
                updateData(fixedAttributes.masterDataPurchasePriceId, $event)
              "
              :secondValue="purchasePriceSecondValueData"
              @input_secondValue="
                updateSecondValueData(
                  fixedAttributes.masterDataPurchasePriceId,
                  arguments[0]
                )
              "
              @currency_changed="
                updateCurrencyValue(
                  purchasePriceAttribute,
                  $event,
                  purchasePriceSecondValueData
                )
              "
              @currencyCode_changed="
                updateCurrencyCodeValue(
                  purchasePriceAttribute,
                  purchasePriceData,
                  $event
                )
              "
            />
            <asset-attribute-link-input
              :attribute="purchaseDateAttribute"
              :value="purchaseDateData"
              @input="
                updateData(fixedAttributes.masterDataPurchaseDateId, $event)
              "
              @dateTime_changed="updateDateValue(purchaseDateAttribute, $event)"
            />
            <asset-attribute-link-input
              :attribute="warrantyEndDateAttribute"
              :value="warrantyEndDateData"
              @input="
                updateData(fixedAttributes.masterDataWarrantyEndDateId, $event)
              "
              @dateTime_changed="
                updateDateValue(warrantyEndDateAttribute, $event)
              "
            />
            <v-row dense>
              <v-col cols="4" align-self="center">
                {{ $t("pages.assets.form.fixedLabels.purchasedAs") }}:
              </v-col>
              <v-col align-self="center">
                <v-select
                  v-model="selectedPurchasedAs"
                  :items="purchasedAsOptions"
                  item-text="text"
                  item-value="id"
                  solo
                  dense
                  hide-details
                >
                </v-select>
              </v-col>
            </v-row>
            <asset-attribute-link-input
              v-if="selectedPurchasedAs === 2"
              :attribute="productionDateAttribute"
              :value="productionDateData"
              @input="
                updateData(fixedAttributes.masterDataProductionDateId, $event)
              "
              @dateTime_changed="
                updateDateValue(productionDateAttribute, $event)
              "
            />
            <asset-attribute-link-input
              :attribute="weightAttribute"
              :value="weightData"
              integerInput
              :numberMinValue="1"
              @input="updateData(weightAttribute.id, $event)"
              @decimal_changed="updateDecimalValue(weightAttribute, $event)"
            />
            <asset-attribute-link-input
              :attribute="quantityAttribute"
              :value="quantityData"
              integerInput
              :numberMinValue="1"
              @input="updateData(quantityAttribute.id, $event)"
              @decimal_changed="updateDecimalValue(quantityAttribute, $event)"
            />
            <asset-attribute-link-input
              :attribute="modelAttribute"
              :value="modelData"
              @input="updateData(modelAttribute.id, $event)"
              @string_changed="handleModelChanged($event)"
            />
            <asset-attribute-link-input
              :attribute="serialNumberAttribute"
              :value="serialNumberData"
              @input="updateData(serialNumberAttribute.id, $event)"
              @string_changed="handleSerialNumberChanged($event)"
            />

            <v-row dense>
              <v-col cols="4" align-self="center">
                {{ $t("pages.assets.form.fixedLabels.portfolio") }}:
              </v-col>
              <v-col cols="8" align-self="center">
                <v-autocomplete
                  :items="portfolios"
                  :value="portfolioId"
                  hide-details
                  clearable
                  item-text="name"
                  item-value="id"
                  dense
                  solo
                  @change="handlePortfolioChanged($event)"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense v-if="portfolioSubcategories.length > 0">
              <v-col cols="4" align-self="center">
                {{ $t("pages.assets.form.fixedLabels.portfolioSubcategory") }}:
              </v-col>
              <v-col cols="8" align-self="center">
                <v-autocomplete
                  :items="portfolioSubcategories"
                  :value="portfolioSubcategoryId"
                  hide-details
                  clearable
                  item-text="name"
                  item-value="id"
                  dense
                  solo
                  @change="handlePortfolioSubcategoryChanged"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="asset != null && asset.id != null" cols="5" offset="1">
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.verarcaId") }}:
              </v-col>
              <v-col cols="7">
                {{ asset.referenceKey }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.createdBy") }}:
              </v-col>
              <v-col cols="7">
                {{ asset.createdBy ? asset.createdBy.name : "" }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.created") }}:
              </v-col>
              <v-col cols="7">
                {{ formattedCreatedDate }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.updatedBy") }}:
              </v-col>
              <v-col cols="7">
                {{ asset.updatedBy ? asset.updatedBy.name : "" }}
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.updated") }}:
              </v-col>
              <v-col cols="7">
                {{ formattedUpdatedDate }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>
<script>
import { fixedAttributes } from "../../../util/fixedAssetData";
import { toLocaleUTCDateStringFiltered } from "@/util/dateTime";
import AssetAttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";

export default {
  components: { AssetAttributeLinkInput },
  props: {
    show: Boolean,
    assetAttributeLinkData: Object,
    attributes: Array,
    currencies: Array,
    portfolios: Array,
    portfolioId: String,
    portfolioSubcategoryId: String,
    asset: Object,
    tabValue: String,
  },
  data() {
    const purchasedAsOptions = [
      { id: 1, text: "New" },
      { id: 2, text: "Used" },
    ];
    return {
      fixedAttributes,
      purchasedAsOptions,
    };
  },
  mounted() {
    this.setDropdownPreselections();
  },
  methods: {
    updateData(attributeId, value) {
      this.$emit("asset_attribute_data_changed", attributeId, value);
    },
    updateSecondValueData(attributeId, secondValue) {
      this.$emit(
        "asset_attribute_data_second_value_changed",
        attributeId,
        secondValue
      );
    },
    updateSelectValue(attribute, selectedOption) {
      this.$emit("select_changed", selectedOption, attribute);
    },
    updateDateValue(attribute, value) {
      this.$emit("dateTime_changed", value, attribute);
    },
    updateCurrencyValue(attribute, price, currency) {
      this.$emit("currency_changed", price, currency, attribute);
    },
    updateCurrencyCodeValue(attribute, price, currency) {
      this.$emit("currencyCode_changed", price, currency, attribute);
    },
    updateDecimalValue(attribute, decimalValue) {
      this.$emit("decimal_changed", decimalValue, attribute);
    },
    handleNameChanged(value) {
      this.$emit("string_changed", value, this.nameAttribute);
    },
    handleManufacturerChanged(value) {
      this.$emit("string_changed", value, this.manufacturerAttribute);
    },
    handleModelChanged(value) {
      this.$emit("string_changed", value, this.modelAttribute);
    },
    handleSerialNumberChanged(value) {
      this.$emit("string_changed", value, this.serialNumberAttribute);
    },
    handlePortfolioChanged(value) {
      this.$emit("portfolio_changed", value);
    },
    handleDateTimeChanged(attribute, value) {
      this.$emit("dateTime_changed", value, attribute);
    },
    handlePortfolioSubcategoryChanged(value) {
      this.$emit("portfolioSubcategory_changed", value);
    },
    setDropdownPreselections() {
      if (this.portfolioId === null && this.portfolios.length > 0) {
        this.$emit("portfolio_changed", this.portfolios[0].id);
      }

      if (
        this.portfolioSubcategoryId === null &&
        this.selectedPortfolio?.preselectedSubcategoryId
      ) {
        this.$emit(
          "portfolioSubcategory_changed",
          this.selectedPortfolio.preselectedSubcategoryId
        );
      }
    },
  },
  computed: {
    formattedCreatedDate() {
      if (!this.asset?.createdAt) return "";
      return toLocaleUTCDateStringFiltered(this.asset.createdAt, false);
    },
    formattedUpdatedDate() {
      if (!this.asset?.updatedAt) return "";
      return toLocaleUTCDateStringFiltered(this.asset.updatedAt, false);
    },
    nameData() {
      return this.assetAttributeLinkData[fixedAttributes.masterDataNameId];
    },
    manufacturerData() {
      return this.assetAttributeLinkData[
        fixedAttributes.masterDataManufacturerId
      ];
    },
    weightData() {
      return this.assetAttributeLinkData[fixedAttributes.masterDataWeightId];
    },
    quantityData() {
      return this.assetAttributeLinkData[fixedAttributes.masterDataQuantityId] >
        0
        ? this.assetAttributeLinkData[fixedAttributes.masterDataQuantityId]
        : 1;
    },
    modelData() {
      return this.assetAttributeLinkData[fixedAttributes.masterDataModelId];
    },
    serialNumberData() {
      return this.assetAttributeLinkData[
        fixedAttributes.masterDataSerialNumberId
      ];
    },
    nameAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.masterDataNameId
      );
    },
    manufacturerAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.masterDataManufacturerId
      );
    },
    weightAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.masterDataWeightId
      );
    },
    quantityAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.masterDataQuantityId
      );
    },
    modelAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.masterDataModelId
      );
    },
    serialNumberAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.masterDataSerialNumberId
      );
    },
    purchaseDateData() {
      return this.assetAttributeLinkData[
        fixedAttributes.masterDataPurchaseDateId
      ];
    },
    warrantyEndDateData() {
      return this.assetAttributeLinkData[
        fixedAttributes.masterDataWarrantyEndDateId
      ];
    },
    productionDateData() {
      return this.assetAttributeLinkData[
        fixedAttributes.masterDataProductionDateId
      ];
    },
    purchaseDateAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.masterDataPurchaseDateId
      );
    },
    warrantyEndDateAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.masterDataWarrantyEndDateId
      );
    },
    productionDateAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.masterDataProductionDateId
      );
    },
    purchasePriceData() {
      return this.assetAttributeLinkData[
        fixedAttributes.masterDataPurchasePriceId
      ];
    },
    purchasePriceSecondValueData() {
      return this.assetAttributeLinkData[
        fixedAttributes.masterDataPurchasePriceId + "-secondValue"
      ];
    },
    purchasePriceAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.masterDataPurchasePriceId
      );
    },
    selectedPortfolio() {
      if (!this.portfolioId) return null;

      return this.portfolios.find((x) => x.id === this.portfolioId);
    },
    portfolioSubcategories() {
      if (!this.selectedPortfolio) return [];

      return this.selectedPortfolio.subcategories;
    },
    selectedPurchasedAs: {
      get() {
        const productionDate =
          this.assetAttributeLinkData[
            fixedAttributes.masterDataProductionDateId
          ];

        if (productionDate && isNaN(new Date(productionDate))) {
          this.updateDateValue(this.productionDateAttribute, null);
          this.updateData(fixedAttributes.masterDataProductionDateId, null);
          return 1;
        }

        if (productionDate) {
          return 2;
        } else {
          return 1;
        }
      },
      set(value) {
        if (value === 1) {
          this.updateDateValue(this.productionDateAttribute, null);
          this.updateData(fixedAttributes.masterDataProductionDateId, null);
        } else if (value === 2) {
          let productionDate = new Date();
          this.updateData(
            fixedAttributes.masterDataProductionDateId,
            productionDate
          );
        }
      },
    },
  },
  watch: {
    show(value) {
      // Mounted lifecycle hook runs on first form reveal, watch runs on all subsequent form reveals
      if (value) this.setDropdownPreselections();
    },
    portfolioId(value) {
      const portfolio = this.portfolios.find((x) => x.id === value);

      if (
        portfolio?.preselectedSubcategoryId &&
        this.portfolioSubcategoryId === null
      ) {
        this.$emit(
          "portfolioSubcategory_changed",
          portfolio.preselectedSubcategoryId
        );
      }
    },
  },
};
</script>
