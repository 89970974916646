<template>
  <v-row class="actionrow" justify="center">
    <v-tooltip content-class="custom-tooltip" left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on"
          >mdi-eye-outline</v-icon
        >
      </template>
      <v-col class="px-6 black--text">
        <v-row class="mb-8" justify="space-between">
          <span class="mr-2">{{
            $t("components.attributes.actionMenu.info.id")
          }}</span>
          <span class="font-weight-black">{{ row.referenceKey }}</span>
        </v-row>
        <v-row justify="space-between">
          <span>{{ $t("components.attributes.actionMenu.info.created") }}</span>
          <span class="font-weight-black">{{ formatDate(row.createdAt) }}</span>
        </v-row>
        <v-row justify="space-between">
          <span>{{
            $t("components.attributes.actionMenu.info.createdBy")
          }}</span>
          <span class="font-weight-black">{{ row.createdBy?.name }}</span>
        </v-row>
        <v-row justify="space-between">
          <span>{{
            $t("components.attributes.actionMenu.info.lastUpdated")
          }}</span>
          <span class="font-weight-black">{{ formatDate(row.updatedAt) }}</span>
        </v-row>
        <v-row justify="space-between">
          <span>{{
            $t("components.attributes.actionMenu.info.lastUpdatedBy")
          }}</span>
          <span class="font-weight-black">{{ row.updatedBy?.name }}</span>
        </v-row>
        <v-row class="mt-8" justify="space-between">
          <span class="mr-16">{{
            $t("components.attributes.actionMenu.info.attributesWithData")
          }}</span>
          <span class="font-weight-black">{{
            row[attributeLinkProperty].length + " / " + attributes.length
          }}</span>
        </v-row>
      </v-col>
    </v-tooltip>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          class="mr-2"
          v-bind="attrs"
          v-on="on"
          @click="copyRow(row)"
          >mdi-content-copy</v-icon
        >
      </template>
      <span>{{ $t("components.attributes.actionMenu.actions.copy") }}</span>
    </v-tooltip>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          class="mr-2"
          v-bind="attrs"
          v-on="on"
          @click="editRow(row)"
          >mdi-pencil</v-icon
        >
      </template>
      <span>{{ $t("components.attributes.actionMenu.actions.edit") }}</span>
    </v-tooltip>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          class="mr-2"
          v-bind="attrs"
          v-on="on"
          @click="destroyRow(row.id)"
          >mdi-trash-can-outline</v-icon
        >
      </template>
      <span>{{ $t("components.attributes.actionMenu.actions.delete") }}</span>
    </v-tooltip>
  </v-row>
</template>
<script>
export default {
  props: {
    row: Object,
    attributes: Array,
    entryType: String,
  },
  emits: ["editRowClicked", "copyRowClicked", "destroyRowClicked"],
  computed: {
    attributeLinkProperty() {
      switch (this.entryType) {
        case "asset":
          return "assetAttributeLinks";
        case "solution":
          return "solutionAttributeLinks";
        case "location":
          return "locationAttributeLinks";
        default:
          return null;
      }
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "N/A";

      return new Date(date).toLocaleDateString();
    },
    editRow(item) {
      this.$emit("editRowClicked", item);
    },
    copyRow(item) {
      this.$emit("copyRowClicked", item);
    },
    destroyRow(id) {
      this.$emit("destroyRowClicked", id);
    },
  },
};
</script>
