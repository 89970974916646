<template>
  <page-modal v-model="show">
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mb-2"
        height="3rem"
        text
        color="primary"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-tray-arrow-down</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.assets.import.import") }}
          </p>
        </div>
      </v-btn>
    </template>
    <template #title>
      <i18n path="pages.assets.import.importStep" tag="span">
        <span
          v-if="selectedImportStep === 3 && tableHasErrors"
          style="margin-left: 4px"
          class="red--text"
        >
          {{ $t("pages.assets.import.invalidData") }}
        </span>
        <span
          v-else
          style="margin-left: 4px"
          :class="
            currentStep && currentStep.color ? currentStep.color + '--text' : ''
          "
        >
          {{
            currentStep
              ? " " + currentStep.title
              : $t("pages.assets.import.stepTitles.unknown")
          }}
        </span>
      </i18n>
    </template>
    <template #body>
      <asset-import-step-one
        v-if="selectedImportStep === 1"
        :selectedImportFile="selectedImportFile"
        @errorChanged="handleStepOneError"
        @invalidFormatChanged="(value) => (invalidImportFileFormat = value)"
        @fileChanged="handleImportFileChanged"
      />
      <asset-import-step-two
        v-if="selectedImportStep === 2"
        :selectedImportFile="selectedImportFile"
        :portfolios="portfolios"
        :selectedPortfolioId="form.portfolioId"
        @portfolioIdChanged="handlePortfolioIdChanged"
        :newPortfolioName="form.portfolioName"
        @newPortfolioNameChanged="handleNewPortfolioNameChanged"
      />
      <asset-import-step-three
        v-if="selectedImportStep === 3"
        :attributes="attributes"
        :headerToAttributeMappings="headerToAttributeMappings"
        :assetRows="form.assetRows"
        :portfolioSubcategories="portfolioSubcategories"
        :isEditing="tableIsEditing"
        :hasErrors="tableHasErrors"
        :updateOnId="form.updateOnId"
        @assetAttributeLinkUpdated="handleAssetAttributeLinkUpdated"
        @assetRemoved="handleAssetRemoved"
        @assetsRemoved="handleAssetsRemoved"
        @headerMappingChanged="handleHeaderMappingChanged"
        @isEditingUpdated="(isEditing) => (tableIsEditing = isEditing)"
        @updateOnIdChanged="(value) => (form.updateOnId = value)"
      />
      <asset-import-step-four
        v-if="selectedImportStep === 4"
        :importCount="importSuccess.importCount"
        :updateCount="importSuccess.updateCount"
        :portfolioName="importSuccess.portfolioName"
        :saveImportedFile="saveImportedFile"
        :importedFileName="importedFileName"
        @saveImportedFileChanged="(value) => (saveImportedFile = value)"
        @importedFileNameChanged="(value) => (importedFileName = value)"
      />
    </template>
    <template #actions>
      <v-alert
        dense
        v-if="errorMessage && errorMessageStep === selectedImportStep"
        type="error"
        class="mb-0"
      >
        {{ errorMessage }}
      </v-alert>
      <v-btn
        color="blue darken-1"
        text
        @click="hideModal"
        :loading="closeButtonLoading"
      >
        <v-icon class="black--text">mdi-close</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ $t("common.actions.close") }}
        </p>
      </v-btn>
      <v-btn
        v-if="selectedImportStep > 1 && selectedImportStep < 4"
        color="blue darken-1"
        text
        @click="moveToPreviousStep"
      >
        <v-icon class="black--text">mdi-chevron-left</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ $t("pages.assets.import.back") }}
        </p>
      </v-btn>
      <v-btn
        v-if="selectedImportStep < 4"
        color="blue darken-1"
        text
        @click="moveToNextStep()"
        :disabled="isNextButtonDisabled"
        :loading="nextButtonLoading"
      >
        <v-icon class="black--text">mdi-chevron-right</v-icon>
        <p
          :class="isNextButtonDisabled ? '' : 'teal--text text--darken-4'"
          class="mb-0"
        >
          {{ $t("pages.assets.import.next") }}
        </p>
      </v-btn>
    </template>
  </page-modal>
</template>
<script>
import { serialize } from "object-to-formdata";

import PageModal from "../PageModal.vue";

import AssetImportStepOne from "./ImportSteps/AssetImportStepOne.vue";
import AssetImportStepTwo from "./ImportSteps/AssetImportStepTwo.vue";
import AssetImportStepThree from "./ImportSteps/AssetImportStepThree.vue";
import AssetImportStepFour from "./ImportSteps/AssetImportStepFour.vue";

export default {
  components: {
    PageModal,
    AssetImportStepOne,
    AssetImportStepTwo,
    AssetImportStepThree,
    AssetImportStepFour,
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentStep() {
      return this.importSteps.find((x) => x.id === this.selectedImportStep);
    },
    isNextButtonDisabled() {
      switch (this.selectedImportStep) {
        case 1:
          return this.invalidImportFileFormat || !this.selectedImportFile;
        case 2:
          return !this.form.portfolioId && !this.form.portfolioName;
        case 3:
          return (
            this.tableIsEditing ||
            Object.values(this.headerToAttributeMappings).filter(
              (x) => x.type === "attribute" && x.attribute !== null
            ).length <= 0 ||
            !this.form.assetRows.length
          );
        default:
          return true;
      }
    },
  },
  data() {
    return {
      selectedImportStep: 1,
      importSteps: [
        { id: 1, title: this.$t("pages.assets.import.stepTitles.method") },
        { id: 2, title: this.$t("pages.assets.import.stepTitles.portfolio") },
        { id: 3, title: this.$t("pages.assets.import.stepTitles.validate") },
        {
          id: 4,
          title: this.$t("pages.assets.import.stepTitles.success"),
          color: "light-green",
        },
      ],
      selectedImportFile: null,
      errorMessage: null,
      errorMessageStep: null,
      invalidImportFileFormat: false,
      portfolios: [],
      portfolioSubcategories: [],
      attributes: [],
      headerToAttributeMappings: {},
      form: {
        portfolioId: null,
        portfolioName: null,
        headerToAttributeIdMappings: {},
        headerToFieldNameMappings: {},
        assetRows: [],
        updateOnId: true,
      },
      tableIsEditing: false,
      tableHasErrors: false,
      nextButtonLoading: false,
      closeButtonLoading: false,
      importSuccess: {
        importCount: 0,
        updateCount: 0,
        portfolioName: null,
      },
      saveImportedFile: true,
      importedFileName: null,
    };
  },
  methods: {
    moveToPreviousStep() {
      const newStep = (this.currentStep?.id ?? 2) - 1;

      if (newStep < 1) {
        this.selectedImportStep = 1;
        return;
      }

      this.selectedImportStep = newStep;
    },
    moveToNextStep(step3Validated) {
      if (this.selectedImportStep === 3 && !step3Validated) {
        this.submitImportData();
        return;
      }

      this.selectedImportStep = (this.currentStep?.id ?? 0) + 1;

      this.runPageChangeSideEffects();
    },
    runPageChangeSideEffects() {
      if (this.selectedImportStep === 3) {
        this.fetchParsedAssetsFromImportFile();
      }
    },
    fetchPortfolios() {
      fetch(this.route("api.portfolios.index"), {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          this.portfolios = data;

          if (this.portfolios.length > 0) {
            const firstPortfolio = this.portfolios[0];

            this.form.portfolioId = firstPortfolio.id;
          } else {
            this.form.portfolioId = null;
          }
        })
        .catch(() => {
          this.portfolios = [];
        });
    },
    fetchParsedAssetsFromImportFile() {
      if (!this.selectedImportFile || this.form.assetRows?.length > 0) return;

      const formData = new FormData();
      formData.append("assetImportSpreadsheet", this.selectedImportFile);
      formData.append("portfolioId", this.form.portfolioId);

      fetch(this.route("api.assets.parse"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.attributes = data.attributes;
          this.headerToAttributeMappings = data.headerToAttributeMappings;
          this.portfolioSubcategories = data.subcategories;

          const headers = Object.keys(this.headerToAttributeMappings);

          const encounteredAttributes = [];
          const encounteredFields = [];

          headers.forEach((header) => {
            const mapping = this.headerToAttributeMappings[header];

            if (mapping.type === "attribute") {
              let attribute = mapping.attribute;

              if (encounteredAttributes.includes(attribute)) {
                this.headerToAttributeMappings[header] = {
                  id: null,
                  name: this.$t("pages.assets.import.skip"),
                };
                attribute = null;
              }

              this.form.headerToAttributeIdMappings[header] =
                attribute?.id ?? null;

              if (attribute) {
                encounteredAttributes.push(attribute);
              }
            } else if (mapping.type === "field") {
              let field = mapping.fieldName;

              if (encounteredFields.includes(field)) {
                this.headerToAttributeMappings[header] = {
                  type: "attribute",
                  attribute: {
                    id: null,
                    name: this.$t("pages.assets.import.skip"),
                  },
                };
                field = null;
              }

              this.form.headerToFieldNameMappings[header] = field ?? null;

              if (field) {
                encounteredFields.push(field);
              }
            }
          });

          this.form.assetRows = data.parsedAssets;
        });
    },
    submitImportData() {
      this.nextButtonLoading = true;

      const omittedMappings = [];

      const attributeMappings = { ...this.form.headerToAttributeIdMappings };

      for (let mapping of Object.keys(attributeMappings)) {
        if (!attributeMappings[mapping]) {
          delete attributeMappings[mapping];
          omittedMappings.push(mapping);
        }
      }

      const fieldMappings = { ...this.form.headerToFieldNameMappings };

      for (let mapping of Object.keys(fieldMappings)) {
        if (!fieldMappings[mapping]) {
          delete fieldMappings[mapping];
          omittedMappings.push(mapping);
        }
      }

      let assetRows = [...this.form.assetRows.map((x) => x.row)];

      for (let mapping of omittedMappings) {
        assetRows.forEach((row) => {
          delete row[mapping];
        });
      }

      const bodyContent = {
        ...this.form,
        assetRowsJson: JSON.stringify(assetRows),
        headerToAttributeIdMappingsJson: JSON.stringify(attributeMappings),
        headerToFieldNameMappingsJson: JSON.stringify(fieldMappings),
      };

      delete bodyContent.assetRows;
      delete bodyContent.headerToAttributeIdMappings;
      delete bodyContent.headerToFieldNameMappings;

      fetch(this.route("api.assets.import"), {
        method: "POST",
        body: serialize(bodyContent, {
          indices: true,
          dotsForObjectNotation: true,
        }),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then((data) => {
              this.handleSuccessfulImport(data);
            });

            return;
          }

          return res.json();
        })
        .then((data) => {
          if (!data || data.status !== "error") return;

          this.errorMessage = this.$t(
            `pages.assets.import.resultDescriptions.${data.description}`
          );
          this.errorMessageStep = 3;
          this.form.assetRows = data.assetRows;
          this.tableHasErrors = true;
        })
        .finally(() => {
          this.nextButtonLoading = false;
        });
    },
    async submitImportedFile() {
      this.closeButtonLoading = true;

      const blob = this.selectedImportFile.slice(
        0,
        this.selectedImportFile.size,
        this.selectedImportFile.type
      );
      const renamedFile = new File([blob], this.importedFileName, {
        type: this.selectedImportFile.type,
      });

      const formData = new FormData();
      formData.append("files", renamedFile);

      return fetch(this.route("api.files.store"), {
        method: "POST",
        body: formData,
      }).finally(() => {
        this.closeButtonLoading = false;
      });
    },
    resetModalState() {
      this.selectedImportStep = 1;
      this.selectedImportFile = null;
      this.errorMessage = null;
      this.attributes = [];
      this.headerToAttributeMappings = {};
      this.form = {
        portfolioId: this.portfolios.length > 0 ? this.portfolios[0].id : null,
        portfolioName: null,
        headerToAttributeIdMappings: {},
        headerToFieldNameMappings: {},
        assetRows: [],
      };
      this.tableIsEditing = false;
      this.tableHasErrors = false;
      this.nextButtonLoading = false;
    },
    handleStepOneError(error) {
      this.errorMessage = error;
      this.errorMessageStep = 1;
    },
    handleImportFileChanged(value) {
      this.selectedImportFile = value;

      this.form.assetRows = [];
      this.form.headerToAttributeIdMappings = {};
      this.form.headerToFieldNameMappings = {};
      this.headerToAttributeMappings = {};

      if (value) {
        this.importedFileName = value.name;
      }
    },
    handlePortfolioIdChanged(portfolioId) {
      this.form.portfolioId = portfolioId;
      this.form.portfolioName = null;
      this.form.assetRows = [];
    },
    handleNewPortfolioNameChanged(portfolioName) {
      this.form.portfolioName = portfolioName;
    },
    handleAssetRemoved(assetRow) {
      this.form.assetRows = this.form.assetRows.filter((x) => x !== assetRow);
    },
    handleAssetsRemoved(assetRows) {
      this.form.assetRows = this.form.assetRows.filter(
        (x) => !assetRows.includes(x)
      );
    },
    handleHeaderMappingChanged(header, attribute) {
      let mapping = this.headerToAttributeMappings[header];

      if (!mapping) {
        mapping = {
          type: "attribute",
          attribute: null,
        };
      }

      this.headerToAttributeMappings[header] = {
        ...mapping,
        attribute: attribute,
      };

      const attributeId = attribute?.id;

      this.form.headerToAttributeIdMappings[header] = attributeId;
    },
    handleAssetAttributeLinkUpdated(assetRow, header, value) {
      const index = this.form.assetRows.indexOf(assetRow);

      if (index === -1) return;

      this.form.assetRows[index].row[header] = value;

      if (this.form.assetRows[index].columnErrorMessages) {
        delete this.form.assetRows[index].columnErrorMessages[header];
      }
    },
    handleSuccessfulImport(data) {
      this.tableHasErrors = false;
      this.errorMessage = false;

      this.importSuccess.importCount = data.importedAssetCount;
      this.importSuccess.updateCount = data.updatedAssetCount;
      this.importSuccess.portfolioName = data.importedToPortfolioName;

      this.moveToNextStep(true);
    },
    async hideModal() {
      if (this.selectedImportStep !== 4) {
        if (!window.confirm(this.$t("pages.assets.import.confirmClose")))
          return;
      }

      if (this.selectedImportStep === 4 && this.saveImportedFile) {
        await this.submitImportedFile();
      }

      this.show = false;
      this.resetModalState();
    },
  },
  mounted() {
    this.fetchPortfolios();
  },
};
</script>
<style scoped>
.import-card {
  position: relative;
}

.import-card .step-label {
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bolder;
}
</style>
