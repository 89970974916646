const fixedClasses = {
  masterDataId: "b83bee53-130b-41df-b3a5-bd245c0b6b01",
  co2Id: "f0275401-57d4-472b-a893-b4c39d8609a9",
  fixedAssetsId: "352bd32c-7c70-41dc-8a3e-aeb516bd0967",
  assessmentId: "72d646dd-b46d-4cff-a9dc-ee364b5b7482",
};

const fixedAttributes = {
  masterDataNameId: "649f345f-bb47-47b5-af15-6ef294e72afd",
  masterDataPurchasePriceId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
  masterDataPurchaseDateId: "e2af7a7d-9f2e-4797-8f0a-3630fd4e8ab0",
  masterDataPortfolioId: "88f727c4-f63a-4d3c-b94a-c774f8d2d28c",
  masterDataPortfolioSubcategoryId: "bce46452-7ca2-4b17-b4f0-df8a1fbe71c4",
  masterDataWarrantyEndDateId: "b05907c9-6989-4fcf-9487-fbffbdc55ad9",
  masterDataModelId: "fdb4c45b-6f92-4124-a66b-f010cf050884",
  masterDataSerialNumberId: "d588bda2-ae9c-4062-8d4b-5c23f1c2e47b",
  masterDataManufacturerId: "a3191a90-d7ae-4aeb-8968-c4bf7544602f",
  masterDataWeightId: "21c04037-5381-462e-862b-a91a8c63e1e3",
  masterDataProductionDateId: "92afcab9-233a-455c-9c0e-414053348b51",
  masterDataQuantityId: "231745de-4cfd-4e7e-b554-3abd781d3864",
  co2MethodId: "da7288f9-50d4-4928-91cc-98790e76b79b",
  co2SectorId: "a2728615-2215-4d06-9326-3db17787268d",
  co2CategoryId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
  co2RegionId: "a6db55d4-f887-48ed-815c-785b419d8dba",
  co2NameId: "9b748631-0d19-4b09-a570-9f43d842b6fa",
  fixedAssetsMethodId: "92fdf736-fc52-45fc-b3a8-37fb63f592bc",
  fixedAssetsDepreciationPeriodId: "add187cf-077e-4167-8f09-9b5a48ad5cff",
  fixedAssetsScrapValueId: "0c078750-ce75-48f1-9c30-f5a814054f70",
  fixedAssetsRegionId: "29f197ae-8a10-4de2-95da-5a23cd794671",
  fixedAssetsFiscalYearId: "6a05fa62-f40b-4395-9deb-6fb1ef961973",
  fixedAssetsTimeOfCommissioningId: "2b99c978-2c8c-430a-aaa9-f9f9ebb553d9",
  fixedAssetsDateOfSaleId: "fd945093-1e00-4925-9eba-d7543de74a25",
  fixedAssetsSalePriceId: "bb95b050-eb10-47b1-9ace-bacfaaadea23",
  fixedAssetsStartOfDepreciationId: "5246b78b-3480-4092-842d-f0075d034cdd",
  assessmentPrice1Id: "a3cd53fc-fe41-48ed-b89a-48a5e51de6e2",
  assessmentPrice2Id: "fbd23286-ac20-43f1-b97e-fad371d99da1",
  assessmentPrice3Id: "27b4c4ec-c0ce-4f66-abf5-ca4287afa629",
  assessmentPrice4Id: "4363e00a-98cb-487f-aecd-df02abdb0c21",
};

const fixedFieldAttributes = {
  co2EmissionFactorId: "9717a873-cf73-425d-8b23-e6df952863aa",
  co2EmissionFactorCurrencyId: "14560bea-4d44-4b22-8a2a-67035e19a392",
  co2EmissionFactorStatusId: "bfffe340-b4af-4e32-a27e-2eeff7a0979d",
  co2EmissionFactorFromAiId: "c23e47be-1b23-4a59-8b31-d01e4759fe0d",
  co2SourceId: "7a858001-a633-4acf-9986-41b30b726411",
  co2UuidId: "97df513e-0671-48e6-998c-5c795b15e459",
  co2EmissionsId: "041a251a-2138-4e14-bdfd-876194f4e04f",
  co2FactorTypeId: "0362fcb6-9ec5-4d20-b5e0-25f8265359df",
  fixedAssetsBookValueId: "de985ba6-4909-4d89-bcc9-6ba715a91ebb",
  fixedAssetsGainLossId: "2a41d3d4-2f8a-49e0-b452-43286c2ed2bb",
  fixedAssetsYearlyDepreciationId: "16c0d406-9723-48d6-bbbf-541d8fdf6623",
  fixedAssetsDepreciationStartMonthId: "35b2b7a4-6e4e-4cdb-9f91-3f38247658c0",
  fixedAssetsCurrentYearDepreciationId: "106c9590-9048-489d-b11a-d5b9e193aaa8",
  fixedAssetsAccumulatedDepreciationId: "fb82c4c5-45f5-48c4-b37d-c5375d171fc8",
  fixedAssetsMonthlyDepreciationId: "d9569925-2540-4fac-abd8-495d98770c22",
  fixedAssetsDepreciationMonthId: "478ac308-9e0e-4eea-84c9-cbb3959f8e7b",
};

const fiscalYearOptionToStartMonth = {
  "d3e0bb86-1064-456c-b7f9-7111a21d26f9": 1,
  "fc0a5b42-8b93-4f9e-a014-c9a80751c4dd": 2,
  "29bebed8-0371-4d2b-b664-b5ea3657aad7": 3,
  "5007a4b0-da89-497c-ae4e-bfd227813304": 4,
  "748da80d-7820-41b8-8000-e3a27f95f0ca": 5,
  "147fb337-468d-48d1-84d5-182b71759a2c": 6,
  "292dae5a-1012-47c2-b8de-7769ac004547": 7,
  "8b0bba4d-4b3b-4d21-8da5-38ccaf41a72d": 8,
  "fc3d39d0-6a6b-4438-a6d6-37ab919a3583": 9,
  "590275f5-7afb-4a92-a273-d385b055100e": 10,
  "22c5a786-65f2-4531-95fe-714107ff35e8": 11,
  "8b9215be-41b7-41e2-a319-bb226f91af7f": 12,
};

const fiscalYearOptionToEndMonth = {
  "d3e0bb86-1064-456c-b7f9-7111a21d26f9": 12,
  "fc0a5b42-8b93-4f9e-a014-c9a80751c4dd": 11,
  "29bebed8-0371-4d2b-b664-b5ea3657aad7": 10,
  "5007a4b0-da89-497c-ae4e-bfd227813304": 9,
  "748da80d-7820-41b8-8000-e3a27f95f0ca": 8,
  "147fb337-468d-48d1-84d5-182b71759a2c": 7,
  "292dae5a-1012-47c2-b8de-7769ac004547": 6,
  "8b0bba4d-4b3b-4d21-8da5-38ccaf41a72d": 5,
  "fc3d39d0-6a6b-4438-a6d6-37ab919a3583": 4,
  "590275f5-7afb-4a92-a273-d385b055100e": 3,
  "22c5a786-65f2-4531-95fe-714107ff35e8": 2,
  "8b9215be-41b7-41e2-a319-bb226f91af7f": 1,
};

const co2MethodOptions = {
  spendBasedMethodId: "cb32f080-d3ce-41cc-8f89-467b88f2e297",
  averageDataMethodId: "ca4543d4-a19f-46fd-af5f-17a772ab4309",
};

const emissionCurrencies = ["EUR", "DKK", "USD"];

const emissionFactorStatus = {
  defining: "Defining",
  defined: "Defined",
  undefined: "Undefined",
  approved: "Approved",
};

const depreciationStartModes = {
  currentMonth: "78d5a3ff-aa41-44aa-8c05-b6eb6ae0c99b",
  nextMonth: "a98949b1-82c0-4c6b-8aa8-59f55ac9e6bb",
  currentDate: "650c2581-3bfc-469e-93de-a549be0d4fcc",
};

const notBulkEditableAttributes = [
  ...Object.values(fixedFieldAttributes),
  fixedAttributes.co2SectorId,
  fixedAttributes.co2CategoryId,
  fixedAttributes.co2RegionId,
  fixedAttributes.co2NameId,
  fixedAttributes.masterDataNameId,
  fixedAttributes.masterDataPortfolioId,
  fixedAttributes.masterDataPortfolioSubcategoryId,
];

export {
  fixedClasses,
  fixedAttributes,
  fixedFieldAttributes,
  fiscalYearOptionToStartMonth,
  fiscalYearOptionToEndMonth,
  emissionCurrencies,
  emissionFactorStatus,
  depreciationStartModes,
  notBulkEditableAttributes,
  co2MethodOptions,
};
