<template>
  <v-dialog :value="visible" @input="$emit('cancel')" max-width="500">
    <v-card>
      <v-card-title>
        <span class="headline">{{ headline }}</span>
      </v-card-title>
      <v-card-text>
        <form autocomplete="off" @submit.prevent="() => {}">
          <v-autocomplete
            ref="filterItemList"
            v-if="filterItems && filterItems.length"
            class="flex-grow-0"
            :label="filterType"
            :items="filterItems"
            :value="filterValue"
            @input="(data) => $emit('filter-input', data)"
            item-text="name"
            item-value="id"
          >
            <v-list-item
              slot="append-item"
              ripple
              class="close-menu-button"
              @click="closeFilterItemListMenu"
            >
              <v-list-item-action>
                <v-icon>mdi-close</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{
                $t("components.supportCases.assign.closeMenu")
              }}</v-list-item-title>
            </v-list-item>
          </v-autocomplete>

          <v-autocomplete
            v-if="!isLocationList"
            ref="itemList"
            class="flex-grow-0"
            :label="itemType"
            :items="items"
            :value="value"
            @input="(data) => $emit('input', data)"
            item-text="name"
            item-value="id"
          >
            <v-list-item
              slot="append-item"
              ripple
              class="close-menu-button"
              @click="closeItemListMenu"
            >
              <v-list-item-action>
                <v-icon>mdi-close</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{
                $t("components.supportCases.assign.closeMenu")
              }}</v-list-item-title>
            </v-list-item>
          </v-autocomplete>
        </form>
        <location-picker
          v-if="isLocationList"
          ref="locationList"
          className="flex-grow-0"
          :supportCaseId="supportCaseId"
          :locations="items"
          :label="itemType"
          :value="value"
          @input="(data) => $emit('input', data)"
        >
          <v-list-item
            slot="append-item"
            ripple
            class="close-menu-button"
            @click="closeItemListMenu"
          >
            <v-list-item-action>
              <v-icon>mdi-close</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{
              $t("components.supportCases.assign.closeMenu")
            }}</v-list-item-title>
          </v-list-item>
        </location-picker>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="teal darken-3" text @click="$emit('cancel')">
          {{ $t("common.actions.cancel") }}
        </v-btn>
        <v-btn color="teal darken-3" text @click="$emit('submit', value)">
          {{ $t("common.actions.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import LocationPicker from "@/Components/LocationPicker";

export default {
  components: { LocationPicker },
  props: {
    headline: String,
    visible: Boolean,
    items: Array,
    itemType: String,
    value: String,
    filterItems: Array,
    filterType: String,
    filterValue: String,
    supportCaseId: String,
  },
  methods: {
    closeFilterItemListMenu() {
      this.$refs.filterItemList.isMenuActive = false;
      this.$refs.filterItemList.blur();
    },

    closeItemListMenu() {
      if (this.$refs.itemList) {
        this.$refs.itemList.isMenuActive = false;
        this.$refs.itemList.blur();
      } else if (this.$refs.locationList) {
        this.$refs.locationList.closeMenu();
      }
    },
  },
  computed: {
    isLocationList() {
      return (
        this.itemType ===
        this.$t("components.supportCases.actionMenu.types.location")
      );
    },
  },
};
</script>
