<template>
  <v-col class="grey--text" cols="12" sm="9">
    <div
      v-if="currentAttributeFilters.length != 0 && allResults"
      class="text-right"
    >
      <strong>{{
        $t("components.attributes.filterStatusLabels.appliedFilters")
      }}</strong>
      <i18n
        path="components.attributes.filterStatusLabels.searchingEntries"
        tag="span"
        class="mx-2"
      >
        <strong>{{
          $t("components.attributes.filterStatusLabels.searchCount", {
            filteredCount: resultsFilteredCount,
            originalCount: resultsOriginalCount,
          })
        }}</strong>
        <template #entries>
          {{ entryTranslation }}
        </template>
      </i18n>
      <a
        class="black--text text-decoration-underline ml-6"
        @click="$emit('allResultsUpdated', false)"
      >
        {{
          $t(
            "components.attributes.filterStatusLabels.clickSearchFilteredEntries",
            { entries: entryTranslation }
          )
        }}
      </a>
    </div>
    <div v-else-if="currentAttributeFilters.length != 0" class="text-right">
      <strong>{{
        $t("components.attributes.filterStatusLabels.appliedFilters")
      }}</strong>
      <i18n
        path="components.attributes.filterStatusLabels.searchingEntries"
        tag="span"
        class="mx-2"
      >
        <strong>{{
          $t("components.attributes.filterStatusLabels.searchCount", {
            filteredCount: resultsFilteredCount,
            originalCount: resultsOriginalCount,
          })
        }}</strong>
        <template #entries>
          {{ entryTranslation }}
        </template>
      </i18n>
      <a
        class="black--text text-decoration-underline ml-6"
        @click="$emit('allResultsUpdated', true)"
      >
        {{
          $t("components.attributes.filterStatusLabels.clickSearchAllEntries", {
            entries: entryTranslation,
          })
        }}
      </a>
    </div>
    <div
      v-if="
        currentLayout.attributeIds.length != attributes.length &&
        allAttributes &&
        hasSearched
      "
      class="text-right"
    >
      <strong>{{
        $t("components.attributes.filterStatusLabels.hiddenAttributes")
      }}</strong>
      <i18n
        path="components.attributes.filterStatusLabels.searchingAttributes"
        tag="span"
        class="mx-2"
      >
        <strong>{{
          $t("components.attributes.filterStatusLabels.searchCount", {
            filteredCount: attributes.length,
            originalCount: attributes.length,
          })
        }}</strong>
      </i18n>
      <a
        class="black--text text-decoration-underline ml-6"
        @click="$emit('allAttributesUpdated', false)"
      >
        {{
          $t(
            "components.attributes.filterStatusLabels.clickSearchShownAttributes"
          )
        }}
      </a>
    </div>
    <div
      v-else-if="
        currentLayout.attributeIds.length != attributes.length && hasSearched
      "
      class="text-right"
    >
      <strong>{{
        $t("components.attributes.filterStatusLabels.hiddenAttributes")
      }}</strong>
      <i18n
        path="components.attributes.filterStatusLabels.searchingAttributes"
        tag="span"
        class="mx-2"
      >
        <strong>{{
          $t("components.attributes.filterStatusLabels.searchCount", {
            filteredCount: currentLayout.attributeIds.length,
            originalCount: attributes.length,
          })
        }}</strong>
      </i18n>
      <a
        class="black--text text-decoration-underline ml-6"
        @click="$emit('allAttributesUpdated', true)"
      >
        {{
          $t(
            "components.attributes.filterStatusLabels.clickSearchAllAttributes"
          )
        }}
      </a>
    </div>
  </v-col>
</template>
<script>
export default {
  props: {
    currentLayout: Object,
    currentAttributeFilters: Array,
    attributes: Array,
    allResults: Boolean,
    allAttributes: Boolean,
    hasSearched: Boolean,
    resultsFilteredCount: Number,
    resultsOriginalCount: Number,
    entryType: String,
  },
  emits: ["allAttributesUpdated", "allResultsUpdated"],
  computed: {
    entryTranslation() {
      if (!this.entryType) return "";

      return this.$t(
        `components.attributes.filterStatusLabels.entryTypes.${this.entryType}`
      );
    },
  },
};
</script>
