<template>
  <v-tab-item>
    <div class="fill-height fill-width">
      <v-row>
        <v-col cols="12" sm="5">
          <v-row dense>
            <v-col cols="8">
              <span>
                {{ $t("pages.portfolios.form.fields.subcategoryName") }}
              </span>
            </v-col>
            <v-col cols="3" class="text-center">
              <span>
                {{ $t("pages.portfolios.form.fields.preselected") }}
              </span>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
          <v-radio-group
            :value="form.preselectedSubcategoryIndex"
            hide-details
            class="mt-0"
            @change="updateForm('preselectedSubcategoryIndex', $event)"
          >
            <subcategory-entry
              v-for="(subcategory, index) in form.subcategories"
              :key="index"
              :value="subcategory.name"
              :index="index"
              :radioDisabled="disabledSubcategoryLookup[index]"
              @textEmpty="handleSubcategoryTextEmpty"
              @deleteEntry="deleteSubcategory"
              @input="handleSubcategoryNameUpdated(index, $event)"
            />
          </v-radio-group>
          <v-row dense>
            <v-col class="d-flex justify-center">
              <v-btn text @click="addSubcategory">
                <span class="primary--text mr-1">
                  {{ $t("pages.portfolios.form.addSubcategory") }}
                </span>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                v-if="form.preselectedSubcategoryIndex !== null"
                text
                @click="clearPreselection"
                class="ml-2"
              >
                <span class="primary--text mr-1">
                  {{ $t("pages.portfolios.form.removePreselection") }}
                </span>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
import SubcategoryEntry from "../SubcategoryEntry.vue";

export default {
  components: { SubcategoryEntry },
  props: {
    form: Object,
  },
  computed: {
    disabledSubcategoryLookup() {
      return this.form.subcategories.map((x) => this.textIsEmpty(x.name));
    },
  },
  emits: ["updateForm"],
  methods: {
    updateForm(formKey, value) {
      this.$emit("updateForm", formKey, value);
    },
    addSubcategory() {
      const subcategories = [
        ...this.form.subcategories,
        { id: null, name: "" },
      ];

      this.updateForm("subcategories", subcategories);
    },
    deleteSubcategory(index) {
      const subcategories = this.form.subcategories.filter(
        (_, listIndex) => listIndex !== index
      );

      this.updateForm("subcategories", subcategories);

      const selectedIndex = this.form.preselectedSubcategoryIndex;

      if (selectedIndex > index) {
        this.updateForm("preselectedSubcategoryIndex", selectedIndex - 1);
      } else if (selectedIndex === index) {
        this.updateForm("preselectedSubcategoryIndex", null);
      }
    },
    clearPreselection() {
      this.updateForm("preselectedSubcategoryIndex", null);
    },
    textIsEmpty(text) {
      return !text || (text + "").trim() === "";
    },
    handleSubcategoryTextEmpty(index) {
      if (this.form.preselectedSubcategoryIndex !== index) return;

      this.updateForm("preselectedSubcategoryIndex", null);
    },
    handleSubcategoryNameUpdated(index, value) {
      const subcategories = [...this.form.subcategories];

      subcategories[index].name = value;

      this.updateForm("subcategories", subcategories);
    },
  },
};
</script>
