export function pascalToSpaces(text) {
  let formattedText = text.replace(
    /[A-Za-z][A-Z]/g,
    (str) => `${str.charAt(0)} ${str.charAt(1).toLowerCase()}`
  );

  return formattedText;
}

export function spacesToPascal(text) {
  let formattedText = text.replace(/ [a-z]/g, (str) =>
    str.trim().toUpperCase()
  );

  return formattedText;
}

const charsToReplace = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
};

export function escapeHtml(unescaped) {
  return unescaped.replace(/[&<>]/g, (char) => charsToReplace[char] || char);
}

export function replaceUrlsWithLinks(text) {
  let escapedUrlRegex = /&lt;(http.*)&gt;/g;

  return text.replace(escapedUrlRegex, (_, $1) => {
    return ` <a href="${$1}" target="_blank">LINK</a>`;
  });
}

export function inlineMailToLinks(text) {
  let mailToRegex = /\S+(&lt;(mailto:.+?)&gt;)(?:\S+)?/g;

  return text.replace(mailToRegex, (match, $1, $2) => {
    let cleanedText = match.replace($1, "");

    return `<a href="${$2}">${cleanedText}</a>`;
  });
}

export function formatHtmlMessage(unescaped) {
  let text = escapeHtml(unescaped);

  text = replaceUrlsWithLinks(text);

  text = inlineMailToLinks(text);

  return text;
}
