<template>
  <div>
    <h4 class="text-h4">{{ $t("pages.files.create.title") }}</h4>
    <v-spacer class="mb-6"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col md="8" lg="6">
          <form @submit.prevent="form.post(route('files.store'))">
            <v-card elevation="1">
              <v-card-text>
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.files.create.file")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-file-input
                      v-model="form.file"
                      name="file"
                      show-size
                      :rules="fileSizeRules"
                      :error-messages="form.errors.file"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.files.create.name")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      name="name"
                      type="text"
                      :error-messages="form.errors.name"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <flash-messages />
                <request-size-error :errors="form.errors" />
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  outlined
                  color="primary"
                  class="ml-2"
                  :disabled="form.processing"
                  >{{ $t("pages.files.create.save") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";
import FlashMessages from "@/Shared/FlashMessages";
import RequestSizeError from "@/Shared/RequestSizeError";

export default {
  layout: appLayout({ title: "pages.files.create.pageTitle" }),
  components: {
    FlashMessages,
    RequestSizeError,
  },
  watch: {
    "form.file": function (newVal, oldVal) {
      if (!this.form.file) {
        this.form.name = null;
        return;
      }

      if (
        (!this.form.name && this.form.file) ||
        (oldVal && newVal.name != oldVal.name)
      ) {
        this.form.name = this.form.file.name.slice(
          0,
          this.form.file.name.lastIndexOf(".")
        );
      }
    },
  },
  data() {
    return {
      fileSizeRules: [
        (value) =>
          !value ||
          value.size < 30000000 ||
          this.$t("pages.files.create.sizeRestriction"),
      ],
      form: this.$inertia.form({
        name: null,
        file: null,
      }),
    };
  },
};
</script>
