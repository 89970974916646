<template>
  <div>
    <file-drag-detector @draggingActive="setOsFilesDragging" />
    <v-row>
      <v-col cols="12" sm="3">
        <h4 class="text-h4">
          {{
            isTemplatePage
              ? $t("pages.assets.index.templateTitle")
              : $t("pages.assets.index.title")
          }}
        </h4>
        <v-spacer></v-spacer>
      </v-col>
      <filter-status-labels
        :currentLayout="currentAssetLayout"
        :currentAttributeFilters="currentAttributeFilters"
        :attributes="attributes"
        :allResults="postFilters.allResults"
        :allAttributes="postFilters.allAttributes"
        :hasSearched="hasSearched"
        :resultsFilteredCount="assets.filteredCount"
        :resultsOriginalCount="assets.originalCount"
        entryType="asset"
        @allAttributesUpdated="postFilters.allAttributes = $event"
        @allResultsUpdated="postFilters.allResults = $event"
      />
    </v-row>
    <v-row justify="space-between" class="d-flex align-center">
      <v-col md="3" v-if="!isTemplatePage">
        <v-autocomplete
          v-model="portfolioIds"
          :items="portfolios"
          :label="$t('pages.assets.index.dropdowns.portfolios.label')"
          item-text="name"
          item-value="id"
          multiple
          dense
          hide-details
          solo
        >
          <v-list-item
            slot="prepend-item"
            ripple
            @click="toggleSelectAllPortfolios"
            :class="
              allPortfoliosSelected && 'primary--text v-list-item--active'
            "
          >
            <v-list-item-action>
              <v-simple-checkbox
                :ripple="false"
                :value="allPortfoliosSelected"
                @click="toggleSelectAllPortfolios"
              />
            </v-list-item-action>
            <v-list-item-title>{{
              $t("pages.assets.index.dropdowns.portfolios.all")
            }}</v-list-item-title>
          </v-list-item>
          <v-divider slot="prepend-item" />
          <template #selection="{ item, index }">
            <span
              v-if="portfolioIds.length === portfolios.length && index === 0"
              >{{
                $t("pages.assets.index.dropdowns.portfolios.allPortfolios")
              }}</span
            >
            <span
              v-else-if="
                portfolioIds.length !== portfolios.length &&
                index < 3 &&
                index !== portfolioIds.length - 1
              "
              class="mr-1"
              >{{ item.name }},</span
            >
            <span
              v-else-if="
                portfolioIds.length !== portfolios.length &&
                index < 3 &&
                index === portfolioIds.length - 1
              "
              >{{ item.name }}</span
            >
            <span
              v-else-if="
                portfolioIds.length !== portfolios.length && index === 3
              "
              >...</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md="3" v-else>
        <v-spacer></v-spacer>
      </v-col>
      <v-col md="3">
        <v-text-field
          :value="postFilters.search"
          @change="searchAttributes($event)"
          clearable
          append-icon="mdi-magnify"
          :label="$t('common.dropdowns.search.label')"
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center">
      <asset-form
        v-model="formDialog"
        :asset="asset"
        :attributeClasses="attributeClasses"
        :attributes="attributes"
        :currencies="currencies"
        :portfolios="portfolios"
        :organizationId="currentOrganizationId"
        :isTemplatePage="isTemplatePage"
        :defaultCurrencyCode="defaultCurrencyCode"
        :solutionId="solutionId"
        @clear="asset = null"
        @reload="reloadAsset"
      />
      <filters-modal
        v-model="filtersDialog"
        :attributes="attributes"
        :attributeFilters="currentAttributeFilters"
        @changeAttributeFilters="(value) => updateAttributeFilters(value)"
      />
      <choose-attributes-modal
        v-model="chooseAttributesDialog"
        :layout="currentAssetLayout"
        :attributes="attributes"
        :attributeClasses="attributeClasses"
        :organizationId="currentOrganizationId"
        @changeLayout="updateAssetLayout"
      />
      <layout-options-modal
        v-model="layoutOptionsDialog"
        :layout="currentAssetLayout"
        :attributeDisplaySizes="attributeDisplaySizes"
        @changeLayout="updateAssetLayout"
        @saveLayout="saveLayout"
      />
      <save-layout-button
        :currentLayout="currentAssetLayout"
        :currentAttributeFilters="currentAttributeFilters"
        :rowsPerPage="pagination.itemsPerPage"
        :triggerSave="triggerSaveLayout"
        :isTemplatePage="isTemplatePage"
        routePrefix="assets"
        @resetTriggerSave="triggerSaveLayout = false"
      />
      <template v-if="!isTemplatePage">
        <import-assets-modal
          v-model="importAssetsDialog"
          :organizationId="currentOrganizationId"
        />
        <export-assets
          ref="export-assets"
          v-model="exportAssetsDialog"
          :attributes="attributes"
          :portfolios="portfolios"
          :filteredAssets="assets"
          :selectedAssets="selectedAssets"
          :assetLayout="currentAssetLayout"
          :organizationId="currentOrganizationId"
          :postFilters="postFilters"
        />
      </template>
      <div style="flex: 1000000"></div>
      <v-btn
        class="mr-4 mb-2 float-right"
        height="3rem"
        text
        color="primary"
        @click="toggleFileMenu"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon v-if="!showFileMenu" class="black--text"
            >mdi-toggle-switch-off-outline</v-icon
          >
          <v-icon v-else class="black--text">mdi-toggle-switch-outline</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.assets.index.toolbar.fileModule") }}
          </p>
        </div>
      </v-btn>
      <v-spacer></v-spacer>
    </v-row>
    <v-row dense no-gutters>
      <v-col :cols="showFileMenu ? 10 : 12">
        <v-data-table
          v-sortable-table="{ onEnd: sortTableHeaders }"
          :key="tableRerenderCounter"
          v-model="selectedAssets"
          class="elevation-1 row-pointer rounded-r-0 fill-height attributes-table"
          :class="isDisplaySize('Minimal') ? 'attributes-fixed-table' : ''"
          :headers="headers"
          :items="mappedAssets"
          :server-items-length="assets.totalCount"
          :options.sync="pagination"
          :footer-props="tableFooterProps"
          :no-data-text="$t('common.dataTable.noData')"
          height="calc(100% - 58px)"
          multi-sort
          show-select
          @update:page="changePage(pagination)"
          @update:items-per-page="changePage(pagination)"
          @update:sort-desc="changeSorting(pagination)"
        >
          <template v-for="header in headers" v-slot:[`header.${header.value}`]>
            <v-tooltip top v-bind:key="header.key">
              <template v-slot:activator="{ on }">
                <em v-on="on">
                  <span>{{ header.text }}</span>
                  <v-icon
                    v-if="getBulkEditAttribute(header)"
                    class="bulk-edit-icon ml-1"
                    small
                    @click.stop="toggleBulkEditModal(header)"
                  >
                    mdi-pencil-box-multiple-outline
                  </v-icon>
                </em>
              </template>
              <span>{{ header.text }}</span>
            </v-tooltip>
          </template>
          <template v-slot:body.prepend>
            <header-search-row
              :columnIdentifiers="columnIdentifiers"
              :attributeFilters="currentAttributeFilters"
              :attributes="attributes"
              :columnFilterData="columnFilterData"
              :postFilters="postFilters"
              :showActionRow="!showFileMenu"
              @updateAttributeFilters="currentAttributeFilters = $event"
              @updatePostFilters="postFilters = $event"
              @updateColumnFilterData="
                (key, value) => (columnFilterData[key] = value)
              "
            />
          </template>
          <template #item="{ isSelected, select, item }">
            <attribute-data-row
              :isSelected="isSelected"
              :select="select"
              :item="item"
              :attributes="attributes"
              :currentLayout="currentAssetLayout"
              :filesDragging="filesDragging"
              :headers="headers"
              :onlyFileAttribute="onlyFileAttribute"
              :hoveredAttributeId="hoveredAttributeId"
              :hoveredRowId="hoveredAssetId"
              :defaultCurrencyCode="defaultCurrencyCode"
              entryType="asset"
              @editRowClicked="editAsset"
              @copyRowClicked="copyAsset"
              @destroyRowClicked="destroyAsset"
              @hoveredAttributeIdUpdated="hoveredAttributeId = $event"
              @hoveredRowIdUpdated="hoveredAssetId = $event"
              @linkFilesToRow="handleAssetFileLinking"
            />
          </template>
          <template #body.append="{ items }">
            <tr>
              <td></td>
              <td
                v-for="header in headers"
                :key="header.value"
                :class="header.value === 'action' ? 'fixed' : ''"
              >
                <span
                  v-if="dynamicHeaderIds.includes(header.value)"
                  class="font-weight-bold"
                >
                  {{
                    summarizeAttribute(mappedAttributes[header.value], items)
                  }}
                </span>
              </td>
            </tr>
          </template>
          <template #footer.prepend>
            <v-row justify="space-between" class="ml-0 mr-8 align-center">
              <v-btn text small @click="removeAssetRange">
                {{ $t("pages.assets.index.deleteSelectedAssets") }}
              </v-btn>
            </v-row>
          </template>
          <template #footer.page-text="pageTextProps">
            {{ $t("common.dataTable.visibleRows", pageTextProps) }}
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="showFileMenu" cols="2">
        <file-menu
          :refreshFiles="refreshFileMenu"
          :osFilesDragging="osFilesDragging"
          :organizationId="currentOrganizationId"
          entryType="asset"
          @filesRefreshed="refreshFileMenu = false"
          @filesDraggingChanged="handleFilesDraggingChanged"
        />
      </v-col>
    </v-row>
    <bulk-edit-assets-modal
      :value="bulkEditAttribute !== null"
      :attribute="bulkEditAttribute"
      :portfolios="portfolios"
      :assets="assets"
      :selectedAssets="selectedAssets"
      :assetLayout="currentAssetLayout"
      :attributeFilters="currentAttributeFilters"
      :postFilters="postFilters"
      :portfolioIds="portfolioIds"
      :currencies="currencies"
      :isTemplatePage="isTemplatePage"
      @input="toggleBulkEditModal(null)"
      @reset="changePage({ page: 1, itemsPerPage: pagination.itemsPerPage })"
    />
  </div>
</template>

<script>
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import { appLayout } from "@/util/layout";
import {
  footerProps,
  generateAttributeTableHeaders,
  sortableTableDirective,
  sortTableColumnLayout,
} from "@/util/dataTable";
import ChooseAttributesModal from "../../Components/Attribute/ChooseAttributesModal.vue";
import ExportAssets from "./ExportAssets.vue";
import FiltersModal from "../../Components/Attribute/FiltersModal.vue";
import AssetForm from "./Form.vue";
import ImportAssetsModal from "../../Components/Asset/ImportAssetsModal.vue";
import BulkEditAssetsModal from "../../Components/Asset/BulkEditAssetsModal.vue";
import HeaderSearchRow from "../../Components/Attribute/HeaderSearchRow.vue";
import FilterStatusLabels from "../../Components/Attribute/FilterStatusLabels.vue";
import LayoutOptionsModal from "../../Components/Layout/LayoutOptionsModal.vue";
import FileMenu from "../../Components/Attribute/FileMenu.vue";
import FileDragDetector from "@/Components/FileDragDetector.vue";
import qs from "qs";
import {
  currencyFormatter,
  decimalFormatter,
  formatAndConvertStringToFloat,
} from "../../util/formatters";
import { fixedAttributes } from "@/util/fixedAssetData";
import { calculateDepreciations } from "@/util/calculators";
import { DateTime } from "luxon";
import Decimal from "decimal.js";
import {
  calculateBookValue,
  calculateEmissionsSpendBased,
} from "@/util/calculators";
import {
  fixedFieldAttributes,
  notBulkEditableAttributes,
} from "../../util/fixedAssetData";
import SaveLayoutButton from "../../Components/Layout/SaveLayoutButton.vue";
import AttributeDataRow from "../../Components/Attribute/AttributeDataRow.vue";

import "../../assets/css/attributeTable.css";
import { serialize } from "object-to-formdata";

export default {
  layout: appLayout({ title: "pages.assets.index.title" }),
  components: {
    ChooseAttributesModal,
    FiltersModal,
    AssetForm,
    ImportAssetsModal,
    BulkEditAssetsModal,
    LayoutOptionsModal,
    ExportAssets,
    AttributeDataRow,
    FileMenu,
    FileDragDetector,
    SaveLayoutButton,
    HeaderSearchRow,
    FilterStatusLabels,
  },
  props: {
    assets: Object,
    assetLayout: Object,
    attributes: Array,
    attributeClasses: Array,
    currentOrganizationId: String,
    portfolios: Array,
    currencies: Array,
    attributeDisplaySizes: Array,
    solutionId: String,
    assetId: String,
    assetToEdit: Object,
  },
  data() {
    let searchParams = qs.parse(window.location.search.substring(1));

    let columnFilterProperties = ["referenceKey"];
    let columnFilterData = {};
    let sortBy = [];
    let sortDesc = [];

    columnFilterProperties.forEach((x) => {
      columnFilterData[`${x}-filter`] = null;
    });
    this.attributes.forEach((x) => {
      columnFilterData[`${x.id}-filter`] = null;
    });

    if (this.assetLayout.attributeFilters != null) {
      this.assetLayout.attributeFilters.forEach((x) => {
        if (x.filterBy != null) {
          if (x.attributeId != null) {
            columnFilterData[`${x.attributeId}-filter`] = x.filter;
            columnFilterData[`${x.attributeId}-filterBy`] = x.filterBy;
          } else {
            columnFilterData[`${x.propertyName}-filter`] = x.filter;
            columnFilterData[`${x.propertyName}-filterBy`] = x.filterBy;
          }
        }
      });

      var filters = this.assetLayout.attributeFilters.filter(
        (x) => x.sortDesc != null
      );
      sortBy = filters.map((x) => {
        if (x.sortDesc != null) {
          if (x.attributeId != null) {
            return x.attributeId;
          }
          return x.propertyName;
        }
      });
      sortDesc = filters.map((x) => {
        if (x.sortDesc != null) {
          return x.sortDesc;
        }
      });
    }

    return {
      asset: null,
      currentAssetLayout: this.assetLayout,
      currentAttributeFilters: this.assetLayout.attributeFilters,
      formDialog: false,
      chooseAttributesDialog: false,
      layoutOptionsDialog: false,
      exportAssetsDialog: false,
      filtersDialog: false,
      importAssetsDialog: false,
      showFileMenu: false,
      hasSearched: false,
      bulkEditAttribute: null,
      pagination: {
        page: this.assets.currentPage,
        itemsPerPage: this.assets.pageSize,
        sortBy,
        sortDesc,
      },
      tableFooterProps: footerProps,
      postFilters: {
        search: searchParams.postFilters?.search ?? null,
        allAttributes: searchParams.postFilters?.allAttributes ?? null,
        allResults: searchParams.postFilters?.allResults,
      },
      organizationId:
        this.currentOrganizationId ?? searchParams.organizationId ?? null,
      portfolioIds: searchParams.portfolioIds ?? [],
      columnFilterProperties,
      columnFilterData,
      filesDragging: false,
      osFilesDragging: false,
      hoveredAssetId: null,
      hoveredAttributeId: null,
      refreshFileMenu: false,
      selectedAssets: [],
      tableRerenderCounter: 0,
      currentRoute: this.routeRaw().current(),
      isTemplatePage: this.routeRaw().current() === "asset-templates.index",
      triggerSaveLayout: false,
    };
  },
  computed: {
    filteredFilterOptions() {
      return this.filterOptions.filter((option) => option.text !== "");
    },
    summedEmissions() {
      let emissionsList = [];
      this.assets.data.forEach((asset) => {
        let attribute = asset.assetAttributeLinks.find(
          (element) =>
            element.attributeId === fixedFieldAttributes.co2EmissionsId
        );
        if (attribute !== undefined) {
          emissionsList.push(attribute.decimalValue);
        }
      });
      return this.getFormattedSum(emissionsList);
    },
    summedEmissionsFactor() {
      let emissionsList = [];
      this.assets.data.forEach((asset) => {
        let emission =
          asset.emissionFactor?.kgCo2eAr5 ?? asset.emissionFactor?.kgCo2eAr4;
        if (emission !== undefined) {
          emissionsList.push(emission);
        }
      });
      return this.getFormattedSum(emissionsList);
    },
    summedScope3Emissions() {
      let emissionsList = [];
      this.assets.data.forEach((asset) => {
        let attribute = asset.assetAttributeLinks.find(
          (element) =>
            element.attributeId === fixedAttributes.masterDataPurchasePriceId
        );
        if (attribute !== undefined) {
          let scope3Emissions = calculateEmissionsSpendBased(
            attribute.decimalValue,
            attribute.stringValue?.trim(),
            asset.emissionFactor
          );

          if (scope3Emissions !== undefined) {
            emissionsList.push(formatAndConvertStringToFloat(scope3Emissions));
          }
        }
      });

      return this.getFormattedSum(emissionsList);
    },
    summedDepreciation() {
      let depreciationList = [];
      this.assets.data.forEach((asset) => {
        let depreciations = this.getDepreciations(asset);

        const currentYear = DateTime.utc().year;

        const depreciation = depreciations[currentYear] ?? new Decimal(0);

        Decimal.set({ rounding: Decimal.ROUND_HALF_UP });

        const roundedNumber = depreciation.round().toNumber();

        depreciationList.push(roundedNumber);
      });

      return this.getFormattedSum(depreciationList);
    },
    summedBookValue() {
      let bookvaluesList = [];
      let purchasePriceDecimalValue = undefined;
      this.assets.data.forEach((asset) => {
        let depreciations = this.getDepreciations(asset);

        let purchasePriceAttribute = asset.assetAttributeLinks.find(
          (element) =>
            element.attributeId === fixedAttributes.masterDataPurchasePriceId
        );
        if (purchasePriceAttribute !== undefined) {
          purchasePriceDecimalValue = purchasePriceAttribute.decimalValue;
        }

        let bookValue = calculateBookValue(
          purchasePriceDecimalValue,
          undefined,
          depreciations
        );

        bookvaluesList.push(formatAndConvertStringToFloat(bookValue));
      });

      return this.getFormattedSum(bookvaluesList);
    },
    headers() {
      return generateAttributeTableHeaders(
        this.currentAssetLayout,
        this.attributes,
        this.showFileMenu
      );
    },
    dynamicHeaderIds() {
      return this.headers.filter((x) => x.dynamic).map((x) => x.value);
    },
    columnIdentifiers() {
      let identifiers = [];

      if (this.columnFilterProperties) {
        identifiers = identifiers.concat(this.columnFilterProperties);
      }

      if (this.dynamicHeaderIds) {
        identifiers = identifiers.concat(this.dynamicHeaderIds);
      }

      return identifiers;
    },
    mappedAssets() {
      let mappedAssets = [];
      this.assets.data.forEach((asset) => {
        let mappedAsset = {
          ...asset,
        };
        asset.assetAttributeLinks.forEach((assetAttributeLink) => {
          mappedAsset[assetAttributeLink.attributeId] = assetAttributeLink;
        });
        mappedAssets.push(mappedAsset);
      });
      return mappedAssets;
    },
    mappedAttributes() {
      let mappedAttributes = [];
      this.attributes.forEach((attribute) => {
        mappedAttributes[attribute.id] = attribute;
      });
      return mappedAttributes;
    },
    visibleAttributes() {
      if (!this.attributes || !this.currentAssetLayout) return [];

      return this.attributes.filter((x) =>
        this.currentAssetLayout.attributeIds.includes(x.id)
      );
    },
    onlyFileAttribute() {
      if (this.attributes.filter((x) => x.format === "File").length <= 1)
        return this.attributes.find((x) => x.format === "File") ?? null;

      if (this.visibleAttributes.filter((x) => x.format === "File").length <= 1)
        return this.visibleAttributes.find((x) => x.format === "File") ?? null;

      return null;
    },
    allPortfoliosSelected() {
      if (!this.portfolioIds) return false;

      return this.portfolios.every((x) => this.portfolioIds.includes(x.id));
    },
    defaultCurrencyId() {
      return this.$inertia.page.props.auth.settings.defaultCurrencyId;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.defaultCurrencyId
      );

      return currency?.currencyCode ?? null;
    },
  },
  methods: {
    getDepreciations(asset) {
      let purchaseDate = undefined;
      let purchasePriceDecimalValue = undefined;
      let purchasePriceCurrency = undefined;
      let scrapDecimalValue = undefined;
      let scrapCurrency = undefined;
      let depreciationPeriod = undefined;
      let fiscalYear = undefined;

      let purchaseDateAttribute = asset.assetAttributeLinks.find(
        (element) =>
          element.attributeId === fixedAttributes.masterDataPurchaseDateId
      );
      if (purchaseDateAttribute !== undefined) {
        purchaseDate = new Date(purchaseDateAttribute.dateTimeValue);
      }

      let purchasePriceAttribute = asset.assetAttributeLinks.find(
        (element) =>
          element.attributeId === fixedAttributes.masterDataPurchasePriceId
      );
      if (purchasePriceAttribute !== undefined) {
        purchasePriceDecimalValue = purchasePriceAttribute.decimalValue;
        purchasePriceCurrency = purchasePriceAttribute.stringValue?.trim();
      }

      let scrapValueAttribute = asset.assetAttributeLinks.find(
        (element) =>
          element.attributeId === fixedAttributes.fixedAssetsScrapValueId
      );
      if (scrapValueAttribute !== undefined) {
        scrapDecimalValue = scrapValueAttribute.decimalValue;
        scrapCurrency = scrapValueAttribute.stringValue?.trim();
      }

      let depreciationPeriodAttribute = asset.assetAttributeLinks.find(
        (element) =>
          element.attributeId ===
          fixedAttributes.fixedAssetsDepreciationPeriodId
      );
      if (depreciationPeriodAttribute !== undefined) {
        depreciationPeriod =
          depreciationPeriodAttribute.attributeSelectOptionId;
      }

      let fiscalYearAttribute = asset.assetAttributeLinks.find(
        (element) =>
          element.attributeId === fixedAttributes.fixedAssetsFiscalYearId
      );
      if (fiscalYearAttribute !== undefined) {
        fiscalYear = fiscalYearAttribute.attributeSelectOptionId;
      }

      let depreciations = calculateDepreciations(
        purchaseDate,
        purchasePriceDecimalValue,
        purchasePriceCurrency,
        scrapDecimalValue,
        scrapCurrency,
        depreciationPeriod,
        fiscalYear
      );

      return depreciations;
    },
    getFormattedSum(listtoSum) {
      var summedListValue = listtoSum.reduce(
        (partialSum, a) => partialSum + a,
        0
      );

      const scale = Math.pow(1, 4);
      return decimalFormatter.format(summedListValue / scale);
    },
    sortTableHeaders(event) {
      this.currentAssetLayout = sortTableColumnLayout(
        event,
        this.headers,
        this.currentAssetLayout
      );

      this.tableRerenderCounter++;
    },
    handleFilesDraggingChanged(value) {
      this.filesDragging = value;
    },
    editAsset(item) {
      this.asset = { ...item };
      this.formDialog = true;
    },
    copyAsset(item) {
      this.asset = { ...item, id: null };
      this.formDialog = true;
    },
    destroyAsset(id) {
      if (!confirm(this.$t("pages.assets.index.confirmDelete"))) {
        return;
      }

      this.$inertia.delete(this.route("assets.destroy", id));
    },
    changePage(options) {
      let query = {
        portfolioIds: this.portfolioIds,
        postFilters: pickBy(this.postFilters),
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.post(
        this.route(this.currentRoute, query),
        {
          ...this.currentAssetLayout,
          attributeFilters: this.currentAttributeFilters,
        },
        { preserveState: true }
      );
    },
    changePageReset(options) {
      let query = {
        portfolioIds: this.portfolioIds,
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(this.route(this.currentRoute, query), {
        preserveState: true,
      });
    },
    changeSorting(options) {
      this.currentAttributeFilters = this.currentAttributeFilters.filter(
        (attributeFilter) => attributeFilter.filter != null
      );

      this.currentAttributeFilters.forEach((attributeFilter) => {
        attributeFilter.sortDesc = null;
      });

      options.sortBy.forEach((x, index) => {
        if (this.attributes.some((attribute) => attribute.id == x)) {
          let attributeFilter = this.currentAttributeFilters.find(
            (attributeFilter) => attributeFilter.attributeId == x
          );
          if (attributeFilter != null) {
            attributeFilter.sortDesc = options.sortDesc[index];
            attributeFilter.sortOrder = index;
          } else {
            this.currentAttributeFilters.push({
              attributeId: x,
              sortDesc: options.sortDesc[index],
              sortOrder: index,
              attribute: this.attributes.find((attribute) => attribute.id == x),
            });
          }
        } else {
          let attributeFilter = this.currentAttributeFilters.find(
            (attributeFilter) => attributeFilter.propertyName == x
          );
          if (attributeFilter != null) {
            attributeFilter.sortDesc = options.sortDesc[index];
            attributeFilter.sortOrder = index;
          } else {
            this.currentAttributeFilters.push({
              propertyName: x,
              sortDesc: options.sortDesc[index],
              sortOrder: index,
            });
          }
        }
      });

      this.changePage({
        page: 1,
        itemsPerPage: this.pagination.itemsPerPage,
        sortBy: options.sortBy,
        sortDesc: options.sortDesc,
      });
    },
    updateAssetLayout(value) {
      this.currentAssetLayout = {
        ...this.currentAssetLayout,
        ...value,
      };

      if (this.currentAssetLayout.sortOrderMappings) {
        const sortOrderKeys = Object.keys(
          this.currentAssetLayout.sortOrderMappings
        );

        for (let key of sortOrderKeys) {
          let sortValue = this.currentAssetLayout.sortOrderMappings[key];

          if (!this.currentAssetLayout.attributeIds.includes(sortValue)) {
            delete this.currentAssetLayout.sortOrderMappings[key];
          }
        }

        this.tableRerenderCounter++;
      }

      if (this.postFilters.search != null && !this.postFilters.allAttributes) {
        this.changePage({
          page: 1,
          itemsPerPage: this.pagination.itemsPerPage,
        });
      }
    },
    updateAttributeFilters(filters) {
      this.currentAttributeFilters = filters;
      this.setColumnFilters(this.currentAttributeFilters ?? []);
      this.setColumnSorting(this.currentAttributeFilters ?? []);
      this.postFilters = { ...this.postFilters, allResults: false };
    },
    setColumnSorting(filters) {
      filters = filters.filter((x) => x.sortDesc != null);
      let sortBy = filters.map((x) => {
        if (x.sortDesc != null) {
          if (x.attributeId != null) {
            return x.attributeId;
          }
          return x.propertyName;
        }
      });
      let sortDesc = filters.map((x) => {
        if (x.sortDesc != null) {
          return x.sortDesc;
        }
      });
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
    },
    setColumnFilters(filters) {
      var columnFilterData = {};

      this.columnFilterProperties.forEach((x) => {
        columnFilterData[`${x}-filter`] = null;
      });
      this.attributes.forEach((x) => {
        columnFilterData[`${x.id}-filter`] = null;
      });
      filters.forEach((x) => {
        if (x.filterBy != null) {
          if (x.attributeId != null) {
            columnFilterData[`${x.attributeId}-filter`] = x.filter;
            columnFilterData[`${x.attributeId}-filterBy`] = x.filterBy;
          } else {
            columnFilterData[`${x.propertyName}-filter`] = x.filter;
            columnFilterData[`${x.propertyName}-filterBy`] = x.filterBy;
          }
        }
      });

      this.columnFilterData = columnFilterData;
    },
    toggleFileMenu() {
      this.showFileMenu = !this.showFileMenu;
    },
    handleAssetFileLinking(assetId, attributeId, formData) {
      fetch(
        this.route("api.assets.attributes.files.link", {
          assetid: assetId,
          attributeid: attributeId,
        }),
        {
          method: "POST",
          body: formData,
        }
      ).then((res) => {
        if (!res.ok) return Promise.reject();

        this.changePage({
          page: this.pagination.page,
          itemsPerPage: this.pagination.itemsPerPage,
        });

        this.refreshFileMenu = true;
      });
    },
    summarizeAttribute(attribute, assets) {
      if (attribute.id === fixedFieldAttributes.co2EmissionsId) {
        return this.summedEmissions;
      }

      if (attribute.id === fixedFieldAttributes.co2EmissionFactorId) {
        return this.summedEmissionsFactor;
      }

      if (attribute.id === fixedFieldAttributes.co2EmissionsId) {
        return this.summedScope3Emissions;
      }

      if (attribute.id === fixedFieldAttributes.fixedAssetsDepreciationId) {
        return this.summedDepreciation;
      }

      if (attribute.format === "Currency") {
        const decimals = attribute.visibleDecimals ?? 2;
        const scale = Math.pow(10, decimals);

        const sum = assets
          .map(
            (x) =>
              x.assetAttributeLinks.find((y) => y.attributeId === attribute.id)
                ?.decimalValue
          )
          .filter((x) => !!x)
          .reduce((total, current) => total + current * scale, 0);

        return currencyFormatter.format(sum / scale);
      }
    },
    removeAssetRange() {
      if (
        !this.selectedAssets.length ||
        !confirm(
          this.$t("pages.assets.index.confirmDeleteSelected", {
            amount: this.selectedAssets.length,
          })
        )
      )
        return;

      const form = serialize({
        assetIds: this.selectedAssets.map((x) => x.id),
      });

      this.$inertia.post(this.route("assets.destroy.range"), form);

      this.selectedAssets = [];
    },
    setOsFilesDragging(value) {
      this.osFilesDragging = value;
    },
    toggleSelectAllPortfolios() {
      if (this.portfolioIds?.length < this.portfolios.length)
        this.portfolioIds = this.portfolios.map((x) => x.id);
      else this.portfolioIds = [];
    },
    isDisplaySize(attributeDisplaySize) {
      return (
        this.currentAssetLayout.attributeDisplaySize === attributeDisplaySize
      );
    },
    openFormByQueryId() {
      const params = qs.parse(window.location.search.substring(1));

      if (!params.editAssetId) return;

      const asset = this.mappedAssets.find((x) => x.id === params.editAssetId);

      if (!asset) return;

      this.editAsset(asset);

      delete params.editAssetId;

      const path =
        window.location.pathname +
        "?" +
        qs.stringify(params, { arrayFormat: "brackets" });

      window.history.replaceState(null, "", path);
    },
    searchAttributes(value) {
      this.postFilters.search = value;
      if (!value || value.length === 0) {
        this.hasSearched = false;
      } else {
        this.hasSearched = true;
      }
    },
    getAttributeFromHeader(header) {
      if (!header?.value) return;

      const attribute = this.attributes.find((x) => x.id === header.value);

      return attribute ?? null;
    },
    getBulkEditAttribute(header) {
      if (notBulkEditableAttributes.includes(header.value)) return null;

      const attribute = this.getAttributeFromHeader(header);

      if (attribute?.format === "File") return null;

      return attribute;
    },
    toggleBulkEditModal(header) {
      if (!header) {
        this.bulkEditAttribute = null;
        return;
      }

      const attribute = this.getBulkEditAttribute(header);

      if (!attribute) return;

      this.bulkEditAttribute = attribute;
    },
    saveLayout() {
      this.triggerSaveLayout = true;
    },
    reloadAsset(assetId) {
      const asset = this.assets.data.find((x) => x.id === assetId);

      if (asset) this.asset = { ...asset };
    },
  },
  directives: {
    "sortable-table": sortableTableDirective,
  },
  watch: {
    postFilters: {
      handler: throttle(function () {
        this.changePage({
          page: 1,
          itemsPerPage: this.pagination.itemsPerPage,
        });
      }, 150),
      deep: true,
    },
    portfolioIds: throttle(function () {
      this.changePageReset({
        page: 1,
        itemsPerPage: this.pagination.itemsPerPage,
      });
    }, 150),
    importAssetsDialog: function (value) {
      if (value) return;

      this.changePageReset(this.pagination);
    },
  },
  mounted() {
    if (this.solutionId) {
      this.formDialog = true;

      let searchParams = qs.parse(window.location.search.substring(1));
      delete searchParams.solutionId;
      const queryString = qs.stringify(searchParams);

      let newRelativeUrl = window.location.pathname;

      if (queryString) newRelativeUrl += "?" + queryString;

      setTimeout(
        () => window.history.replaceState(null, "", newRelativeUrl),
        100
      );
    }

    if (this.assetId && this.assetToEdit) {
      let asset = this.assetToEdit;

      this.editAsset(asset);

      let searchParams = qs.parse(window.location.search.substring(1));
      delete searchParams.assetId;
      const queryString = qs.stringify(searchParams);

      let newRelativeUrl = window.location.pathname;

      if (queryString) newRelativeUrl += "?" + queryString;

      setTimeout(
        () => window.history.replaceState(null, "", newRelativeUrl),
        100
      );
    }
    setTimeout(() => this.openFormByQueryId(), 1000);
  },
};
</script>
