<template>
  <v-dialog
    style="z-index: 20001"
    v-model="show"
    scrollable
    max-width="80vw"
    hide-overlay
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-tray-arrow-up</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.assets.export.export") }}
          </p>
        </div>
      </v-btn>
    </template>
    <v-card height="70vh" class="base-background-color">
      <v-card-title>{{ $t("pages.assets.export.export") }}</v-card-title>
      <v-card-text style="height: 300px">
        <v-row>
          <v-col>
            <div>
              <strong>{{ $t("pages.assets.export.whichAssets") }}</strong>
            </div>
            <v-row>
              <v-col>
                <v-radio-group column mandatory @change="setAssetsSelection" v-model="selectedAssetOption">
                  <v-radio value="All">
                    <template v-slot:label>
                      <div>
                        <strong>
                          {{ $t("pages.assets.export.assetOptions.all") }}
                        </strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="Filtered assets">
                    <template v-slot:label>
                      <div>
                        <strong>
                          {{ $t("pages.assets.export.assetOptions.filtered") }}
                        </strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="Selected assets">
                    <template v-slot:label>
                      <div>
                        <strong>
                          {{ $t("pages.assets.export.assetOptions.selected") }}
                        </strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="Specific portfolio">
                    <template v-slot:label>
                      <div>
                        <strong>
                          {{ $t("pages.assets.export.assetOptions.specific") }}:
                        </strong>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col>
                <div class="pt-6">({{ allAssetsNumber }} assets)</div>
                <div class="pt-2">({{ filteredAssetsNumber }} assets)</div>
                <div class="pt-2">({{ selectedAssetsNumber }} assets)</div>
                <div class="pt-2">
                  ({{ selectedPortfolioAssetCount }} assets)
                </div>
              </v-col>
            </v-row>
            <v-select
              id="PortfolioSelector"
              v-model="selectedPortfolio"
              :items="portfolios"
              item-text="name"
              item-value="id"
              :label="$t('pages.assets.export.selectPortfolio')"
              solo
              dense
              @change="setSelectedPortfolioId"
            ></v-select>
          </v-col>
          <v-col>
            <div>
              <strong>{{ $t("pages.assets.export.withAttributes") }}</strong>
            </div>
            <v-row>
              <v-col>
                <v-radio-group
                  column
                  mandatory
                  @change="setAttributesSelection"
                >
                  <v-radio value="All">
                    <template v-slot:label>
                      <div>
                        <strong>
                          {{ $t("pages.assets.export.attributeOptions.all") }}
                        </strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="Selected attributes">
                    <template v-slot:label>
                      <div>
                        <strong>
                          {{
                            $t("pages.assets.export.attributeOptions.selected")
                          }}
                        </strong>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col>
                <div class="pt-6">
                  ({{
                    $t("pages.assets.export.attributeAmount", {
                      amount: allAttributesNumber,
                    })
                  }})
                </div>
                <div class="pt-2">
                  ({{
                    $t("pages.assets.export.attributeAmount", {
                      amount: selectedAttributesNumber,
                    })
                  }})
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <div class="pb-6">
              <strong>{{ $t("pages.assets.export.inFormat") }}</strong>
            </div>

            <div>
              <strong>{{ $t("pages.assets.export.exportTo") }}</strong>
            </div>
            <v-select
              id="ExportType"
              v-model="selectedExportOption"
              :items="exportOptions"
              item-text="text"
              item-value="id"
              :label="$t('pages.assets.export.exportTo')"
              solo
              dense
            ></v-select>

            <div>
              <strong>{{ $t("pages.assets.export.exportToFileType") }}</strong>
            </div>
            <v-select
              id="ExportFileType"
              v-model="selectedFileExportOption"
              :items="exportFileOptions"
              item-text="text"
              item-value="id"
              :label="$t('pages.assets.export.exportToFileType')"
              solo
              dense
              @change="setSelectedExportType"
            ></v-select>

            <template v-if="selectedFileExportOption === 2">
              <div>
                <strong>{{ $t("pages.assets.export.csvLocalization") }}</strong>
              </div>
              <v-select
                id="CsvLocalizationType"
                v-model="selectedCsvLocalizationOption"
                :items="csvLocalizationOptions"
                item-text="text"
                item-value="id"
                :label="$t('pages.assets.export.csvLocalization')"
                solo
                dense
                @change="setSelectedCsvLocalizationType"
              ></v-select>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row justify="end">
          <v-btn color="blue darken-1" text @click="show = false">
            <v-icon class="black--text">mdi-close</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.close") }}
            </p>
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="exportAssets()"
            :disabled="loading"
            :loading="exportButtonLoading"
          >
            <v-icon class="black--text">mdi-chevron-right</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.export") }}
            </p>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import pickBy from "lodash/pickBy";
import { serialize } from "object-to-formdata";

export default {
  props: {
    value: Boolean,
    dialog: Boolean,
    attributes: Array,
    portfolios: Array,
    filteredAssets: Object,
    selectedAssets: Array,
    assetLayout: Object,
    organizationId: String,
    postFilters: Object,
  },
  computed: {
    allAssetsNumber() {
      return this.filteredAssets.originalCount;
    },
    filteredAssetsNumber() {
      return this.filteredAssets.data.length;
    },
    selectedAssetsNumber() {
      return this.selectedAssets.length;
    },
    allAttributesNumber() {
      return this.attributes.length;
    },
    selectedAttributesNumber() {
      return this.assetLayout.attributeIds.length;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    const exportOptions = [{ id: 1, text: "File" }];
    const exportFileOptions = [
      { id: 1, text: "xlsx" },
      { id: 2, text: "csv" },
    ];
    const csvLocalizationOptions = [
      { id: 1, text: "US" },
      { id: 2, text: "DK" },
    ];

    return {
      selectedExportOption: 1,
      exportOptions,
      selectedFileExportOption: 1,
      exportFileOptions,
      selectedCsvLocalizationOption: 1,
      csvLocalizationOptions,
      selectedPortfolio: 1,
      loading: false,
      search: "",
      exportFileType: exportFileOptions[0].text,
      csvLocalization: csvLocalizationOptions[0].text,
      assetsSelection: "",
      attributesSelection: "",
      portfolioSelection: "",
      localPostFilters: { ...this.postFilters },
      selectedPortfolioAssetCount: 0,
      exportAllAttributes: 0,
      exportButtonLoading: false,
      selectedAssetOption: null,
    };
  },
  methods: {
    exportAssets() {
      this.exportLoading = true;
      this.exportButtonLoading = true;

      let portfolioIds = [];
      let selectedAssetIds = [];

      let page = 1;
      let pageSize = -1;

      switch (this.attributesSelection) {
        case "All":
          this.exportAllAttributes = 1;
          this.localPostFilters.allAttributes = "true";
          break;
        case "Selected attributes":
          this.exportAllAttributes = 0;
          this.localPostFilters.allAttributes = this.postFilters.allAttributes;
          break;
        default:
          this.localPostFilters.allAttributes = "true";
      }

      switch (this.assetsSelection) {
        case "All":
          this.localPostFilters.allResults = "true";
          break;
        case "Filtered assets":
          this.localPostFilters.allResults = this.postFilters.allResults;
          this.localPostFilters.search = this.postFilters.search;
          page = this.filteredAssets.currentPage;
          pageSize = this.filteredAssets.pageSize;
          break;
        case "Selected assets":
          this.localPostFilters.allResults = this.postFilters.allResults;
          this.localPostFilters.search = this.postFilters.search;
          page = this.filteredAssets.currentPage;
          pageSize = this.filteredAssets.pageSize;
          selectedAssetIds = this.getSelectedAssetIds();
          break;
        case "Specific portfolio":
          portfolioIds.push(this.portfolioSelection);
          break;
        default:
          this.localPostFilters.allResults = "true";
      }

      let allAttributeIds = [];

      if (this.exportAllAttributes === 1) {
        this.attributes.forEach((element) => {
          allAttributeIds.push(element.id);
        });
      } else {
        this.assetLayout.attributeIds.forEach((attributeId) => {
          allAttributeIds.push(attributeId);
        });
      }

      let body = {
        page,
        pageSize,
        postFilters: pickBy(this.localPostFilters),
        organizationId: this.organizationId,
        portfolioIds: portfolioIds,
        assetIds: selectedAssetIds,
        fileType: this.exportFileType,
        allAttributeIds: allAttributeIds,
        localization: this.csvLocalization,
        exportAllAttributes: this.exportAllAttributes,
      };

      fetch(this.route("assets.export"), {
        method: "POST",
        body: serialize(body, {
          indices: true,
        }),
      })
        .then(async (res) => ({
          fileName: this.getFileNameFromHeader(
            res.headers.get("content-disposition")
          ),
          contentType: res.headers.get("content-type"),
          blob: await res.blob(),
        }))
        .then((fileData) => {
          const blob = new Blob([fileData.blob], {
            type: fileData.contentType,
          });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
          } else {
            const blobUrl = URL.createObjectURL(blob);

            let link = document.createElement("a");
            link.href = blobUrl;
            link.download = fileData.fileName;
            link.click();

            setTimeout(() => {
              URL.revokeObjectURL(blobUrl);
            }, 250);
          }
        })
        .finally(() => this.finalizeExport());
    },
    finalizeExport() {
      this.exportLoading = false;
      this.exportButtonLoading = false;
    },
    // Based on: https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c
    getFileNameFromHeader(header) {
      let contentDispostion = header.split(";");
      const fileNameToken = `filename*=UTF-8''`;

      let fileName = null;
      for (let thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
          fileName = decodeURIComponent(
            thisValue.trim().replace(fileNameToken, "")
          );
          break;
        }
      }

      return fileName;
    },
    setSelectedExportType(value) {
      this.exportFileType = this.exportFileOptions.find(
        (x) => x.id === value
      ).text;
    },
    setSelectedCsvLocalizationType(value) {
      this.csvLocalization = this.csvLocalizationOptions.find(
        (x) => x.id === value
      ).text;
    },
    setAttributesSelection(value) {
      this.attributesSelection = value;
    },
    setAssetsSelection(value) {
      this.assetsSelection = value;
    },
    setSelectedPortfolioId(value) {
      this.portfolioSelection = value;
      this.selectedPortfolioAssetCount = this.portfolios.find(
        (x) => x.id === value
      ).assetCount;
      this.selectedAssetOption = `Specific portfolio`;
      this.assetsSelection = `Specific portfolio`;
    },
    getSelectedAssetIds() {
      return this.selectedAssets.map((x) => x.id);
    },
  },
};
</script>
