<template>
  <tr
    :class="`${filesDragging && onlyFileAttribute ? 'outline-drop-zone' : ''} ${
      hoveredRowId === item.id ? 'hovered' : ''
    }`"
    @dblclick="$emit('editRowClicked', item)"
    @drop="
      (e) =>
        onlyFileAttribute
          ? handleDropEvent(e, onlyFileAttribute, item)
          : undefined
    "
    @dragover="
      (e) =>
        onlyFileAttribute
          ? handleDragOverEvent(e, onlyFileAttribute, item)
          : undefined
    "
    @dragenter="
      (e) =>
        onlyFileAttribute
          ? handleDragEnterEvent(e, onlyFileAttribute, item)
          : undefined
    "
    @dragleave="
      (e) =>
        onlyFileAttribute
          ? handleDragLeaveEvent(onlyFileAttribute, item)
          : undefined
    "
  >
    <td class="text-start">
      <v-simple-checkbox
        :ripple="false"
        :value="isSelected"
        @input="() => select(!isSelected)"
      ></v-simple-checkbox>
    </td>
    <td
      v-for="header in headers"
      :key="header.value"
      class="text-start"
      :class="`${header.value === 'action' ? 'fixed' : ''}`"
    >
      <template v-if="header.value === 'createdAt'">
        {{ formatCreatedDate(item) }}
      </template>
      <template v-else-if="header.value === 'updatedAt'">
        {{ new Date(item.updatedAt).toLocaleString() }}
      </template>
      <template
        v-else-if="header.value === 'emissionsSource' && item.emissionFactor"
      >
        <a :href="item.emissionFactor.sourceLink" target="_blank">
          {{ item.emissionFactor.source }}
        </a>
      </template>
      <template v-else-if="dynamicHeaderIds.includes(header.value)">
        <div
          v-if="mappedAttributes[header.value].format == 'File'"
          class="fill-height d-flex align-center px-2"
          :class="`${
            filesDragging && !onlyFileAttribute ? 'outline-drop-zone' : ''
          } ${
            hoveredRowId === item.id && hoveredAttributeId === header.value
              ? 'hovered'
              : ''
          }
          ${isDisplaySize('Minimal') ? 'flex-wrap' : ''}`"
          :key="header.value + item.id"
          @drop="
            (e) =>
              onlyFileAttribute
                ? handleDropEvent(e, onlyFileAttribute, item)
                : handleDropEvent(e, mappedAttributes[header.value], item)
          "
          @dragover="
            (e) =>
              onlyFileAttribute
                ? handleDragOverEvent(e, onlyFileAttribute, item)
                : handleDragOverEvent(e, mappedAttributes[header.value], item)
          "
          @dragenter="
            (e) =>
              onlyFileAttribute
                ? handleDragEnterEvent(e, onlyFileAttribute, item)
                : handleDragEnterEvent(e, mappedAttributes[header.value], item)
          "
          @dragleave="
            (e) =>
              onlyFileAttribute
                ? handleDragLeaveEvent(onlyFileAttribute, item)
                : handleDragLeaveEvent(mappedAttributes[header.value], item)
          "
        >
          <template
            v-if="
              item[header.value] != null &&
              (item[header.value].fileId != null ||
                (item[header.value][fileLinkProperty] &&
                  item[header.value][fileLinkProperty].length))
            "
          >
            <file-preview
              v-if="item[header.value].file"
              :file="item[header.value].file"
              className="mx-1"
            />
            <template v-if="item[header.value][fileLinkProperty]">
              <file-preview
                v-for="fileLink in item[header.value][fileLinkProperty]"
                :key="fileLink.id"
                :file="fileLink.file"
                className="mx-1"
              />
            </template>
          </template>
        </div>
        <div
          v-else
          :key="header.value + item.id"
          class="fill-height d-flex align-center"
        >
          <span
            :class="`
              ${isDisplaySize('Minimal') ? 'minimal-attribute-column' : ''}
            `"
            :title="undefined"
          >
            {{ handleAttributeValue(item[header.value]) }}
          </span>
        </div>
      </template>
      <template v-else-if="header.value === 'action'">
        <attribute-row-action-menu
          :row="item"
          :attributes="attributes"
          :entryType="entryType"
          @editRowClicked="$emit('editRowClicked', $event)"
          @copyRowClicked="$emit('copyRowClicked', $event)"
          @destroyRowClicked="$emit('destroyRowClicked', $event)"
        />
      </template>
      <template v-else>
        {{ item[header.value] }}
      </template>
    </td>
  </tr>
</template>
<script>
import { formatAttributeLink } from "@/util/formatters";
import AttributeRowActionMenu from "./AttributeRowActionMenu.vue";
import FilePreview from "./FilePreview.vue";
import { toLocaleUTCDateStringFiltered } from "@/util/dateTime";

export default {
  components: {
    AttributeRowActionMenu,
    FilePreview,
  },
  props: {
    isSelected: Boolean,
    select: Function,
    item: Object,
    entryType: String,
    currentLayout: Object,
    onlyFileAttribute: Object,
    attributes: Array,
    headers: Array,
    filesDragging: Boolean,
    hoveredRowId: String,
    hoveredAttributeId: String,
    defaultCurrencyCode: String,
  },
  emits: [
    "hoveredRowIdUpdated",
    "hoveredAttributeIdUpdated",
    "linkFilesToRow",
    "editRowClicked",
    "copyRowClicked",
    "destroyRowClicked",
  ],
  computed: {
    fileLinkProperty() {
      switch (this.entryType) {
        case "asset":
          return "assetAttributeFileLinks";
        case "solution":
          return "solutionAttributeFileLinks";
        case "location":
          return "locationAttributeFileLinks";
        default:
          return null;
      }
    },
    mappedAttributes() {
      let mappedAttributes = [];
      this.attributes.forEach((attribute) => {
        mappedAttributes[attribute.id] = attribute;
      });
      return mappedAttributes;
    },
    dynamicHeaderIds() {
      return this.headers.filter((x) => x.dynamic).map((x) => x.value);
    },
  },
  methods: {
    formatCreatedDate(asset) {
      if (!asset?.createdAt) return "";
      return toLocaleUTCDateStringFiltered(asset.createdAt, false);
    },
    handleAttributeValue(propertyItem) {
      return formatAttributeLink(propertyItem, this.defaultCurrencyCode);
    },
    isDisplaySize(attributeDisplaySize) {
      return this.currentLayout.attributeDisplaySize === attributeDisplaySize;
    },
    handleDropEvent(event, attribute, row) {
      if (!attribute || !row) return;

      event.preventDefault();

      this.$emit("hoveredRowIdUpdated", null);
      this.$emit("hoveredAttributeIdUpdated", null);

      const selectedFileIds = event.dataTransfer.getData(
        "filemenu:selectedFileIds"
      );

      if (!selectedFileIds || selectedFileIds.trim() === "") return;

      const fileIds = JSON.parse(selectedFileIds);

      const formData = new FormData();
      fileIds.forEach((fileId, index) => {
        formData.append(`fileIds[${index}]`, fileId);
      });

      this.$emit("linkFilesToRow", row.id, attribute.id, formData);
    },
    handleDragOverEvent(event, attribute, row) {
      if (!attribute || !row) return;

      if (!event.dataTransfer.types.some((x) => x === "Files"))
        event.preventDefault();

      event.dataTransfer.dropEffect = "link";

      this.$emit("hoveredRowIdUpdated", row.id);
      this.$emit("hoveredAttributeIdUpdated", attribute.id);

      return false;
    },
    handleDragEnterEvent(event, attribute, row) {
      if (!attribute || !row) return;

      if (!event.dataTransfer.types.some((x) => x === "Files"))
        event.preventDefault();

      this.$emit("hoveredRowIdUpdated", row.id);
      this.$emit("hoveredAttributeIdUpdated", attribute.id);
    },
    handleDragLeaveEvent(attribute, row) {
      if (!attribute || !row) return;

      this.$emit("hoveredRowIdUpdated", null);
      this.$emit("hoveredAttributeIdUpdated", null);
    },
  },
};
</script>
<style scoped>
.minimal-attribute-column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
