<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">{{ $t("pages.dataTypes.index.title") }}</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row class="d-flex align-center">
      <v-col cols="6" sm="4">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          :label="$t('common.dropdowns.search.label')"
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        v-inertia
        :href="route('information-types.create')"
      >
        {{ $t("pages.dataTypes.index.create") }}</v-btn
      >
    </v-row>
    <v-data-table
      class="elevation-1 row-pointer"
      :headers="headers"
      :items="informationTypes.data"
      :server-items-length="informationTypes.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      :must-sort="true"
      @click:row="rowClick"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ new Date(item.updatedAt).toLocaleString() }}
      </template>
      <template v-slot:item.actions>
        <v-icon class="mr-2"> mdi-chevron-right </v-icon>
      </template>
      <template #footer.page-text="pageTextProps">
        {{ $t("common.dataTable.visibleRows", pageTextProps) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import { appLayout } from "@/util/layout";
import { footerProps } from "@/util/dataTable";

export default {
  layout: appLayout({ title: "pages.dataTypes.index.title" }),
  props: {
    informationTypes: Object,
  },
  data() {
    var searchParams = new URLSearchParams(window.location.search);
    return {
      headers: [
        { text: this.$t("common.fieldNames.name"), value: "name" },
        { text: this.$t("common.fieldNames.createdAt"), value: "createdAt" },
        { text: this.$t("common.fieldNames.updatedAt"), value: "updatedAt" },
        {
          text: this.$t("common.fieldNames.actions"),
          value: "actions",
          sortable: false,
          align: "right",
        },
      ],
      pagination: {
        page: this.informationTypes.currentPage,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") || "name"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
      },
      tableFooterProps: footerProps,
      filters: {
        search: searchParams.get("filters[search]"),
      },
    };
  },
  methods: {
    rowClick(item) {
      this.$inertia.get(this.route("information-types.edit", item.id));
    },
    changePage(options) {
      let query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("information-types.index", query),
        {},
        { preserveState: true }
      );
    },
  },
  watch: {
    filters: {
      handler: throttle(function () {
        this.pagination = { page: 1, sortBy: [], sortDesc: [] };
        this.changePage(this.pagination);
      }, 150),
      deep: true,
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
