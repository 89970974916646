<template>
  <v-dialog
    :value="visible"
    @input="!loading && $emit('cancel')"
    max-width="500"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          $t("components.supportCases.assignRelatedCases.title")
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="font-weight-bold">
              {{ `${selectedCase.name} [${selectedCase.referenceKey}]` }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>
              {{ $t("components.supportCases.assignRelatedCases.relatesTo") }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <form autocomplete="off" @submit.prevent="() => {}">
              <v-autocomplete
                ref="supportCaseList"
                class="flex-grow-0 pt-0 mt-0"
                :label="
                  $t('components.supportCases.assignRelatedCases.supportCases')
                "
                :items="allCases"
                :value="value"
                multiple
                @input="
                  (data) => {
                    $emit('input', data);
                  }
                "
                item-text="name"
                item-value="id"
                :search-input.sync="searchText"
              >
                <v-list-item v-if="!!searchTimeout" slot="prepend-item">
                  <v-list-item-title>{{
                    $t(
                      "components.supportCases.assignRelatedCases.loadingCases"
                    )
                  }}</v-list-item-title>
                </v-list-item>
                <v-divider v-if="!!searchTimeout" slot="prepend-item" />
                <v-list-item
                  v-if="!searchTimeout"
                  slot="append-item"
                  ripple
                  class="close-menu-button"
                  @click="closeSupportCaseListMenu"
                >
                  <v-list-item-action>
                    <v-icon>mdi-close</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{
                    $t("components.supportCases.assignRelatedCases.closeMenu")
                  }}</v-list-item-title>
                </v-list-item>
              </v-autocomplete>
            </form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="teal darken-3"
          :disabled="loading"
          text
          @click="$emit('cancel')"
        >
          {{ $t("common.actions.cancel") }}
        </v-btn>
        <v-btn
          color="teal darken-3"
          :disabled="loading"
          text
          @click="$emit('submit')"
        >
          {{ $t("common.actions.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Array,
    visible: Boolean,
    selectedCase: Object,
    allCases: Array,
    loading: Boolean,
  },
  data() {
    return {
      searchText: undefined,
      searchTimeout: null,
    };
  },
  methods: {
    setSearchTimeout() {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        let text = this.searchText;

        if (!text) {
          text = null;
        } else {
          text = this.searchText.replace(/[#/]/g, "");
        }

        this.$emit("filter-input", text);

        this.searchTimeout = null;
      }, 1000);
    },
    closeSupportCaseListMenu() {
      this.$refs.supportCaseList.isMenuActive = false;
      this.$refs.supportCaseList.blur();
    },
  },
  watch: {
    searchText() {
      this.setSearchTimeout();
    },
  },
};
</script>
