<template>
  <v-dialog :value="visible" @input="$emit('cancel')" max-width="500">
    <v-card>
      <v-card-title>
        <span class="headline">{{
          $t("components.supportCases.settings.title")
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="colorTimerForm"
          lazy-validation
          @submit.prevent="$emit('submit', supportCaseColorTimer)"
        >
          <p>{{ $t("components.supportCases.settings.noResponseSupport") }}</p>
          <div class="d-flex justify-space-between">
            {{ $t("components.supportCases.settings.hoursUntilYellow") }}
            <input
              type="number"
              v-model="supportCaseColorTimer.supportTransition1"
            />
          </div>
          <div class="d-flex justify-space-between">
            {{ $t("components.supportCases.settings.hoursUntilRed") }}
            <input
              type="number"
              v-model="supportCaseColorTimer.supportTransition2"
            />
          </div>
          <p class="mt-2">
            {{ $t("components.supportCases.settings.noResponseCustomer") }}
          </p>
          <div class="d-flex justify-space-between">
            {{ $t("components.supportCases.settings.hoursUntilYellow") }}
            <input
              type="number"
              v-model="supportCaseColorTimer.customerTransition1"
            />
          </div>
          <div class="d-flex justify-space-between">
            {{ $t("components.supportCases.settings.hoursUntilRed") }}
            <input
              type="number"
              v-model="supportCaseColorTimer.customerTransition2"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="teal darken-3" text @click="$emit('cancel')">
          {{ $t("common.actions.cancel") }}
        </v-btn>
        <v-btn color="teal darken-3" text @click="submit()">
          {{ $t("common.actions.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    settings: Object,
    visible: Boolean,
  },
  data() {
    return {
      supportCaseColorTimer: this.settings
        ? this.settings
        : {
            supportTransition1: 0,
            supportTransition2: 0,
            customerTransition1: 0,
            customerTransition2: 0,
          },
    };
  },
  methods: {
    submit() {
      this.supportCaseColorTimer = {
        supportTransition1:
          parseInt(this.supportCaseColorTimer.supportTransition1) || 0,
        supportTransition2:
          parseInt(this.supportCaseColorTimer.supportTransition2) || 0,
        customerTransition1:
          parseInt(this.supportCaseColorTimer.customerTransition1) || 0,
        customerTransition2:
          parseInt(this.supportCaseColorTimer.customerTransition2) || 0,
      };
      this.$emit("submit", this.supportCaseColorTimer);
    },
  },
};
</script>
