<template>
  <div class="userTagMarkdownEditor">
    <Mentionable :keys="['@']" :items="userDict" offset="6">
      <v-textarea
        ref="textarea"
        :label="label"
        type="text"
        rows="3"
        no-details
        outlined
        hide-details
        :value="value"
        @input="onTextareaUpdate"
        append-outer-icon="mdi-send"
        @click:append-outer="$emit('submit')"
        @keydown.enter="() => sendOnEnter && $emit('submit')"
        :disabled="disabled"
      />
      <template #no-result>
        <div class="dim">{{ $t("components.mentionInput.noResult") }}</div>
      </template>

      <template #item-@="{ item }">
        <div class="user">
          {{ item.name }}
          <span class="dim"> ({{ item.email }}) </span>
        </div>
      </template>
    </Mentionable>
  </div>
</template>

<script>
import { Mentionable } from "vue-mention";

export default {
  name: "user-tag-v-textarea",
  components: {
    Mentionable,
  },
  props: {
    label: String,
    value: String,
    disabled: Boolean,
    users: Array,
    sendOnEnter: Boolean,
  },
  methods: {
    buildUserDictionary() {
      this.users.forEach((user) => {
        this.userDict[user.initials.toUpperCase()] = user;
      });
    },
    onTextareaUpdate(newVal) {
      this.$emit("input", newVal);
    },
  },
  computed: {
    userDict() {
      return this.users
        ? this.users.map((user) => ({
            ...user,
            value: user.name.replaceAll(" ", "") + "[" + user.email + "]",
          }))
        : [];
    },
  },
};
</script>

<style scoped></style>
