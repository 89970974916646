<template>
  <v-menu bottom offset-y>
    <template #activator="{ on: onMenu, attrs: attrsMenu }">
      <v-tooltip top>
        <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
          <v-btn
            x-small
            text
            v-bind="{ ...attrsTooltip, ...attrsMenu }"
            v-on="{ ...onTooltip, ...onMenu }"
          >
            <v-icon>mdi-vector-square</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("components.fileMenu.iconSizes.title") }}</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item-group
        :value="value"
        @change="handleMenuItemSelected"
        mandatory
      >
        <v-list-item
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          <template #default="{ active }">
            <v-list-item-icon>
              <v-icon>{{ option.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ option.text }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: Number,
  },
  emits: ["input"],
  data() {
    return {
      options: [
        {
          text: this.$t("components.fileMenu.iconSizes.small"),
          value: 4,
          icon: "mdi-numeric-4-box-multiple-outline",
        },
        {
          text: this.$t("components.fileMenu.iconSizes.medium"),
          value: 3,
          icon: "mdi-numeric-3-box-multiple-outline",
        },
        {
          text: this.$t("components.fileMenu.iconSizes.large"),
          value: 1,
          icon: "mdi-numeric-1-box-multiple-outline",
        },
      ],
    };
  },
  methods: {
    handleMenuItemSelected(value) {
      this.$emit("input", value ?? this.value);
    },
  },
};
</script>
