<template>
  <v-row dense>
    <v-col v-if="hideLabel === undefined" cols="4" align-self="center">
      {{ inputLabel + ":" }}
    </v-col>

    <!-- BOOLEAN -->
    <v-col
      v-if="attribute.format == 'Boolean' && !selectOptions"
      align-self="center"
    >
      <v-select
        :key="rerenderCounter"
        v-model="currentValue"
        :items="boolValueOptions"
        hide-details
        clearable
        item-text="text"
        item-value="value"
        dense
        solo
        @change="$emit('bool_changed', $event)"
      ></v-select>
    </v-col>
    <!-- /BOOLEAN -->

    <!-- CURRENCY -->
    <v-col
      v-if="attribute.format == 'Currency' && !selectOptions"
      align-self="center"
    >
      <v-row dense align="center">
        <v-col cols="6" align-self="center">
          <v-autocomplete
            :key="rerenderCounter"
            dense
            hide-details
            v-model="currentCurrencyCode"
            :items="currencies"
            clearable
            hide-selected
            item-text="currencyCode"
            item-value="currencyCode"
            solo
            @change="handleCurrencyCodeChanged"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="6" align-self="center">
          <v-text-field
            :key="rerenderCounter"
            type="number"
            hide-spin-buttons
            hide-details
            :value="currentValue"
            @input="
              ($value) =>
                (currentValue = isNaN($value) ? null : parseFloat($value))
            "
            clearable
            solo
            dense
            @change="$emit('currency_changed', $event)"
          />
        </v-col>
      </v-row>
    </v-col>
    <!-- /CURRENCY -->

    <!-- DATE -->
    <v-col
      v-if="attribute.format == 'Date' && !selectOptions"
      align-self="center"
    >
      <date-picker
        :lang="lang"
        :key="rerenderCounter"
        style="width: full"
        :appendToBody="false"
        v-model="currentValue"
        type="date"
        :disabled-date="disabledDate"
        @change="$emit('dateTime_changed', $event)"
      >
      </date-picker>
    </v-col>
    <v-col v-if="attribute.format == 'Date'" align-self="center">
      <span>{{ $t("pages.assets.form.dateFormat") }}</span>
    </v-col>
    <!-- /DATE -->

    <!-- DATETIME -->
    <v-col
      v-if="attribute.format == 'DateTime' && !selectOptions"
      align-self="center"
    >
      <date-picker
        :lang="lang"
        :key="rerenderCounter"
        style="width: full"
        :appendToBody="false"
        v-model="currentValue"
        type="datetime"
        :disabled-date="disabledDate"
        @change="$emit('dateTime_changed', $event)"
      >
      </date-picker>
    </v-col>
    <v-col v-if="attribute.format == 'DateTime'" align-self="center">
      <span>{{ $t("pages.assets.form.dateTimeFormat") }}</span>
    </v-col>
    <!-- /DATETIME -->

    <!-- NUMBER -->
    <v-col
      v-if="attribute.format == 'Number' && !selectOptions"
      align-self="center"
    >
      <v-text-field
        ref="numberInput"
        :key="rerenderCounter"
        dense
        hide-spin-buttons
        hide-details
        :value="currentValue"
        @input="($value) => handleNumberInputEvent($value)"
        clearable
        type="number"
        solo
      >
      </v-text-field>
    </v-col>
    <!-- /NUMBER -->

    <!-- PERCENTAGE -->
    <v-col
      v-if="attribute.format == 'Percentage' && !selectOptions"
      align-self="center"
    >
      <v-text-field
        :key="rerenderCounter"
        dense
        hide-details
        :value="currentValue"
        @input="
          ($value) => (currentValue = isNaN($value) ? null : parseFloat($value))
        "
        clearable
        type="number"
        solo
        hide-spin-buttons
        append-icon="mdi-percent-outline"
        @change="$emit('decimal_changed', $event)"
      >
      </v-text-field>
    </v-col>
    <!-- /PERCENTAGE -->

    <!-- SELECT -->
    <v-col
      v-if="attribute.format == 'Select' || selectOptions"
      align-self="center"
    >
      <v-autocomplete
        :key="rerenderCounter"
        dense
        hide-details
        v-model="currentValue"
        :items="selectDropdownOptions"
        clearable
        hide-selected
        :item-text="itemText"
        :item-value="itemValue"
        solo
        @change="$emit('select_changed', $event)"
      >
      </v-autocomplete>
    </v-col>
    <!-- /SELECT -->

    <!-- TEXT -->
    <v-col
      v-if="attribute.format == 'Text' && !selectOptions"
      align-self="center"
    >
      <v-text-field
        :key="rerenderCounter"
        dense
        hide-details
        v-model="currentValue"
        clearable
        type="text"
        solo
        @change="$emit('string_changed', $event)"
      >
      </v-text-field>
    </v-col>
    <!-- /TEXT -->

    <!-- TEXTAREA -->
    <v-col
      v-if="attribute.format == 'TextArea' && !selectOptions"
      align-self="center"
    >
      <v-textarea
        :key="rerenderCounter"
        dense
        hide-details
        v-model="currentValue"
        clearable
        type="text"
        solo
        @change="$emit('string_changed', $event)"
        no-resize
      >
      </v-textarea>
    </v-col>
    <!-- /TEXTAREA -->

    <!-- PHONENUMBER -->
    <v-col
      v-if="attribute.format === 'PhoneNumber' && !selectOptions"
      align-self="center"
    >
      <maz-phone-number-input
        :key="rerenderCounter"
        v-model="currentValue"
        defaultCountryCode="DK"
        clearable
        size="sm"
        @update="({ e164 }) => $emit('string_changed', e164)"
      />
    </v-col>
    <!-- /PHONENUMBER -->

    <!-- FILE -->
    <v-col
      v-if="attribute.format == 'File' && !selectOptions"
      align-self="center"
    >
      <div v-if="currentSecondValue !== null" class="d-flex flex-column">
        <div
          v-for="file in currentSecondValue"
          :key="file.id"
          class="d-flex flex-row align-center mb-1"
        >
          <a
            class="text-decoration-none"
            :href="
              route('api.files.content', {
                id: file.id,
                filename: file.originalName,
              })
            "
            target="_blank"
          >
            <v-icon>mdi-attachment</v-icon>
            {{ file.originalName }}
          </a>
          <v-btn
            v-if="attribute.multipleFiles"
            icon
            small
            class="ml-2"
            @click="() => $emit('file_removed', file.id)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </div>
      <v-file-input
        :key="rerenderCounter"
        prepend-icon=""
        :multiple="attribute.multipleFiles"
        dense
        hide-details
        v-model="currentValue"
        show-size
        :rules="fileSizeRules"
        solo
        @change="$emit('file_changed', $event)"
      />
    </v-col>
    <!-- /FILE -->
  </v-row>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { DatePicker },
  props: [
    "value",
    "secondValue",
    "attribute",
    "currencies",
    "selectOptions",
    "integerInput",
    "numberMinValue",
    "numberMaxValue",
    "hideLabel",
    "customLabel",
    "selectItemText",
    "selectItemValue",
    "disabledDate",
    "fieldRerenderCounter",
  ],
  emits: [
    "file_changed",
    "string_changed",
    "currency_changed",
    "currencyCode_changed",
    "select_changed",
    "decimal_changed",
    "dateTime_changed",
    "bool_changed",
  ],
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      boolValueOptions: [
        { value: true, text: "true" },
        { value: false, text: "false" },
      ],
      fileSizeRules: [
        (value) =>
          !value ||
          value.size < 30000000 ||
          "File size should be less than 26 MB",
      ],
      rerenderCounter: 0,
    };
  },
  computed: {
    defaultCurrencyId() {
      return this.$inertia.page.props.auth.settings.defaultCurrencyId;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.defaultCurrencyId
      );

      return currency?.currencyCode;
    },
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentSecondValue: {
      get() {
        return this.secondValue;
      },
      set(value) {
        this.$emit("input_secondValue", value);
      },
    },
    currentCurrencyCode: {
      get() {
        if (
          this.defaultCurrencyCode &&
          (!this.secondValue || this.secondValue.trim() === "")
        )
          return this.defaultCurrencyCode;

        return this.secondValue;
      },
      set(value) {
        if (value === this.defaultCurrencyCode) {
          this.currentSecondValue = null;
          return;
        }

        this.currentSecondValue = value;
      },
    },
    selectDropdownOptions() {
      if (!this.selectOptions && !this.attribute.attributeSelectOptions)
        return [];

      if (this.selectOptions) return this.selectOptions;

      return this.attribute.attributeSelectOptions;
    },
    inputLabel() {
      return this.customLabel ?? this.attribute?.name ?? "";
    },
    itemText() {
      return this.selectItemText ?? "value";
    },
    itemValue() {
      return this.selectItemValue ?? "id";
    },
  },
  methods: {
    handleCurrencyCodeChanged(value) {
      if (value === this.defaultCurrencyCode) {
        this.$emit("currencyCode_changed", null);
        return;
      }

      this.$emit("currencyCode_changed", value);
    },
    handleNumberInputEvent(value) {
      if (isNaN(value) || value === "" || value === null) {
        this.setCurrentValue(null);
        this.$emit("decimal_changed", null);
        return;
      }

      const parsedValue = this.integerInput
        ? parseInt(value)
        : parseFloat(value);

      if (this.numberMinValue !== undefined) {
        const minValue = this.integerInput
          ? parseInt(this.numberMinValue)
          : parseFloat(this.numberMinValue);

        const isLowerThanMin = parsedValue < minValue;

        if (isLowerThanMin) {
          this.setCurrentValue(minValue);
          this.$emit("decimal_changed", minValue);
          return;
        }
      }

      if (this.numberMaxValue !== undefined) {
        const maxValue = this.integerInput
          ? parseInt(this.numberMaxValue)
          : parseFloat(this.numberMaxValue);

        const isHigherThanMax = parsedValue > maxValue;

        if (isHigherThanMax) {
          this.setCurrentValue(maxValue);
          this.$emit("decimal_changed", maxValue);
          return;
        }
      }

      this.setCurrentValue(parsedValue);
      this.$emit("decimal_changed", parsedValue);
    },
    setCurrentValue(value) {
      this.currentValue = value;

      this.rerenderCounter++;

      this.$nextTick(() => {
        this.$refs.numberInput.focus();
      });
    },
  },
  watch: {
    fieldRerenderCounter() {
      this.rerenderCounter++;
    },
  },
};
</script>
