<template>
  <v-menu bottom offset-y :disabled="disabled">
    <template #activator="{ on: onMenu, attrs: attrsMenu }">
      <v-tooltip top>
        <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
          <v-btn
            x-small
            text
            v-bind="{ ...attrsTooltip, ...attrsMenu }"
            v-on="{ ...onTooltip, ...onMenu }"
          >
            <v-icon>
              {{
                value.descending ? "mdi-sort-descending" : "mdi-sort-ascending"
              }}
            </v-icon>
          </v-btn>
        </template>
        <span
          >{{ value.displayName }} -
          {{
            value.descending
              ? $t("common.sorting.descending")
              : $t("common.sorting.ascending")
          }}</span
        >
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item-group :value="value.sortBy" @change="handleMenuItemSelected">
        <v-list-item
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          <v-list-item-action>
            <v-checkbox
              :input-value="option.value === value.sortBy"
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ option.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: Object,
    disabled: Boolean,
  },
  emits: ["input"],
  data() {
    return {
      options: [
        {
          text: this.$t("components.fileMenu.sortOptions.name"),
          value: "name",
        },
        {
          text: this.$t("components.fileMenu.sortOptions.fileType"),
          value: "filetype",
        },
        {
          text: this.$t("components.fileMenu.sortOptions.uploaded"),
          value: "uploaded",
        },
        {
          text: this.$t("components.fileMenu.sortOptions.size"),
          value: "size",
        },
      ],
    };
  },
  methods: {
    handleMenuItemSelected(sortBy) {
      const selectedSortBy = sortBy ?? this.value.sortBy;

      const selectedOption = this.options.find(
        (x) => x.value === selectedSortBy
      );

      const descending =
        selectedSortBy === this.value.sortBy ? !this.value.descending : false;

      this.$emit("input", {
        sortBy: selectedSortBy,
        displayName: selectedOption.text,
        descending,
      });
    },
  },
};
</script>
