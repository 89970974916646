<template>
  <div>
    <h4 class="text-h4">
      {{ $t("pages.dataTypes.edit.title") }} {{ form.name }}
    </h4>
    <v-spacer class="mb-6"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col md="8" lg="6">
          <form
            @submit.prevent="
              form.put(route('information-types.update', informationType.id), {
                preserveScroll: true,
                preserveState: false,
              })
            "
          >
            <v-card elevation="1">
              <v-card-text>
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.name")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      name="name"
                      type="text"
                      :error-messages="form.errors.name"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.dataTypes.edit.defaultVisibility")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-radio-group
                      v-model="form.defaultVisibility"
                      row
                      :error-messages="form.errors.defaultVisibility"
                    >
                      <v-radio
                        v-for="visibility in visibilities"
                        :key="visibility"
                        :label="$t(`common.visibilities.${visibility}`)"
                        :value="visibility"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.lastUpdated")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="updatedAt"
                      name="updatedAt"
                      disabled
                      type="text"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.createdAt")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="createdAt"
                      name="createdAt"
                      disabled
                      type="text"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <flash-messages />
                <v-btn
                  text
                  color="error"
                  :disabled="sending || form.processing"
                  @click="destroy"
                  >{{ $t("common.actions.delete") }}</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  outlined
                  color="primary"
                  :disabled="sending || form.processing"
                  >{{ $t("common.actions.save") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import { pascalToSpaces } from "@/util/text";
import FlashMessages from "@/Shared/FlashMessages";

export default {
  layout: appLayout({ title: "pages.dataTypes.edit.pageTitle" }),
  props: {
    informationType: Object,
    visibilities: Array,
  },
  components: {
    FlashMessages,
  },
  data() {
    return {
      sending: false,
      form: this.$inertia.form({
        name: this.informationType.name,
        defaultVisibility: this.informationType.defaultVisibility,
      }),
    };
  },
  computed: {
    createdAt() {
      return new Date(this.informationType.createdAt).toLocaleString();
    },
    updatedAt() {
      return new Date(this.informationType.createdAt).toLocaleString();
    },
  },
  methods: {
    destroy() {
      if (window.confirm(this.$t("pages.dataTypes.edit.confirmDelete"))) {
        this.$inertia.delete(
          this.route("information-types.destroy", this.informationType.id),
          {
            onStart: () => (this.sending = true),
            onFinish: () => (this.sending = false),
          }
        );
      }
    },
    pascalToSpaces(text) {
      return pascalToSpaces(text);
    },
  },
};
</script>
