<template>
  <div>
    <v-menu bottom left offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mb-n4"
          text
          color="teal darken-3"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="teal darken-3">mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="openColorTimerDialog">
          <v-list-item-title>{{
            $t("components.supportCases.settings.menu.colorTimers")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <setting-color-timer-dialog
      ref="emailDialog"
      :visible="colorTimerDialogVisible"
      @cancel="colorTimerDialogVisible = false"
      @submit="updateSupportCaseColorTimer"
      :settings="colorTimerSetting"
    />
  </div>
</template>
<script>
import SettingColorTimerDialog from "@/Components/SupportCase/SettingColorTimerDialog";

export default {
  components: {
    SettingColorTimerDialog,
  },
  props: {
    colorTimerSetting: Object,
  },
  data() {
    return {
      colorTimerDialogVisible: false,
    };
  },
  methods: {
    openColorTimerDialog() {
      this.$inertia.reload({ only: ["colorTimerSetting"] });
      this.colorTimerDialogVisible = true;
    },
    updateSupportCaseColorTimer(setting) {
      this.updateSettingsLoading = true;

      this.$inertia.post(
        this.route("support-cases.support-case-color-timer"),
        setting,
        {
          only: ["colorTimerSetting"],
          onFinish: () => {
            this.updateSettingsLoading = false;
            this.colorTimerDialogVisible = false;
          },
        }
      );
    },
  },
};
</script>
