<template>
  <div>
    <v-menu bottom left offset-y :disabled="disabled" style="z-index: 11">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="teal darken-3" v-bind="attrs" v-on="on">
          {{ $t("components.supportCases.actionMenu.assign.title") }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="openLocationDialog">
          <v-list-item-title>{{
            $t("components.supportCases.actionMenu.assign.location")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!selectedCase.locationId"
          @click="openSolutionDialog"
        >
          <v-list-item-title>{{
            $t("components.supportCases.actionMenu.assign.solution")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!selectedCase.solutionId"
          @click="openAssetDialog"
        >
          <v-list-item-title>{{
            $t("components.supportCases.actionMenu.assign.asset")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!selectedCase.locationId"
          @click="openContactDialog"
        >
          <v-list-item-title>{{
            $t("components.supportCases.actionMenu.assign.contact")
          }}</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item @click="manualContactEmailDialogVisible = true">
          <v-list-item-title>Assign contact email</v-list-item-title>
        </v-list-item> -->
        <v-list-item @click="openRelatedCasesDialog">
          <v-list-item-title>{{
            $t("components.supportCases.actionMenu.assign.relatedCases")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="openRegionDialog"
          :disabled="!selectedCase.isSupportRegionFallback"
        >
          <v-list-item-title>{{
            $t("components.supportCases.actionMenu.assign.supportRegion")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <assign-dialog
      :headline="$t('components.supportCases.actionMenu.assign.location')"
      :visible="locationDialogVisible"
      :filter-items="organizations"
      :filter-type="$t('components.supportCases.actionMenu.types.organization')"
      :filter-value="selectedOrganizationId"
      :items="locations"
      :item-type="$t('components.supportCases.actionMenu.types.location')"
      :supportCaseId="selectedCase.id"
      v-model="selectedLocationId"
      @cancel="locationDialogVisible = false"
      @submit="changeLocation"
      @filter-input="filterChanged"
    />
    <assign-dialog
      :headline="$t('components.supportCases.actionMenu.assign.solution')"
      :visible="solutionDialogVisible"
      :items="solutions"
      :item-type="$t('components.supportCases.actionMenu.types.solution')"
      v-model="selectedSolutionId"
      @cancel="solutionDialogVisible = false"
      @submit="(solutionId) => submit({ solutionId })"
    />
    <assign-dialog
      :headline="$t('components.supportCases.actionMenu.assign.asset')"
      :visible="assetDialogVisible"
      :items="mappedAssets"
      :item-type="$t('components.supportCases.actionMenu.types.asset')"
      v-model="selectedAssetId"
      @cancel="assetDialogVisible = false"
      @submit="(assetId) => submit({ assetId })"
    />
    <assign-dialog
      :headline="$t('components.supportCases.actionMenu.assign.supportRegion')"
      :visible="regionDialogVisible"
      :items="regions"
      :item-type="$t('components.supportCases.actionMenu.types.region')"
      v-model="selectedRegionId"
      @cancel="regionDialogVisible = false"
      @submit="(supportRegionId) => submit({ supportRegionId })"
    />
    <assign-dialog
      :headline="$t('components.supportCases.actionMenu.assign.contact')"
      :visible="contactDialogVisible"
      :items="employees"
      :item-type="$t('components.supportCases.actionMenu.types.contact')"
      v-model="selectedUserId"
      @cancel="contactDialogVisible = false"
      @submit="(userId) => submit({ userId })"
    />
    <!-- <assign-email-dialog
      ref="emailDialog"
      :visible="manualContactEmailDialogVisible"
      v-model="selectedContactEmail"
      @cancel="manualContactEmailDialogVisible = false"
      @submit="changeManualContactEmail"
    /> -->
    <assign-related-cases-dialog
      :visible="relatedCasesDialogVisible"
      :all-cases="allCases"
      :selected-case="selectedCase"
      :loading="assignRelationsLoading"
      v-model="selectedRelatedCases"
      @cancel="relatedCasesDialogVisible = false"
      @submit="assignRelations"
      @filter-input="allCasesSearchChanged"
    />
  </div>
</template>
<script>
import { objectToFormData } from "@/util/formData";

import AssignDialog from "@/Components/SupportCase/AssignDialog";
import AssignRelatedCasesDialog from "./AssignRelatedCasesDialog.vue";
import { fixedAttributes } from "../../util/fixedAssetData";

export default {
  components: {
    AssignDialog,
    AssignRelatedCasesDialog,
  },
  props: {
    organizations: Array,
    solutions: Array,
    locations: Array,
    assets: Array,
    regions: Array,
    employees: Array,
    allCases: Array,
    selectedCase: Object,
    disabled: Boolean,
  },
  data() {
    return {
      selectedOrganizationId: null,
      selectedLocationId: null,
      selectedSolutionId: null,
      selectedAssetId: null,
      selectedUserId: null,
      selectedRegionId: null,

      selectedContactEmail: null,

      selectedRelatedCases: [],

      locationDialogVisible: false,
      solutionDialogVisible: false,
      assetDialogVisible: false,
      contactDialogVisible: false,
      manualContactEmailDialogVisible: false,
      relatedCasesDialogVisible: false,
      regionDialogVisible: false,

      assignRelationsLoading: false,
    };
  },
  computed: {
    mappedAssets() {
      if (!this.assets) return [];

      return this.assets.map((x) => ({
        id: x.id,
        name:
          x.assetAttributeLinks?.find(
            (y) => y.attributeId === fixedAttributes.masterDataNameId
          )?.stringValue ?? this.$t("common.placeholders.noAssetName"),
      }));
    },
  },
  methods: {
    openLocationDialog() {
      this.selectedOrganizationId = this.selectedCase.organizationId;
      this.selectedLocationId = this.selectedCase.locationId;

      this.filterChanged(this.selectedOrganizationId);

      this.locationDialogVisible = true;
    },
    openSolutionDialog() {
      this.$inertia.reload({ only: ["solutions"] });

      this.selectedSolutionId = this.selectedCase.solutionId;
      this.solutionDialogVisible = true;
    },
    openAssetDialog() {
      this.$inertia.reload({ only: ["assets"] });

      this.selectedAssetId = this.selectedCase.assetId;
      this.assetDialogVisible = true;
    },
    openContactDialog() {
      this.$inertia.reload({ only: ["employees"] });

      this.selectedUserId = this.selectedCase.userId;
      this.contactDialogVisible = true;
    },
    openRelatedCasesDialog() {
      this.$inertia.reload({ only: ["allCases"] });

      this.relatedCasesDialogVisible = true;
    },
    openRegionDialog() {
      this.$inertia.reload({ only: ["regions"] });

      this.regionDialogVisible = true;
    },
    changeLocation() {
      const validLocationSelected = this.locations.some(
        (x) => x.id === this.selectedLocationId
      );
      const validOrganizationSelected = this.organizations.some(
        (x) => x.id === this.selectedOrganizationId
      );

      if (!validLocationSelected && !validOrganizationSelected) {
        alert(this.$t("components.supportCases.actionMenu.selectLocation"));
        return;
      }

      const isExistingSelection =
        this.selectedCase.locationId === this.selectedLocationId &&
        this.selectedCase.organizationId === this.selectedOrganizationId;

      if (
        isExistingSelection ||
        confirm(this.$t("components.supportCases.actionMenu.changeLocation"))
      ) {
        this.submit({
          locationId: this.selectedLocationId,
          organizationId: this.selectedOrganizationId,
        });
      }
    },
    submit(updateObject) {
      this.$emit("update-case", updateObject);

      this.locationDialogVisible = false;
      this.solutionDialogVisible = false;
      this.assetDialogVisible = false;
      this.contactDialogVisible = false;
      this.regionDialogVisible = false;

      this.manualContactEmailDialogVisible = false;
      this.selectedContactEmail = null;
    },
    filterChanged(organizationId) {
      this.selectedOrganizationId = organizationId;
      this.selectedLocationId = null;

      this.$emit("apply-organization-filter", this.selectedOrganizationId);
    },
    changeManualContactEmail() {
      if (this.$refs.emailDialog.$refs.emailForm.validate()) {
        this.submit({ manualContactEmail: this.selectedContactEmail });
      }
    },
    allCasesSearchChanged(searchText) {
      this.$emit("apply-all-cases-search-filter", searchText);
    },
    assignRelations() {
      this.assignRelationsLoading = true;

      let body = {
        relatedCaseIds: this.selectedRelatedCases,
      };

      this.$inertia.post(
        this.route("support-cases.assign-relations", this.selectedCase.id),
        objectToFormData(body),
        {
          only: ["selectedCase"],
          onFinish: () => {
            this.assignRelationsLoading = false;

            this.selectedRelatedCases = [];

            this.relatedCasesDialogVisible = false;
          },
        }
      );
    },
  },
};
</script>
