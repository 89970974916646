<template>
  <div class="d-flex fill-height flex-column">
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">{{ $t("pages.supportCases.index.title") }}</h4>
      <v-spacer></v-spacer>
      <v-btn
        class="mb-n4"
        text
        color="primary"
        v-inertia
        :href="route('support-cases.create')"
      >
        {{ $t("pages.supportCases.index.create") }}
      </v-btn>
      <support-case-setting-menu :colorTimerSetting="colorTimerSetting" />
    </div>
    <v-row>
      <v-col :cols="12" :md="3">
        <support-case-list
          :auth="auth"
          :supportCases="supportCases"
          :selectedCase="selectedCaseProxy"
          :inactivityIconInHours="inactivityIconInHours"
          :colorTimerSetting="colorTimerSetting"
        />
      </v-col>
      <v-col
        v-if="!selectedCaseProxy"
        :cols="12"
        :md="9"
        class="d-flex flex-column fill-height"
      >
        <v-sheet
          color="white"
          class="d-flex justify-center align-center fill-height"
        >
          <h4 class="text-h4 px-4 text-center">
            {{
              isMobileLayout
                ? $t("pages.supportCases.index.supportCaseAbove")
                : $t("pages.supportCases.index.supportCaseLeft")
            }}
          </h4>
        </v-sheet>
      </v-col>
      <v-col v-else :cols="12" :md="6" class="d-flex flex-column">
        <support-case-conversations
          :organizations="organizations"
          :selectedCase="selectedCaseProxy"
          :statuses="statuses"
          :users="users"
          :employees="employees"
          :locations="locations"
          :solutions="solutions"
          :assets="assets"
          :regions="regions"
          :allCases="allCases"
          :regionMails="regionMails"
          @editCase="editCase"
          @setSelectedCaseStatus="handleSelectedCaseStatusChanged"
        />
      </v-col>
      <v-col v-if="selectedCase" :cols="12" :md="3" class="d-flex flex-column">
        <support-case-sidebar
          :selectedCase="selectedCaseProxy"
          :isMobileLayout="isMobileLayout"
          :rootCauses="rootCauses"
          @editCase="editCase"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SupportCaseList from "@/Components/SupportCase/List";
import SupportCaseSidebar from "@/Components/SupportCase/Sidebar";
import SupportCaseConversations from "@/Components/SupportCase/Conversations";
import SupportCaseSettingMenu from "@/Components/SupportCase/SettingMenu";

import { appLayout } from "@/util/layout";

export default {
  layout: appLayout({ title: "pages.supportCases.index.title" }),
  components: {
    SupportCaseList,
    SupportCaseSidebar,
    SupportCaseConversations,
    SupportCaseSettingMenu,
  },
  data() {
    return {
      selectedCaseProxy: this.selectedCase,
    };
  },
  props: {
    supportCases: Object,
    selectedCase: Object,
    users: Array,
    employees: Array,
    statuses: Array,
    auth: Object,
    organizations: Array,
    locations: Array,
    solutions: Array,
    assets: Array,
    regions: Array,
    allCases: Array,
    inactivityIconInHours: Number,
    colorTimerSetting: Object,
    rootCauses: Array,
    regionMails: Array,
  },
  computed: {
    isMobileLayout() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  created() {
    this.$inertia.reload({ only: ["users", "statuses"] });
  },
  methods: {
    editCase(updateParams) {
      this.editForm = this.$inertia.form({
        ...this.selectedCase,
        ...updateParams,
      });

      this.editForm.put(
        this.route("support-cases.update", this.selectedCase.id),
        {
          preserveState: true,
          only: ["selectedCase", "supportCases"],
        }
      );
    },
    handleSelectedCaseStatusChanged(value) {
      if (!this.selectedCaseProxy) return;

      this.selectedCaseProxy.status = value;
    },
  },
  watch: {
    selectedCase: function (value) {
      this.selectedCaseProxy = value;
    },
  },
};
</script>
