<template>
  <v-tab-item>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <attribute-link-input
              class="mb-4"
              :attribute="countryAttribute"
              :value="countryData"
              :selectOptions="countries"
              @input="updateData(countryAttribute.id, $event)"
              @select_changed="updateStringValue(countryAttribute, $event)"
            />
            <attribute-link-input
              class="mb-4"
              :attribute="addressLine1Attribute"
              :value="addressLine1Data"
              @input="updateData(addressLine1Attribute.id, $event)"
              @string_changed="updateStringValue(addressLine1Attribute, $event)"
            />
            <attribute-link-input
              class="mb-4"
              :attribute="addressLine2Attribute"
              :value="addressLine2Data"
              @input="updateData(addressLine2Attribute.id, $event)"
              @string_changed="updateStringValue(addressLine2Attribute, $event)"
            />
            <v-row dense class="mb-4">
              <v-col cols="4" align-self="center">
                {{ zipAndCityLabel }}:
              </v-col>
              <v-col>
                <v-row dense>
                  <v-col cols="5">
                    <v-text-field
                      dense
                      hide-details
                      :value="postalCodeData"
                      clearable
                      type="text"
                      solo
                      @input="updateData(postalCodeAttribute.id, $event)"
                      @change="updateStringValue(postalCodeAttribute, $event)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col offset="1" cols="6">
                    <v-text-field
                      dense
                      hide-details
                      :value="cityData"
                      clearable
                      type="text"
                      solo
                      @input="updateData(cityAttribute.id, $event)"
                      @change="updateStringValue(cityAttribute, $event)"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <attribute-link-input
              :attribute="stateAttribute"
              :value="stateData"
              @input="updateData(stateAttribute.id, $event)"
              @string_changed="updateStringValue(stateAttribute, $event)"
            />
          </v-col>
          <v-col cols="5" offset="1">
            <GmapMap
              class="fill-height"
              style="aspect-ratio: 1/1"
              :center="centerCoords"
              :zoom="zoomLevel"
              :options="googleMapOptions"
              @zoom_changed="zoomLevel = $event"
            >
              <GmapCircle
                v-if="circleCoords"
                :radius="circleRadius"
                :center="circleCoords"
                strokeColor="white"
                :strokeWeight="1"
                fillColor="#82CA3F"
                :fillOpacity="1"
              />
            </GmapMap>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>
<script>
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import {
  fixedAttributes,
  fixedFieldAttributes,
} from "../../../util/fixedLocationData";
import mapTheme from "../../../mapTheme";

export default {
  components: { AttributeLinkInput },
  props: {
    attributes: Array,
    attributeLinkData: Object,
    countries: Array,
  },
  data() {
    return { manualZoomLevel: null };
  },
  computed: {
    countryAttribute() {
      return this.getAttribute(fixedAttributes.addressCountryId);
    },
    countryData() {
      return this.attributeLinkData[fixedAttributes.addressCountryId];
    },
    addressLine1Attribute() {
      return this.getAttribute(fixedAttributes.addressAddressLine1Id);
    },
    addressLine1Data() {
      return this.attributeLinkData[fixedAttributes.addressAddressLine1Id];
    },
    addressLine2Attribute() {
      return this.getAttribute(fixedAttributes.addressAddressLine2Id);
    },
    addressLine2Data() {
      return this.attributeLinkData[fixedAttributes.addressAddressLine2Id];
    },
    postalCodeAttribute() {
      return this.getAttribute(fixedAttributes.addressPostalCodeId);
    },
    postalCodeData() {
      return this.attributeLinkData[fixedAttributes.addressPostalCodeId];
    },
    cityAttribute() {
      return this.getAttribute(fixedAttributes.addressCityId);
    },
    cityData() {
      return this.attributeLinkData[fixedAttributes.addressCityId];
    },
    stateAttribute() {
      return this.getAttribute(fixedAttributes.addressStateId);
    },
    stateData() {
      return this.attributeLinkData[fixedAttributes.addressStateId];
    },
    latitudeData() {
      return this.attributeLinkData[fixedFieldAttributes.addressLatitudeId];
    },
    longitudeData() {
      return this.attributeLinkData[fixedFieldAttributes.addressLongitudeId];
    },
    zipAndCityLabel() {
      const zipName = this.postalCodeAttribute?.name;
      const cityName = this.cityAttribute?.name;

      return `${zipName} / ${cityName}`;
    },
    googleMapOptions() {
      return {
        styles: mapTheme,
        gestureHandling: "none",
        streetViewControl: false,
        mapTypeControl: false,
        minZoom: 3,
      };
    },
    circleCoords() {
      if (!this.latitudeData || !this.latitudeData) return null;

      return { lat: this.latitudeData, lng: this.longitudeData };
    },
    circleRadius() {
      return Math.round(600000 / Math.pow(2, this.zoomLevel));
    },
    centerCoords() {
      if (this.circleCoords) return this.circleCoords;

      // Center of Europe
      return { lat: 48.03677329220678, lng: 10.170877850017007 };
    },
    defaultZoomLevel() {
      return this.circleCoords ? 6 : 4;
    },
    zoomLevel: {
      get() {
        return this.manualZoomLevel ?? this.defaultZoomLevel;
      },
      set(value) {
        this.manualZoomLevel = value;
      },
    },
  },
  methods: {
    getAttribute(attributeId) {
      return this.attributes?.find((x) => x.id === attributeId) ?? null;
    },
    updateData(attributeId, value) {
      this.$emit("attribute_link_data_changed", attributeId, value);
    },
    updateStringValue(attribute, value) {
      this.$emit("string_changed", value, attribute);
    },
  },
};
</script>
