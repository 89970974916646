<template>
  <div>
    <h4 class="text-h4">
      {{ $t("pages.documentRequest.confirmation.title") }}
    </h4>
    <v-spacer class="mb-4"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col col="12" lg="8">
          <v-card elevation="1">
            <v-card-text>
              <div v-if="responseType === 'Accept'" class="text-center">
                <v-icon class="text-h2" color="green darken-2"
                  >mdi-check-circle-outline</v-icon
                >
              </div>
              <div v-else class="text-center">
                <v-icon class="text-h2" color="red darken-2"
                  >mdi-minus-circle-outline</v-icon
                >
              </div>
              <p class="text-h4 text--primary text-center">
                {{
                  responseType === "Accept"
                    ? $t("pages.documentRequest.confirmation.requestAccepted")
                    : $t("pages.documentRequest.confirmation.requestRejected")
                }}
              </p>
              <p v-if="responseType === 'Accept'" class="text-body-1">
                {{ $t("pages.documentRequest.confirmation.nowAccepted") }}
              </p>
              <p v-else class="text-body-1">
                {{ $t("pages.documentRequest.confirmation.nowRejected") }}
              </p>
              <p class="text-body-1">
                <b>{{
                  $t("pages.documentRequest.confirmation.primeUserMail")
                }}</b>
                {{ contactEmail }}
              </p>
              <p class="text-body-1">
                {{ $t("pages.documentRequest.confirmation.closeWindow") }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";

export default {
  layout: appLayout({ title: "pages.documentRequest.confirmation.title" }),
  props: {
    responseType: String,
    contactEmail: String,
  },
};
</script>
