<template>
  <v-dialog
    style="z-index: 20001"
    v-model="show"
    scrollable
    max-width="80vw"
    hide-overlay
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mb-2"
        height="3rem"
        text
        color="primary"
        @click="$emit('clear')"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-plus</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.attributeClasses.form.create") }}
          </p>
        </div>
      </v-btn>
    </template>
    <v-card height="70vh" class="base-background-color">
      <v-card-title>{{ modalTitle }}</v-card-title>
      <v-card-text style="height: 300px">
        <v-row class="black--text">
          <v-col cols="12" sm="5">
            <v-row dense>
              <v-col>
                <span>{{ $t("pages.attributeClasses.form.className") }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="form.name"
                  name="name"
                  solo
                  dense
                  type="text"
                  :disabled="attributeClass && attributeClass.fixed"
                  :error-messages="form.errors.name"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="black--text">
          <v-col cols="12" sm="5">
            <v-row dense>
              <v-col>
                <span>{{
                  $t("pages.attributeClasses.form.availableAttributes.label")
                }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  :placeholder="
                    $t(
                      'pages.attributeClasses.form.availableAttributes.placeholder'
                    )
                  "
                  v-model="availableAttributesSearch"
                  solo
                  dense
                  type="text"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <div class="solo-item-list-area py-2">
                  <span
                    :class="{
                      selected: isAttributeSelected(attribute),
                      disabled: isUnassignedAttributeDisabled(attribute),
                    }"
                    v-for="attribute in availableAttributes"
                    :key="attribute.id"
                    @click="selectedAttribute = attribute"
                  >
                    {{ attribute.name }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="2" align-self="center">
            <v-row>
              <v-col cols="6" sm="12">
                <v-row dense justify="center">
                  <v-btn
                    outlined
                    icon
                    color="blue darken-1"
                    text
                    @click="removeAttributeClass(selectedAttribute)"
                  >
                    <v-icon class="black--text">mdi-chevron-left</v-icon>
                  </v-btn>
                </v-row>
              </v-col>

              <v-col cols="6" sm="12">
                <v-row dense justify="center">
                  <v-btn
                    outlined
                    icon
                    color="blue darken-1"
                    text
                    @click="addAttributeClass(selectedAttribute)"
                  >
                    <v-icon class="black--text">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="5">
            <v-row dense>
              <v-col>
                <span>{{
                  $t("pages.attributeClasses.form.availableAttributes.label")
                }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  :placeholder="
                    $t(
                      'pages.attributeClasses.form.availableAttributes.placeholder'
                    )
                  "
                  v-model="selectedAttributesSearch"
                  solo
                  dense
                  type="text"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <div class="solo-item-list-area py-2">
                  <span
                    :class="{
                      selected: isAttributeSelected(attribute),
                      disabled: isAssignedAttributeDisabled(attribute),
                    }"
                    v-for="attribute in selectedAttributes"
                    :key="attribute.id"
                    @click="selectedAttribute = attribute"
                  >
                    {{ attribute.name }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row justify="end">
          <v-alert dense v-if="form.errors.alert" type="error" class="mb-0">
            {{ form.errors.alert }}
          </v-alert>
          <v-btn color="blue darken-1" text @click="show = false">
            <v-icon class="black--text">mdi-close</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.close") }}
            </p>
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveAttributeClass()"
            :disabled="loading"
          >
            <v-icon class="black--text">mdi-content-save-outline</v-icon>
            <p class="teal--text text--darken-4 mb-0">
              {{ $t("common.actions.save") }}
            </p>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    attributeClass: Object,
    dialog: Boolean,
    attributes: Array,
    attributeClassRoute: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    availableAttributes() {
      var search = this.availableAttributesSearch.toLowerCase();
      return this.attributes.filter((x) => {
        return (
          x.name.toLowerCase().includes(search) &&
          !this.form.attributes.some((y) => x.id == y.id)
        );
      });
    },
    selectedAttributes() {
      var search = this.selectedAttributesSearch.toLowerCase();
      return this.form.attributes.filter((x) => {
        return x.name.toLowerCase().includes(search);
      });
    },
    modalTitle() {
      if (!this.attributeClass?.id) {
        return this.$t("pages.attributeClasses.index.title");
      }

      return `#${this.attributeClass.referenceKey} ${this.attributeClass.name}`;
    },
  },
  data() {
    return {
      loading: false,
      form: this.$inertia.form({
        id: "",
        name: "",
        attributes: [],
      }),
      selectedAttributesSearch: "",
      availableAttributesSearch: "",
      selectedAttribute: null,
    };
  },
  watch: {
    attributeClass: function (attributeClass) {
      this.setForm(attributeClass);
    },
  },
  methods: {
    setForm(attributeClass) {
      this.selectedAttribute = null;
      if (attributeClass != null) {
        this.form.id = attributeClass.id;
        this.form.name = attributeClass.name;
        this.form.attributes = attributeClass.attributes ?? [];
      } else {
        this.form.id = "";
        this.form.name = "";
        this.form.attributes = [];
      }
    },
    removeAttributeClass(attribute) {
      this.form.attributes = this.form.attributes.filter(
        (x) => x.id != attribute.id
      );
    },
    addAttributeClass(attribute) {
      if (
        attribute.attributeClassId != null &&
        attribute.attributeClassId != this.form.id
      )
        return;
      this.form.attributes.push(attribute);
    },
    saveAttributeClass() {
      if (this.loading) return;
      this.loading = true;

      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
          attributes: this.form.attributes.map((x) => x.id),
        };

        return transformedData;
      });
      if (this.form.id) {
        preparedForm.put(
          this.route(
            `${this.attributeClassRoute}.update`,
            this.attributeClass.id
          ),
          {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
            },
            onFinish: () => {
              this.loading = false;
            },
          }
        );
      } else {
        preparedForm.post(this.route(`${this.attributeClassRoute}.store`), {
          preserveScroll: true,
          onSuccess: () => {
            this.show = false;
            this.setForm(null);
            this.$emit("clear");
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      }
    },
    isUnassignedAttributeDisabled(attribute) {
      return (
        attribute?.attributeClassId !== null &&
        attribute?.attributeClassId !== this.attributeClass?.id
      );
    },
    isAssignedAttributeDisabled(attribute) {
      return attribute?.organizationId === null;
    },
    isAttributeSelected(attribute) {
      return attribute?.id === this.selectedAttribute?.id;
    },
  },
};
</script>
<style scoped>
.radio-group-full-width >>> .v-input__control {
  width: 100% !important;
}
.solo-item-list-area {
  height: 30vh;
  overflow-y: auto;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 12px;
  background: #ffffff;
  background-origin: padding-box;
  background-clip: border-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.selected {
  background: #004d40;
  color: white;
}
.disabled {
  opacity: 0.3;
}
</style>
