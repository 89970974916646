<template>
  <v-list>
    <v-list-item-group v-model="selectedMenu" class="px-2">
      <v-list-group
        v-for="item in availableItems"
        :append-icon="null"
        :key="item.route"
        :href="
          item.filter != null
            ? route(item.route, calculateFilters(item.filter))
            : route(item.route)
        "
        class="v-list-item--dense"
        @click="visit(item)"
        :class="isOnRoute(item) ? 'highlighted' : ''"
      >
        <template v-slot:activator>
          <v-list-item-title :to="item.route">
            {{ item.title }}
            <span class="red-dot" v-if="shouldNotify(item)"></span>
          </v-list-item-title>
        </template>
        <v-list-item
          v-for="sub in item.subRoutes"
          :key="sub.route"
          v-inertia
          link
          dense
          active-class=""
          :href="
            sub.filter != null
              ? route(sub.route, calculateFilters(sub.filter))
              : route(sub.route)
          "
          :class="isOnRoute(sub) ? 'highlighted indented' : 'indented'"
        >
          <v-list-item-content class="black--text text--lighten-2">
            <v-list-item-title>
              {{ sub.title }}
              <span class="red-dot" v-if="shouldNotify(sub)"></span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  props: {
    url: String,
  },
  computed: {
    selectedMenu: {
      get() {
        return this.url;
      },
      set() {
        //redirects
      },
    },
    availableItems() {
      let items = this.items.filter(
        (i) =>
          !i.permission || this.user.claims.some((x) => x.type == i.permission)
      );
      items.forEach((item) => {
        if (item.subRoutes) {
          item.subRoutes = item.subRoutes.filter(
            (i) =>
              !i.permission ||
              this.user.claims.some((x) => x.type == i.permission)
          );
        }
      });
      return items;
    },
    user() {
      return this.$inertia.page.props.auth.user;
    },
  },
  methods: {
    calculateFilters(filterType) {
      var searchParams = new URLSearchParams(window.location.search);
      if (filterType == "filters") {
        return {
          filters: {
            organizationId:
              searchParams.get("organizationId") ??
              searchParams.get("filters[organizationId]"),
          },
        };
      }
      if (filterType == "flat") {
        return {
          organizationId:
            searchParams.get("organizationId") ??
            searchParams.get("filters[organizationId]"),
        };
      }
    },
    isOnRoute(item) {
      if (item.subRoutes != null) {
        return false;
      }
      if (this.$page.url.substr(1).toLowerCase() == "") {
        return item.route.split(".")[0] == "dashboard";
      }
      return this.$page.url
        .substr(1)
        .toLowerCase()
        .startsWith(item.route.split(".")[0]);
    },
    shouldNotify(item) {
      if (item.route === "support-cases.index") {
        return this.$page.props.notifications.isOpenSupportCasesPopulated;
      }

      return false;
    },
    visit(item) {
      setTimeout(() => {
        item.filter != null
          ? this.$inertia.get(
              this.route(item.route, this.calculateFilters(item.filter))
            )
          : this.$inertia.get(this.route(item.route));
      }, 150);
    },
  },
  data() {
    return {
      items: [
        {
          title: this.$t("components.menuItems.dashboard"),
          route: "dashboard.index",
        },
        // {
        //   title: this.$t("components.menuItems.organizations"),
        //   route: "organizations.index",
        // },
        {
          title: this.$t("components.menuItems.assetManagement.title"),
          filter: "flat",
          subRoutes: [
            {
              title: this.$t("components.menuItems.assetManagement.assets"),
              route: "assets.index",
              filter: "flat",
            },
            {
              title: this.$t(
                "components.menuItems.assetManagement.assetTemplates"
              ),
              route: "asset-templates.index",
              filter: "flat",
            },
            {
              title: this.$t("components.menuItems.assetManagement.attributes"),
              route: "asset-attributes.index",
              filter: "filters",
            },
            {
              title: this.$t("components.menuItems.assetManagement.classes"),
              route: "asset-attribute-classes.index",
              filter: "filters",
            },
            {
              title: this.$t("components.menuItems.assetManagement.portfolios"),
              route: "portfolios.index",
              filter: "filters",
            },
          ],
        },
        {
          title: this.$t("components.menuItems.solutionManagement.title"),
          route: "solutions.index",
          filter: "flat",
          subRoutes: [
            {
              title: this.$t(
                "components.menuItems.solutionManagement.solutions"
              ),
              route: "solutions.index",
              filter: "flat",
            },
            {
              title: this.$t(
                "components.menuItems.solutionManagement.solutionTemplates"
              ),
              route: "solution-templates.index",
              filter: "filters",
            },
            {
              title: this.$t(
                "components.menuItems.solutionManagement.attributes"
              ),
              route: "solution-attributes.index",
            },
            {
              title: this.$t("components.menuItems.solutionManagement.classes"),
              route: "solution-attribute-classes.index",
              filter: "filters",
            },
          ],
        },
        {
          title: this.$t("components.menuItems.locationManagement.title"),
          route: "locations.index",
          filter: "flat",
          subRoutes: [
            {
              title: this.$t(
                "components.menuItems.locationManagement.locations"
              ),
              route: "locations.index",
              filter: "flat",
            },
            {
              title: this.$t(
                "components.menuItems.locationManagement.attributes"
              ),
              route: "location-attributes.index",
            },
            {
              title: this.$t("components.menuItems.locationManagement.classes"),
              route: "location-attribute-classes.index",
              filter: "filters",
            },
          ],
        },
        /* {
          title: this.$t("components.menuItems.files"),
          route: "files.index",
          permission: "Files",
        }, */
        {
          title: this.$t("components.menuItems.userManagement.title"),
          permission: "Users",
          subRoutes: [
            {
              title: this.$t("components.menuItems.userManagement.users"),
              route: "users.index",
            },
            {
              title: this.$t("components.menuItems.userManagement.roles"),
              route: "roles.index",
            },
            {
              title: this.$t("components.menuItems.userManagement.permissions"),
              route: "permissions.index",
            },
          ],
        },
        {
          title: this.$t("components.menuItems.supportCases"),
          route: "support-cases.index",
          permission: "SupportModule",
        },
        {
          title: this.$t("components.menuItems.settings"),
          route: "settings.index",
          permission: "BackofficeSettings",
        },
        {
          title: this.$t("components.menuItems.logs"),
          route: "logs.index",
          permission: "BackofficeLogs",
        },
      ],
    };
  },
};
</script>
<style scoped>
.highlighted {
  background: white;
  border-radius: 5px;
}

.indented {
  margin-left: 1rem;
}

.red-dot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  background-color: red;
  top: 7px;
  right: 19px;
  width: 10px;
  height: 10px;
}

.v-list-item--dense >>> .v-list-group__header {
  min-height: 40px;
}
</style>
