import { app, plugin, Link } from "@inertiajs/inertia-vue";
import { InertiaProgress } from "@inertiajs/progress/src";
import Vue from "vue";
import vuetify from "./plugins/vuetify";
import directives from "./directives";
import draggable from "vuedraggable";
import VTooltip from "v-tooltip";
import { MazPhoneNumberInput } from "maz-ui";
import GmapVue from "gmap-vue";

import "@/assets/css/common.css";
import "@/assets/css/tooltip.css";
import "@/assets/scss/font.scss";

import i18n from "@/i18n.js";

Vue.config.productionTip = true;

Vue.mixin({
  methods: {
    route: (name, params, absolute, config) =>
      window.route(name, params, absolute, config) + "",
    routeRaw: window.route,
  },
});
Vue.use(plugin);
Vue.use(VTooltip);

Vue.component("vue-draggable", draggable);
Vue.component("inertia-link", Link);
Vue.use(MazPhoneNumberInput);

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_MAPS_KEY,
  },
  installComponents: true,
  dynamicLoad: false,
});

Object.entries(directives).forEach(([name, directive]) => {
  Vue.directive(name, directive);
});

InertiaProgress.init();

const el = document.getElementById("app");

new Vue({
  vuetify,
  i18n,

  render: (h) =>
    h(app, {
      props: {
        initialPage: JSON.parse(el.dataset.page),
        resolveComponent: (name) => require(`./Pages/${name}`).default,
      },
    }),
}).$mount(el);
