import menuItems from "./menuItems.json";
import layout from "./layout.json";
import notificationMenu from "./notificationMenu.json";
import requestSizeError from "./requestSizeError.json";
import fileMenu from "./fileMenu.json";
import contents from "./contents.json";
import assetDialog from "./assets/dialog.json";
import assetPreview from "./assets/preview.json";
import attributeActionMenu from "./attributes/actionMenu.json";
import attributeChooseAttributes from "./attributes/chooseAttributes.json";
import attributeFilters from "./attributes/filters.json";
import attributeFilterStatusLabels from "./attributes/filterStatusLabels.json";
import createSolutions from "./createSolutions.json";
import layoutOptions from "./layouts/options.json";
import supportCaseActionMenu from "./supportCases/actionMenu.json";
import supportCaseAssign from "./supportCases/assign.json";
import supportCaseAssignEmail from "./supportCases/assignEmail.json";
import supportCaseAssignRelatedCases from "./supportCases/assignRelatedCases.json";
import supportCaseConversations from "./supportCases/conversations.json";
import supportCaseList from "./supportCases/list.json";
import supportCaseReminder from "./supportCases/reminder.json";
import supportCaseSettings from "./supportCases/settings.json";
import supportCaseSidebar from "./supportCases/sidebar.json";
import mentionInput from "./mentionInput.json";

export default {
  menuItems,
  layout,
  notificationMenu,
  requestSizeError,
  fileMenu,
  contents,
  assets: {
    dialog: assetDialog,
    preview: assetPreview,
  },
  attributes: {
    actionMenu: attributeActionMenu,
    chooseAttributes: attributeChooseAttributes,
    filters: attributeFilters,
    filterStatusLabels: attributeFilterStatusLabels,
  },
  createSolutions,
  layouts: {
    options: layoutOptions,
  },
  supportCases: {
    actionMenu: supportCaseActionMenu,
    assign: supportCaseAssign,
    assignMenu: supportCaseAssignEmail,
    assignRelatedCases: supportCaseAssignRelatedCases,
    conversations: supportCaseConversations,
    list: supportCaseList,
    reminder: supportCaseReminder,
    settings: supportCaseSettings,
    sidebar: supportCaseSidebar,
  },
  mentionInput,
};
