<template>
  <v-autocomplete
    v-model="organizationId"
    :items="auth.organizations"
    item-text="name"
    item-value="id"
    solo
    dense
    hide-details
    :dark="false"
    class="organization-menu"
  ></v-autocomplete>
</template>
<script>
import { serialize } from "object-to-formdata";
import { EventBus } from "@/eventBus";

export default {
  methods: {
    changeCurrentOrganization(organizationId) {
      fetch(this.route("users.current.organizations.current.update"), {
        method: "PUT",
        body: serialize({ organizationId }),
      }).then(() => {
        this.$inertia.get(
          this.route("dashboard.index"),
          {},
          {
            onSuccess: () => {
              EventBus.$emit("organizationChanged");
            },
          }
        );
      });
    },
  },
  computed: {
    auth() {
      return this.$inertia.page.props.auth;
    },
    organizationId: {
      get() {
        return this.auth.organizationId;
      },
      set(organizationId) {
        if (!organizationId) return;

        this.changeCurrentOrganization(organizationId);
      },
    },
  },
};
</script>
<style scoped>
.organization-menu,
.organization-menu:deep(.v-icon),
.organization-menu:deep(input) {
  color: #000 !important;
  caret-color: #000 !important;
}

.organization-menu:deep(.v-input__slot) {
  background: #fff !important;
}
</style>
