// File types blocked by gmail: https://support.google.com/mail/answer/6590?hl=en#zippy=%2Cmessages-that-have-attachments
export default [
  "ade",
  "adp",
  "apk",
  "appx",
  "appxbundle",
  "bat",
  "cab",
  "chm",
  "cmd",
  "com",
  "cpl",
  "dll",
  "dmg",
  "ex",
  "ex_",
  "exe",
  "hta",
  "ins",
  "isp",
  "iso",
  "jar",
  "js",
  "jse",
  "lib",
  "lnk",
  "mde",
  "msc",
  "msi",
  "msix",
  "msixbundle",
  "msp",
  "mst",
  "nsh",
  "pif",
  "ps1",
  "scr",
  "sct",
  "shb",
  "sys",
  "vb",
  "vbe",
  "vbs",
  "vxd",
  "wsc",
  "wsf",
  "wsh",
];
