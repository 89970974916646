<template>
  <v-alert
    v-if="!!errors[''] && errors[''].includes('Request body too large')"
    dense
    type="error"
    class="mb-0 w-full"
  >
    {{ $t("components.requestSizeError.uploadFailed") }}
  </v-alert>
</template>
<script>
export default {
  props: {
    errors: Object,
  },
};
</script>
<style scoped>
.w-full {
  width: 100%;
}
</style>
