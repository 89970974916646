var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t("pages.files.edit.title"))+" "+_vm._s(_vm.file.name))]),_c('v-spacer',{staticClass:"mb-6"}),_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"8","lg":"6"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.form.put(_vm.route('files.update', _vm.file.id), {
              preserveScroll: true,
              preserveState: false,
            })}}},[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("common.fieldNames.name")))])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"name":"name","type":"text","error-messages":_vm.form.errors.name},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("pages.files.edit.file")))])],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.file)?_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.route('api.files.content', {
                        id: _vm.item.id,
                        filename: _vm.item.originalName,
                      }),"target":"_blank"}},[_c('v-icon',[_vm._v(" mdi-attachment")]),_vm._v(" "+_vm._s(_vm.file.name)+_vm._s(_vm.file.extension)+" ")],1):_vm._e()])],1),_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("pages.files.edit.originalName")))])],1),_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.file.originalName)+" ")])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("pages.files.edit.lastUpdated")))])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"name":"updatedAt","disabled":"","type":"text"},model:{value:(_vm.updatedAt),callback:function ($$v) {_vm.updatedAt=$$v},expression:"updatedAt"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("common.fieldNames.createdAt")))])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"name":"createdAt","disabled":"","type":"text"},model:{value:(_vm.createdAt),callback:function ($$v) {_vm.createdAt=$$v},expression:"createdAt"}})],1)],1)],1),_c('v-card-actions',[_c('flash-messages'),_c('v-btn',{attrs:{"text":"","color":"error","disabled":_vm.sending || _vm.form.processing},on:{"click":_vm.destroy}},[_vm._v(_vm._s(_vm.$t("common.actions.delete")))]),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","outlined":"","color":"primary","disabled":_vm.sending || _vm.form.sending}},[_vm._v(_vm._s(_vm.$t("pages.files.edit.save")))])],1)],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }